import React from "react";
import { useTranslate } from "react-admin";

function PasswordStrengthComp(isStrong) {
  let style = {
    fontSize: 11,
    margin: "10px",
    color: "red"
  };

  const translate = useTranslate();

  const translatedText = translate(
    "application.profile.fields.password_verification"
  );

  const splitTranslatedText = translatedText.split("\n").map((item, i) => {
    return <p key={i}>{item}</p>;
  });

  return (
    <div style={style}>{isStrong.isStrong ? <p></p> : splitTranslatedText}</div>
  );
}
export default PasswordStrengthComp;

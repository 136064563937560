/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
 const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

/**
 * For projects update only, convert uploaded image in base 64 and attach it to
 * the `attachment` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {
    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'projects') {
        // notice that following condition can be true only when `<ImageInput source="Attachments" />` component has parameter `multiple={true}`
        // if parameter `multiple` is false, then data.attachments is not an array, but single object
        if (params.data.projectAttachments && params.data.projectAttachments.length) {
            // only freshly dropped Attachments are instance of File
            const formerAttachments = params.data.projectAttachments.filter(attachment => !(attachment.rawFile instanceof File));
            const newAttachments = params.data.projectAttachments.filter(attachment => attachment.rawFile instanceof File);

            return Promise.all(newAttachments.map(convertFileToBase64))
                .then(base64Attachments => base64Attachments.map((attachment64, index) => ({
                    source: attachment64,
                    title: `${newAttachments[index].title}`,
                    fileSize: `${newAttachments[index].rawFile.size}`,
                })))
                .then(transformedNewAttachments => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        projectAttachments: [...transformedNewAttachments, ...formerAttachments],
                    },
                }));
        }
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default addUploadFeature;
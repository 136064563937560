import React, { Component, Fragment } from 'react';
import { BooleanInput, useTranslate, FormDataConsumer, SelectInput, showNotification, required, FunctionField, TextInput, Button as CustomButton, SimpleForm, Toolbar, SaveButton, CheckboxGroupInput, usePermissions, ReferenceArrayField, EmailField, BooleanField, SelectField, DateField, Datagrid, ReferenceManyField, ArrayField, SingleFieldList, ChipField, Labeled, Show, SimpleShowLayout, ReferenceField, TextField, RichTextField, EditButton, ListButton, CardActions, translate } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { useForm } from 'react-final-form';

import { Form, Field } from "react-final-form";

import { Select, MenuItem, Card, CardHeader, CardContent, TablePagination, Grid, List, ListItem, RadioGroup, Radio, FormControlLabel, ListItemAvatar, Avatar, Stepper, Step, StepLabel, ListItemIcon, ListItemText, Typography, Chip, Icon, IconButton, Tooltip, Button, Checkbox } from "@material-ui/core";

import UpdateIcon from '@material-ui/icons/Update';
import CheckIcon from '@material-ui/icons/CheckCircle';
import MinusIcon from '@material-ui/icons/Remove';
import PlusIcon from '@material-ui/icons/Add';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { withStyles } from '@material-ui/core';
import AcceptButton from './AcceptButton';
import DeclineButton from './DeclineButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Link as ComponentLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import InfoIcon from '@material-ui/icons/Info';

import LinkIcon from '@material-ui/icons/LaunchOutlined';

import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';

import HourglassFullIcon from '@material-ui/icons/HourglassEmpty';
import CancelButton from './CancelButton';
import ScoreDetails from './ScoreDetails';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

import { getQueryParameters } from '../utilities';

import './style.css';
import CheckboxInput from '../component/CheckboxInput';
import axios from 'axios';

import { Matrix } from './Matrix';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import 'date-fns';
import { SuggestionMessage } from './SuggestionMessage';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Divider from '@material-ui/core/Divider';
import MoreInformationButton from './MoreInformationButton';

import { PreviewMessage } from './PreviewMessage';

const useStyles = makeStyles(theme => ({
    toolbar: {
        background:'none',
        paddingTop:'10px',
        flex: '0 0 auto',
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    button:{
        marginRight:'10px'
    }
}));

const styles = {
    greenIcon: {
        fontSize: 50,
        color: '#c5e1a5',
    },
    orangeIcon: {
        fontSize: 50,
        color: '#ffb74d',
    },
    blueIcon: {
        fontSize: 50,
        color: '#39a1de',
    },
    websiteIcon: {
        fontSize: 12,
        // top: 2,
        // position: 'relative',
        color: '#777777'
    },
    websiteLink: {
        fontSize: 11,
        color: '#39a1de',
        textDecoration: 'none !important',
        border: '1px solid',
        borderRadius: 5,
        padding: 2,
        position: 'relative',
        top: '-1px',
        left: 7,
    },
    redIcon: {
        fontSize: 50,
        color: '#d50000',
    },
    button: {
        minWidth: 110, 
        marginLeft: 16, 
        marginRight: 16,
    },
    selectMenu: {
        padding: 0,
        paddingLeft: 5,
        paddingRight: 18,
    },
    root: {
        fontSize: '0.75rem',
    },
    icon: {
        right: '-3px',
    },

    label: {
        fontWeight: 'bold',
        fontSize: '1.15em',
        textDecoration: 'underline'
        // marginBottom: '-6px'
    },
    value: {
        padding: 0,
        // marginBottom: '-12px'
    }
};

const CustomDateTimeInput = (props) => {

    const { source, label } = props;

    const [selectedDate, setSelectedDate] = React.useState(null);
    const form = useForm();

    const translate = useTranslate();

    const handleOnChange = (data) => {
        // console.log('source: ', source)
        form.change(source, data);
        setSelectedDate(data);
    }

    // Replace: load defaults from the server.
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* <KeyboardDatePicker
                margin="normal"
                id="foundedAt"
                source="foundedAt"
                name="foundedAt"
                label={foundedAtMessage}
                format="yyyy-MM-dd"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            /> */}
            <KeyboardDateTimePicker 
                variant="dialog"
                label={translate(label)}
                disablePast={true}
                value={selectedDate}
                onChange={handleOnChange}
                source={source}
                name={source}
                id={source}
            />
            <p id={`${source}-error`} style={{ color: 'red', fontSize: '0.75rem', display: 'none' }}>{translate('ra.validation.required')}</p>
        </MuiPickersUtilsProvider>
    )
};

const Actions = ({ suggestion, refreshMessages, refreshProject }) => {
    const { permissions } = usePermissions();
    // console.log('permissions: ', permissions)
    
    return ( 
        
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px'}}>
            {permissions && permissions.includes('ROLE_CONSUMER') ? 
                renderActions(suggestion, refreshMessages, refreshProject)
                :
                null
            }
        </div>
    )
}

const renderActions = (suggestion, refreshMessages, refreshProject) => {
    switch (suggestion.consumerStatus) {
        case 'PENDING':
            return (
                <Fragment>
                    <DeclineButton refreshProject={refreshProject} refreshMessages={refreshMessages} record={suggestion} />
                    <MoreInformationButton refreshMessages={refreshMessages} record={suggestion} />
                    <AcceptButton refreshProject={refreshProject} refreshMessages={refreshMessages} record={suggestion} />
                </Fragment>
            )
        case 'ACCEPTED':
        case 'DECLINED':
        case 'SELF_SERVICE':
        default:
            if (suggestion.providerStatus === 'APPROVED' || suggestion.providerStatus === 'DECLINED') {
                return <MoreInformationButton refreshMessages={refreshMessages} record={suggestion} />;
            }
            return (
                <Fragment>
                    <CancelButton record={suggestion} />
                    <MoreInformationButton refreshMessages={refreshMessages} record={suggestion} />
                </Fragment>
            )
    }
}

const highlightFoundKeyPhrases = (text, searchResults) => {

    function isBlank(string) {
        return (!string || /^\s*$/.test(string));
    }

    var keyPhrases = searchResults
            .filter(searchResult => searchResult.found && !isBlank(searchResult.foundInitialValue))
            .map(searchResult => searchResult.foundInitialValue)
            .map(foundInitialValue => foundInitialValue.trim());

    var pattern = new RegExp(keyPhrases.join("|"), "gi");
    
    text = text.replace(pattern, function(value) {

        if (isBlank(value)) {
            return value;
        }

        // console.log('value: ', value)

        var searchResult = searchResults
                .find(searchResult => !isBlank(searchResult.foundInitialValue) && searchResult.foundInitialValue.toLowerCase().trim() == value.toLowerCase().trim());
        var color = null;
        if (searchResult.foundKeyWeight == 1) {
            color = '#daecc6';
        } else if (searchResult.foundKeyWeight == 0.5) {
            color = '#ffe0b3';
        }

        return '<span style="padding: 0px 0px 2px 0px; border-radius: 3px; background-color: ' + color + '">' + value.trim() + '</span>';
    });

    // console.log('text: ', text)

    return text;
}

class Suggestions  extends Component {

    constructor(props) {
        super(props);

        this.state = {
            viewMode: 'DESCRIPTION',
            fullView: false,
            totalItems: 0,
            isMessageVisible: false,
            message: '',
            isDialogOneVisible: false,
            isDialogTwoVisible: false,
            language: localStorage.getItem('language') && localStorage.getItem('language') !== 'undefined' ? localStorage.getItem('language').toUpperCase() : 'EN',
            pdfDisabled: false,
            loading: false,
            userId: JSON.parse(localStorage.getItem("decoded_token")).user_name,
            messagesHidden: false
        }
    }

    changeViewMode = (newViewMode) => {
        this.setState({ viewMode: newViewMode });
    }

    componentDidMount() {
        const { record } = this.props;

        // this.setState({
        //     language: localStorage.getItem('language') ? localStorage.getItem('language').toUpperCase() : 'EN',
        // })

        if (record.suggestions && record.suggestions.length > 0) {
            
            let selectedItem = 0;

            const parameters = getQueryParameters();
            if (parameters['suggestion']) {
                // console.log('has');
                for (let index = 0; index < record.suggestions.length; index++) {
                    const element = record.suggestions[index];
                    // console.log('element: ', element);
                    // console.log('suggestion: ', parameters['suggestion']);
                    if (element.id === parameters['suggestion']) {
                        console.log('found');
                        selectedItem = index;
                        break;
                    }
                }
            }

            this.setState({ 
                selectedItem: selectedItem, 
                totalItems: record.suggestions.length,
            });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.record.suggestions.length > this.state.totalItems) {
            this.setState({ 
                selectedItem: 0, 
                totalItems: nextProps.record.suggestions.length,
            });
        }
    }

    componentDidUpdate(prevProps) {

        if (!prevProps.record.suggestions && this.props.record.suggestions) {
            const { record } = this.props;

            // console.log('record updated: ', record);

            this.setState({
                language: localStorage.getItem('language') ? localStorage.getItem('language').toUpperCase() : 'EN',
            })

            if (record.suggestions && record.suggestions.length > 0) {
                this.setState({ 
                    selectedItem: 0, 
                    totalItems: record.suggestions.length,
                });
            }
        }
    }

    selectItem = event => {
        this.setState({ selectedItem: event.target.value });
    };

    toggleFullView = () => {
        this.setState({ fullView: !this.state.fullView });
    };

    handlePageChange = (event, page) => {
        this.setState({ selectedItem: page });
    }

    handleLanguageChange = (event) => {
        this.setState({ language: event.target.value });
    }

    refreshMessages = () => {
        this.setState({ messagesHidden: true }, () => this.setState({ messagesHidden: false }));
    }

    validateDialogOne = (values) => {

        const errors = {};

        if (!values.userChoices || !values.userChoices.find(choice => choice === 'companyShared') || !values.userChoices.find(choice => choice === 'emailShared')) {
            errors.userChoices = [this.props.translate('resources.suggestions.validation.message_1')];
        }
        // else if (!values.userChoices || !values.userChoices.find(choice => choice === 'phoneShared' || choice === 'mobilePhoneShared')) {
        //     errors.userChoices = [this.props.translate('resources.suggestions.validation.message_2')];
        // }
        
        return errors;
    }

    validateDialogTwo = (values) => {

        const errors = {};

        if (!values.favouredDate) {
            // errors.favouredDate = [this.props.translate('ra.validation.required')];
        }
        
        return errors;
    }

    submitDialogOne = (values, suggestion) => {

        const { showNotification } = this.props;

        console.log('values: ', values);

        const contact = {
            message: values.userMessage,
            type: values.type,
        };

        values.userChoices.forEach(choice => {

            switch (choice) {
                case 'companyShared':
                    contact.companyShared = true;
                    break;
                case 'positionShared':
                    contact.positionShared = true;
                    break;
                case 'emailShared':
                    contact.emailShared = true;
                    break;
                case 'phoneShared':
                    contact.phoneShared = true;
                    break;
                case 'mobilePhoneShared':
                    contact.mobilePhoneShared = true;
                    break;
                default:
                    break;
            }
        })

        let url = process.env.REACT_APP_API_URL + "/suggestions/" + values.id + "/contacts";
        const token = localStorage.getItem('token');

        const setLoading = (value) => {
            this.setState({ loading: value });
        }

        const hideDialogOne = () => {
            this.setState({ isDialogOneVisible: false })
        }
    
        // setLoading(true);

        fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
                    body: JSON.stringify(contact),

                })
                .then(result => result.json())
                .then(response => {
                    suggestion.consumerContacts = response.consumerContacts;
                    showNotification('resources.suggestions.notification.shared_success');
                })
                .catch(error => {
                    showNotification(
                        typeof error === 'string'
                            ? error
                            : typeof error === 'undefined' || !error.message
                            ? 'ra.notification.http_error'
                            : error.message,
                        'warning'
                    );
                })
                .finally(function () {
                    hideDialogOne();
                    // setLoading(false);
                });
    }

    submitDialogTwo = (values, suggestion) => {

        const { showNotification } = this.props;
        
        console.log('values: ', values)

        if (!values.favouredDate) {
            document.getElementById('favouredDate-error').style.display = 'block';
            return;
        } else {
            document.getElementById('favouredDate-error').style.display = 'none';
        }
        
        let url = process.env.REACT_APP_API_URL + "/suggestions/" + values.id + "/appointment";
        const token = localStorage.getItem('token');

        const hideDialogTwo = () => {
            this.setState({ isDialogTwoVisible: false })
        }
    
        // setLoading(true);

        fetch(url, {
                    method: "POST",
                    headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
                    body: JSON.stringify(values),

                })
                .then(result => result.json())
                .then(response => {
                    suggestion.appointment = response.appointment;
                    showNotification('resources.suggestions.notification.appointment_created_success');
                })
                .catch(error => {
                    showNotification(
                        typeof error === 'string'
                            ? error
                            : typeof error === 'undefined' || !error.message
                            ? 'ra.notification.http_error'
                            : error.message,
                        'warning'
                    );
                })
                .finally(function () {
                    hideDialogTwo();
                    // setLoading(false);
                });

    }

    downloadPdf = (data) => {

        console.log('data: ', data);

        let url = process.env.REACT_APP_API_URL + "/suggestions/pdf/" + data.id + "?language=" + this.state.language;
        const token = localStorage.getItem('token');

        const setPdfDisabled = (value) => {
            this.setState({ pdfDisabled: value });
        }
    
        setPdfDisabled(true);

        axios({
            url: url,
            method: 'GET',
            headers: { 'Content-Type': 'application/octet-stream', 'Authorization': `Bearer ${token}` },
            responseType: 'blob', // important
        }).then((response) => {            
            let fileName = "product.pdf";
            try {
                //
                // try reading the filename from the content disposition attribute
                [, fileName] = response.headers['content-disposition'].split('filename=');            
                
                if (fileName.indexOf('"') > -1)
                  fileName = fileName.substring(1, fileName.length-1);
            } catch (error) {
                console.error(error);                

                const neutralName = data.descriptions.find(description => description.languageCode === this.state.language).neutralName;
                fileName = neutralName + ".pdf";
            }

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        }).catch((error) => {

        }).finally(function () {
            setPdfDisabled(false);
        });
    }

    render() {

        const { classes, record, basePath, translate, refreshProject } = this.props;
        const { totalItems, selectedItem, fullView, language, isMessageVisible, message, isDialogOneVisible, isDialogTwoVisible, viewMode } = this.state;

        // console.log('record: ', record);
        const projectLanguage = record.language;

        const validationMessage = translate('resources.suggestions.dialog_two.validation.message_1');

        const CustomRichTextField = ({ record, label, suggestion, ...rest }) => {

            return record.descriptions
                    .filter(description => description.languageCode === language)
                    .map((description, index) => {

                        // Object.keys(description).forEach(key => {

                        //     if (typeof description[key] === 'string' || description[key] instanceof String) {
                        //         description[key] = highlightFoundKeyPhrases(description[key], suggestion.searchResults);
                        //     }
                        // });

                        return (
                            <Labeled key={index} label={label} fullWidth={true} classes={classes}>
                                <RichTextField record={description} {...rest} />
                            </Labeled>
                        )
                    });
        }


        const CustomRichTextFieldNoLabel = ({ record, ...rest }) => {
            return record.descriptions
                    .filter(description => description.languageCode === language)
                    .map((description, index) => 
                        <RichTextField key={index} record={description} {...rest} />
                    );
        }

        const CustomTextField = ({ record, source, ...rest }) => {
            return record.descriptions
                    .filter(description => description.languageCode === language)
                    .map((description, index) => <Typography display="inline" style={{ fontWeight: 'bold', color: '#39a1de' }} variant="button" key={index}>{description[source]}</Typography>);
        }
        CustomTextField.defaultProps = { addLabel: true };

        const CustomSelect = ({ basePath, record, label }) => {
            return (
                <Select
                    label={label}
                    style={{ padding: '0px' }}
                    value={language}
                    variant="outlined"
                    onChange={this.handleLanguageChange}
                    classes={classes}
                >
                    <MenuItem style={{ padding: '0px' }} value={'EN'}>EN</MenuItem>
                    <MenuItem style={{ padding: '0px' }} value={'DE'}>DE</MenuItem>
                </Select>
            )
        }
        CustomSelect.defaultProps = { addLabel: true };

        const ProductDescription = props => (
            <Fragment>
                <CustomRichTextField {...props} label="resources.products.fields.challenge" source="challenge" />
                <CustomRichTextField {...props} label="resources.products.fields.advantage" source="advantage" />
                <CustomRichTextField {...props} label="resources.products.fields.solution" source="solution" />
                <CustomRichTextField {...props} label="resources.products.fields.feature" source="feature" />
            </Fragment>
        )

        const userChoices = [
            { id: 'companyShared', name: 'application.menu.company_information' },
            { id: 'positionShared', name: 'resources.users.fields.position' },
            { id: 'emailShared', name: 'resources.users.fields.email' },
            { id: 'phoneShared', name: 'resources.users.fields.phone' },
            { id: 'mobilePhoneShared', name: 'resources.users.fields.mobilePhone' },
        ];

        const connectionOptions = [
            { id: 'WEBSESSION', name: 'resources.suggestions.dialog_two.field_values.preferredMedium.option_1' },
            { id: 'PHONE_CALL', name: 'resources.suggestions.dialog_two.field_values.preferredMedium.option_2' },
            { id: 'OTHER', name: 'resources.suggestions.dialog_two.field_values.preferredMedium.option_3' },
        ];

        const FullNameField = ({ record = {} }) => record ? <span>{record.firstName} {record.lastName}</span> : null;

        const renderUser = (value) => {
            return <div>
                <SelectField style={{ display: 'inline-block', marginRight: 10, color: '#92316a', fontSize: '1.25rem' }} record={value} source="salutation" choices={[
                    { id: 'MALE', name: 'resources.users.field_values.salutation.male' },
                    { id: 'FEMALE', name: 'resources.users.field_values.salutation.female' },
                ]} />
                <Typography style={{ display: 'inline-block', color: '#92316a'}} variant="h6">{value.firstName} {value.lastName}</Typography>
            </div>
        }

        const CurrentUser = (props) => {

            const record = { user_name: JSON.parse(localStorage.getItem('decoded_token')).user_name };

            return record.user_name && (
                <ReferenceField link={false} label="" source="user_name" reference="users" basePath="projects" record={record}>
                    <FunctionField label="Name" render={record => renderUser(record)} />
                </ReferenceField>
            )
        }

        const EmptyIcon = () => {
            return null;
        }

        const DialogOneToolbar = (props) => {
            const classes = useStyles();

            return (
                <Toolbar className={classes.toolbar} {...props} >
                    <Button startIcon={<CancelIcon />} onClick={()=> this.setState({ isDialogOneVisible: false })}>
                        {translate("ra.action.cancel")}
                    </Button>

                    <SaveButton
                        icon={<EmptyIcon />}
                        label="resources.suggestions.dialog_one.action_1"
                        style={{ backgroundColor: '#39a1de' }}
                        // disabled={this.state.loading}
                    />
                </Toolbar>
            )
        }

        const DialogTwoToolbar = (props) => {
            const classes = useStyles();

            return (
                <Toolbar className={classes.toolbar} {...props} >
                    <Button startIcon={<CancelIcon />} onClick={()=> this.setState({ isDialogTwoVisible: false })}>
                        {translate("ra.action.cancel")}
                    </Button>

                    <SaveButton
                        icon={<EmptyIcon />}
                        label="resources.suggestions.dialog_two.action_1"
                        style={{ backgroundColor: '#39a1de' }}
                        // disabled={props.loading}
                    />
                </Toolbar>
            )
        }


        const UserCard = (props) => {

            const { record, suggestion, options } = props;
        
            const translate = useTranslate();
        
            return (
        
                <Card style={{ minHeight: 252 }}>
                                        
                    <CardContent>
                        <div style={{ textAlign: 'center' }}>
                            <SelectField record={record} style={{ display: 'inline-block', marginRight: 10, color: '#92316a', fontSize: '1.25rem' }} source="salutation" choices={[
                                { id: 'MALE', name: 'resources.users.field_values.salutation.male' },
                                { id: 'FEMALE', name: 'resources.users.field_values.salutation.female' },
                            ]} />
                            <Typography style={{ display: 'inline-block', color: '#92316a'}} variant="h6">{record.firstName} {record.lastName}</Typography>
                        </div>
                        
                        <Grid container spacing={2} style={{ marginTop: 1 }}>
                            {options.positionShared && 
                                <Fragment>
                                    <Grid item md={5} xs={12}>
                                        <span style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>{translate('resources.users.fields.position')}:</span>
                                    </Grid>
                                    <Grid item md={7} xs={12}>
                                        <TextField record={record} label="Position" source="position" />
                                    </Grid>
                                </Fragment>
                            }
                            {options.emailShared &&
                                 <Fragment>
                                     <Grid item md={5} xs={12}>
                                        <span style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>{translate('resources.users.fields.email')}:</span>
                                    </Grid>
                                    <Grid item md={7} xs={12}>
                                        <EmailField record={record} source="email" />
                                    </Grid>
                                 </Fragment>
                            }
                            {options.phoneShared &&
                                 <Fragment>
                                     <Grid item md={5} xs={12}>
                                        <span style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>{translate('resources.users.fields.phone')}:</span>
                                    </Grid>
                                    <Grid item md={7} xs={12}>
                                        <TextField record={record} source="phone" />
                                    </Grid>
                                 </Fragment>
                            }
                            {options.mobilePhoneShared &&
                                 <Fragment>
                                     <Grid item md={5} xs={12}>
                                        <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>{translate('resources.users.fields.mobilePhone')}:</span>
                                    </Grid>
                                    <Grid item md={7} xs={12}>
                                        <TextField record={record} source="mobilePhone" />
                                    </Grid>
                                 </Fragment>
                            }
                            {suggestion.appointment && suggestion.appointment.type === 'PROVIDER' &&
                                <Grid item md={12} xs={12}>
                                    <span style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>{translate('resources.suggestions.contact_information.notes')}:</span>
                                    <Typography>{translate('resources.suggestions.contact_information.message.message_1')}</Typography>
                                </Grid>
                            }
                            {(!suggestion.appointment || suggestion.appointment.type !== 'PROVIDER') && options.message &&
                                <Grid item md={12} xs={12}>
                                    <span style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}>{translate('resources.suggestions.contact_information.notes')}:</span>
                                    <Typography>{options.message}</Typography>
                                </Grid>
                            }
                            {(!suggestion.appointment || suggestion.appointment.type !== 'PROVIDER') && options.emailShared &&
                                <Button href={`mailto:${record.email}?cc=info@knooing.com&subject=knooing | ${suggestion.projectName}`} style={{ backgroundColor: '#51ace1', display: 'block', marginTop: 15, textAlign: 'center' }} variant="contained" size="small" color="primary" fullWidth={true}>{translate('resources.suggestions.contact_information.send_message')}</Button>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            )
        }

        const ContactInformation = props => {
            const { data, suggestion, providerCompanyName } = props;

            const newMessage = () => {
                var messageForm = document.getElementById('message-form');

                if (messageForm != null) {
                    messageForm.scrollIntoView();

                    var messagePlaceholder = document.getElementById('message-placeholder');
                    if (messagePlaceholder != null) {
                        messagePlaceholder.click();
                    }
                }
            }

            // console.log('suggestion: ', suggestion);

            return (
                <Fragment>
                     <Grid container spacing={2}>
                        <Grid item md={8} xs={8}>
                            <Typography variant="h6" style={{ color: '#39a1de' }}>{providerCompanyName}</Typography>
                        </Grid>
                        {/* <Grid item md={4} xs={4} style={{ textAlign: 'center' }}>
                            <Button onClick={() => this.downloadPdf(suggestion)} disabled={this.state.pdfDisabled} variant="outlined" size="small" startIcon={<PlusIcon/>}>{translate('resources.suggestions.contact_information.create_pdf')}</Button>
                        </Grid> */}
                    </Grid>
                    <Typography variant="overline" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{translate('resources.suggestions.contact_information.name')}:</Typography>
                    <Grid container spacing={2} style={{ marginBottom: 10 }}>

                        {suggestion.providerContacts.map((value, key) => {

                            // console.log('key', key);
                            // console.log('value', value);

                            return (
                                <Grid key={key} item md={4} xs={6}>
                                    <ReferenceField link={null} record={value} label="" source="userId" reference="users" basePath={basePath}>
                                        <UserCard suggestion={suggestion} options={value} />
                                    </ReferenceField>
                                </Grid>
                            )
                        })}
                        <Grid item md={4} xs={6}>
                            <Card style={{ minHeight: 252 }}>
                                <CardContent>
                                    <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="h6" align="center">{translate('resources.suggestions.contact_information.other_options.title')}</Typography>
                                    
                                    <Tooltip title={suggestion.consumerContacts && suggestion.consumerContacts.find(contact => contact.userId === parseInt(this.state.userId)) ? translate('resources.suggestions.contact_information.other_options.action_1_tooltip') : ""} arrow="true" placement="right">
                                        <div>
                                            <Button 
                                                disabled={suggestion.consumerContacts && suggestion.consumerContacts.find(contact => contact.userId === parseInt(this.state.userId))}
                                                onClick={() => this.setState({ isDialogOneVisible: true })} 
                                                style={{ backgroundColor: '#8e94aa', display: 'block', marginTop: 15 }} 
                                                variant="contained" 
                                                size="small" 
                                                color="primary" 
                                                fullWidth={true}
                                            >
                                                {translate('resources.suggestions.contact_information.other_options.action_1')}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                    
                                    <Dialog
                                        fullWidth
                                        open={isDialogOneVisible}
                                        onClose={() => this.setState({ isDialogOneVisible: false })}
                                        disableEnforceFocus
                                    >
                                        <DialogTitle style={{ color: '#39a1de' }}>{translate('resources.suggestions.dialog_one.title')}</DialogTitle>
                                        <DialogContent>
                                            <Typography>{translate('resources.suggestions.dialog_one.message_1')}</Typography>
                                            <SimpleForm
                                                initialValues={{ id: suggestion.id, type: 'CONSUMER' }}
                                                validate={this.validateDialogOne}
                                                id="contact-share"
                                                variant="standard"
                                                resource="suggestions"
                                                save={(values) => this.submitDialogOne(values, suggestion)}
                                                toolbar={<DialogOneToolbar />}
                                            >
                                                <CurrentUser />
                                                <CheckboxGroupInput label={false} row={false} source="userChoices" choices={userChoices}  />
                                                <Typography>{translate('resources.suggestions.dialog_one.message_2')}</Typography>
                                                <TextInput multiline source="userMessage" label="" fullWidth={true} placeholder={translate('resources.suggestions.dialog_one.textarea_placeholder')} />
                                            </SimpleForm>
                                        </DialogContent>
                                    </Dialog>

                                    <Tooltip title={suggestion.appointment != null ? suggestion.appointment.type === 'CONSUMER' ? translate('resources.suggestions.contact_information.other_options.action_2_tooltip') : translate('resources.suggestions.contact_information.message.message_1') : ""} arrow="true" placement="right">
                                        <div>
                                            <Button 
                                                disabled={suggestion.appointment != null}
                                                onClick={() => this.setState({ isDialogTwoVisible: true })} 
                                                style={{ backgroundColor: '#8e94aa', display: 'block', marginTop: 25 }} 
                                                variant="contained" 
                                                size="small" 
                                                color="primary" 
                                                fullWidth={true}
                                            >
                                                {translate('resources.suggestions.contact_information.other_options.action_2')}
                                            </Button>
                                        </div>
                                    </Tooltip>
                                    <Dialog
                                        fullWidth
                                        open={isDialogTwoVisible}
                                        onClose={() => this.setState({ isDialogTwoVisible: false })}
                                        disableEnforceFocus
                                    >
                                        <DialogTitle style={{ color: '#39a1de' }}>{translate('resources.suggestions.dialog_two.title')}</DialogTitle>
                                        <DialogContent>
                                            <Typography>{translate('resources.suggestions.dialog_two.message_1')}</Typography>
                                            
                                            <SimpleForm
                                                initialValues={{ id: suggestion.id, type: 'CONSUMER' }}
                                                id="appointment-arrange"
                                                variant="standard"
                                                resource="suggestions"
                                                save={(values) => this.submitDialogTwo(values, suggestion)}
                                                validate={this.validateDialogTwo}
                                                toolbar={<DialogTwoToolbar />}
                                            >
                                                <CustomDateTimeInput label="resources.suggestions.dialog_two.fields.favouredDate" source="favouredDate" />
                                                {/* <DateTimeInput label="resources.suggestions.dialog_two.fields.favouredDate" source="favouredDate" validate={required()} /> */}
                                                <CustomDateTimeInput label="resources.suggestions.dialog_two.fields.alternativeDate" source="alternativeDate" />
                                                <SelectInput label="resources.suggestions.dialog_two.fields.preferredMedium" source="preferredMedium" choices={connectionOptions} validate={required()} />

                                                <FormDataConsumer>
                                                    {({ formData, ...rest }) => formData.preferredMedium === 'OTHER' &&
                                                        <TextInput label="resources.suggestions.dialog_two.fields.alternativeMedium" variant="standard" multiline source="alternativeMedium" fullWidth={true} validate={required()} />
                                                    }
                                                </FormDataConsumer>

                                                <TextInput label="resources.suggestions.dialog_two.fields.comment" multiline source="message" fullWidth={true} />

                                                <CheckboxInput label="resources.suggestions.dialog_two.message_2" source="consumerContactShared" validate={required(validationMessage)} />
                                            </SimpleForm>
                                        </DialogContent>
                                    </Dialog>

                                    <Button onClick={() => newMessage()} style={{ backgroundColor: '#8e94aa', display: 'block', marginTop: 25, textAlign: 'center' }} variant="contained" size="small" color="primary" fullWidth={true}>{translate('resources.suggestions.contact_information.other_options.action_3')}</Button>
                                </CardContent>
                            </Card>
                            {/* <List style={{ padding: 0 }}>
                                <ListItem style={{ padding: 0 }}>
                                    <ListItemIcon>
                                        <InfoIcon className={classes.blueIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <Typography variant="body2" component="p">
                                            {translate('resources.suggestions.contact_information.message.primary_text')}
                                        </Typography>
                                    } secondary={<Link component={ComponentLink}
                                    to={`/message/${record.id}/create`} style={{ display: 'block' }}>
                                    {translate('resources.suggestions.contact_information.message.secondary_text')}
                                </Link>} />
                                </ListItem>
                            </List> */}
                        </Grid> 
                    </Grid>
                </Fragment>
            )
        }

        function renderProviderStatus(suggestion) {
        // console.log(suggestion.providerStatus);
            switch (suggestion.providerStatus) {
                case 'PENDING':
                    return (
                        <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                            <ListItemIcon>
                                <ScheduleIcon className={classes.greenIcon} />
                            </ListItemIcon>
                            <ListItemText primary={translate('resources.suggestions.fields.status')} secondary={translate('resources.suggestions.field_values.provider_status.pending')} />
                        </ListItem>
                    );
                case 'ACCEPTED':
                    return (
                        <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                            <ListItemIcon>
                                <ScheduleIcon className={classes.greenIcon} />
                            </ListItemIcon>
                            <ListItemText primary={translate('resources.suggestions.fields.status')} secondary={translate('resources.suggestions.field_values.provider_status.accepted')} />
                        </ListItem>
                    );
                case 'APPROVED':
                    return (
                        <Fragment>
                            <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                                <ListItemIcon>
                                    <CheckIcon className={classes.greenIcon} />
                                </ListItemIcon>
                                <ListItemText primary={translate('resources.suggestions.fields.status')} secondary={translate('resources.suggestions.field_values.provider_status.approved')} />
                            </ListItem>
                        </Fragment>
                    );
                case 'SELF_SERVICE':
                    return (
                        <Fragment>
                            <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                                <ListItemIcon>
                                    <CheckIcon className={classes.greenIcon} />
                                </ListItemIcon>
                                <ListItemText primary={translate('resources.suggestions.fields.status')} secondary={translate('resources.suggestions.field_values.provider_status.self_service')} />
                            </ListItem>
                        </Fragment>
                    );
                case 'DECLINED':
                    return (
                        <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                            <ListItemIcon>
                                <CancelIcon className={classes.redIcon} />
                            </ListItemIcon>
                            <ListItemText primary={translate('resources.suggestions.fields.status')} secondary={translate('resources.suggestions.field_values.provider_status.declined')} />
                        </ListItem>
                    );
                default:
                    break;
            }
        }

        function renderStatus(suggestion) {
            switch (suggestion.consumerStatus) {
                case 'SELF_SERVICE':
                    return (
                        <Fragment>
                            <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                                <ListItemIcon>
                                    <CheckIcon className={classes.greenIcon} />
                                </ListItemIcon>
                                <ListItemText primary={translate('resources.suggestions.fields.status')} secondary={translate('resources.suggestions.field_values.consumer_status.self_service')} />
                            </ListItem>
                        </Fragment>
                    );
                case 'PENDING':
                    return (
                        <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                            <ListItemIcon>
                                <ScheduleIcon className={classes.blueIcon} />
                            </ListItemIcon>
                            <ListItemText primary={translate('resources.suggestions.fields.status')} secondary={translate('resources.suggestions.field_values.consumer_status.pending')} />
                        </ListItem>
                    );
                case 'ACCEPTED':
                    return (
                        <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                            <ListItemIcon>
                                <ScheduleIcon className={classes.orangeIcon} />
                            </ListItemIcon>
                            <ListItemText 
                                primary={translate('resources.suggestions.fields.status')} 
                                secondary={
                                    <Tooltip title={translate('resources.projects.message.in_review')}>
                                        <span>{translate('resources.suggestions.field_values.consumer_status.accepted')}</span>
                                    </Tooltip>
                                } 
                            />
                        </ListItem>
                    );
                case 'APPROVED':
                    return renderProviderStatus(suggestion);
                case 'DECLINED':
                    return (
                        <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                            <ListItemIcon>
                                <CancelIcon className={classes.redIcon} />
                            </ListItemIcon>
                            <ListItemText primary={translate('resources.suggestions.fields.status')} secondary={translate('resources.suggestions.field_values.consumer_status.declined')} />
                        </ListItem>
                    );
                default:
                    break;
            }
        }

        const getUrl = (url) => {
        
            if (url && !url.includes('http')){
                url = 'https://' + url;
            }
            return url;
        };
        
        const Suggestion = (props) => {

            const { record, project } = props;

            // console.log('record: ', record)
            const suggestion = record;

            const projectId = 3028;
        
            return (
                <div>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item md={8} xs={8}>
                            <List style={{ paddingTop: 0 }}>
                                {renderStatus(suggestion)}
                            </List>
                        </Grid>
                        <Grid item md={4} xs={4} style={{ textAlign: 'center' }} >
                            <div style={{ verticalAlign: 'middle' }}>
                                {project.id != projectId && <Fragment><span style={{ fontSize: '20px', fontWeight: 'bold', color: '#39a1de' }}>{suggestion.score / 10}</span><span style={{ fontSize: '15px', fontWeight: 'bold', color: '#39a1de' }}> / 10</span></Fragment>}
                                {project.id != projectId && <Fragment><span style={{ fontSize: '10px', display: 'block' }}>{translate('resources.suggestions.fields.matching_score')}</span></Fragment>}
                                {project.id != projectId && <Fragment><ScoreDetails scoreDetails={suggestion.notFoundPhrases} /></Fragment>}
                                {project.state !== 'ANALYSIS_RUNNING' && <Button style={{ marginBottom: 5 }} onClick={() => this.downloadPdf(suggestion)} disabled={this.state.pdfDisabled} variant="outlined" size="small" startIcon={<PlusIcon/>}>{translate('resources.suggestions.contact_information.create_pdf')}</Button>}
                            </div>
                        </Grid>
                        {/* {!suggestion.addedByCrm ?

                            <Grid item md={4} xs={4} style={{ textAlign: 'center' }} >
                                <div style={{ verticalAlign: 'middle' }}>
                                    <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#39a1de' }}>{suggestion.score / 10}</span><span style={{ fontSize: '15px', fontWeight: 'bold', color: '#39a1de' }}> / 10</span>
                                    <span style={{ fontSize: '10px', display: 'block' }}>{translate('resources.suggestions.fields.matching_score')}</span>
                                    <ScoreDetails scoreDetails={suggestion.notFoundPhrases} />
                                </div>
                            </Grid>
                            :
                            <Grid item md={4} xs={4} style={{ textAlign: 'center' }} >
                                <div style={{ verticalAlign: 'middle' }}>
                                    <Typography color="secondary" variant="overline" >{translate('resources.suggestions.message.top_recommended')}</Typography>
                                </div>
                            </Grid>
                        } */}
                    </Grid>

                    {suggestion.providerStatus === 'APPROVED' &&
                        <div>
                            {/* <ReferenceArrayField {...props} source="providerContacts" reference="users">
                                <ContactInformation providerCompanyName={suggestion.providerCompanyName} suggestion={suggestion} />
                            </ReferenceArrayField> */}
                            <ContactInformation suggestion={suggestion} />
                        </div>
                    }

                    <div style={{ marginBottom: 16 }}>
                        <Typography display="inline" style={{ fontWeight: 'bold', color: '#39a1de', fontSize: '1.0625rem' }} variant="button">{suggestion.providerCompanyName}</Typography>
                        {suggestion.providerCompanyWebsite && <a className={classes.websiteLink} href={getUrl(suggestion.providerCompanyWebsite)} target="_blank">open website <LinkIcon className={classes.websiteIcon} /></a>}
                    </div>

                    <div style={{ marginBottom: 16 }}><CustomTextField {...props} source="neutralName" /><span style={{ position: 'relative', left: '15px', top: '-1px' }}><CustomSelect label="resources.products.fields.language" /></span></div>
                    
                    {fullView ? 
                        <Fragment>
                            
                            <ReferenceField style={{ marginTop: 23 }} basePath={basePath} record={suggestion} source="productId" reference="products" linkType={false}>
                                <ProductDescription suggestion={suggestion} />
                            </ReferenceField>
                            <Button style={{ display: 'flex' }} size="small" variant="text" color="primary" onClick={() => this.toggleFullView()}>
                                {translate('resources.suggestions.action.less_information')}
                                <ExpandLessIcon />
                            </Button>
                        </Fragment>
                        :
                        <Fragment>
                            <CustomRichTextFieldNoLabel {...props} source="challenge" />
                            <Button size="small" variant="text" style={{ display: 'flex' }} color="primary" onClick={() => this.toggleFullView()}>
                                {translate('resources.suggestions.action.show_more')}
                                <ExpandMoreIcon />
                            </Button>
                        </Fragment>
                        
                    }

                    {!project.archived && <Actions suggestion={suggestion} refreshMessages={this.refreshMessages} refreshProject={refreshProject} />}

                    <div style={{ marginTop: '2.5rem', marginBottom: '2rem' }}>
                        <Divider />
                    </div>

                    <div>
                        {!this.state.messagesHidden && <SuggestionMessage record={suggestion} project={project} />}
                    </div>
                </div>
            )
        }
        
        return (
            record.internalInsightStarted ?
                <div>
                    <Typography variant="h6">{translate('resources.projects.internal_insight.title')}</Typography>
                    <Typography>{translate('resources.projects.internal_insight.text_5')}</Typography>
                </div>
                :
                totalItems > 0 ? 
                    <Fragment>
                        {viewMode === 'DESCRIPTION' && 
                            <List>
                                {record.scoreCalculated && record.matrixDisplayed && 
                                    <ListItem style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Button variant="outlined" onClick={() => this.changeViewMode('MATRIX')}>{translate('resources.suggestions.action.show_matrix')}</Button>
                                    </ListItem>
                                }
                                <ListItem style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
                                    <TablePagination
                                        count={totalItems}
                                        rowsPerPage={1}
                                        page={selectedItem}
                                        labelRowsPerPage={null}
                                        rowsPerPageOptions={[]}
                                        labelDisplayedRows={({ from, to, count }) => `${from} of ${count}`}
                                        onChangePage={this.handlePageChange}
                                        component="span"
                                    />
                                </ListItem>

                                {record.suggestions.map((suggestion, index) => index === Number(selectedItem) && 
                                        <ListItem key={suggestion.id}>
                                            <ListItemText disableTypography={true} secondary={<ReferenceField basePath={basePath} record={suggestion} source="id" reference="suggestions" linkType={false}><Suggestion project={record} /></ReferenceField>} />
                                        </ListItem>)
                                }
                                
                            </List>
                        }
                        {viewMode === 'MATRIX' && 
                            <List>
                                <ListItem style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button variant="outlined" onClick={() => this.changeViewMode('DESCRIPTION')}>{translate('resources.suggestions.action.show_description')}</Button>
                                </ListItem>
                                <ListItem style={{ display: 'block', padding: 0 }}>
                                    <Matrix record={record} />
                                </ListItem>
                            </List>
                        }
                        {record.state === 'ANALYSIS_RUNNING' && <PreviewMessage message={translate('resources.projects.message.preview')} />}
                    </Fragment>
                    : 
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <UpdateIcon className={classes.orangeIcon} />
                            </ListItemIcon>
                            <ListItemText secondary={translate('resources.suggestions.message.no_matching')} />
                        </ListItem>
                        {record.state === 'ANALYSIS_RUNNING' && <PreviewMessage message={translate('resources.projects.message.preview')} />}
                    </List>
        )
    }
}

export default compose(
    withStyles(styles),
    translate, 
    connect(
        null, 
        { push, showNotification }
    ),

) (Suggestions);
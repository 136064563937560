import React, { useState } from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import Dashboard from './Dashboard';
import CustomLayout from './layout/CustomLayout';
import dataProvider from './dataProvider'
import authProvider from './authProvider'
import './App.css';
import themeReducer from './themeReducer';

import customRoutes from './routes';
import englishMessages from './i18n/en';
import germanMessages from './i18n/de';

import projects from './projects';
import UserList from './users/UserList';
import { UserCreate } from './users/UserCreate';
import { UserEdit } from './users/UserEdit';
import UserShow from './users/UserShow';
import CompanyShow from './company/CompanyShow';
import CompanyEdit from './company/CompanyEdit';
import CustomLoginPage from './layout/CustomLoginPage';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './db';
import feedbacks from './feedbacks';
import { LanguageObserver } from './component/LanguageObserver';
import ConditionsAccept from './settings/ConditionsAccept'
import recommendations from './recommendations';
import addUploadFeature from './addUploadFeature';

import TokenValidator from './component/TokenValidator';
import ItVendorShow from './recommendations/ItVendorShow';
import ItVendorList from './recommendations/ItVendorList';
import { ItVendorCreate } from './recommendations/ItVendorCreate';
import { ItVendorEdit } from './recommendations/ItVendorEdit';
import SoftwarePortfolioList from './recommendations/SoftwarePortfolioList';
import { SoftwarePortfolioCreate } from './recommendations/SoftwarePortfolioCreate';
import { SoftwarePortfolioEdit } from './recommendations/SoftwarePortfolioEdit';
import SoftwarePortfolioShow from './recommendations/SoftwarePortfolioShow';

const languageMessages = {
  de: germanMessages,
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider(locale => languageMessages[locale] ? 
  languageMessages[locale] 
  : 
  languageMessages.en, localStorage.getItem('language') ? localStorage.getItem('language') : 'en'); // default english language

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('client-type', 'USER');
    return fetchUtils.fetchJson(url, options);
}

const dataProviderInstance = dataProvider(process.env.REACT_APP_API_URL, httpClient);
const uploadCapableDataProvider = addUploadFeature(dataProviderInstance);

const App = () => {

  const [language, setLanguage] = useState([]);

  return (
    <Admin
      title = "your myKnooing platform!"
      loginPage={CustomLoginPage}
      customRoutes={customRoutes}
      customReducers={{ theme: themeReducer }}
      i18nProvider={i18nProvider}
      appLayout={CustomLayout}
      dashboard={Dashboard}
      dataProvider={uploadCapableDataProvider}
      authProvider={(type, props) => authProvider(type, props, setLanguage)}
    >
        {permissions => [
            <Resource name="topics" />,
            <Resource name="products" />,
            <Resource name="projects" {...projects} />,
            <Resource name="keywords" />,
            <Resource name="suggestions"/>,
            <Resource 
                name="users" 
                create={permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? UserCreate : null}
                edit={permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? UserEdit : null} 
                list={permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? UserList : null}
                show={permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? UserShow : null} />,

            <Resource name="companies" edit={permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? CompanyEdit : null} show={CompanyShow} />,
            <Resource name="feedbacks" {...feedbacks}/>,
            <Resource name="external-solutions"/>,
            <Resource name="recommendations" {...recommendations}/>,
            <Resource 
              name="it-vendors" 
              list={ItVendorList} 
              show={ItVendorShow} 
              edit={permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? ItVendorEdit : null} 
              create={permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? ItVendorCreate : null}
            />,
            <Resource 
              name="software-portfolio" 
              list={SoftwarePortfolioList} 
              show={SoftwarePortfolioShow} 
              edit={permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? SoftwarePortfolioEdit : null} 
              create={permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? SoftwarePortfolioCreate : null}
            />,

            <Resource name="internal-search" />,
            <Resource name="internal-insights" />,
      
            <LanguageObserver language={language} />,
            <ConditionsAccept/>,
            <TokenValidator />
        ]}
    </Admin>
  )
};

export default App;
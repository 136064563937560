import React, { Component } from 'react';
import Tab from './projects/Tab';
import ProjectBestPractices from './projects/showcase/ProjectBestPractices';
import ProjectGeneralInformation from './projects/showcase/ProjectGeneralInformation';
import ProjectResourcesAndDownlods from './projects/showcase/ProjectResourcesAndDownlods';


export function isShowcaseTrue() {
    const showcaseParam = localStorage.getItem('showcase') || false;
    console.log(showcaseParam);
    return showcaseParam;
}


export const TabGeneralInformation = props => {
    return (
        <Tab label="Allgemeine Informationen" >
            <ProjectGeneralInformation />
        </Tab >
    )
};


export const TabBestPractices = props => (
    <Tab label='Best Practices & Tendenzen ' >
        <ProjectBestPractices />
    </Tab>
);

export const TabResourcesAndDownload = props => (
    <Tab label='Ressourcen & Downloads ' >
        <ProjectResourcesAndDownlods />
    </Tab>
);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
});

const imageUrl = `${process.env.REACT_APP_ROOT_FOLDER}images/servicenow.jpg`;

export default function MediaCardSap() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="images/sapariba.jpg"
          title="SAP Ariba"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            SAP Ariba
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            SAP Ariba is a software company that provides cloud-based
            procurement, spend management and supply chain services that enable
            suppliers and buyers to connect and do business globally.
            <br /> <br />
            The technology SAP Ariba offers is designed to help companies
            negotiate better agreements with suppliers and provide better
            visibility and control over spending.
            <br /> <br />
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          href="https://www.ariba.com/"
          target="_blank"
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

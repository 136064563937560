import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    card: {
        width: '23em',
    },
    media: {
        height: 64,
        backgroundSize: 48,
    },
});

export default function CustomCard({ image, title, description, link }) {
    const classes = useStyles();

    const translate = useTranslate();

    return (
        <Card className={classes.card}>
            <CardActionArea component={Link} to={link}>
                <CardMedia
                    className={classes.media}
                    image={`${process.env.REACT_APP_ROOT_FOLDER}${image}`}
                    // title="Profile image"
                />
                <CardContent style={{ height: 80 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button component={Link} to={link} size="small" color="primary">
                    {translate('application.menu.action.show')}
                </Button>
            </CardActions>
        </Card>
    );
}
import mock from './../mock';

const faqDB = [
    {
        'id'      : '1',
        'question': 'What is Knooing?',
        'answer'  : '<ul><li>Knooing is an <b>insight engine</b> for your projects. </li> <li>Knooing provides a richer set of <b>knowledge/insights</b> about business domains and technical fields. </li> </ul>'
    },
    {
        'id'      : '2',
        'question': 'Why is Knooing useful for me?',
        'answer'  : '<ul><li><b>Be Proficient, Be Fast, Think Ahead! with your it projects </b> based on a vast knowledge base and lots of AI technologies. </li> <li> <b>Simple, Smart, Safe, Sweeping!</b> </li> <li>Knooing makes knowldge <i>connected and in-context</i>, <i>findable and accessible</i>, <i>trusted and up-to-date.</i></li></ul>'
    },
    {
        'id'      : '3',
        'question': 'Projects in what technical fields are supported by Knooing?',
        'answer'  : 'Please have a look at <a href="#/knowledge/portfolio">the Knooing\'s portfolio</a>. '
    },
    {
        'id'      : '4',
        'question': 'What technologies are in use by Knooing?',
        'answer'  : '<ul><li>Web Crawling, </li><li>Entity Recognition, </li><li>NLP/NLU, </li><li>Machine Learning, </li><li>Deep Learning, </li><li>Expert System, </li><li>Text Mining, </li><li>Knowledge Graph Management, </li><li>Ontology Management</li></ul>'
    },
    {
        'id'      : '5',
        'question': 'What makes Knooing different?',
        'answer'  : 'It\'s much more than an insight engine: <ul><li>vast knowledge base</li><li>powered by knowledge graph technologies</li> <li>cloud native on aws in Frankfurt</li><li>built to integrate via apis and connectors</li><li>out-of-box for scaling and extension</li></ul>  '
    },
    {
        'id'      : '6',
        'question': 'What kind of knowledge for a particular topic is available on the Knooing platform?',
        'answer'  : 'For a particular topic Knooing has knowledge in the following categories: <ul><li>best_practices,</li><li>certifications_and_standards,</li><li> challenges, </li><li>conference_forum, </li><li>definition, </li><li>expert_insight, </li><li>implementation_partner, </li><li>industry_trends, </li><li>leader_provider, </li><li>manufacturing_company, </li><li>mistakes, </li><li>news, </li><li>platform_low_code, </li><li>solutions, </li><li>success_stories, </li><li>technology_trends</li></ul>'
    },
    {
        'id'      : '7',
        'question': 'What is an insight engine?',
        'answer'  : 'According to Gartner, “insight engines are an evolution of search technologies that provide <b> on-demand and proactive knowledge discovery and exploration</b> augmented by semantic and machine learning (ML) technologies. They typically create a new index by crawling, indexing and mining both internal and external data sources, as well as structured and unstructured content, to ensure that a broad set of information is easily discoverable.”'
    },
    {
        'id'      : '8',
        'question': 'Why should I use an insight engine?',
        'answer'  : '<ul> <li>The results that come from using an Insights Engine are markedly better than those delivered by traditional enterprise search. The right solution will deliver <b>results – content and recommendations – </b> that users will need next, whether they even know that yet or not. </li><li> The technology within an Insights Engine can work with unstructured data – websites/intranets, content services platforms, databases, third-party websites, content collaboration platforms, digital experience platforms/web content management platforms, social media, digital/media asset management systems, data lakes and more – <b>the insight extracted and delivered is far more valuable and comprehensive</b>. Unstructured data tends to be where the most insight is found, and most enterprise search options are simply unable to work with data of this type. </li><li>Insights Engines are Becoming an Essential Enterprise Solution.</li></ul>'
    },
    {
        'id'      : '9',
        'question': 'What is the major difference between search engine and insight engine?',
        'answer'  : 'A search engine is typically a fulltext search based on keyword relevance and frequency. An insight engine can <b>understand complex requests</b>, <b>provide contextual, semantic and domain relationships</b> and <b>present a richer set of concepts and knowledge in a flexible manner</b>.'
    }

];

mock.onGet(/^\/api\/faq/).reply((config) => {
    return [200, faqDB];
});

import React, { useCallback } from 'react';
import { useCreate, useRedirect, useNotify } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

import SaveButton from './SaveButton';

export const CreateButton = (props) => {

    const redirectTo = useRedirect();
    const notify = useNotify();
    const { basePath, redirect, resource } = props;

    const formState = useFormState();
    const form = useForm();

    const [create, { loading }] = useCreate(resource, formState.values);

    const handleClick = useCallback(() => {
        if (!formState.valid) {
            form.submit();
            return;
        }

        const options = {
            onSuccess: ({ data: newRecord }) => {
                notify('ra.notification.created', 'info', {
                    smart_count: 1,
                });
                redirectTo(redirect, basePath, newRecord.id, newRecord);
            },
            onFailure: (error) => {

                const isString = Object.prototype.toString.call(error.message) == '[object String]';

                var message = error.message;

                if (!isString) {
                    Object.entries(error.message).forEach(([key, value]) => {
                        formState.errors[key] = value;
                    })

                    message = Object.entries(error.message)
                            .map(([key, value]) => value)
                            .join('\n');

                    form.submit();
                }

                notify(message, 'warning');
            },
        }

        create(null, options);
        
    }, [
        formState.valid,
        formState.values,
        create,
        notify,
        redirectTo,
        redirect,
        basePath,
    ]);

    return <SaveButton {...props} saving={loading} handleSubmitWithRedirect={handleClick} />;
};
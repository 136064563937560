
import React, { useState, Fragment, useEffect } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import tileData from './tileData';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';
import { Title, useTranslate } from 'react-admin'; 

import vendorImage from './vendor_consolidation.png';

const mainDivStyle = {
   top: '10%',
   left: '20%',
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '80%',
    height: 'auto',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  card: {
      maxWidth: 345,
      display: 'inline-block',
      border: '1px solid #92316',
      margin: '10px',
    },
    media: {
      height: 140,
    },
    mediaContain: {
      height: 140,
      backgroundSize: 'contain',
    }
}));

function MediaCardPortfolio() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea component={Link} to="/knowledge/portfolio">
        <CardMedia
          className={classes.media}
          image="https://knooing.com/wp-content/themes/knooing/assets/img/intro-bg.jpg"
          title="Portfolio"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Portfolio
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            The Knowledge Base of our Platform offers you a wide range of solutions. And it keeps growing! Because we are constantly screening the market in search of new, innovative technologies.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge/portfolio`}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

function MediaCardReferences() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="https://knooing.com/wp-content/uploads/2019/10/customer_reference-middle.jpg"
          title="References"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            References
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            You find some knooing's reference partners from various industires.
            <br/>
            <br/>
             <br/>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

function MediaCardReportsAndTrends() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="https://knooing.com/wp-content/uploads/2019/10/reports-middle.jpg"
          title="Reports & Trends"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Reports & Trends
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Here you can find lots of reports from leading research and advisory companies, e.g. Gartner, Forrester. and reports created by knooing in cooperation with partners.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

function MediaCardDownloads() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="https://knooing.com/wp-content/uploads/2019/10/download-icon-v3-middle.png"
          title="Downloads"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Downloads
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Here you find lots of documents and files for download.
            <br/>
            <br/>
            <br/>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

function MediaCardBlog() {
  const classes = useStyles();

  return (
        <Card className={classes.card}>
          <CardActionArea href="https://knooing.com/blog/" target="_blank">
            <CardMedia
              className={classes.media}
              image="https://knooing.com/wp-content/uploads/2019/10/blog-v5.png"
              title="Blog"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Blog
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Interesting blog articles about technologies, business and news.
                <br/>
                <br/>
                <br/>
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://knooing.com/blog/" target="_blank">
              Go to blog Page
            </Button>
          </CardActions>
        </Card>
  );
}

function MediaCardEcosystems() {
  const classes = useStyles();

  return (
        <Card className={classes.card}>
          <CardActionArea component={Link} to="/knowledge/ecosystems">
            <CardMedia
              className={classes.media}
              image="https://knooing.com/wp-content/uploads/2019/10/ecosystems-v3.png"
              title="Ecosystems"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Ecosystems & Integrations
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Do you want to integrate knooing into your systems? do you want to find knooing in some mature ecosystems, e.g. Servicenow, SAP?
                <br/>
                <br/>
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge/ecosystems`}>
              Learn More
            </Button>
          </CardActions>
        </Card>
  );
}

function Recommendations() {
  const classes = useStyles();

  const translate = useTranslate();

  return (
    <Card className={classes.card}>
      <CardActionArea component={Link} to="/knowledge/recommendations">
        <CardMedia
          className={classes.media}
          image="https://knooing.com/wp-content/uploads/2019/10/customer_reference-middle.jpg"
          title={translate('resources.recommendations.plural_name')}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {translate('resources.recommendations.plural_name')}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {translate('resources.recommendations.message.description')}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge/recommendations`}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

function InternalSearch() {

  const classes = useStyles();
  const translate = useTranslate();

  const [allowed, setAllowed] = useState(false);
  const dataProvider = useDataProvider();

  const companyId = JSON.parse(localStorage.getItem('decoded_token')).company_id;
  const companyName = JSON.parse(localStorage.getItem('decoded_token')).company_name;

  useEffect(() => {
    dataProvider.getOne('companies', { id:  companyId + '/internal-search' })
        .then(({ data }) => {
            setAllowed(data.enabled);
        })
        .catch(error => {})
  }, []);

  return (
    allowed ? 
      <Card className={classes.card}>
        <CardActionArea component={Link} to="/knowledge/internal-search">
          <CardMedia
            className={classes.media}
            image="https://knooing.com/wp-content/uploads/2020/01/approval-confirm-search.png"
            title={translate('application.internal_search.title')}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {translate('application.internal_search.title')}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {translate('application.internal_search.description', { company_name: companyName })}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge/internal-search`}>
            Learn More
          </Button>
        </CardActions>
      </Card>
      :
      null
  );
}

function Vendor() {

  const classes = useStyles();
  const translate = useTranslate();

  const [allowed, setAllowed] = useState(false);
  const companyId = JSON.parse(localStorage.getItem('decoded_token')).company_id;

  useEffect(() => {
    setAllowed(parseInt(companyId) == 128)
  }, []);

  return (
    allowed ? 
      <Card className={classes.card}>
        <CardActionArea component={Link} to="/knowledge/vendors">
          <CardMedia
            className={classes.mediaContain}
            image={vendorImage}
            title={translate('resources.projects.vendor_consolidation.title_1')}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {translate('resources.projects.vendor_consolidation.title_1')}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {translate('resources.projects.vendor_consolidation.description_1')}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge/vendors`}>
            {translate('resources.projects.vendor_consolidation.open')}
          </Button>
        </CardActions>
      </Card>
      :
      null
  );
}

class KnowledgeCenter extends React.Component {
  
  render () {
    return (
      <div style={mainDivStyle}>
        <Title title="application.menu.knowledge_center" />
        <div>
            <Vendor />
            {/* <Recommendations /> */}
            <MediaCardPortfolio />
            <MediaCardEcosystems />
            <MediaCardBlog />
        </div>
      </div>
    );
  }
}

export default KnowledgeCenter;
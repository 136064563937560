import React, { Component, Fragment } from "react";
import { Title, translate } from "react-admin";

import { connect } from "react-redux";
import { push } from "react-router-redux";
import compose from "recompose/compose";
import { showNotification } from "react-admin";

import { Redirect } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// import Link from '@material-ui/core/Link';

import { withStyles } from "@material-ui/core";

import { getQueryParameters, getApplicationUrl } from "../utilities";

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "1px",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  card: {
    minWidth: 300,
    marginTop: "6em"
  },
  link: {
    textAlign: "center"
  }
};

const tempParam = getQueryParameters();
if (tempParam.language) {
  localStorage.setItem('language', tempParam.language)
}

class EmailActivation extends Component {
  state = {};
  
  componentWillMount() {
    this.activateToken();
  }

  activateToken() {
    const parameters = getQueryParameters();
    const form = {
      code: parameters.code,
      type: "EMAIL_CONFIRMATION"
    };

    if (parameters !== null) {
      fetch(
        `${process.env.REACT_APP_API_URL}/activation-tokens/${parameters.token}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(form)
        }
      )
        .then(response => {
          if (response.status !== 204) {
            window.location.replace(getApplicationUrl());
            throw new Error("Server responded: " + response.status);
          } else {
            this.setState({ activated: true });
          }
        })
        .catch(exception => {});
    }
  }

  render() {
    const { classes, translate } = this.props;
    return (
      <Fragment>
        {this.state.activated ? (
          <div className={classes.main}>
            <Card className={classes.card}>
              <Title title={translate("application.email_activation.name")} />
              <CardContent>
                <Typography>
                  {translate("application.email_activation.message_1")}
                </Typography>
                <div className={classes.link}>
                  <a href={`${process.env.REACT_APP_ROOT_FOLDER}#/`}>
                    {translate("application.email_activation.message_2")}
                  </a>
                </div>
                {/* <Typography className={classes.link}>
                                    <Link href={"/"}>
                                        Go to Dashboard
                                    </Link>
                                </Typography> */}
              </CardContent>
            </Card>
          </div>
        ) : (
          <div></div>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect(null, {
    showNotification,
    push
  }),
  translate,
  withStyles(styles)
)(EmailActivation);

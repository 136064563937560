import React, { Component, Fragment, useState, useEffect } from 'react';
import { useTranslate, RadioButtonGroupInput, SelectArrayInput, ReferenceArrayInput, required,
    Button as CancelButton,
    FormDataConsumer,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify, 
    useRedirect,
    useDataProvider,
    useRefresh,
} from 'react-admin';

import axios from 'axios';

import { useFormState } from 'react-final-form';

import EyeIcon from '@material-ui/icons/RemoveRedEye';
import CreateIcon from '@material-ui/icons/Create';
import ArchiveIcon from '@material-ui/icons/Archive';
import CancelIcon from '@material-ui/icons/Cancel';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import TableChart from '@material-ui/icons/TableChart';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import SendIcon from '@material-ui/icons/Send';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toolbar: {
        background:'none',
        paddingTop:'10px',
        flex: '0 0 auto',
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    dialogToolbar: {
        background:'none',
        flex: '0 0 auto',
        display: 'flex',
        padding: '0px 20px',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    button:{
        marginRight:'10px'
    }
}));

const CustomToolbar = (props) => {
    const classes = useStyles();
    // console.log('props: ', props);
    return (
        <Toolbar className={classes.toolbar} {...props} >
            <SaveButton
                label="resources.projects.action.archive"
                icon={<ArchiveIcon />}
                disabled={props.loading}
                loading={props.loading}
                className={classes.button}
            />
            <CancelButton label="ra.action.cancel" onClick={props.hideDialog}>
                <CancelIcon />
            </CancelButton>
        </Toolbar>
    )
}

const DialogSaveButton = props => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [ loading, setLoading ] = useState(false);

    const { recordId, setDialogOpen, setRecommendationOpen, feedback } = props;

    const formState = useFormState();
    const handleClick = () => {

        if (!formState.valid) {
            return;
        }

        setLoading(true);

        feedback.recommendationSelected = true;

        if (formState.values.recommendation !== 'NEW') {

            feedback.recommendationId = formState.values.recommendation;
        }

        dataProvider
            .update('projects', { id: `${recordId}/archived`, data: feedback })
            .then(response => {

                redirect('/projects');
                refresh();
                notify('resources.projects.archive_dialog.notification.archived_success');
            })
            .catch(error => {
                
                notify('resources.projects.archive_dialog.notification.archived_error', 'warning');
            })
            .finally(() => {
                setLoading(false);
                setRecommendationOpen(false);
                setDialogOpen(false);
            });
    }

    return <SaveButton {...props} disabled={loading} loading={loading} handleSubmitWithRedirect={handleClick} />
};

const RecommendationToolbar = props => {

    const { setRecommendationOpen } = props;
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Toolbar {...props} className={classes.dialogToolbar}>
            <DialogSaveButton {...props} />
            <Button startIcon={<CancelIcon />} variant="contained" onClick={()=> setRecommendationOpen(false)}>{translate("ra.action.cancel")}</Button>
        </Toolbar>
    )
};

export const Actions = ({ record, activateVendorConsolidation }) => {

    // console.log('record: ', record)

    const recordId = record.id;

    const translate = useTranslate();

    // const exportMatrixActionDisabled = !(record.scoreCalculated || record.internalInsightMatrixDisplayed || record.externalScoreCalculated);
    const exportMatrixActionDisabled = !(record.matrixDisplayed || record.internalInsightMatrixDisplayed || record.externalMatrixDisplayed);

    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [watcherOpen, setWatcherOpen] = useState(false);
    const [choicesTwo, setChoicesTwo] = useState([]);

    const [recommednationOpen, setRecommendationOpen] = useState(false);
    const [ similarRecommendations, setSimilarRecommendations ] = useState();
    const [ feedback, setFeedback ] = useState();

    const [loading, setLoading] = useState(false);

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const dataProvider = useDataProvider();

    useEffect(() => {

        
    }, []);

    function hideWatcherDialog(event) {
        // event.stopPropagation();

        setWatcherOpen(false);
    }

    const getProviders = () => {
        const token = localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/projects/${record.id}/providers`, { 
                method: 'GET', 
                headers: { 'Authorization': `Bearer ${token}` }, 
            })
            .then(response => response.json())
            .then(result => {

                const choicesTwo = result.map((provider, index) => {
                    let item = {
                        id: provider.id,
                        name: provider.name
                    }
                    return item;
                })

                let lastItem = { id: 'OTHER', name: 'resources.projects.archive_dialog.field_values.feedback.option_1_other' };
                choicesTwo.push(lastItem);

                setChoicesTwo(choicesTwo);
            })
            .catch((exception) => {

            });
    }

    const exportMatrix = () => {

        console.log('Export matrix for project: ', record.id);

        const language = localStorage.getItem('language') && localStorage.getItem('language') !== 'undefined' ? localStorage.getItem('language').toUpperCase() : 'EN';
        const token = localStorage.getItem('token');

        let url = process.env.REACT_APP_API_URL + "/projects/" + record.id + "/export-excel/?step=" + record.step + "&language=" + language + "&user=true";

        axios({
            url: url,
            method: 'GET',
            headers: { 'Content-Type': 'application/vnd.ms-excel', 'Authorization': `Bearer ${token}` },
            responseType: 'blob',
        })
        .then((response) => {            
            let fileName = "matrix.xslx";
            try {
                //
                // try reading the filename from the content disposition attribute
                [, fileName] = response.headers['content-disposition'].split('filename=');            
                
                if (fileName.indexOf('"') > -1)
                    fileName = fileName.substring(1, fileName.length-1);
            } catch (error) {
                console.error(error);                
            }

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        }).catch((error) => {
            console.log(error);
        });
    }

    function addWatcher(event) {
        // event.stopPropagation();
        // redirect(`/projects/${record.id}/#add-watcher`);
        hideMenu();
        setWatcherOpen(true);
    }

    function edit(event) {
        event.stopPropagation();
        redirect(`/projects/${record.id}`);
    }

    function archive(event) {
        event.stopPropagation();
        hideMenu();
        getProviders();
        setDialogOpen(true);
    }

    function vendorConsolidation(event) {
        event.stopPropagation();
        hideMenu();
        activateVendorConsolidation();
        redirect(`/projects/${record.id}/show/vendor-consolidation`);
    }

    function hideDialog(event) {
        event.stopPropagation();

        setDialogOpen(false);
    }

    const [anchor, setAnchor] = useState(null);

    const showMenu = event => {
        // console.log('event: ', event)
        event.stopPropagation();

        setAnchor(event.currentTarget);
        setOpen(true);
    };

    const hideMenu = () => {
        setAnchor(null);
        setOpen(false);
    };

    const submit = (values) => {
        
        // console.log('values: ', values);

        setLoading(true);

        setFeedback({...values});

        dataProvider
            .update('projects', { id: `${record.id}/archived`, data: { ...values } })
            .then(response => {

                // console.log('response: ', response)
                if (response.data.similarRecommendations) {

                    let similarRecommendations = response.data.similarRecommendations;
                    let empty = { id: 'NEW', providerName: translate('resources.recommendations.similar_recommendations.new_recommendation') }
                    similarRecommendations.push(empty);

                    setSimilarRecommendations(similarRecommendations);
                    setRecommendationOpen(true);

                } else {
                    redirect('/projects');
                    refresh();
                    notify('resources.projects.archive_dialog.notification.archived_success');
                }
            })
            .catch(error => {
                
                notify('resources.projects.archive_dialog.notification.archived_error', 'warning');
            })
            .finally(() => {
                setLoading(false);
                setDialogOpen(false);
            });
    };

    const choicesOne = [
        { id: 'OPTION_1', name: 'resources.projects.archive_dialog.field_values.feedback.option_1' },
        { id: 'OPTION_2', name: 'resources.projects.archive_dialog.field_values.feedback.option_2' },
        { id: 'OPTION_3', name: 'resources.projects.archive_dialog.field_values.feedback.option_3' },
        { id: 'OPTION_4', name: 'resources.projects.archive_dialog.field_values.feedback.option_4' },
    ]

    const projectId = 3024;

    return (
        <Fragment>
            <Button variant="outlined" color="primary" aria-haspopup="true" onClick={showMenu}>
                {translate('resources.projects.action.actions')}
            </Button>
            <Menu
                // id="project-actions"
                keepMounted
                open={open}
                anchorEl={anchor}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={hideMenu}
                onClick={(event) => event.stopPropagation()}
            >
                { record.id == projectId &&
                <MenuItem onClick={vendorConsolidation}>
                    <ListItemIcon><CallMergeIcon /></ListItemIcon>
                    <Typography variant="inherit">{translate("resources.projects.action.vendor_consolidation")}</Typography>
                </MenuItem>
                }
                <MenuItem onClick={addWatcher}>
                    <ListItemIcon><EyeIcon /></ListItemIcon>
                    <Typography variant="inherit">{translate("resources.projects.action.add_watcher")}</Typography>
                </MenuItem>
                <MenuItem onClick={edit}>
                    <ListItemIcon><CreateIcon /></ListItemIcon>
                    <Typography variant="inherit">{translate("ra.action.edit")}</Typography>
                </MenuItem>
                <MenuItem disabled={exportMatrixActionDisabled} onClick={() => exportMatrix()}>
                    <ListItemIcon><TableChart /></ListItemIcon>
                    <Typography variant="inherit">{translate("resources.projects.action.export_matrix")}</Typography>
                </MenuItem>
                <MenuItem onClick={archive}>
                    <ListItemIcon><ArchiveIcon /></ListItemIcon>
                    <Typography variant="inherit">{translate("resources.projects.action.archive")}</Typography>
                </MenuItem>
            </Menu>

            <Dialog
                fullWidth
                open={dialogOpen}
                onClose={hideDialog}
                disableEnforceFocus
                disableBackdropClick
                onClick={(event) => event.stopPropagation()}
            >
                <DialogTitle>{translate('resources.projects.archive_dialog.title')}</DialogTitle>
                <DialogContent>
                    <Typography style={{ marginBottom: 15 }} variant="body2">
                        {translate('resources.projects.archive_dialog.message.message_1')}
                    </Typography>
                    <SimpleForm
                        variant="standard"
                        resource="projects"
                        save={submit}
                        toolbar={<CustomToolbar loading={loading} hideDialog={hideDialog} />}
                    >
                        <Typography style={{ marginLeft: -15 }} variant="body2">
                            {translate('resources.projects.archive_dialog.message.message_2')}
                        </Typography>
                        <SelectInput 
                            style={{ marginLeft: -15, marginRight: -15, minWidth: '70%' }} 
                            label="resources.suggestions.dialog.fields.feedback" 
                            source="code" 
                            choices={choicesOne} 
                        />
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData.code === 'OPTION_1' &&
                                <Fragment>
                                    <Typography style={{ marginLeft: -15 }} variant="body2">
                                    {translate('resources.projects.archive_dialog.message.message_3')}
                                </Typography>
                                    <SelectInput 
                                        style={{ marginLeft: -15, marginRight: -15, minWidth: '70%' }} 
                                        label="resources.suggestions.dialog.fields.feedback" 
                                        source="provider" 
                                        variant="standard"
                                        choices={choicesTwo} 
                                    />
                                </Fragment>
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => formData.code === 'OPTION_1' && formData.provider === 'OTHER' &&
                                <TextInput
                                    style={{ marginLeft: -15, marginRight: -15, marginBottom: -15, width: '100%' }}
                                    label="resources.projects.archive_dialog.message.message_4"
                                    source="alternativeProvider"
                                    resettable
                                    multiline
                                    {...rest}
                                />
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => (formData.code === 'OPTION_2' 
                                || formData.code === 'OPTION_3' 
                                || formData.code === 'OPTION_4') &&
                                <TextInput
                                    style={{ marginLeft: -15, marginRight: -15, marginBottom: -15, width: '100%' }}
                                    label="resources.projects.archive_dialog.fields.comment"
                                    source="comment"
                                    resettable
                                    multiline
                                    {...rest}
                                />
                            }
                        </FormDataConsumer>
                    </SimpleForm>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth
                open={recommednationOpen}
                onClose={() => setRecommendationOpen(false)}
                disableEnforceFocus
                disableBackdropClick
                onClick={(event) => event.stopPropagation()}
            >
                <DialogTitle style={{ paddingBottom: 0 }}>{translate('resources.recommendations.similar_recommendations.title')}</DialogTitle>
                <DialogContent style={{ overflow: 'auto', padding: 0, margin: 0 }}>
                    <SimpleForm variant="standard" toolbar={<RecommendationToolbar recordId={recordId} feedback={feedback} setDialogOpen={setDialogOpen} setRecommendationOpen={setRecommendationOpen} />}>
                        <RadioButtonGroupInput 
                            label={translate('resources.recommendations.similar_recommendations.recommendation')} 
                            row={false}
                            fullWidth
                            source="recommendation"
                            optionText={(choice) => 
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>{choice.providerName}</span>{choice.website && <span> - {choice.website}</span>}
                                </div>
                            }
                            choices={similarRecommendations} 
                            translateChoice={false} 
                            validate={required()}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <AddWatcher record={record} open={watcherOpen} hideDialog={hideWatcherDialog} />
        </Fragment>
    );
}

const optionText = choice => `${choice.firstName} ${choice.lastName}`;
const CustomSelectArrayInput = ({ formData, choices, ...props }) => {
    const filteredChoices = choices.filter(choice => choice.id !== Number(formData.ownerId));
    return <SelectArrayInput {...props} choices={filteredChoices} />
};
const AddWatcherCustomToolbar = (props) => {
    const classes = useStyles();

    return (
        <Toolbar className={classes.toolbar} {...props} >
            <SaveButton
                disabled={props.loading}
                loading={props.loading}
                className={classes.button}
            />
            <CancelButton label="ra.action.cancel" onClick={props.hideDialog}>
                <CancelIcon />
            </CancelButton>
        </Toolbar>
    )
}
export const AddWatcher = ({ record, open, hideDialog }) => {

    // console.log('record: ', record)

    const translate = useTranslate();

    const [loading, setLoading] = useState(false);

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const dataProvider = useDataProvider();

    useEffect(() => {

        
    }, []);

    const submit = (values) => {
        
        // console.log('values: ', values);

        setLoading(true);

        dataProvider
            .update('projects', { id: `${record.id}`, data: { ...values } })
            .then(response => {

                refresh();
            })
            .catch(error => {
                
                // notify('', 'warning');
            })
            .finally(() => {
                setLoading(false);
                hideDialog();
            });
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={hideDialog}
            disableEnforceFocus
            disableBackdropClick
            onClick={(event) => event.stopPropagation()}
        >
            <DialogTitle>{translate('resources.projects.action.add_watcher')}</DialogTitle>
            <DialogContent>
                <SimpleForm
                    variant="standard"
                    resource="projects"
                    save={submit}
                    defaultValue={{ ...record }}
                    toolbar={<AddWatcherCustomToolbar loading={loading} hideDialog={hideDialog} />}
                >
                    <FormDataConsumer>
                        {formDataProps => (
                            <ReferenceArrayInput variant="standard" style={{ minWidth: '30%', margin:"8px" }} label="resources.projects.fields.watchers" reference="users" source="watcherId" resource="users" filter={{ companyId: JSON.parse(localStorage.getItem('decoded_token')).company_id }}>
                                <CustomSelectArrayInput optionText={optionText} {...formDataProps} />
                            </ReferenceArrayInput>
                        )}
                    </FormDataConsumer>
                </SimpleForm>
            </DialogContent>
        </Dialog>
    );
}
import React from 'react';
import { Create } from 'react-admin';
import { SoftwarePortfolioForm } from './SoftwarePortfolioForm';

export const SoftwarePortfolioCreate = (props) => {

    return (
        <Create undoable={false} title=" " {...props}>
            <SoftwarePortfolioForm />
        </Create>
    )
}
import React, { FunctionComponent, useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup, { FormGroupProps } from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox'
import { FieldTitle, useInput, InputProps } from 'ra-core';

import {
    InputHelperText,
    InputPropTypes
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '0.85rem',
    },
}));

const sanitizeRestProps = ({ staticContext, ...props }) => props;

const CheckboxInput = ({
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    options,
    parse,
    resource,
    source,
    validate,
    ...rest
}) => {
    const {
        id,
        input: { onChange: finalFormOnChange, type, value, ...inputProps },
        isRequired,
        meta: { error, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'checkbox',
        validate,
        ...rest,
    });

    const handleChange = useCallback(
        (event, value) => {
            finalFormOnChange(value);
        },
        [finalFormOnChange]
    );

    const classes = useStyles();

    return (
        <FormGroup {...sanitizeRestProps(rest)} style={{ width: '100%' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        id={id}
                        color="primary"
                        checked={!!value}
                        onChange={handleChange}
                        {...inputProps}
                        {...options}
                        style={{ color: touched && error ? '#f44336' : '' }}
                    />
                }
                label={
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                    />
                }
            />
            {(touched && error) || helperText ? (
                <FormHelperText error={!!error}>
                    <InputHelperText
                        touched={touched}
                        error={error}
                        helperText={helperText}
                    />
                </FormHelperText>
            ) : null}
        </FormGroup>
    );
};

CheckboxInput.propTypes = {
    ...InputPropTypes,
    options: PropTypes.shape(Checkbox.propTypes),
};

CheckboxInput.defaultProps = {
    options: {},
};

export default CheckboxInput;
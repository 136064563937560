import React, { useState, useEffect } from 'react';
import { 
    ReferenceField, 
    ReferenceArrayField, 
    SingleFieldList, 
    DateField, 
    TabbedShowLayout, 
    TextField, 
    RichTextField,
    SelectField,
    useTranslate, 
} from 'react-admin';

import { Badge, Typography, Chip, Icon, Tooltip } from '@material-ui/core';

import Tab from './Tab';
import Suggestions from './Suggestions';
import InternalInsight from './InternalInsight';

import { ExternalSolution } from './ExternalSolution';
import { ProjectMessage } from './ProjectMessage';

import { makeStyles } from '@material-ui/core/styles';
import { VendorConsolidation } from './VendorConsolidation';

const useStyles = makeStyles(theme => ({
    padding: {
        padding: theme.spacing(0, 2, 0, 1),
    },
    grey: { backgroundColor: '#e0e0e0' },
    green: { backgroundColor: '#dcedc8' },
    orange: { backgroundColor: '#ffe0b2' },
}));

const CustomChip = (props) => {
    const { record, source } = props;

    if (!record) {
        return null;
    }

    let icon = <Icon>{record.icon}</Icon>;
    return (
        <Chip
            style={{ margin: 4 }}
            icon={icon}
            color="default"
            label={record[source]}
        />
    )
}

const FullName = (props) => {
    const { record } = props;

    if (!record) {
        return null;
    }

    return (
        <Chip
            style={{ margin: 4 }}
            color="default"
            label={record.firstName + " " + record.lastName}
        />
    )
}

const FullNameField = ({ record = {} }) => record ? <span>{record.firstName} {record.lastName}</span> : null;

const Keywords = ({ record }) => {

    const keywords = record.keywords || [];
    return keywords.map(item => <Chip key={item} label={item} style={{ margin: 4 }}></Chip>)
}
Keywords.defaultProps = { addLabel: true };

const activeStepTextStyle = {

    fontWeight: 'bold'
}

const CustomLabel = ({ record }) => {

    const classes = useStyles();
    const translate = useTranslate();

    const [count, setCount] = useState();

    useEffect(() => {

        const result = record.suggestions
                .filter(suggestion => suggestion.consumerStatus === 'PENDING')
                .length;
        setCount(result);

    }, [record.suggestions]);

    return (count > 0 ?
        <Badge color="secondary" badgeContent={count}>
            <Typography style={ record.step === 'STEP_3' && record.previewEnabled ? activeStepTextStyle : {}} variant="body2" className={classes.padding}>{translate('resources.projects.message.tab_title_2')}</Typography>
        </Badge>
        :
        <Typography style={ record.step === 'STEP_3' && record.previewEnabled ? activeStepTextStyle : {}} variant="body2">{translate('resources.projects.message.tab_title_2')}</Typography>
        // `${translate('resources.projects.message.tab_title_2')}`
    )
}

const CustomFileField = ({ record }) => {

    return (
        record.projectAttachments.map(attachment => {
            let fileSize = attachment.fileSize;
            if (fileSize) {
                fileSize = ` (${(fileSize / 1000000).toFixed(2)} MB)`;
            }
            return (
                <a 
                    style={{ display: 'block', marginBottom: 5 }}
                    href={attachment.url} 
                    download={attachment.title}
                >
                    {attachment.title}{fileSize ? fileSize : ''}
                </a>
            )
        })
    )
}

CustomFileField.defaultProps = { addLabel: true };

const ProjectLayout = (props) => {

    const { record, refreshProject, vendorConsolidationActivated } = props;

    const translate = useTranslate();

    const projectId = 3028;

    return (
        <TabbedShowLayout {...props}>

            <Tab label={<Typography style={activeStepTextStyle} variant="body2">{translate('resources.projects.message.tab_title_1')}</Typography>}>
                
                <TextField source="name" />
                <SelectField 
                    source="offering" 
                    choices={[
                        { id: 'SOFTWARE', name: 'Software' },
                        { id: 'SERVICE', name: 'Service' },
                    ]} 
                />
                <RichTextField source="description" />
                <Keywords label={translate('resources.projects.fields.keywords')} />

                <CustomFileField label="resources.projects.fields.uploadedDocuments" />
                {record && record.topicsEnabled &&
                <ReferenceArrayField source="topics" reference="topics">
                    <SingleFieldList linkType={false}>
                        <CustomChip source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                }
                <ReferenceField label="resources.projects.fields.owner.id" source="ownerId" reference="users" linkType={false} allowEmpty>
                    <FullNameField />
                </ReferenceField>
                <ReferenceArrayField label="resources.projects.fields.watchers" source="watcherId" reference="users">
                    <SingleFieldList linkType={false}>
                        <FullName />
                    </SingleFieldList>
                </ReferenceArrayField>
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </Tab>
            {/* {record && record.id != projectId && record.internalInsightActivated && record.onlyFavoriteProviders && 
                
                <Tab 
                    tooltip={record.step === 'STEP_1' && !record.previewEnabled ? translate('resources.projects.message.preview_disabled') : ''}
                    disabled={record.step === 'STEP_1' && !record.previewEnabled}
                    path="step_1" 
                    label={<Typography style={ record.step === 'STEP_1' && !record.previewEnabled ? {} : activeStepTextStyle } variant="body2">{translate('resources.projects.message.tab_title_6')}</Typography>}
                >
                    <InternalInsight type="STEP_1" matrixDisplayed={record.topAndPreferredSupplierMatrixDisplayed} />
                </Tab>
            }
            {record && record.id != projectId && record.internalInsightActivated && record.onlyFavoriteProviders &&
                
                <Tab 
                    tooltip={record.step === 'STEP_1' ? translate('resources.projects.message.tab_disabled') : record.step === 'STEP_2' && !record.previewEnabled ? translate('resources.projects.message.preview_disabled') : ''}
                    disabled={record.step === 'STEP_1' || (record.step === 'STEP_2' && !record.previewEnabled) } 
                    path="step_2" 
                    label={<Typography style={ record.step === 'STEP_1' || (record.step === 'STEP_2' && !record.previewEnabled) ? {} : activeStepTextStyle } variant="body2">{translate('resources.projects.message.tab_title_7')}</Typography>}
                >
                    <InternalInsight type="STEP_2" matrixDisplayed={record.furtherSupplierMatrixDisplayed} />
                </Tab>
            } */}
            {record && record.id != projectId && record.matchingSetup && (record.matchingSetup.softwareSearchEnabled || record.matchingSetup.vendorSearchEnabled) &&
                // Internal insights
                <Tab 
                    tooltip={record.step === 'STEP_2' && !record.previewEnabled ? translate('resources.projects.message.preview_disabled') : ''}
                    disabled={record.step === 'STEP_2' && !record.previewEnabled}
                    path="step_2" 
                    label={
                        <Typography 
                            style={record.step === 'STEP_2' && !record.previewEnabled ? {} : activeStepTextStyle} 
                            variant="body2">{translate('resources.projects.message.tab_title_5')}
                        </Typography>
                    }
                >
                    <InternalInsight type="MIXED" matrixDisplayed={record.internalInsightMatrixDisplayed} />
                </Tab>
            }
            {record && vendorConsolidationActivated &&
                
                <Tab 
                    path="vendor-consolidation" 
                    label={<Typography style={activeStepTextStyle} variant="body2">{translate('resources.projects.action.vendor_consolidation')}</Typography>}
                >
                    <VendorConsolidation />
                </Tab>
            }
            <Tab 
                tooltip={record.step !== 'STEP_3' ? translate('resources.projects.message.tab_disabled') : record.step === 'STEP_3' && !record.previewEnabled ? translate('resources.projects.message.preview_disabled') : ''}
                disabled={record.step !== 'STEP_3' || !record.previewEnabled} 
                path="step_3" 
                label={<CustomLabel {...props} />}
            >
                <Suggestions refreshProject={refreshProject} />
            </Tab>
            {record && record.metaMatchingDisplayed && 
                
                <Tab path="3" label={<Typography variant="body2">{translate('resources.projects.message.tab_title_4')}</Typography>}>
                    <ExternalSolution />
                </Tab>
            }
            <Tab label={<Typography style={activeStepTextStyle} variant="body2">{translate('resources.projects.message.tab_title_3')}</Typography>} path="messages">
                <ProjectMessage {...props} />
            </Tab>
        </TabbedShowLayout>
    )    
}

export default ProjectLayout;
import React from 'react';
import { AppBar, UserMenu } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Logotype from './Logotype';
import CustomBarMenu from './CustomBarMenu';

const useStyles = makeStyles(theme => ({
    bar: {
        backgroundColor: '#304d75',
        // minHeight: '48px',
        // height: '48px',
        color: 'white',
    },
    adoitBar: {
        backgroundColor: 'rgb(0, 176, 94)',
        // minHeight: '48px',
        // height: '48px',
        color: 'white',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
}));

const CustomBar = (props) => {
    
    const classes = useStyles();

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    var companyId = null;
    if (decodedToken) {
        companyId = parseInt(decodedToken.company_id);
    }

    return (
        <AppBar {...props} 
            className={parseInt(process.env.REACT_APP_ADOIT_ID) == companyId ? classes.adoitBar : classes.bar} 
            userMenu={localStorage.getItem('user_name') ? <UserMenu /> : <CustomBarMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logotype />
            <span className={classes.spacer} />
        </AppBar>
    )
};

export default CustomBar;
import React, { useState, useEffect } from 'react';
import { Grid, Button, Chip, InputAdornment, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import AsyncSelect from 'react-select/async';

import { useNotify } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { LinearProgress, CircularProgress, IconButton, Collapse } from '@material-ui/core';

import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { TableSortLabel } from '@material-ui/core';

import ReactECharts from 'echarts-for-react';


const useStyles = makeStyles({
    root: {
        minWidth: 480,
    },
    tableWrapper: {
        overflowX: 'auto',
      },
    table: {
        minWidth: 650,
    },
    progressBar: {
        height: 10,
        borderRadius: 5,
        marginTop: 4,
      },
      barColorred: {
        backgroundColor: 'red',
      },
      barColororange: {
        backgroundColor: 'orange',
      },
      barColorgreen: {
        backgroundColor: 'green',
      },
      percentage: {
        display: 'flex',
        alignItems: 'center',
      },
      progressContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      progressLabel: {
        marginLeft: 10,
    },
    headerCell: {
        // fontWeight: 'bold',
        // fontSize: '1.2rem',
    },
});

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const FeatureOverlapGraph = ({ data, loading }) => {
    // Transform data to nodes and edges
    const { nodes, edges } = transformDataToGraph(data);

    const options = {
        tooltip: {
            formatter: (params) => {
                if (params.dataType === 'edge') {
                    return `${params.data.source} → ${params.data.target}<br/>Common Features: ${params.data.value}`;
                }
                return `${params.data.name}<br/>Total Features: ${params.data.totalFeatures}`;
            },
        },
        series: [
            {
                type: 'graph',
                layout: 'circular',
                symbolSize: (value) => Math.sqrt(value) * 5, // Adjust node size based on total features
                roam: true,
                label: {
                    show: true,
                    position: 'right',
                    formatter: '{b}',
                },
                data: nodes,
                links: edges,
                lineStyle: {
                    color: 'source',
                    curveness: 0.3,
                },
            },
        ],
    };

    if (loading) {
        return <div style={{ marginTop: 30, width: '100%', display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>;
    }

    if (!data) {
        return null;
    }

    return <ReactECharts option={options} style={{ height: '500px', width: '100%' }} />;
};

// Helper function to transform the data
const transformDataToGraph = (data = []) => {
    const nodesMap = new Map();
    const edges = [];

    data.forEach((item) => {
        // Add nodes
        if (!nodesMap.has(item.leftProductName)) {
            nodesMap.set(item.leftProductName, {
                name: item.leftProductName,
                totalFeatures: item.leftProductTotalFeatures,
                value: item.leftProductTotalFeatures,
                itemStyle: { color: getRandomColor() },
            });
        }
        if (!nodesMap.has(item.rightProductName)) {
            nodesMap.set(item.rightProductName, {
                name: item.rightProductName,
                totalFeatures: item.rightProductTotalFeatures,
                value: item.rightProductTotalFeatures,
                itemStyle: { color: getRandomColor() },
            });
        }

        // Add edge with the higher overlap percentage
        edges.push({
            source: item.leftProductName,
            target: item.rightProductName,
            value: item.commonFeaturesCount,
            maxOverlapPercentage: Math.max(item.leftOverlapPercentage, item.rightOverlapPercentage),
        });
    });

    const topEdges = edges
        .sort((a, b) => b.maxOverlapPercentage - a.maxOverlapPercentage)
        .slice(0, 30);

    const topNodesSet = new Set();
    topEdges.forEach(edge => {
        topNodesSet.add(edge.source);
        topNodesSet.add(edge.target);
    });

    // Convert the set to an array of nodes
    const nodes = Array.from(topNodesSet).map(nodeName => nodesMap.get(nodeName));

    return { nodes, edges: topEdges };
};

const getProgressColor = (value) => {
    if (value >= 70) return 'green';
    if (value >= 40) return 'orange';
    return 'red';
};


const Row = ({ row }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [rawLeftProductFeatures, setRawLeftProductFeatures] = useState([]);
    const [rawRightProductFeatures, setRawRightProductFeatures] = useState([]);
    const [leftProductFeatures, setLeftProductFeatures] = useState([]);
    const [rightProductFeatures, setRightProductFeatures] = useState([]);
    const [commonProductFeatures, setCommonProductFeatures] = useState([]);
    const [loading, setLoading] = useState(false);
    const [leftLoaded, setLeftLoaded] = useState(false);
    const [rightLoaded, setRightLoaded] = useState(false);

    const token = localStorage.getItem('token');

    const fetchData = async (productName, setRawFeatures, setLoaded) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/internal-products/features?name=${productName}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setRawFeatures(data);
            setLoaded(true);
        } catch (error) {
            console.error('Error:', error);
            setLoaded(true); // Even on error, mark as loaded to avoid infinite loading state
        }
    };

    const findCommonFeatures = (leftFeatures, rightFeatures) => {
        const commonFeatures = [];
        const lowerCaseFeatures = feature => `${feature.feature.toLowerCase()} (${feature.category.toLowerCase()})`;

        // First iteration: exact match by feature and category
        const leftMap = new Map();
        const rightMap = new Map();

        leftFeatures.forEach(feature => {
            const key = lowerCaseFeatures(feature);
            if (!leftMap.has(key)) {
                leftMap.set(key, feature);
            }
        });

        rightFeatures.forEach(feature => {
            const key = lowerCaseFeatures(feature);
            if (!rightMap.has(key)) {
                rightMap.set(key, feature);
            }
        });

        leftMap.forEach((leftFeature, key) => {
            if (rightMap.has(key)) {
                commonFeatures.push(leftFeature);
                leftMap.delete(key);
                rightMap.delete(key);
            }
        });

        // Second iteration: match by feature but different category
        const remainingLeftFeatures = [...leftMap.values()];
        const remainingRightFeatures = [...rightMap.values()];

        remainingLeftFeatures.forEach(leftFeature => {
            const key = leftFeature.feature.toLowerCase();
            const matchIndex = remainingRightFeatures.findIndex(rightFeature => rightFeature.feature.toLowerCase() === key);
            if (matchIndex !== -1) {
                commonFeatures.push(leftFeature);
                remainingRightFeatures.splice(matchIndex, 1);
            }
        });

        // Set final left and right product features, excluding common features
        const filteredLeftFeatures = remainingLeftFeatures.filter(feature => !commonFeatures.includes(feature));
        const filteredRightFeatures = remainingRightFeatures.filter(feature => !commonFeatures.includes(feature));

        // Transform data before storing
        setLeftProductFeatures(filteredLeftFeatures.map(feature => `${feature.feature} (${feature.category})`));
        setRightProductFeatures(filteredRightFeatures.map(feature => `${feature.feature} (${feature.category})`));
        setCommonProductFeatures(commonFeatures.map(feature => `${feature.feature} (${feature.category})`));
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            setLeftLoaded(false);
            setRightLoaded(false);
            fetchData(row.leftProductName, setRawLeftProductFeatures, setLeftLoaded);
            fetchData(row.rightProductName, setRawRightProductFeatures, setRightLoaded);
        }
    }, [open, row.leftProductName, row.rightProductName]);

    useEffect(() => {
        if (leftLoaded && rightLoaded) {
            findCommonFeatures(rawLeftProductFeatures, rawRightProductFeatures);
            setLoading(false);
        }
    }, [leftLoaded, rightLoaded]);

    const maxLength = Math.max(
        leftProductFeatures.length,
        commonProductFeatures.length,
        rightProductFeatures.length
    );

    const renderProgressBar = (value) => {
        const color = getProgressColor(value);
        return (
            <div className={classes.progressContainer}>
                <LinearProgress
                    variant="determinate"
                    value={value}
                    classes={{
                        bar: classes[`barColor${color}`],
                    }}
                    className={classes.progressBar}
                    style={{ width: `${value}%` }}
                />
                <Typography className={classes.progressLabel} style={{ color }}>
                    {formatPercentage(value)}
                </Typography>
            </div>
        );
    };

    const formatPercentage = (value) => {
        const roundedValue = Math.round(value);
        return `${roundedValue}%`;
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.leftProductName}</TableCell>
                <TableCell>{row.leftProductTotalFeatures}</TableCell>
                <TableCell>{renderProgressBar(row.leftOverlapPercentage)}</TableCell>
                <TableCell>{row.commonFeaturesCount}</TableCell>
                <TableCell>{renderProgressBar(row.rightOverlapPercentage)}</TableCell>
                <TableCell>{row.rightProductTotalFeatures}</TableCell>
                <TableCell>{row.rightProductName}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div style={{ margin: 10 }}>
                            {loading ? (
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : (
                                <div>
                                    <Table size="small" aria-label="features">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>{row.leftProductName} Features</strong></TableCell>
                                                <TableCell><strong>Common Features</strong></TableCell>
                                                <TableCell><strong>{row.rightProductName} Features</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.from({ length: maxLength }).map((_, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{leftProductFeatures[index] || ''}</TableCell>
                                                    <TableCell>{commonProductFeatures[index] || ''}</TableCell>
                                                    <TableCell>{rightProductFeatures[index] || ''}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            )}
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const FeatureOverlap = ({ data, loading }) => {
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortedData, setSortedData] = useState([]);

    const [sortBy, setSortBy] = useState();

    // Dynamic sorting by common features count
    useEffect(() => {
        if (data) {
            let sorted = [];
            switch (sortBy) {
                case 'commonFeaturesCount':
                    sorted = [...data].sort((a, b) =>
                        sortDirection === 'asc' ? a.commonFeaturesCount - b.commonFeaturesCount : b.commonFeaturesCount - a.commonFeaturesCount
                    );
                    break;
                case 'leftOverlapPercentage':
                    sorted = [...data].sort((a, b) =>
                        sortDirection === 'asc' ? a.leftOverlapPercentage - b.leftOverlapPercentage : b.leftOverlapPercentage - a.leftOverlapPercentage
                    );
                    break;
                case 'rightOverlapPercentage':
                    sorted = [...data].sort((a, b) =>
                        sortDirection === 'asc' ? a.rightOverlapPercentage - b.rightOverlapPercentage : b.rightOverlapPercentage - a.rightOverlapPercentage
                    );
                    break;
                default:
                    sorted = [...data];
                    break;
            }
            setSortedData(sorted);
        }
    }, [data, sortBy, sortDirection]);   

    // Sorting handler
    const handleSortRequest = (column) => {
        const isAsc = sortBy === column && sortDirection === 'asc';
        setSortBy(column);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };        

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return <div style={{ marginTop: 30, width: '100%', display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>;
    }

    if (!data) {
        return null;
    }

    const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <div style={{ marginTop: 30, width: '100%' }}>
            <Paper className={classes.tableWrapper}>
                <Table className={classes.table} aria-label="feature overlap table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell><strong>Solution</strong></TableCell>
                            <TableCell><strong># Features</strong></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortBy === 'leftOverlapPercentage'}
                                    direction={sortDirection}
                                    onClick={() => handleSortRequest('leftOverlapPercentage')}
                                >
                                    <strong>→ Overlap</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortBy === 'commonFeaturesCount'}
                                    direction={sortDirection}
                                    onClick={() => handleSortRequest('commonFeaturesCount')}
                                >
                                    <strong># Common Features</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortBy === 'rightOverlapPercentage'}
                                    direction={sortDirection}
                                    onClick={() => handleSortRequest('rightOverlapPercentage')}
                                >
                                    <strong>← Overlap</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell><strong># Features</strong></TableCell>
                            <TableCell><strong>Alternative</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <Row key={index} row={row} />
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
};

const SearchBar = ({ companyId, setData, setLoading }) => {

    const [errorText, setErrorText] = useState('');

    const [searchTerm, setSearchTerm] = useState([]);

    const notify = useNotify();

    const classes = useStyles();

    const handleShowResults = () => {
        if (!searchTerm) {
            return;
        }
        setErrorText('');

        setLoading({ chart: true, matrix: true });

        let searchRequest = {
            companyId: companyId,
            searchTerm: searchTerm
        };

        const token = localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/dashboard/feature-overlaps`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(searchRequest)
        })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                console.log(response);
                setData(response);
                setLoading({ chart: false, matrix: false });
            })
            .catch((error) => {
                const isString = Object.prototype.toString.call(error.message) == '[object String]';

                var message = error.message;

                if (!isString) {
                    message = Object.entries(error.message)
                        .map(([key, value]) => value)
                        .join('\n');
                }

                if (message == null || message == '') {
                    message = 'Internall server error';
                }

                notify(message, 'warning');
            })
            .finally(() => {

            });


    };

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleClear = () => {
        setSearchTerm('');
    };

    return (
        <div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>

                    <div className={classes.root}>
                        <TextField
                            style={{ marginTop: 4 }}
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="Search"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            error={!!errorText}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: '#777' }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ClearIcon onClick={handleClear} style={{ cursor: 'pointer', color: '#777' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <Button style={{ position: 'relative' }} variant="contained" color="primary" onClick={handleShowResults}>
                        Show results
                    </Button>
                </div>
            </div>
            {errorText && <div style={{ color: 'red' }}>{errorText}</div>}
        </div>
    );
};

const DataField = ({ loading, data, source }) => {

    if (loading || !data) {
        return <CircularProgress />
    }
    return <span>{data[source]}</span>
}


const DashboardSolutionOverlap = () => {

    const [companyId, setCompanyId] = useState(null);

    const [loading, setLoading] = useState({ chart: false, matrix: false });

    const [summary, setSummary] = useState();

    const [data, setData] = useState();

    const notify = useNotify();

    const loadSummary = () => {

        setLoading(previousState => ({
            ...previousState,
            matrix: true,
        }));

        const token = localStorage.getItem('token');

        let url = `${process.env.REACT_APP_API_URL}/dashboard/summary`;
        if (companyId) {
            url += `?companyId=${companyId}`;
        }

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify({ internal: true, external: false }),
        })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                setSummary(response);
            })
            .catch((error) => {
                const isString = Object.prototype.toString.call(error.message) == '[object String]';

                var message = error.message;

                if (!isString) {
                    message = Object.entries(error.message)
                        .map(([key, value]) => value)
                        .join('\n');
                }

                if (message == null || message == '') {
                    message = 'Internall server error';
                }

                notify(message, 'warning');
            })
            .finally(() => {
                setLoading(previousState => ({
                    ...previousState,
                    matrix: false,
                }));
            });
    }


    const getFeatureOverlaps = () => {

        setLoading(previousState => ({
            ...previousState,
            chart: true,
        }));

        const token = localStorage.getItem('token');

        let url = `${process.env.REACT_APP_API_URL}/dashboard/feature-overlaps`;

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify({ companyId }),
        })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                setData(response);
            })
            .catch((error) => {
                const isString = Object.prototype.toString.call(error.message) == '[object String]';

                var message = error.message;

                if (!isString) {
                    message = Object.entries(error.message)
                        .map(([key, value]) => value)
                        .join('\n');
                }

                if (message == null || message == '') {
                    message = 'Internall server error';
                }

                notify(message, 'warning');
            })
            .finally(() => {
                setLoading(previousState => ({
                    ...previousState,
                    chart: false,
                }));
            });
    }

    useEffect(() => {

        const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));
        if (decodedToken) {

            let companyId = decodedToken.company_id;
            setCompanyId(companyId);
        }
    }, []);

    useEffect(() => {
        if (companyId) {
            getFeatureOverlaps();
        }

    }, [companyId]);
    useEffect(() => {
        loadSummary();

    }, [companyId]);

    const classes = useStyles();

    return (
        <div style={{ marginTop: 0, width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                    <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>

                        <Typography variant="h5" style={{ color: '#605e5e' }}>Solution Insights</Typography>
                        <SearchBar setData={setData} setLoading={setLoading} companyId={companyId} />
                    </div>

                    <Grid container spacing={2} style={{ marginTop: 10 }}>

                        <Grid item sm={3} lg={2}>
                            <div style={{ backgroundColor: '#e6e6e6', padding: 20, borderRadius: 5, width: 170, position: 'relative', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)' }}>
                                <div className={classes.tagBlock}>
                                    <Typography variant="h4">
                                        <DataField loading={loading.matrix} data={summary} source="solutions" />
                                    </Typography>
                                    <Typography variant="h6" style={{ color: '#605e5e' }}>#Solutions</Typography>
                                </div>

                                <div className={classes.tagBlock} style={{ marginTop: 70 }}>
                                    <Typography variant="h4">
                                        <DataField loading={loading.matrix} data={summary} source="categories" />
                                    </Typography>
                                    <Typography variant="h6" style={{ color: '#605e5e' }}>#Categories</Typography>
                                </div>
                                <div className={classes.tagBlock}>
                                    <Typography variant="h4">
                                        <DataField loading={loading.matrix} data={summary} source="features" />
                                    </Typography>
                                    <Typography variant="h6" style={{ color: '#605e5e' }}>#Features</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={9} lg={10}>
                            <FeatureOverlapGraph loading={loading.chart} data={data} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'left', marginTop: '-20px' }}>
                        <FeatureOverlap loading={loading.matrix} data={data} />
                    </div>
                </Grid>
            </Grid>

        </div>

    )
}

export default DashboardSolutionOverlap;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
});

const imageUrl = `${process.env.REACT_APP_ROOT_FOLDER}images/servicenow.jpg`;

export default function MediaCardSap() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="images/aws-marketplace.png"
          title="AWS Marketplace"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            AWS Marketplace
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            AWS Marketplace is a curated digital catalog that makes it easy for
            customers to find, buy, deploy, and manage third-party software,
            data, and services that customers need to build solutions and run
            their businesses
            <br /> <br />
            Customers can quickly launch pre-configured software with just a few
            clicks, and choose software solutions in AMI and SaaS formats, as
            well as other formats
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          href="https://aws.amazon.com/marketplace"
          target="_blank"
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

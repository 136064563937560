import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    // popover: {
    //   pointerEvents: 'none',
    // },
    paper: {
      padding: theme.spacing(1),
    },
  }));

export default function SimplePopover({ scoreDetails = [] }) {
  const translate = useTranslate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button size="small" variant="text" color="secondary" aria-describedby={id} onClick={handleClick}>
        {translate('resources.suggestions.fields.details')}
      </Button>
      <Popover
        id={id}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
          {scoreDetails.length > 0 ? 
            <Fragment>
              <Typography>{translate('resources.suggestions.field_values.details.fit_less_100')}:</Typography>
              {scoreDetails.map(it => <Typography variant="caption" component="p" key={it}>- {it}</Typography>)}
            </Fragment>
            :
            <Fragment>
               <Typography>{translate('resources.suggestions.field_values.details.fit_100')}</Typography>
            </Fragment>
          }
          
        
      </Popover>
    </div>
  );
}
import React, { Fragment, useEffect, useState } from 'react';
import {
    List,
    CardActions, 
    Datagrid,
    TextField,
    CreateButton,
    // DeleteButton,
    EditButton,
    BooleanField,
    useTranslate,
    useDataProvider,
    Filter,
    SearchInput,
    SelectField,
    DateField,
    ArrayField,
    SingleFieldList,
    ChipField,

} from 'react-admin';

import Paper from '@material-ui/core/Paper';

import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { isSiemens } from '../utilities';

import compose from 'recompose/compose';

import DeleteButton from '../projects/DeleteButton';
import RecommendationState from './RecommendationState';

const CustomActions = ({ basePath, data, resource, resourceLabel = "create", permissions }) => (
    <CardActions>
        {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? <CreateButton 
            basePath={basePath}
            style={{ marginTop: 10, marginBottom: 15 }} 
            label={"resources.recommendations.action." + resourceLabel}
        /> : null}
        
    </CardActions>
);

const CustomFilter = (props) => (
    <Filter {...props} variant="standard">
        <SearchInput source="query" alwaysOn />
    </Filter>
);

const SoftwarePortfolioList = ({ permissions, theme, ...rest }) => {

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    if (decodedToken) {

        const companyId = decodedToken.company_id;

        return (
            <List 
                title="application.menu.software_portfolio"
                resource="software-portfolio"
                exporter={false}
                filters={<CustomFilter />} 
                filterDefaultValues={{ 'companyId': companyId, 'offering': 'SOFTWARE'  }}
                sort={{ field: 'updatedAt', order: 'DESC' }}
                perPage={25}
                actions={<CustomActions {...rest} permissions={permissions} resourceLabel="add_publisher" />} 
                bulkActionButtons={false}
                {...rest}
            >
                <Datagrid rowClick="show">
                    {isSiemens() && <TextField label="resources.recommendations.fields.supplier_number" source="attribute" />}
                    <TextField label="resources.recommendations.software_portfolio.publisher_name" source="providerName" />
                    <ArrayField label="resources.recommendations.software_portfolio.software" source="products" sortable={false}>
                        <SingleFieldList linkType={false}>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ArrayField>
                    {/* <DateField label="resources.recommendations.fields.createdAt" source="createdAt" /> */}
                    <DateField label="resources.recommendations.fields.updatedAt" source="updatedAt" />
                    <BooleanField label="resources.recommendations.fields.blocked" source="blocked" />
                    <RecommendationState />
                    
                    {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) && <EditButton />}
                    {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) && <DeleteButton />}
                </Datagrid>
            </List>
        )
    }

    return null;
    
};

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
);

export default enhance(SoftwarePortfolioList);
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { useTranslate,showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '20px',
    },
    button: {
        marginTop: '20px',
        marginBottom: '20px',
        margin: theme.spacing(1),
    },
    desc: {
        padding: '20px',
        display: 'flex',
    },
    descleft: {
        width: '60%'
    },
    descright: {
        color: "#ef9a9a"
    }
}));

export const Feedbak = connect(undefined, { showNotification })(props => {
    const classes = useStyles();
    const [open, setOpenDialog] = React.useState(false);
    const translate = useTranslate();
    const message=translate('application.validation.required');
    const { showNotification } = props;

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const onSubmit = (values) => { 
        let postUrl = process.env.REACT_APP_API_URL + "/feedbacks/knowledge";

        var formData = new FormData();
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('companyId', JSON.parse(localStorage.getItem('decoded_token')).company_id);
        axios.post(postUrl,formData,{})
        .then(function (response) {
            showNotification('Thank you for completing our online feedback form. Your feedback helps us understand what we do well and where we can improve.')
            handleCloseDialog();
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });
    };
    return (
        <div className={classes.root}>
            <Typography variant="h5" color="textPrimary">
                Are we missing a suggestion?
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
                Our database features thousands of suggestions but we’re always looking for more.
            </Typography>

            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpenDialog}
                className={classes.button}
                startIcon={<FeedbackIcon />}
            >
                Add a Suggestion
            </Button>

            <Paper className={classes.desc}>
                <div className={classes.descleft}>
                    <Typography variant="h5" component="h3">
                        Have Questions?
                    </Typography>
                    <Typography component="p">
                        Our team of experts is ready to help!
                    </Typography>
                </div>

                <div className={classes.descright}>
                    <Typography variant="h5">t +49 (0) 89 143 792 90</Typography>
                    <Typography variant="h5">f +49 (0) 89 143 792 98</Typography>
                </div>
            </Paper>

            <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Suggestion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        We would love to hear your thoughts, we’re always looking for more.
                    </DialogContentText>
                    <Form
                        onSubmit={onSubmit}
                        validate={values => {
                            const errors = {};
                            if (!values.title) {
                                errors.title = translate('application.validation.required');
                            }
                            if (!values.description) {
                                errors.description = translate('application.validation.required');
                            }
                            return errors;
                        }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <Field
                                    fullWidth
                                    required
                                    name="title"
                                    type="text"
                                    component={TextField}
                                    label='Title'
                                />
                                <Field
                                    fullWidth
                                    multiline
                                    required
                                    name="description"
                                    type="text"
                                    component={TextField}
                                    label='Description'
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                >
                                    {translate('ra.action.save')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className={classes.button}
                                    startIcon={<CancelIcon />}
                                    onClick={() => handleCloseDialog()}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
})

export default Feedbak;
import { stringify } from "query-string";
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from "react-admin";

/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&pageSize=10
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {};
    switch (type) {
      case GET_LIST: {
        // console.log('get list...', params);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        url = `${apiUrl}/${resource}?page=${page - 1}&size=${perPage}&sort=${field},${order}&${stringify(params.filter)}`;
        break;
      }
      case GET_ONE:
        // console.log('get one...');
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case GET_MANY: {
        // console.log('get many...');
        // const query = {
        //   filter: JSON.stringify({ id: params.ids })
        // };
        let identifiers = "";
        const queryString = params.ids.map(id => identifiers + `${id}`);
        url = `${apiUrl}/${resource}?id=${queryString}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        // console.log('get many reference...');
        const { page, perPage } = params.pagination;

        const { target, id, filter } = params;

        const { field, order } = params.sort;

        let queryString = "";
        if (target && id) {
          queryString += `&${target}=${id}`;
        }
        if (filter) {
          Object.entries(filter).forEach(([key, value]) => {
            queryString += `&${key}=${value}`;
          });
        }
        if (field && order && field) {
         
          queryString += `&sort=${field},${order}`;
        }

        url = `${apiUrl}/${resource}?page=${page -1}&size=${perPage}${queryString}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
  const convertHTTPResponse = (response, type, resource, params) => {
    const {
      // headers, 
      json
    } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!json.hasOwnProperty("totalElements")) {
          throw new Error(
            "The numberOfElements property must be must be present in the Json response"
          );
        }
        return {
          data: json.content,
          total: parseInt(json.totalElements, 10)
        };
      case CREATE:
        return { data: { ...json } };
      case GET_MANY:
        if (json.content) {
          return {
            data: json.content,
          };
        } else {
          return { data: json };
        }

      default:
        // console.log('data: ', json);
        return { data: json };
    }
  };

  /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE"
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    if (type === DELETE) {
      var url2 = `${apiUrl}/${resource}/${params.id}`;

      return httpClient(url2, {
        method: "DELETE"
      })
        .then(response => {
          return { data: "{}" };
        }
        )
        .catch(error => {
          return { data: "{}" };
          //console.log(error, error.message, error.status);
        });

    }

    if (type != DELETE) {

      const { url, options } = convertDataRequestToHTTP(type, resource, params);

      return httpClient(url, options)
        .then(response =>
          convertHTTPResponse(response, type, resource, params)
        )
        .catch(error => {
          // error is HttpError object
          console.log(error, error.message, error.status);
          // alert("error");
          //  window.stop();
          return Promise.reject(error); // rethrow it
        });
    }


  };
};
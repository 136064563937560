import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
//import { change, submit, isSubmitting } from 'redux-form';

import compose from 'recompose/compose';

import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    Button as CancelButton,
    FormDataConsumer, 
    SaveButton,
    SelectInput, 
    SimpleForm,
    TextInput,
    UPDATE,
    REDUX_FORM_NAME,
    withDataProvider,
    translate,
    Toolbar
} from 'react-admin';

import IconContentAdd from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import SendIcon from '@material-ui/icons/Send';

import { Button, Typography } from "@material-ui/core";
import { suggestionDecline } from './suggestionActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toolbar: {
        background:'none',
        paddingTop:'10px',
        flex: '0 0 auto',
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    button:{
        marginRight:'10px'
    }
}));

const PostCreateToolbar = (props) => {
    const classes = useStyles();
    return (
        <Toolbar className={classes.toolbar} {...props} >
            <SaveButton
                label="resources.messages.action.send"
                icon={<SendIcon />}
                className={classes.button}
            />
            <CancelButton label="ra.action.cancel" onClick={()=> props.hideDialog()}>
                <CancelIcon />
            </CancelButton>
        </Toolbar>
    )
}

class AcceptButton extends Component {
    state = {
        error: false,
        visible: false
    };

    showDialog = () => {
        this.setState({ visible: true });
    };

    hideDialog = () => {
        this.setState({ visible: false });
    };

    submitForm = () => {
        const { submit } = this.props;

        console.log('submit form');

        // submit(REDUX_FORM_NAME);
        document
              .getElementById('suggestion-decline')
              .dispatchEvent(new Event('submit', { cancelable: true }))

    };

    hideDialogAndRefreshMessagesAndShowMessage = (messageType) => {
        this.props.refreshMessages();
        this.props.refreshProject(this.props.record.id, messageType);
        this.setState({ visible: false, loading: false });
    };

    handleSubmit = values => {
        const { change, fetchStart, fetchEnd, showNotification, record, dataProvider, suggestionDecline } = this.props;
        suggestionDecline(record.id + "/consumer-status", { consumerStatus: 'DECLINED', message: values.message, feedbackType: 0, feedback: values.feedback }, this.hideDialogAndRefreshMessagesAndShowMessage);
    };

    render() {
        const { visible } = this.state;
        const { isSubmitting, translate } = this.props;

        return (
            <Fragment>
                <Button 
                    onClick={this.showDialog} 
                    variant="contained" 
                    style={{ minWidth: 110, marginLeft: 16, marginRight: 16, backgroundColor: 'rgb(171, 84, 141)', color: 'white' }}
                >
                    {translate('resources.suggestions.action.decline')}
                </Button>
                <Dialog
                    fullWidth
                    open={visible}
                    onClose={this.hideDialog}
                    disableEnforceFocus
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>{translate('resources.suggestions.dialog.decline')}</DialogTitle>
                    <DialogContent>
                        <Typography style={{ marginBottom: 16 }} variant="body2" id="alert-dialog-description">
                            {translate('resources.suggestions.dialog.message.message_1')}
                        </Typography>
                        <SimpleForm
                            id="suggestion-decline"
                            variant="standard"
                            resource="suggestions"
                            save={this.handleSubmit}
                            toolbar={<PostCreateToolbar hideDialog={this.hideDialog.bind(this)} />}
                            margin="normal"
                        >
                            <SelectInput style={{ marginLeft: -24, marginRight: -24, marginTop: -16 }} label="resources.suggestions.dialog.fields.feedback" source="feedback" validate={required()} choices={[
                                { id: 'NOT_FIT_PROJECT', name: 'resources.suggestions.dialog.field_values.feedback.option_1' },
                                { id: 'NOT_MEET_SOME_REQUIREMENTS', name: 'resources.suggestions.dialog.field_values.feedback.option_2' },
                                // { id: 'THE_GIVE_INFO_NOT_SUFFICIENT', name: 'resources.suggestions.dialog.field_values.feedback.option_3' },
                            ]} />
                            {/* <FormDataConsumer>
                                {({ formData, ...rest }) => formData.feedback === 'THE_GIVE_INFO_NOT_SUFFICIENT' &&
                                    <TextInput 
                                        style={{ marginLeft: -24, marginRight: -24, marginBottom: -16, width: '100%' }} 
                                        label="resources.suggestions.dialog.fields.question"
                                        source="message" 
                                        validate={required()} 
                                        resettable 
                                        multiline
                                        {...rest}
                                    />
                                }
                            </FormDataConsumer> */}
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (formData.feedback === 'NOT_FIT_PROJECT' || formData.feedback === 'NOT_MEET_SOME_REQUIREMENTS' ) &&
                                    <TextInput 
                                        style={{ marginLeft: -24, marginRight: -24, marginBottom: -16, width: '100%' }} 
                                        label="resources.suggestions.dialog.fields.message"
                                        source="message" 
                                        resettable 
                                        multiline
                                        {...rest}
                                    />
                                }
                            </FormDataConsumer>
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}


export default compose(
    connect(
        state => ({
            //isSubmitting: isSubmitting('suggestion-decline')(state)
        }),
        {
            //change,
            fetchEnd,
            fetchStart,
            showNotification,
            //submit,
            suggestionDecline: suggestionDecline,
        }
    ),
    // withStyles(styles),
    translate,
    withDataProvider,
) (AcceptButton);
import React, {useEffect } from 'react';
import { ReferenceInput, SelectInput, FormDataConsumer,Toolbar, SimpleForm, Edit, required, useTranslate,ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import AutocompleteKeywordArrayInput from './AutocompleteKeywordArrayInput';
import TopicInput from '../topic/TopicInput';
import DeleteButton from './DeleteButton';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { Field } from 'react-final-form';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CKEditor5 from '../common/CKEditor5'
import UnsavedDataObserver from '../UnsavedDataObserver';
import { ProjectEditButton } from './ProjectEditButton';
import { fetch, setDarkRichTextInput } from '../utilities';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import ReactDOM from 'react-dom';
import CustomFileInput from './CustomFileInput';
import KnooingFeatureBulletsTemplate from '../common/KnooingFeatureBulletsTemplate';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width : "80%"
    },
    gridcontainer: {
        padding: theme.spacing(1),
        marginBottom : "20px"
    },
    helpIconGrid:{
        position: "relative"
    },
    helpIcon:{
        paddingLeft:"20px",
        position: "absolute",
        bottom: "0",
        cursor: "pointer" ,
    },
    tooltip:{

    },
    title:{
        color: "#333333",
        fontSize: "1rem",
        fontWeight: "bold",
    },
    input: {
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.54)",
          fontSize: "16px",
          opacity: "1"
        },
        /**color: "black", // if you also want to change the color of the input, this is the prop you'd use**/
    },
    darkinput: {
        "&::placeholder": {
          color: "rgba(255, 255, 255, 0.7)",
          fontSize: "16px",
          opacity: "1"
        },
        /**color: "black", // if you also want to change the color of the input, this is the prop you'd use**/
    }
}));

const redirect = (basePath, id, data) => `/projects/${id}/show`;

const CustomToolbar = (props) => {
    const translate = useTranslate();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                <Toolbar {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ProjectEditButton redirect={redirect} undoable={false} />
                    {/* <DeleteButton /> */}
                </Toolbar>
            </Grid>
        </Grid>
    )
}

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => {
    const classes = useStyles();
    return (
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            placeholder={props.placeholder}
            {...inputProps}
            {...props}
            fullWidth
            InputLabelProps={{
                shrink: true
            }}
            InputProps={{
                classes: { input: classes.input}
            }}
        />
    )
};

const HtmlTooltip = withStyles(theme => ({
tooltip: {
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(13)
},
}))(Tooltip);

const TitleInput = props => {
    const translate = useTranslate();
    const message = translate('application.validation.required');
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.gridcontainer}>
            <Grid item xs={12} sm={11}>
                <Field
                    required
                    name="name"
                    validate={required(message)}
                    label={props.label}
                    placeholder={translate('resources.projects.placeholder.title')}
                    component={Input}
                />
            </Grid>
            <Grid item xs={12} sm={1} className={classes.helpIconGrid}>
                <HtmlTooltip className={classes.tooltip} TransitionProps={{ timeout: 600 }}  title={translate('resources.projects.tips.title')}  placement="right" arrow> 
                    <HelpOutlineIcon className={classes.helpIcon}/>
                </HtmlTooltip>  
            </Grid>
        </Grid>
    )
}

const DescriptionInput = props => {
    const translate = useTranslate();
    const message = translate('application.validation.required');
    const classes = useStyles();

    // Fill in the placeholders
    const knooingFeatureBullets = KnooingFeatureBulletsTemplate
        .replace("{cell01}", '<img src="tick-green.png" height="10"/>')
        .replace("{cell02}", translate('application.dashboard.info_box.item1'))
        .replace("{cell11}", '<img src="tick-green.png" height="10"/>')
        .replace("{cell12}", translate('application.dashboard.info_box.item2'))
        .replace("{cell21}", '<img src="tick-green.png" height="10"/>')
        .replace("{cell22}", translate('application.dashboard.info_box.item3'))
        .replace("{cell31}", '<img src="tick-green.png" height="10"/>')
        .replace("{cell32}", translate('application.dashboard.info_box.item4'))
        .replace("{cell41}", '<img src="x-red.png" height="10"/>')
        .replace("{cell42}", translate('application.dashboard.info_box.item5'))
        .replace("{cell51}", '<img src="x-red.png" height="10"/>')
        .replace("{cell52}", translate('application.dashboard.info_box.item6'));

    return (
        <Grid container spacing={1} className={classes.gridcontainer}>
            <Grid item xs={12} sm={11}>
                <CKEditor5 {...props}/>
            </Grid>
            <Grid item xs={12} sm={1} className={classes.helpIconGrid}>
                <HtmlTooltip className={classes.tooltip} TransitionProps={{ timeout: 600 }} title={<div dangerouslySetInnerHTML={{ __html: knooingFeatureBullets }} />} placement="right" arrow> 
                    <HelpOutlineIcon className={classes.helpIcon}/>
                </HtmlTooltip>
            </Grid>
        </Grid>
    )
}

const KeywordsInput = props => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.gridcontainer}>
            <Grid item xs={12} sm={11}>
                <AutocompleteKeywordArrayInput label="resources.projects.fields.keywords" {...props} />
            </Grid>
            <Grid item xs={12} sm={1} className={classes.helpIconGrid}>
                <HtmlTooltip className={classes.tooltip} TransitionProps={{ timeout: 600 }} title={translate('resources.projects.tips.keywords')} placement="right" arrow> 
                    <HelpOutlineIcon className={classes.helpIcon}/>
                </HtmlTooltip>
            </Grid>
        </Grid>
    )
}

const TopicInputsInput = props => {
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.gridcontainer}>
            <Grid item xs={12} sm={11}>
                <TopicInput label="resources.projects.fields.topics" source="topics"  {...props}/>
            </Grid>
            <Grid item xs={12} sm={1} className={classes.helpIconGrid}>
            </Grid>
        </Grid>
    )
}

const optionText = choice => `${choice.firstName} ${choice.lastName}`;

const CustomSelectArrayInput = ({ formData, choices, ...props }) => {
    const filteredChoices = choices.filter(choice => choice.id !== Number(formData.ownerId));
    return <div id="add-watcher"><SelectArrayInput {...props} choices={filteredChoices} /></div>
};
const CustomSelectInput = ({ formData,choices,...props }) => {
    const filteredChoices = choices.filter(choice => !formData.watcherId.includes(choice.id));
    return <SelectInput {...props} choices={filteredChoices}/>
};

export const Offering = props => {
    const translate = useTranslate();
    const message = translate('application.validation.required');
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.gridcontainer}>
            <Grid item xs={12} sm={12}>
                <SelectInput
                    // validate={required(message)}
                    {...props}
                />
            </Grid>
            
        </Grid>
    )
}

const ProjectEdit = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();

    useEffect(() => {
         if (props.theme === 'dark') {
            setDarkRichTextInput();

            const placeholders = document.querySelectorAll("input[placeholder]");
            placeholders.forEach(element => {
                element.classList.add(classes.darkinput);
            });
            
            const labels = document.querySelectorAll('label');
            labels.forEach(element => {
                element.style.color = "#FFFFFF";
                element.style.fontSize = "1.2rem";
                element.style.fontWeight = "bold";
            });
        }else{
            const labels = document.querySelectorAll('label');
            labels.forEach(element => {
                element.style.color = "#333333";
                element.style.fontSize = "1.2rem";
                element.style.fontWeight = "bold";
            });
        }

    });

    useEffect(() => {
        const anchor = props.location.hash.replace('#', '');
        console.log('anchor: ', anchor)
        if (anchor) {
            const element = document.getElementById(anchor.trim());
            if (element) {
                element.scrollIntoView();
            }
        }

    });

    return (
        <div className={classes.root}>
            <Edit undoable={false} title="resources.projects.singular_name" {...props}>
                    <SimpleForm variant="standard" margin="normal" toolbar={<CustomToolbar />} defaultValue={{ companyId : JSON.parse(localStorage.getItem('decoded_token')).company_id }}>
                        {/* <UnsavedDataObserver /> */}

                        {/* This should not happen because this page is hidden from not allowed users but if a user will try to access somehow this will throw an error */}
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>  {

                                const userId = Number(JSON.parse(localStorage.getItem('decoded_token')).user_name);

                                if (formData.ownerId !== userId && (!formData.watcherId.includes(userId) && permissions && permissions.includes('ROLE_USER'))) {
                                    throw new Error('Forbidden!');
                                }
                                if (formData.archived) {
                                    throw new Error('Forbidden!');
                                }
                                // console.log('formData: ', formData);
                                // console.log('userId: ', userId);
                            }}
                        </FormDataConsumer>

                        <Offering 
                            disabled
                            source="offering" 
                            emptyText="resources.projects.fields.offering_placeholder"
                            choices={[
                                { id: 'SOFTWARE', name: 'Software' },
                                { id: 'SERVICE', name: 'Service' },
                            ]} 
                        />

                        <TitleInput source="name" label={translate('resources.projects.fields.title')}/>
                        <DescriptionInput source="description" label={translate('resources.projects.fields.description')} 
                            placeholder={translate('resources.projects.placeholder.description')} {...props}/>
                        <KeywordsInput source="keywords" label="resources.projects.fields.keywords"/>
                        <CustomFileInput />

                        <FormDataConsumer> 
                            {formDataProps => 
                                formDataProps.formData.topicsEnabled &&
                                    <TopicInputsInput source="topics" label="resources.projects.fields.topics"
                                        placeholder={translate('resources.projects.placeholder.topics')} {...formDataProps} />
                            }
                        </FormDataConsumer>

                        <FormDataConsumer>
                            {formDataProps => (
                                <ReferenceInput variant="standard" style={{ minWidth: '30%', margin:"8px" }} label="resources.projects.fields.owner.id" source="ownerId" resource="users" reference="users" filter={{ companyId: JSON.parse(localStorage.getItem('decoded_token')).company_id }}>
                                    <CustomSelectInput optionText={optionText}  {...formDataProps}/>
                                </ReferenceInput> 
                            )}
                        </FormDataConsumer>

                        {/* <FormDataConsumer>
                            {formDataProps => (
                                <ReferenceArrayInput variant="standard" style={{ minWidth: '30%', margin:"8px" }} label="resources.projects.fields.watchers" reference="users" source="watcherId" resource="users" filter={{ companyId: JSON.parse(localStorage.getItem('decoded_token')).company_id }}>
                                    <CustomSelectArrayInput optionText={optionText} {...formDataProps} />
                                </ReferenceArrayInput>
                            )}
                        </FormDataConsumer>  */}
  
                    </SimpleForm>
                </Edit>
        </div>
    )
};

export default compose(
    connect(
        state => ({
            theme: state.theme
        }),
    )
)(ProjectEdit);
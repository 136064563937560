import React from 'react';
import { EmailField, BooleanField, SelectField, DateField, Show, SimpleShowLayout, TextField, EditButton, ListButton, CardActions } from 'react-admin';

import { connect } from 'react-redux';

import compose from 'recompose/compose';

const CustomActions = ({ permissions, basePath, data, resource }) => (
    <CardActions>
        <ListButton basePath={basePath} />
        {permissions && (permissions.includes('ROLE_EXECUTIVE') || (permissions.includes('ROLE_POWER_USER') && data && data.roleName === 'ROLE_USER')) ? <EditButton basePath={basePath} record={data} /> : null}
    </CardActions>
);

const CustomEmailField = ({ theme, ...props }) => {

    return theme === 'dark' ? 
        <EmailField style={{ color: 'white' }} source="email" {...props} />
        :
        <EmailField source="email" {...props} />
};

const UserShow = ({ permissions, theme, ...props }) => (
    <Show title="resources.users.singular_name" actions={<CustomActions permissions={permissions} />} {...props}>
        <SimpleShowLayout>
            <SelectField source="salutation" choices={[
                { id: 'MALE', name: 'resources.users.field_values.salutation.male' },
                { id: 'FEMALE', name: 'resources.users.field_values.salutation.female' },
            ]} />
            <TextField source="firstName" />
            <TextField source="lastName" />

            <TextField source="position" />

            <CustomEmailField source="email" theme={theme} />
            <TextField source="phone" />
            <TextField source="mobilePhone" />

            <SelectField source="roleName" choices={[
                { id: 'ROLE_USER', name: 'resources.users.field_values.role.user' },
                { id: 'ROLE_POWER_USER', name: 'resources.users.field_values.role.power_user' },
                { id: 'ROLE_EXECUTIVE', name: 'resources.users.field_values.role.executive' },
            ]} />
            <SelectField source="jobExperience" choices={[
                { id: 'LEVEL_0', name: 'resources.users.field_values.job_experience.level_0' },
                { id: 'LEVEL_1', name: 'resources.users.field_values.job_experience.level_1' },
                { id: 'LEVEL_2', name: 'resources.users.field_values.job_experience.level_2' },
                { id: 'LEVEL_3', name: 'resources.users.field_values.job_experience.level_3' },
                { id: 'LEVEL_4', name: 'resources.users.field_values.job_experience.level_4' },
                { id: 'LEVEL_5', name: 'resources.users.field_values.job_experience.level_5' },
                { id: 'UNDEFINED', name: 'resources.users.field_values.job_experience.undefined' },
            ]} />

            <BooleanField source="enabled" />
            <DateField source="enabledAt" />
            <BooleanField source="locked" />

        </SimpleShowLayout>
    </Show>
);

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
    // translate,
    // withStyles(styles)
);

export default enhance(UserShow);
import React, { Component, Fragment, useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { Menu, MenuItem, Card, CardHeader, CardContent, TablePagination, Grid, List, ListItem, RadioGroup, Radio, FormControlLabel, ListItemAvatar, Avatar, Stepper, Step, StepLabel, ListItemIcon, ListItemText, Typography, Chip, Icon, IconButton, Tooltip, Button, Checkbox } from "@material-ui/core";

import CrossIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@material-ui/icons/Delete';

import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import InformationIcon from '@material-ui/icons/Info';

import classnames from 'classnames';
import { Legend } from './Legend';
import { VendorConsolidation } from './VendorConsolidation';
import EditCapability from './EditCapability';

const useStyles = makeStyles(theme => ({
    root: {
        height: '20em',
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftColumn: { flex: 1, marginRight: '1em' },
    rightColumn: { flex: 1, marginLeft: '1em' },
    singleColumn: { marginBottom: '2em' },
    fab: {
        margin: theme.spacing(1),
        backgroundColor: '#304d75'
    },
    tableContainer: {
        maxWidth: '75vw',
        overflowX: 'auto',
        margin: '0 auto',
    },
    table: {
        tableLayout: 'fixed',
        margin: '0px auto 0px auto',
    },
    fixedHeader: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        height: 50,
        backgroundColor: 'white',
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
    },
    fixedCell: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        borderRight: '1px solid black',
        height: 29,
        backgroundColor: 'white',
        zIndex: 1,
    },
    header: {
        width: 200,
        height: 50
    },
    cell: {
        width: 200,
        borderCollapse: 'collapse',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        height: 29
    },
    firstHeader: {
        width: 200,
        paddingLeft: 300,
        height: 50,
    },
    firstCell: {
        width: 200,
        paddingLeft: 300,
        borderCollapse: 'collapse',
        borderRight: '1px solid black',
        height: 29
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    border : {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    green: {
        color: 'rgb(144, 198, 83)',
    },
    orange: {
        color: '#ffb74d',
    },
    blue: {
        color: '#39a1de',
    },
    gray: {
        color: '#cccccc',
    }
}));

export const Vendor = (props) => {

    const { record, step, type } = props;

    const [open, setOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);

    const [capabilityDialogVisible, setCapabilityDialogVisible] = useState(false);

    const showMenu = event => {
        event.stopPropagation();

        setAnchor(event.currentTarget);
        setOpen(true);
    };

    const hideMenu = () => {
        setAnchor(null);
        setOpen(false);
    };

    function editCapability(event) {
        event.stopPropagation();
        setCapabilityDialogVisible(true);
        hideMenu();
    }

    const classes = useStyles();
    
    const translate = useTranslate();

    const headers = [
        translate('resources.projects.vendor_consolidation.title'),
        translate('resources.projects.vendor_consolidation.owner'),
        translate('resources.projects.vendor_consolidation.watcher'),
        translate('resources.projects.vendor_consolidation.created_date'),
        '',
    ]

    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginLeft: 16, marginTop: 20 }}>
                `<Typography style={{ marginRight: 16, fontSize: '1.25rem', fontWeight: 'bold' }}>{translate('resources.projects.vendor_consolidation.example_1')}</Typography>
                <Button variant="outlined" color="primary" aria-haspopup="true" onClick={showMenu}>
                    {translate('resources.projects.vendor_consolidation.actions')}
                </Button>
                <Menu
                    keepMounted
                    open={open}
                    anchorEl={anchor}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={hideMenu}
                    onClick={(event) => event.stopPropagation()}
                >
                    <MenuItem>
                        <Typography variant="inherit">{translate("resources.projects.vendor_consolidation.add_watcher")}</Typography>
                    </MenuItem>
                    <MenuItem onClick={editCapability}>
                        <Typography variant="inherit">{translate("resources.projects.vendor_consolidation.edit_capability")}</Typography>
                    </MenuItem>
                    <MenuItem>
                        <Typography variant="inherit">{translate("resources.projects.vendor_consolidation.edit_vendors")}</Typography>
                    </MenuItem>
                    <MenuItem>
                        <Typography variant="inherit">{translate("resources.projects.vendor_consolidation.refresh_result")}</Typography>
                    </MenuItem>
                    <MenuItem>
                        <Typography variant="inherit">{translate("resources.projects.vendor_consolidation.delete_task")}</Typography>
                    </MenuItem>
                </Menu>
            </div>`
            
            <Card>
                <CardContent>
                    
                    <Typography component="p" style={{ fontSize: '0.875rem' }}>{translate('resources.projects.vendor_consolidation.description_3')}</Typography>
                    <VendorConsolidation visibleHeader={false} />
                </CardContent>
            </Card>
            <EditCapability open={capabilityDialogVisible} setOpen={setCapabilityDialogVisible} />
        </Fragment>
    );
}
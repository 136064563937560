import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud,faChartBar,faBrain,faNetworkWired,faShieldAlt,faVoicemail,faFileCode,faDatabase,faChartPie,faTh } from '@fortawesome/free-solid-svg-icons'
import Feedback from '../feedbacks/Feedback';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.colorPrimary,
    flexBasis: '45%',
    flexShrink: 0,
    marginLeft: '20px',
    width: '45%',
  },
  icon: {
    width: '1.25em'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
    marginLeft: '10px',
  },
  details: {
    flexDirection: "column"
  },
  title:{
    fontSize: theme.typography.pxToRem(15),
    color: "#64c7f3",
    fontWeight: 700,
  },
  countent:{
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
}));

const useNavStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

function IconBreadcrumbs() {
  const classes = useNavStyles();

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge`} >
          Knowledge Center
          </Link>
          <Typography color="textPrimary">Portfolio</Typography>
        </Breadcrumbs>
      </Paper>
    </div>
  );
}

function Portfolio(){
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faCloud}/></span>
          <Typography className={classes.heading}>Cloud</Typography>
          <Typography className={classes.secondaryHeading}>Cloud Strategy, Architecture und Migration ...</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          Cloud Strategy, Architecture und Migration
          </Typography>
          <Typography className={classes.countent}>
            Cloud Strategy, Architecture und Migration
          </Typography>
          <p/>
          <Typography className={classes.title}>
            Cloud Computing:
          </Typography>
          <Typography className={classes.countent}>
            Public, Private/Colocation<br/>
            Hybrid<br/>
          Multicloud<br/>
          Big Data Cloud<br/>
          </Typography>   
          <p/>
          <Typography className={classes.title}>
          Cloud Services:
          </Typography>
          <Typography className={classes.countent}>
          IaaS<br/>
          PaaS<br/>
          serverless und SaaS<br/>
          </Typography>  
          <p/>
          <Typography className={classes.title}>
          Security, Compliance & Privacy & Risks
          </Typography>
          <Typography className={classes.countent}>
          Security, Compliance & Privacy & Risks
          </Typography>         
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faChartBar}/></span>
          <Typography className={classes.heading}>Analytics and Data Science</Typography>
          <Typography className={classes.secondaryHeading}>
          Analytics and Business Intelligence
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          Analytics and Business Intelligence:
          </Typography>
          <Typography className={classes.countent}>
          Analytics and BI Platform<br/>
          Data Visualization<br/>
          Predictive Analytics<br/>
          Conversion Optimization<br/>
          Web Analytics<br/>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faBrain}/></span>
          <Typography className={classes.heading}>Artificial Intelligence</Typography>
          <Typography className={classes.secondaryHeading}>
          Machine Learning(ML), AutoML ... 
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          Data Science:
          </Typography>
          <Typography className={classes.countent}>
          Machine Learning(ML), AutoML<br/>
          Deep Learning(DL)<br/>
          Computer Vision(CV)<br/>
          Natural Language Processing(NLP)<br/>
          Robotics<br/>
          Neural Networks<br/>
          Expert Systems<br/>
          Speech Recognition<br/>
          Cognitive Science<br/>
          Chatbot<br/>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faNetworkWired}/></span>
          <Typography className={classes.heading}>Internet of Things (IOT)</Typography>
          <Typography className={classes.secondaryHeading}>
          IoT Platform, Clouds, Security, OS ...
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          Proof-of-Concepts
          </Typography>
          <Typography className={classes.countent}>
          Proof-of-Concepts
          </Typography>
          <p/>
          <Typography className={classes.title}>
          IoT Platform, Clouds, Security, OS
          </Typography>
          <Typography className={classes.countent}>
          IoT Platform, Clouds, Security, OS
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Mobile IoT (MIoT)
          </Typography>
          <Typography className={classes.countent}>
          Mobile IoT (MIoT)
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Smart Building
          </Typography>
          <Typography className={classes.countent}>
          Smart Building
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Digital / Smart Factory
          </Typography>
          <Typography className={classes.countent}>
          Digital / Smart Factory
          </Typography>
          <p/>
          <Typography className={classes.title}>
          IoT Hardware
          </Typography>
          <Typography className={classes.countent}>
          IoT Hardware
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faShieldAlt}/></span>
          <Typography className={classes.heading}>Security</Typography>
          <Typography className={classes.secondaryHeading}>
          Governance, Risk Management, and Compliance (GRC) ...
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          Governance, Risk Management, and Compliance (GRC)
          </Typography>
          <Typography className={classes.countent}>
          Governance, Risk Management, and Compliance (GRC)
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Digital Security, Cyber Security
          </Typography>
          <Typography className={classes.countent}>
          Digital Security, Cyber Security
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Threats, Attacks & Vulnerabilities
          </Typography>
          <Typography className={classes.countent}>
          Threats, Attacks & Vulnerabilities
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Communication and Network Security, Monitoring, Logging
          </Typography>
          <Typography className={classes.countent}>
          Communication and Network Security, Monitoring, Logging
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Identity and Access Management (IAM)
          </Typography>
          <Typography className={classes.countent}>
          Identity and Access Management (IAM)
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Security Architecture and Engineering
          </Typography>
          <Typography className={classes.countent}>
          Security Architecture and Engineering
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faVoicemail}/></span>
          <Typography className={classes.heading}>Enterprise Communications & Collaboration</Typography>
          <Typography className={classes.secondaryHeading}>
          Unified Communication and Collaboration (UCC) ...
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          Unified Communication and Collaboration (UCC)
          </Typography>
          <Typography className={classes.countent}>
          Unified Communication and Collaboration (UCC)
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Web and Video Conferencing
          </Typography>
          <Typography className={classes.countent}>
          Web and Video Conferencing
          </Typography>
          <p/>
          <Typography className={classes.title}>
          VoIP and SIP
          </Typography>
          <Typography className={classes.countent}>
          VoIP and SIP
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Emergency Communications
          </Typography>
          <Typography className={classes.countent}>
          Emergency Communications
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Instant Messaging
          </Typography>
          <Typography className={classes.countent}>
          Instant Messaging
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Collaborative Workspaces
          </Typography>
          <Typography className={classes.countent}>
          Collaborative Workspaces
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Document Collaboration
          </Typography>
          <Typography className={classes.countent}>
          Document Collaboration
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Team Collaboration
          </Typography>
          <Typography className={classes.countent}>
          Team Collaboration
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Wikis and Discussion Spaces
          </Typography>
          <Typography className={classes.countent}>
          Wikis and Discussion Spaces
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faFileCode}/></span>
          <Typography className={classes.heading}>Software Development and Devops</Typography>
          <Typography className={classes.secondaryHeading}>
          Software Development Services ...
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          Software Development Services:
          </Typography>
          <Typography className={classes.countent}>
          Proof of Concepts<br/>
          Backend and Frontend Web<br/>
          Native Apps Development<br/>
          </Typography>
          <p/>
          <Typography className={classes.title}>
          API Ecosystems:
          </Typography>
          <Typography className={classes.countent}>
          Design<br/>
          Documentation<br/>
          Management<br/>
          Deployment<br/>
          Monitoring<br/>
          Analytics<br/>
          Security<br/>
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Databases:
          </Typography>
          <Typography className={classes.countent}>
          Relational Databases<br/>
          Time Series Databases<br/>
          NoSQL Databases<br/>
          Database Tools<br/>
          Graph Databases<br/>
          In-Memory Data Store<br/>
          </Typography>
          <p/>
          <Typography className={classes.title}>
          DevOps:
          </Typography>
          <Typography className={classes.countent}>
          Continuous Integration and Delivery(CI&CD)<br/>
          Test Automation & Management<br/>
          Build and Deployment Automation<br/>
          Application Maintenance and Monitoring<br/>
          Resource Profiling and Monitoring<br/>
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Testing:
          </Typography>
          <Typography className={classes.countent}>
          Browser Testing<br/>
          Performance and Load Testing<br/>
          Application Testing<br/>
          Security Testing<br/>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faDatabase}/></span>
          <Typography className={classes.heading}>Enterprise Resource Planning (ERP)</Typography>
          <Typography className={classes.secondaryHeading}>
          Reorganisation, Carve Out ...
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          M&A
          </Typography>
          <Typography className={classes.countent}>
          M&A
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Reorganisation, Carve Out
          </Typography>
          <Typography className={classes.countent}>
          Reorganisation, Carve Out
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Datenmigration
          </Typography>
          <Typography className={classes.countent}>
          Datenmigration
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faChartPie}/></span>
          <Typography className={classes.heading}>Productivity and Operations</Typography>
          <Typography className={classes.secondaryHeading}>
          Ecommerce , Productivity , Operations ...
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          Ecommerce:
          </Typography>
          <Typography className={classes.countent}>
          Platform<br/>
          Analytics<br/>
          Customization<br/>
          API<br/>
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Productivity:
          </Typography>
          <Typography className={classes.countent}>
          Digital Signatures<br/>
          Digital Transformation<br/>
          Workflow Automation<br/>
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Operations:
          </Typography>
          <Typography className={classes.countent}>
          Supply Chain Management<br/>
          Partner Management<br/>
          Warehouse Management System<br/>
          Fleet Management and Logistics<br/>
          BPM<br/>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <span className={classes.icon}><FontAwesomeIcon icon={faTh}/></span>
          <Typography className={classes.heading}>Emerging Technologies</Typography>
          <Typography className={classes.secondaryHeading}>
          Intelligent , Digital , Mesh , Privacy and Ethics ...
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Typography className={classes.title}>
          Intelligent:
          </Typography>
          <Typography className={classes.countent}>
          Autonomous Things<br/>
          Augmented Analytics<br/>
          AI-Driven Development<br/>
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Digital:
          </Typography>
          <Typography className={classes.countent}>
          Digital Twin<br/>
          Empowered Edge<br/>
          Immersive Experience<br/>
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Mesh:
          </Typography>
          <Typography className={classes.countent}>
          Blockchain<br/>
          Smart Spaces<br/>
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Privacy and Ethics
          </Typography>
          <Typography className={classes.countent}>
          Privacy and Ethics
          </Typography>
          <p/>
          <Typography className={classes.title}>
          Quantum Computing
          </Typography>
          <Typography className={classes.countent}>
          Quantum Computing
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

class PortfolioInfo extends React.Component {
  render () {
    return (
      <div>
        <IconBreadcrumbs/>
        <br/>
        <Portfolio />
        <br/>
        <Feedback/>
      </div>
    );
  }
}

export default PortfolioInfo;


import React, { useState, useEffect } from 'react';
import { TextField, SelectField, Show, SimpleShowLayout, TopToolbar, useTranslate, useRefresh } from 'react-admin';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import ProjectLayout from './ProjectLayout';
import { Actions } from './Actions';
import { ArchivedProjectActions } from './ArchivedProjectActions';
import { SuggestionsProcessed } from './SuggestionsProcessed';

import { makeStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { TramRounded } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    white: {
        color: 'white',
        opacity: '1'
    },
    gray: {
        color: '#777'
    }
}));

const CustomActions = ({ basePath, data, resource, theme, activateVendorConsolidation }) => {

    const translate = useTranslate();
    const classes = useStyles();
    
    return (
        <TopToolbar style={{ justifyContent: 'space-between', alignItems: 'baseline', marginLeft: 16 }}>
            <div>
                <TextField className={theme === 'dark' ? classes.white : null } style={{ marginRight: 16, fontSize: '1.25rem', fontWeight: 'bold' }} source="name" record={data}/>
                
                <span className="MuiTypography-body2" className={theme === 'dark' ? classes.white : classes.gray }>{translate('resources.projects.fields.state')}: </span>
                
                <SelectField 
                    record={data}
                    source="state" 
                    className={theme === 'dark' ? classes.white : classes.gray }
                    choices={[
                        { id: 'ANALYSIS_RUNNING', name: 'resources.projects.field_values.state.analysis_running' },
                        { id: 'MATCHING_AVAILABLE', name: 'resources.projects.field_values.state.matching_available' },
                        { id: 'VERIFICATION_RUNNING', name: 'resources.projects.field_values.state.verification_running' },
                        { id: 'SOURCING', name: 'resources.projects.field_values.state.sourcing' },
                    ]}
                />
            </div>

            {data && !data.archived ? 
                <Actions activateVendorConsolidation={activateVendorConsolidation} record={data} />
                :
                <ArchivedProjectActions record={data} />
            }
        </TopToolbar>
    )
};

const Notification = ({ message, open, onClose }) => {

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ]}
            message={message}
        />
    )
}

const ProjectShow = (props) => {

    const { theme } = props;

    const refresh = useRefresh();
    const translate = useTranslate();

    const [vendorConsolidationActivated, setVendorConsolidationActivated] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    const showNotification = (type) => {
        let message = '';

        switch (type) {
            case 0:
                message = translate('resources.suggestions.notification.declined_success');
                break;
            case 1:
                message = translate('resources.suggestions.dialog.message.message_3');
                break;
            case 2:
                message = translate('resources.suggestions.dialog.message.message_4');
                break;
        
            default:
                break;
        }

        setNotificationMessage(message);
        setNotificationOpen(true);
    }

    const hideNotification = () => {

        setNotificationOpen(false);
        setNotificationMessage('');
    }

    const refreshProject = (suggestionId, notificationType) => {

        if (suggestionId) {
            window.location.href = window.location.href.split('?')[0] + "?suggestion=" + suggestionId;
        }
        refresh();
        if (notificationType) {
            showNotification(notificationType);
        }
    }

    return (
        <Show title="resources.projects.singular_name" {...props} actions={<CustomActions activateVendorConsolidation={() => setVendorConsolidationActivated(TramRounded)} theme={theme} />}>
            <SimpleShowLayout title="resources.projects.singular_name" {...props}>
                <ProjectLayout vendorConsolidationActivated={vendorConsolidationActivated} refreshProject={refreshProject} />
                
                <SuggestionsProcessed refreshProject={refreshProject} />

                <Notification open={notificationOpen} message={notificationMessage} onClose={hideNotification} />
            </SimpleShowLayout>
        </Show>
    );
}

export default compose(
    connect(
        state => ({
            theme: state.theme
        }),
    )
)(ProjectShow);

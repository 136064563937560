import React, { useState, Fragment } from 'react';
import { Confirm } from 'react-admin';
import { connect } from 'react-redux';
//import { change, submit, isSubmitting } from 'redux-form';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';

import {
    fetchEnd,
    fetchStart,
    required,
    useNotify,
    FormDataConsumer, 
    SaveButton,
    SelectInput, 
    SimpleForm,
    TextInput,
    UPDATE,
    useRefresh,
    useDataProvider,
    useTranslate
} from 'react-admin';

import IconContentAdd from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import SendIcon from '@material-ui/icons/Send';

import { Button } from "@material-ui/core";

const CancelButton = ({ record }) => {

    const notify = useNotify();
    const translate = useTranslate();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const [open, setOpen] = useState(false);

    const showDialog = event => {
        if (event) {
            event.stopPropagation();
        }
        setOpen(true);
    };

    const hideDialog = event => {
        if (event) {
            event.stopPropagation();
        }
        setOpen(false);
    };

    const confirm = () => {

        dataProvider(UPDATE, 'suggestions', { id: record.id + "/consumer-status", data: { consumerStatus: 'PENDING' } })
            .then(({ data }) => {

                // change(REDUX_FORM_NAME, 'status', data.status);
                // this.setState({ visible: false });

                window.location.href = window.location.href.split('?')[0] + "?suggestion=" + record.id;
                window.location.reload(false);
                
                // push(`/projects/${record.id}/show/1`);
                // this.hideDialog();
            })
            .catch(error => {
                notify(error.message, 'error');
            });
    };

    return (

        <Fragment>
            <Button 
                onClick={showDialog} 
                size="small" 
                variant="contained" 
            >
                {translate('resources.suggestions.dialog.cancel')}
            </Button>
            <Confirm
                isOpen={open}
                title="resources.suggestions.dialog.cancel"
                content="resources.suggestions.dialog.message.confirmation"
                cancel="ra.boolean.false"
                onConfirm={confirm}
                onClose={hideDialog}
            />
        </Fragment>
    );
}

export default CancelButton;
import React, {
    HtmlHTMLAttributes,
    ReactNode,
    useRef,
    useEffect,
    useMemo,
} from 'react';

import { Notification, defaultTheme, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    Card,
    Avatar,
    createMuiTheme,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { StaticContext } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';

import ProviderRegistrationForm from './ProviderRegistrationForm';

import './style.css';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '0em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
}));


const ProviderRegistration = ({
    theme,
    className,
    children,
    staticContext,
    backgroundImage,
    ...rest
}) => {
    const styles = useStyles({});
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    const translate = useTranslate();
    
    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classnames(styles.main, className)}
                {...rest}
            >
                <Card className={styles.card}>
                    <div className={styles.avatar}>
                        <Avatar className={styles.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    
                    {children}

                </Card>
                <Notification />
            </div>
        </ThemeProvider>
    );
};

ProviderRegistration.propTypes = {
    backgroundImage: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

ProviderRegistration.defaultProps = {
    backgroundImage: `${process.env.REACT_APP_ROOT_FOLDER}images/background-image.jpeg`,
    theme: defaultTheme,
    children: <ProviderRegistrationForm />,
};

export default ProviderRegistration;
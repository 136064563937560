import React, { useState, useEffect } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';

import { Dialog, DialogTitle, DialogContent, TextField, Button } from "@material-ui/core";

import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import { Field, Form } from 'react-final-form';

const useStyles = makeStyles(theme => ({

    icon: {
        marginRight: theme.spacing(1),
    },
}));

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        // fullWidth
    />
);

export const PasswordDialog = ({ userProfile, visible, onClose, callback }) => {

    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(visible);
    const [error, setError] = useState();

    const url = `${userProfile.id}/matched-password`;

    function submit(values) {
        setError(false);
        
        dataProvider
            .update('users', { id: url, data: { ...values } })
            .then(({ data }) => {

                if (!data.matched) {

                    let error = {
                        message: translate('application.profile.notification.password.updated_error')
                    }
                    setError(error);
                } else {
                    callback();
                }
            })
            .catch(error => {
                setError(error);
            });
    }

    useEffect(() => {

        setOpen(visible);
    }, [visible]);

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
            disableEnforceFocus
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle>{translate('application.profile.message.action_confirmation')}</DialogTitle>
            <DialogContent style={{ overflow: 'visible' }}>
                <Form
                    onSubmit={submit}
                    validate={(values) => {
                        const errors = {};
                        if (!values.oldPassword) {
                            errors.oldPassword = 'Required'
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <p>{translate('application.profile.message.action_confirmation_password')}:</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Field
                                    autoFocus
                                    id="oldPassword"
                                    name="oldPassword"
                                    type="password"
                                    component={Input}
                                    disabled={submitting}
                                />
                            </div>
                            {error && 
                                <div style={{ marginTop: 15, textAlign: 'center', color: 'red' }}>
                                    {error.message}
                                </div>
                            }
                            <div style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }}>
                                <Button type="button" disabled={submitting} onClick={onClose} style={{ marginRight: 15 }}>
                                    {translate('ra.action.cancel')}
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={submitting}
                                >
                                    {submitting && (
                                        <CircularProgress
                                            className={classes.icon}
                                            size={18}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('ra.action.confirm')}
                                </Button>
                            </div>
                        </form>
                    )}
                />   
            </DialogContent>
        </Dialog>
    )
}
import React, { cloneElement, Fragment, useEffect, useState } from 'react';
import {
    List,
    ArrayField, 
    Datagrid,
    TextField,
    CreateButton,
    // DeleteButton,
    EditButton,
    BooleanField,
    useTranslate,
    useDataProvider,
    Filter,
    SearchInput,
    SelectField,
    DateField,
    NullableBooleanInput,
    SelectArrayInput,
    SingleFieldList,
    ChipField,
    TopToolbar,
    sanitizeListRestProps,
    TextInput,
    SelectInput,
} from 'react-admin';

import Paper from '@material-ui/core/Paper';

import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import compose from 'recompose/compose';

import DeleteButton from '../projects/DeleteButton';
import TextArrayField from '../common/TextArrayField';
import { isRwe, isZf, isSiemens } from '../utilities';
import { contractTypes, operationCompanies, regions, segments, sourcingClusters, spendTypes, statuses } from './ItVendorForm';
import SegmentMatrix from './SegmentMatrix';
import RecommendationState from './RecommendationState';

const CustomActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    refresh,
    companyId,
    resourceLabel = "create", permissions,
    ...rest
}) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? <CreateButton 
            basePath={basePath}
            // style={{ marginTop: 10, marginBottom: 15 }} 
            label={"resources.recommendations.action." + resourceLabel}
        /> : null}
    </TopToolbar>
);

const CustomFilter = (props) => (
    <Filter {...props} variant="standard">
        <SearchInput source="query" alwaysOn />

        {isRwe() && <SelectArrayInput 
            source="providerStatusList"
            label="resources.recommendations.it_vendor.segment"
            style={{ minWidth: '150px' }}
            choices={segments}
        />}
        {isRwe() && <SelectArrayInput 
            source="operationCompanyNameList"
            label="resources.recommendations.it_vendor.internal_company"
            style={{ minWidth: '150px' }}
            choices={operationCompanies}
        />}
        {isRwe() && <SelectArrayInput 
            source="regions"
            label="resources.recommendations.it_vendor.region"
            style={{ minWidth: '150px' }}
            choices={regions}
        />}
        {isRwe() && <SelectArrayInput 
            source="statuses"
            label="resources.recommendations.it_vendor.status"
            style={{ minWidth: '150px' }}
            choices={statuses}
        />}
        {isRwe() && <SelectArrayInput 
            source="sourcingClusters"
            label="resources.recommendations.it_vendor.sourcing_cluster"
            style={{ minWidth: '150px' }}
            choices={sourcingClusters}
        />}
        {isRwe() && <SelectArrayInput 
            source="contractTypes"
            label="resources.recommendations.it_vendor.contract_type"
            style={{ minWidth: '150px' }}
            choices={contractTypes}
        />}
        {isRwe() && <NullableBooleanInput
            source="blocked"
            helperText={false}
            label="resources.recommendations.fields.blocked"
        />}

        {isZf() && <TextInput source="plant" style={{ minWidth: '150px' }} />}
        {isZf() && <SelectInput source="spendType" style={{ minWidth: '150px' }} choices={spendTypes} />}
    </Filter>
);

const ItVendorList = ({ permissions, theme, ...rest }) => {

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    if (decodedToken) {

        const companyId = decodedToken.company_id;

        return (
            <List 
                title="application.menu.it_vendors"
                resource="it-vendors"
                exporter={false}
                filters={<CustomFilter />} 
                filterDefaultValues={{ 'companyId': companyId, 'offering': 'SERVICE'  }}
                sort={{ field: 'updatedAt', order: 'DESC' }}
                perPage={25}
                actions={<CustomActions {...rest} permissions={permissions} resourceLabel="add_it_vendor" />} 
                bulkActionButtons={false}
                {...rest}
            >
                <Datagrid rowClick="show">
                    {isRwe() && <TextField label="resources.recommendations.it_vendor.id" source="itVendorData.id" />}
                    {isSiemens() && <TextField label="resources.recommendations.fields.supplier_number" source="attribute" />}
                    <TextField label="resources.recommendations.it_vendor.vendor_name" source="providerName" />
                    {isRwe() && <TextField label="resources.recommendations.it_vendor.duns_global_name" source="itVendorData.dunsGlobalName" />}
                    
                    {isRwe() && <SegmentMatrix label="resources.recommendations.it_vendor.segment" />}
                    
                    {!isRwe() && (
                        <SelectField 
                            label="resources.recommendations.it_vendor.segment"
                            source="status" 
                            choices={segments}
                        />
                    )}
                    <ArrayField label="resources.recommendations.fields.products" source="products" sortable={false}>
                        <SingleFieldList linkType={false}>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ArrayField>
                    {isZf() && <TextField label="Plant" source="itVendorData.plant" />}
                    {isZf() && <TextField label="Spend type" source="itVendorData.spendType" />}
                    {isRwe() && (
                        <TextArrayField label="resources.recommendations.it_vendor.internal_company" source="operationCompanies">
                            <SingleFieldList>
                                <ChipField source="id" />
                            </SingleFieldList>
                        </TextArrayField>
                    )}
                    {isRwe() && <TextField label="resources.recommendations.it_vendor.mentor" source="itVendorData.mentor" />}
                    {isRwe() && <TextField label="resources.recommendations.it_vendor.management" source="itVendorData.management" />}
                    {isRwe() && (
                        <TextArrayField label="resources.recommendations.it_vendor.region" source="itVendorData.regions">
                            <SingleFieldList>
                                <ChipField source="id" />
                            </SingleFieldList>
                        </TextArrayField>
                    )}
                    {isRwe() && (
                        <SelectField
                            label="resources.recommendations.it_vendor.status"
                            source="itVendorData.status"
                            choices={statuses}
                            translateChoice={false}
                        />
                    )}
                    {isRwe() && <TextField label="resources.recommendations.it_vendor.ofa" source="itVendorData.ofa" />}
                    {isRwe() && (
                        <TextArrayField label="resources.recommendations.it_vendor.sourcing_cluster" source="itVendorData.sourcingClusters">
                            <SingleFieldList>
                                <ChipField source="id" />
                            </SingleFieldList>
                        </TextArrayField>
                    )}
                    {isRwe() && (
                        <TextArrayField label="resources.recommendations.it_vendor.contract_type" source="itVendorData.contractTypes">
                            <SingleFieldList>
                                <ChipField source="id" />
                            </SingleFieldList>
                        </TextArrayField>
                    )}
                    {/* <DateField label="resources.recommendations.fields.createdAt" source="createdAt" /> */}
                    {!isRwe() && <DateField label="resources.recommendations.fields.updatedAt" source="updatedAt" />}
                    <BooleanField label="resources.recommendations.fields.blocked" source="blocked" />
                    <RecommendationState />
                    
                    {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) && <EditButton />}
                    {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) && <DeleteButton />}
                </Datagrid>
            </List>
        )
    }

    return null;
    
};

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
);

export default enhance(ItVendorList);
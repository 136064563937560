import React, { useState } from 'react';
import { useTranslate } from 'react-admin';

import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

import InformationIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: '#436ba3',
    },
    closeIcon: {
        fontSize: 20,
    },
    informationIcon: {
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 20
    },
    blue: {
        color: '#39a1de',
    },
    action: {
        position: 'absolute',
        top: 3,
        right: 10
    },
}));

export const PreviewMessage = ({ message }) => {

    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const translate = useTranslate();

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            onClose={() => setOpen(false)}
        >
            <SnackbarContent
                className={classes.background}
                classes={{ action: classes.action }}
                aria-describedby="preview-message"
                message={
                    <span id="preview-message" className={classes.message}>
                        <InformationIcon className={classes.informationIcon} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={() => setOpen(false)}>
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
}
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import Footer from '../Footer';
import Snackbar from '@material-ui/core/Snackbar';

import { isWhiteLabel } from "../utilities";

const useStyles = makeStyles(theme => ({
    form: {
        padding: '0 1em 1em 1em',
        paddingTop: '1.5em',
        backgroundPosition: 'center top',
        backgroundImage: isWhiteLabel() ? `url(${process.env.REACT_APP_ROOT_FOLDER}images/rwe-black-logotype.png)` : `url(${process.env.REACT_APP_ROOT_FOLDER}logotype-440x158.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 2.5rem',
    },
    input: {
        marginTop: '1em',
    },
    button: {
        width: '100%',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    link: {
        textAlign: 'center',
        padding: '0.75em',
    },
    registrationLink: {
        textAlign: 'center',
        paddingBottom: '0.75em',
    },
    error: {
        backgroundColor: theme.palette.error.light,
    },
}));

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm = ({ redirectTo }) => {
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles({});

    const [locked, setLocked] = useSafeSetState(false);
    const [message, setMessage] = useSafeSetState(null);

    const sessionExpired = localStorage.getItem('is_session_expired');

    const validate = (values) => {
        const errors = { username: undefined, password: undefined };

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const submit = values => {
        var encodedPassword = encodeURIComponent(values["password"]);
        values.password = encodedPassword;

        var encodedEmail = encodeURIComponent(values["email"]);
        values.email = encodedEmail;
        setLoading(true);
        login(values, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);

                if (error.message === 'LOCKED') {
                    setMessage(translate('application.message.locked'));
                    setLocked(true);
                } else {
                    notify(
                        typeof error === 'string'
                            ? error
                            : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                        'warning'
                    );
                }
            });
    };

    return (
        <div>
            <Form
                onSubmit={submit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    id="username"
                                    name="username"
                                    component={Input}
                                    label={translate('application.login.email')}
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    id="password"
                                    name="password"
                                    component={Input}
                                    label={translate('ra.auth.password')}
                                    type="password"
                                    disabled={loading}
                                    autocomplete="current-password"
                                />
                            </div>
                        </div>
                        <CardActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className={classes.button}
                            >
                                {loading && (
                                    <CircularProgress
                                        className={classes.icon}
                                        size={18}
                                        thickness={2}
                                    />
                                )}
                                {translate('ra.auth.sign_in')}
                            </Button>
                        </CardActions>
                        <div className={classes.link}>
                            <a href={`${process.env.REACT_APP_ROOT_FOLDER}#/password-recovery`}>{translate('application.login.password_recovery')}</a>
                        </div>
                        {!isWhiteLabel() && 
                            <div className={classes.registrationLink}>
                                <a href={`${process.env.REACT_APP_PROVIDER_HOST_URL}/#/provider-registration`}>{translate('application.login.provider_registration')}</a>
                            </div>
                        }
                    </form>
                )}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={sessionExpired}
                ContentProps={{ className: classes.error }}
                message={translate('application.message.session_expired')}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={locked}
                ContentProps={{ className: classes.error }}
                message={message}
            />
            <Footer/>
        </div>
    );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginForm;
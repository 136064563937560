import React, { useEffect, useState, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useNotify, usePermissions, useTranslate, required } from 'react-admin';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red, blue } from '@material-ui/core/colors';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Divider from '@material-ui/core/Divider';

import axios from "axios";

import ExpandIcon from '@material-ui/icons/ExpandMore';
import MoreIcon from '@material-ui/icons/MoreVert';

import { Form, Field } from 'react-final-form'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: 'none',
    },
    cardContent: {
        paddingTop: 0,
    },
    cardActions: {
        padding: '0px 16px 8px 16px',

    },
    avatarRed: {
        backgroundColor: red[500],
    },
    avatarBlue: {
        backgroundColor: blue[500],
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    expanded: {
        transform: 'rotate(180deg) !important',
    },
    notExpanded: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
}));

const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <TextField
        variant="outlined"
        fullWidth
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
);

const CustomAvatar = ({ message }) => {

    const classes = useStyles();

    let initials = null;

    if (message.sender !== undefined) {
        let fullName = message.sender.toUpperCase().split(' ');
        initials = fullName[0].charAt(0);
        if (fullName[1]) {
            initials = initials + fullName[1].charAt(0)
        }
    }

    return (
        <Avatar className={message.messageType === 'CRM_TO_USER' ? classes.avatarRed : null}>
            {initials}
        </Avatar>
    )
}

export const ProjectMessage = ({ record }) => {

    // console.log('record.messages', record.messages);

    const [messages, setMessages] = useState(record.messages);
    const [expanded, setExpanded] = useState(true);

    const [focused, setFocused] = useState(false);

    const classes = useStyles();
    const notify = useNotify();
    const { permissions } = usePermissions();

    const translate = useTranslate();

    const [value, setValue] = useState('');

    useEffect(() => {

        setMessages(record.messages);

    }, [record.messages]);

    const getText = (message) => {

        const nextStep = "Next step: ";

        if (message.message === 'CONTINUE_SUGGESTION_SEARCH_REVIEWED_ACCEPTED' 
            || message.message === 'CONTINUE_SUGGESTION_SEARCH_REVIEWED_MIXED'
            || message.message === 'STOP_SUGGESTION_SEARCH_REVIEWED_ACCEPTED'
            || message.message === 'STOP_SUGGESTION_SEARCH_REVIEWED_MIXED'
            || message.message === 'RESEARCH_COMPLETED'
            || message.message === 'SKIP_STEP_1'
            || message.message === 'SKIP_STEP_2'
            || message.message === 'SKIP_STEP_2_MIXED') {

            return nextStep + translate('resources.suggestions.dialog.field_values.feedback.' + message.message.toLowerCase())
        } else {
            return message.message
                .replace(' : ', ': ')
                .replace('NOT_FIT_PROJECT', '')
                .replace('NOT_MEET_SOME_REQUIREMENTS', '')
                .replace('THE_GIVE_INFO_NOT_SUFFICIENT', '')
                .replace('USER_NEED_MORE_INFO', '')
                .replace('DIRECT_CONTACT_TO_PROVIDER', '');
        }
    }

    const clearForm = (form) => {
        form.reset();
        setValue('');
        setFocused(false);
    }

    const sendMessage = (values, form) => {

        // console.log('values: ', values);

        const token = localStorage.getItem('token');

        const formData  = new FormData();
        formData.append('projectId', record.id);
        formData.append('messageType', "USER_TO_CRM");
        formData.append('message', values.message);

        // axios.post(`${process.env.REACT_APP_API_URL}/project-messages`, formData, 
        //         { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`, 'client-type': 'USER' } })

        fetch(`${process.env.REACT_APP_API_URL}/project-messages`, { 
                method: 'POST', 
                headers: { 'Authorization': `Bearer ${token}`, 'client-type': 'USER' }, 
                body: formData
            })
            .then(response => response.json())
            .then(message => {

                setMessages([...messages, message]);
                setTimeout(() => clearForm(form));
        
                notify('The message has been sent successfully!');
            })
            .catch((error) => {
                const isString = Object.prototype.toString.call(error.message) == '[object String]';

                var message = error.message;

                if (!isString) {
                    message = Object.entries(error.message)
                            .map(([key, value]) => value)
                            .join('\n');
                }

                notify(message, 'warning');
            });
    }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    return (
        <div style={{ width: '100%' }}>

            <Fragment>
                <div style={{ marginBottom: '2rem' }}>
                    <Typography variant="caption">
                        {translate('resources.project_messages.message.information')}
                    </Typography>
                </div>
                {messages.sort((one, two) => new Date(one.createdAt) - new Date(two.createdAt)).map(message => {

                    return (
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <CustomAvatar message={message} />
                                }
                                // action={
                                //     <IconButton aria-label="settings">
                                //         <MoreIcon />
                                //     </IconButton>
                                // }
                                title={message.sender}
                                subheader={new Date(message.createdAt).toLocaleString()}
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <div dangerouslySetInnerHTML={{ __html: getText(message) }} />
                                </Typography>
                            </CardContent>
                        </Card>
                    )
                    })
                }
                {messages.length == 0 && 
                    <Typography variant="body1">
                        {translate('resources.project_messages.message.no_messages')}
                    </Typography>
                }

                {/* <Divider variant="middle" /> */}

                {!record.archived &&
                    <Form
                        onSubmit={sendMessage}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {
                            
                            return (
                                <form id="message-form" onSubmit={event => handleSubmit(event, form)}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            {/* <Field
                                                name="text"
                                                component={renderTextField}
                                                type="text"
                                                placeholder={translate('resources.project_messages.action.create')}
                                                onFocus={() => setFocused(true)}
                                            /> */}
                                            {/* <RichTextInput placeholder={translate('resources.project_messages.action.create')} source="text" label="" /> */}

                                            {!focused && <div onClick={() => setFocused(true)} style={{ backgroundColor: 'rgb(244, 245, 247)', color: 'rgb(153, 153, 153)', width: '100%', display: 'flex', alignItems: 'center', cursor: 'text', padding: 10 }}>{translate('resources.project_messages.action.create')}</div>}

                                            {focused && 
                                                <Field
                                                    name="message"
                                                    // validate={required}
                                                    placeholder={translate('resources.project_messages.action.create')}
                                                    render={({ input, meta, ...props }) => {
                                                        if (meta.touched && meta.error){

                                                        }
                                                        return (
                                                            <div>
                                                                <style dangerouslySetInnerHTML = {{__html: `
                                                                    .ck-editor__editable {
                                                                        min-height: 80px;
                                                                    }
                                                                    .ck.ck-editor__main>.ck-editor__editable{
                                                                        background: none;
                                                                    }
                                                                    .ck.ck-toolbar {
                                                                        background: none;
                                                                    }
                                                                `}} />

                                                                {props.theme === 'dark' ?
                                                                    <style dangerouslySetInnerHTML = {{__html: `
                                                                        .ck.ck-editor__editable > .ck-placeholder::before {
                                                                            color: rgba(255, 255, 255, 0.7);
                                                                        }
                                                                    `}} />
                                                                    :
                                                                    <style dangerouslySetInnerHTML = {{__html: `
                                                                        .ck.ck-editor__editable > .ck-placeholder::before {
                                                                            color: rgba(0, 0, 0, 0.54);
                                                                        }
                                                                    `}} />
                                                                }
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={value}
                                                                    config={ {
                                                                        placeholder : props.placeholder,
                                                                        toolbar:  [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
                                                                    } }
                                                                    onInit={editor => {

                                                                        editor.editing.view.focus();
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setValue(data);
                                                                        input.onChange(data);
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        //console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        // console.log( 'Focus.', editor );
                                                                    }}
                                                                />

                                                                <textarea style={{ display: 'none' }} {...input} name={props.source} value={value} />
                                                                <div className={classes.error}>{meta.touched && meta.error && <span>{meta.error}</span>}</div>
                                                            </div>
                                                        )
                                                    }}
                                                /> }
                                        </CardContent>
                                        {focused && 
                                            <CardActions className={classes.cardActions}>
                                                <Button type="submit" disabled={submitting || pristine} size="small" color="primary" variant="contained">
                                                    {translate('resources.messages.action.send')}
                                                </Button>
                                                <Button type="button" onClick={() => clearForm(form)} disabled={submitting} size="small" color="primary">
                                                    {translate('ra.action.cancel')}
                                                </Button>
                                            </CardActions>
                                        }
                                    </Card>
                                </form>
                            )
                        }}
                    />
                }
            </Fragment>
        </div>
    )
}
import React, { Fragment, useEffect, useState } from 'react';
import {
    List,
    CardActions, 
    Datagrid,
    TextField,
    CreateButton,
    // DeleteButton,
    EditButton,
    BooleanField,
    useTranslate,
    useDataProvider,
    Filter,
    SearchInput,
    SelectField,
    DateField,
    ArrayField,
    SingleFieldList,
    ChipField,

} from 'react-admin';

import Paper from '@material-ui/core/Paper';

import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import compose from 'recompose/compose';

import DeleteButton from '../projects/DeleteButton';

const CustomActions = ({ basePath, data, resource, resourceLabel = "create" }) => (
    <CardActions>
        <CreateButton 
            basePath={basePath}
            style={{ marginTop: 10, marginBottom: 15 }} 
            label={"resources.recommendations.action." + resourceLabel}
        />
    </CardActions>
);

const CustomFilter = (props) => (
    <Filter {...props} variant="standard">
        <SearchInput source="query" alwaysOn />
    </Filter>
);

const Description = () => {
    
    const translate = useTranslate();

    return (
        <Typography variant="body1"  style={{ marginTop: '1rem' }}>
            {translate('resources.recommendations.message.description')}
        </Typography>
    )
}

const Empty = ({ basePath, resource }) => {

    const translate = useTranslate();

    return (
        <Paper style={{ marginTop: '1rem', padding: '1rem' }}>
            <Description />
            <Box textAlign="center" m={1}>
                
                <Typography variant="body1" paragraph>
                    {translate('resources.recommendations.message.empty')}
                </Typography>
                <CreateButton basePath={basePath} label="resources.recommendations.action.create" />
            </Box>
        </Paper>
    )
};

const ExtendedList = (props) => {

    const { permissions } = props;

    const companyId = JSON.parse(localStorage.getItem('decoded_token')).company_id;
    const dataProvider = useDataProvider();

    const [onlyFavoriteProviders, setOnlyFavoriteProviders] = useState(false);

    // useEffect(() => {

    //     dataProvider.getOne('companies', { id:  companyId + '/properties' })
    //             .then(({ data }) => {
    //                 setOnlyFavoriteProviders(data.onlyFavoriteProviders);
    //             });
    // }, []);
    
    return (
        <List 
            title="application.menu.active_providers"
            basePath="/recommendations"
            resource="recommendations"
            exporter={false}
            filters={<CustomFilter />} 
            filterDefaultValues={{ 'companyId': companyId }}
            sort={{ field: 'updatedAt', order: 'DESC' }}
            perPage={25}
            actions={<CustomActions {...props} resourceLabel="add" />} 
        >
            <Datagrid>
                <TextField source="providerName" />
                <ArrayField source="products" sortable={false}>
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ArrayField>
                {permissions && onlyFavoriteProviders && 
                    <SelectField 
                        label="Supplier status"
                        source="status" 
                        choices={[
                            { id: 'TOP', name: 'resources.recommendations.field_values.status.top' },
                            { id: 'PREFERRED', name: 'resources.recommendations.field_values.status.preferred' },
                            // { id: 'STANDARD', name: 'resources.recommendations.field_values.status.standard' },
                            { id: 'NICHE', name: 'resources.recommendations.field_values.status.niche' },
                            { id: 'OTHER', name: 'resources.recommendations.field_values.status.other' },
                            // { id: 'PHASE_OUT', name: 'resources.recommendations.field_values.status.phase_out' },
                            { id: 'BLOCKED', name: 'resources.recommendations.field_values.status.blocked' },
                        ]}
                    />
                }
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <BooleanField source="activelyUsed" />
                
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
}

const RegularList = (props) => {
    return (
        <Fragment>
            <Description />
            <List 
                resource="recommendations"
                bulkActionButtons={false} 
                actions={<CustomActions />} 
                filterDefaultValues={{ userId: JSON.parse(localStorage.getItem('decoded_token')).user_name }} 
                title="resources.recommendations.message.title"
                {...props}
            >
                <Datagrid rowClick="show" {...props}>
                    <TextField source="providerName" />
                    <TextField source="productName" />
                    <BooleanField source="activelyUsed" />
                    
                    <EditButton />
                    <DeleteButton undoable={false} confirmTitle="" />
                </Datagrid>
            </List>
        </Fragment>
    )
}

const RecommendationList = ({ permissions, theme, ...rest }) => {

    const [ extended, setExtended ] = useState();
    const [ total, setTotal ] = useState(null);

    const dataProvider = useDataProvider();

    const basePath = "/knowledge/recommendations"; // it's set only if there is no this value in property

    useEffect(() => {

        if (rest.basePath != null) {

            if (permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER'))) {
                setExtended(true);
            }
        } else {
            dataProvider.getOne('recommendations', { id: `?userId=${JSON.parse(localStorage.getItem('decoded_token')).user_name}` })
                .then(({ data }) => {
                    setTotal(data.totalElements);
                });
        }
    }, [permissions]);

    return (
        extended ? 
            <ExtendedList permissions={permissions} {...rest} />
            :
            total == null ?
                null
                :
                total == 0 ?
                    <Empty basePath={basePath} />
                    :
                    <RegularList basePath={basePath} permissions={permissions} {...rest} />
    )
};

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
);

export default enhance(RecommendationList);
import React from 'react';
import { translate, changeLocale, Title, showNotification } from 'react-admin';
import { connect } from 'react-redux';

import compose from 'recompose/compose';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import SettingsIcon from '@material-ui/icons/Settings';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import CustomCard from './CustomCard';

import { changeTheme } from './actions';
const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(20, 20),
    },
  }));
const styles = {
    label: { width: '7em', display: 'inline-block', fontSize: '1rem', color: 'rgba(0, 0, 0, 0.54)' },
    button: { margin: '1em', width: '100px' },
};

const saveLanguage = newLanguage => {
    
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}/users/${JSON.parse(localStorage.getItem('decoded_token')).user_name}/language`, { 
            method: 'PUT', 
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
            body: JSON.stringify(newLanguage.toUpperCase()) 
        })
        .then((response) => {
            if (response.status !== 204) {
                throw new Error('Server responded with status: ' + response.status);
            } else {
                localStorage.setItem('language', newLanguage);
            }
        })
        .catch((exception) => {
            // showNotification('', 'warning')
        });
}

const Configuration = ({
    theme,
    locale,
    changeTheme,
    changeLocale,
    translate,
    basePath
}) => {
    const classes = useStyles();
    return (
        <div>
            <Title title={translate('application.menu.settings')} />
            <Paper className={classes.root}>
            <Grid container justify="center" alignItems="center" spacing={4}>
                {/* <Grid item>
                    <CustomCard 
                        image="images/baseline_account_circle_black_48dp.png" 
                        title={translate('application.menu.personal_data')} 
                        description="Manage your personal information (first name, last name, contact information and etc.)"
                        link="/profile/personal-data" 
                    />
                </Grid> */}
                <Grid item>
                    <CustomCard 
                        image="images/baseline_settings_applications_black_48dp.png" 
                        title={translate('application.menu.general_configuration')} 
                        description={translate('application.menu.general_configuration_description')} 
                        link="/settings/general-configuration" 
                    />
                </Grid>
                <Grid item>
                    <CustomCard 
                        image="images/baseline_security_black_48dp.png" 
                        title={translate('application.menu.security')} 
                        description={translate('application.menu.security_description')} 
                        link="/settings/security" 
                    />
                </Grid>
                {/* <Grid item>
                    <CustomCard 
                        image="images/baseline_notifications_black_48dp.png" 
                        title={translate('application.menu.notification_configuration')} 
                        description="Manage your notification options" 
                        link="/settings/notification-configuration" 
                    />
                </Grid> */}
            </Grid>
            </Paper>
        </div>
    )
}

const enhance = compose(
    connect(
        state => ({
            // theme: state.theme,
            // locale: state.i18n.locale,
        }),
        {
            changeLocale,
            changeTheme,
            showNotification,
        }
    ),
    translate,
    withStyles(styles)
);

export default enhance(Configuration);
import React from 'react';
import { Route } from 'react-router-dom';

import Settings from './settings/Settings';
import { PersonalDataShow } from './profile/PersonalDataShow';
import { PersonalDataEdit } from './profile/PersonalDataEdit';
import GeneralConfiguration from './settings/GeneralConfiguration';
import Security from './settings/security/Security';
import NotificationConfiguration from './settings/NotificationConfiguration';

import UserActivation from './activation/UserActivation';
import EmailActivation from './activation/EmailActivation';
import PasswordActivation from './activation/PasswordActivation';

import PasswordRecovery from './recovery/PasswordRecovery';

import ProviderRegistration from './registration/ProviderRegistration';

import ProjectMessageCreate from './projects/ProjectMessageCreate.js';

import ProjectMessageWait from './projects/ProjectMessageWait.js';
import EnvsInfo  from './envs_info/EnvsInfo';
import Faqs from './faqs/Faqs';
import ChatIntercomm from './chat/ChatIntercomm';
import KnowledgeCenter from './knowledge/KnowledgeCenter';
import KnowledgePortfolio from './knowledge/PortfolioInfo';
import Ecosystems from './knowledge/Ecosystems';
import ProjectChart from './analytics/ProjectCharts';
import Invoice from './invoice/Invoice';
import RecommendationList from './recommendations/RecommendationList';
import { RecommendationCreate } from './recommendations/RecommendationCreate';
import { RecommendationEdit } from './recommendations/RecommendationEdit';
import RecommendationShow from './recommendations/RecommendationShow';

import { Vendors } from './projects/Vendors';
import { Vendor } from './projects/Vendor';

import InternalSearch from './knowledge/InternalSearch';

import Logout from './settings/Logout';
import SoftwareSearch from './knowledge/SoftwareSearch';
import VendorSearch from './knowledge/VendorSearch';
import Dashboard from './projects/Dashboard.js';
import DashboardSolutionOverlap from './projects/DashboardSolutionOverlap.js';

export default [
    <Route exact path="/profile/personal-data" component={PersonalDataShow} />,
    <Route exact path="/profile/personal-data/edit" component={PersonalDataEdit} />,
    <Route exact path="/settings" component={Settings} />,
    <Route exact path="/settings/general-configuration" component={GeneralConfiguration} />,
    <Route exact path="/settings/security" component={Security} />,
    <Route exact path="/settings/notification-configuration" component={NotificationConfiguration} />,

    <Route exact path="/user-activation" component={UserActivation} />,
    <Route exact path="/email-activation" component={EmailActivation} />,
    <Route exact path="/password-activation" component={PasswordActivation} />,

    <Route exact path="/provider-registration" component={ProviderRegistration} />,

    <Route exact path="/password-recovery" component={PasswordRecovery} />,

    <Route exact path="/progress-wait" component={ProjectMessageWait} />,

    <Route exact path="/faqs" component={ Faqs } />,

    <Route exact path="/envs" component={ EnvsInfo } />,

    <Route exact path="/chat" component={ ChatIntercomm } />,

    <Route exact path="/knowledge" component={ KnowledgeCenter } />,
    <Route exact path="/knowledge/portfolio" component={ KnowledgePortfolio } />,
    <Route exact path="/knowledge/ecosystems" component={ Ecosystems } />,
    
    <Route exact path="/software-search" component={SoftwareSearch} />,
    <Route exact path="/vendor-search" component={VendorSearch} />,
    <Route exact path="/knowledge/vendors" component={Vendors} />,
    <Route exact path="/knowledge/vendor" component={Vendor} />,

    <Route exact path="/solution-insights" component={Dashboard} />,
    <Route exact path="/solution-overlaps" component={DashboardSolutionOverlap} />,

//     <Route exact path="/knowledge/recommendations" component={RecommendationList} />,
//     <Route exact path="/knowledge/recommendations/create" component={RecommendationCreate} />, 
//     <Route exact path="/knowledge/recommendations/:id" component={RecommendationEdit} />,
//     <Route exact path="/knowledge/recommendations/:id/show" component={RecommendationShow} />,

    <Route exact path="/complete-verification" component={Logout} />,

    <Route exact path="/project-chart" component={ ProjectChart } />,

    <Route exact path="/invoice" component={ Invoice } />,

    <Route
          path='/message/:projectId/create'
          render={(props, projectId) => <ProjectMessageCreate {...props} {...projectId} dialogShouldBeOpened={true} initOpen={true} />
          }
     />


];

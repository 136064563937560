import React, { useEffect } from 'react';
import { ArrayInput, SimpleFormIterator, Toolbar, SaveButton, SimpleForm, Edit, TextInput, BooleanInput, SelectInput, FormDataConsumer, required, useTranslate, SelectArrayInput } from 'react-admin';

import CancelIcon from '@material-ui/icons/Cancel';

import Button from '@material-ui/core/Button';
import LimitedInput from '../component/LimitedInput';
import { SupplierStatus } from './SupplierStatus';

import { makeStyles } from '@material-ui/core/styles';
import { SupplierValuation } from './SupplierValuation';
import { CustomSaveButton } from './CustomSaveButton';

import { isRwe, isSiemens } from '../utilities';

import { arrayInputChoicesOfferings } from './offerings';

const useStyles = makeStyles(theme => ({
    formIterator: {
        '& p:nth-child(1)' :{
            textAlign: 'center',
        }
    },
}));

const CustomButton = ({ color, label, onClick, icon }) => {
    return <Button startIcon={icon} variant="contained" color={color} onClick={onClick}>{label}</Button>
}

const CustomToolbar = (props) => {
    const translate = useTranslate();
    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
            {props.editMode ? <SaveButton undoable={false} /> : <CustomSaveButton undoable={false} />}
            <CustomButton label={translate('ra.action.cancel')} icon={<CancelIcon />} onClick={() => document.location.href = `${process.env.REACT_APP_ROOT_FOLDER}#${props.basePath}`} />
        </Toolbar>
    )
}

export const SoftwarePortfolioForm = ({ permissions, editMode, ...props }) => {
    const translate = useTranslate();
    const message = translate('application.validation.required');

    useEffect(() => {
        if (!editMode) {
            setTimeout(() => {
                let buttons = document.getElementsByClassName('button-add-products');
                if (buttons && buttons[0]) {
                    buttons[0].click();
                }
            }, 1000);
        }
    }, []);

    let defaultValue = null;
    if (!editMode) {
        defaultValue = {
            companyId: JSON.parse(localStorage.getItem('decoded_token')).company_id,
            offerings: ['SOFTWARE'],
            activelyUsed: true,
        };
    }

    const classes = useStyles();

    return (
        <SimpleForm {...props} toolbar={<CustomToolbar editMode={editMode} />} defaultValue={defaultValue} variant="standard">
            {/* This should not happen because this page is hidden from not allowed users but if a user will try to access somehow this will throw an error */}
            <FormDataConsumer>
                {({ formData, ...rest }) =>  {
                    if (permissions && (permissions.includes('ROLE_USER'))) {
                        throw new Error('Forbidden!');
                    }
                }}
            </FormDataConsumer>
            {isSiemens() && <TextInput source="attribute" label="resources.recommendations.fields.supplier_number" />}
            <TextInput source="providerName" label="resources.recommendations.software_portfolio.publisher_name" validate={required(message)} />
            <TextInput source="website" validate={required(message)} />
            <ArrayInput label="resources.recommendations.software_portfolio.software" source="products" validate={required(message)} className={classes.formIterator}>
                <SimpleFormIterator>
                    {/* <SelectInput source="offering" choices={arrayInputChoicesOfferings} /> */}
                    <TextInput validate={required(message)} source="name" label="resources.recommendations.software_portfolio.software_name" />
                    <LimitedInput source="useCase" label="resources.recommendations.fields.useCase" multiline fullWidth maxLength={4096} />
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput label="resources.recommendations.fields.blocked" source="blocked" />
            <FormDataConsumer>
                {({ formData, ...rest }) =>  {
                    if (formData.blocked) {
                        return <TextInput fullWidth variant="standard" label="resources.recommendations.fields.blockedComment" source="blockedComment" />
                    }
                }}
            </FormDataConsumer>
        </SimpleForm>
    )
}
import React, { Component, Fragment, useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { Select, MenuItem, Card, CardHeader, CardContent, TablePagination, Grid, List, ListItem, RadioGroup, Radio, FormControlLabel, ListItemAvatar, Avatar, Stepper, Step, StepLabel, ListItemIcon, ListItemText, Typography, Chip, Icon, IconButton, Tooltip, Button, Checkbox } from "@material-ui/core";

import CrossIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/CheckCircle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import InformationIcon from '@material-ui/icons/Info';

import classnames from 'classnames';
import { Legend } from './Legend';

const useStyles = makeStyles(theme => ({
    root: {
        height: '20em',
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftColumn: { flex: 1, marginRight: '1em' },
    rightColumn: { flex: 1, marginLeft: '1em' },
    singleColumn: { marginBottom: '2em' },
    fab: {
        margin: theme.spacing(1),
        backgroundColor: '#304d75'
    },
    tableContainer: {
        maxWidth: '75vw',
        overflowX: 'auto',
        margin: '0 auto',
    },
    table: {
        tableLayout: 'fixed',
        margin: '0px auto 0px auto',
    },
    fixedHeader: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        height: 50,
        backgroundColor: 'white',
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
    },
    fixedCell: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        borderRight: '1px solid black',
        height: 29,
        backgroundColor: 'white',
        zIndex: 1,
    },
    header: {
        width: 200,
        height: 50
    },
    cell: {
        width: 200,
        borderCollapse: 'collapse',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        height: 29
    },
    firstHeader: {
        width: 200,
        paddingLeft: 300,
        height: 50,
    },
    firstCell: {
        width: 200,
        paddingLeft: 300,
        borderCollapse: 'collapse',
        borderRight: '1px solid black',
        height: 29
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    border : {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    green: {
        color: 'rgb(144, 198, 83)',
    },
    orange: {
        color: '#ffb74d',
    },
    blue: {
        color: '#39a1de',
    },
    gray: {
        color: '#cccccc',
    }
}));

const GREEN = 'GREEN';
const BLUE = 'BLUE';
const GRAY = 'GRAY';

function calculateColumnWidth(totalColumns) {

    let clientWidth = document.documentElement.clientWidth;

    const totalWidth = clientWidth * 75 / 100 - 300;
    const columnWidth = totalWidth / totalColumns;
    const minimumColumnWidth = 200;

    return columnWidth < minimumColumnWidth ? minimumColumnWidth : columnWidth;
}

const renderKeySynonyms = (data, translate) => {

    if (data.keySynonyms.length === 0) {
        return '';
    }

    return translate('resources.suggestions.matrix.synonyms') + ': ' + data.keySynonyms.join(', ');
}

const renderTextFragment = (data, translate) => {

    if (data.textFragment === null) {
        return translate('resources.suggestions.matrix.no_details_available');
    }

    if (data.header) {
        return (
            <div>
                <div style={{ textDecoration: 'underline'}}>
                    {data.header}
                </div>
                <div style={{ marginTop: 5 }}>
                    {data.textFragment}
                </div>
            </div>
        )
    }

    return data.textFragment;
}

const getBackgroundColor = (cell) => {
    return cell.highlighted ? '#e6f9ff' : 'inherit';
}

const MatrixContent = ({ matrix, type }) => {

    const translate = useTranslate();
    const classes = useStyles();

    return matrix.rows.map((row, index) => (

        <TableRow key={index}>
            <TableCell className={classes.fixedCell} component="th" scope="row">
                <Typography className={classes.textOverflow}>{row.key}</Typography>
            </TableCell>

            {row.restCells.map((cell, index) => (
                <TableCell style={{ width: calculateColumnWidth(matrix.solutions.length), backgroundColor: getBackgroundColor(cell) }} className={index == 0 ? classes.firstCell : classes.cell} align="center">
                    <CheckIcon 
                        className={classnames({
                            [classes.green]: cell.status == GREEN,
                            [classes.blue]: cell.status == BLUE,
                            [classes.gray]: cell.status == GRAY,
                        })}
                    />
                </TableCell>
            ))}
        </TableRow>
    ));

}

export const VendorConsolidation = (props) => {

    const { record, step, type, visibleHeader = true } = props;

    const classes = useStyles();
    const matrix = {
        rows: [
            { 
                key: 'Phishing Test', 
                restCells: [
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GREEN },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                ] 
            },
            { 
                key: 'Threat Detection', 
                restCells: [
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GREEN },
                    { status: GRAY },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                ] 
            },
            { 
                key: 'Pentests', 
                restCells: [
                    { status: BLUE },
                    { status: BLUE },
                    { status: GREEN },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GREEN },
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: BLUE },
                ] 
            },
            { 
                key: 'Cyber Security Intelligence', 
                restCells: [
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GREEN },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'Securityanalyse AD', 
                restCells: [
                    { status: GREEN },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                ] 
            },
            { 
                key: 'Application Management Services', 
                restCells: [
                    { status: GREEN },
                    { status: GRAY },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'Incident Response and Forensics', 
                restCells: [
                    { status: BLUE },
                    { status: GREEN },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'Infrastructure Services', 
                restCells: [
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                ] 
            },
            { 
                key: 'IT Security Managed Services', 
                restCells: [
                    { status: BLUE },
                    { status: BLUE },
                    { status: GREEN },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'Implementation Services', 
                restCells: [
                    { status: BLUE },
                    { status: GRAY },
                    { status: GREEN },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'Enterprise Backup Software', 
                restCells: [
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GREEN },
                    { status: BLUE },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'OnDemand Migration Services', 
                restCells: [
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GREEN },
                    { status: BLUE },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'Health and Performance Monitoring of Database Platforms', 
                restCells: [
                    { status: GRAY },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GREEN },
                    { status: BLUE },
                    { status: BLUE },
                ] 
            },
            { 
                key: 'Process Automation Services', 
                restCells: [
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GREEN },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'Cloud Security', 
                restCells: [
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GREEN },
                    { status: BLUE },
                ] 
            },
            { 
                key: 'Industrial Security', 
                restCells: [
                    { status: BLUE },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GREEN },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'User Experience Services', 
                restCells: [
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GRAY },
                    { status: GREEN },
                    { status: GRAY },
                ] 
            },
            { 
                key: 'IT Hardening', 
                restCells: [
                    { status: GRAY },
                    { status: GRAY },
                    { status: BLUE },
                    { status: BLUE },
                    { status: GRAY },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GRAY },
                    { status: BLUE },
                    { status: GREEN },
                ] 
            },
        ],
        solutions: [
            { name: 'Wipro' },
            { name: 'AirbusCyber Security' },
            { name: 'Bechtle' },
            { name: 'ImproSec' },
            { name: 'Crowdstrike' },
            { name: 'Sec Consult' },
            { name: 'Paramount Defenses' },
            { name: 'Quest' },
            { name: 'Computacenter' },
            { name: 'Filder Cloud' },
        ]
    }

    const translate = useTranslate();

    return matrix !== null ? 
        <Fragment>
            {visibleHeader && <Typography component="p" style={{ fontSize: '0.875rem' }}>{translate('resources.projects.vendor_consolidation.description')}</Typography>}
            <div className={classes.tableContainer}>
                <Table className={classes.table} aria-label="matrix">
                    <TableHead>
                        <TableRow>

                            <TableCell className={classes.fixedHeader} style={{ fontSize: '0.875rem', textTransform: 'uppercase' }}>
                                Services
                            </TableCell>

                            {matrix.solutions.map((solution, index) => 
                                <TableCell style={{ width: calculateColumnWidth(matrix.solutions.length) }} className={index == 0 ? classes.firstHeader : classes.header} align="center">
                                    <span className={classes.textOverflow} style={{ display: 'block', textTransform: 'uppercase', fontSize: '0.875rem' }}>{solution.name}</span>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <MatrixContent type={type} matrix={matrix} />
                    </TableBody>
                </Table>
            </div>
            <div style={{ textAlign: 'left', marginTop: 17 }}>
                <ul style={{ listStyleType: 'none' }}>
                    <li>
                        <span className={classes.green}>Green</span> = Service in use
                    </li>
                    <li>
                        <span className={classes.blue}>Blue</span> = Service offered (not in used)
                    </li>
                    <li>
                        <span className={classes.gray}>Gray</span> = Service not offered
                    </li>
                </ul>       
            </div>
        </Fragment>
        :
        <div style={{ display: 'flex', justifyContent: 'center', padding: 16 }}>
            <CircularProgress />
        </div>;
}
import React, { Component } from 'react';
import { useTranslate } from 'react-admin';

import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

const UnsavedDataDialog = ({ unsavedOpen, setUnsavedOpen, setOpen }) => {

    const translate = useTranslate();

    return (
        <Dialog
            fullWidth
            open={unsavedOpen}
            onClose={() => setUnsavedOpen(false)}
            disableEnforceFocus
        >
            <DialogTitle>{translate('application.unsaved_changes.name')}</DialogTitle>
            <DialogContent>
                <Typography style={{ marginBottom: 16 }} variant="body2" id="alert-dialog-description">
                    {translate('application.unsaved_changes.message')}
                </Typography>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setUnsavedOpen(false)} color="primary">
                    {translate('application.unsaved_changes.no')}
                </Button>
                <Button onClick={() => { setUnsavedOpen(false); setOpen(false); }} color="primary" autoFocus>
                    {translate('application.unsaved_changes.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UnsavedDataDialog;
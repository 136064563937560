import React from 'react';
import { EmailField, BooleanField, SelectField, DateField, Show, SimpleShowLayout, TextField, EditButton, CardActions } from 'react-admin';

const CustomActions = ({ permissions, basePath, data, resource }) => {
    const record = { id: 'edit' };
    return <CardActions>
        <EditButton basePath={basePath} record={record} />
    </CardActions>
};

export const PersonalDataShow = ({ staticContext, permissions, ...props }) => (
    <Show id={JSON.parse(localStorage.getItem('decoded_token')).user_name} resource="users" title="application.menu.profile" actions={<CustomActions />} basePath="/profile/personal-data" {...props}>
        <SimpleShowLayout>
            <SelectField source="salutation" choices={[
                { id: 'MALE', name: 'resources.users.field_values.salutation.male' },
                { id: 'FEMALE', name: 'resources.users.field_values.salutation.female' },
            ]} />
            <TextField source="firstName" />
            <TextField source="lastName" />

            <TextField source="position" />

            <TextField source="email" />
            <TextField source="phone" />
            <TextField source="mobilePhone" />

            <SelectField source="role.name" choices={[
                { id: 'ROLE_USER', name: 'resources.users.field_values.role.user' },
                { id: 'ROLE_POWER_USER', name: 'resources.users.field_values.role.power_user' },
                { id: 'ROLE_EXECUTIVE', name: 'resources.users.field_values.role.executive' },
            ]} />
            <SelectField source="jobExperience" choices={[
                { id: 'LEVEL_0', name: 'resources.users.field_values.job_experience.level_0' },
                { id: 'LEVEL_1', name: 'resources.users.field_values.job_experience.level_1' },
                { id: 'LEVEL_2', name: 'resources.users.field_values.job_experience.level_2' },
                { id: 'LEVEL_3', name: 'resources.users.field_values.job_experience.level_3' },
                { id: 'LEVEL_4', name: 'resources.users.field_values.job_experience.level_4' },
                { id: 'LEVEL_5', name: 'resources.users.field_values.job_experience.level_5' },
                { id: 'UNDEFINED', name: 'resources.users.field_values.job_experience.undefined' },
            ]} />

            <BooleanField source="enabled" />
            <BooleanField source="locked" />

            <DateField source="createdAt" />
        </SimpleShowLayout>
    </Show>
);
import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Grid from "@material-ui/core/Grid";
import { useTranslate } from "react-admin";

function CustomTooltip(pathToStringForTranslation) {
  const useStyles = makeStyles(theme => ({
    tooltip: {},
    root: {
      flexGrow: 1,
      width: "80%"
    },
    gridcontainer: {
      padding: theme.spacing(1),
      marginBottom: "20px"
    },
    helpIconGrid: {
      position: "relative"
    },
    helpIcon: {
      paddingLeft: "20px",
      position: "absolute",
      bottom: "0",
      cursor: "pointer"
    }
  }));
  const classes = useStyles();
  const translate = useTranslate();

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(13)
    }
  }))(Tooltip);

  return (
    <Grid item xs={12} sm={1} className={classes.helpIconGrid}>
      <HtmlTooltip
        className={classes.tooltip}
        TransitionProps={{ timeout: 600 }}
        title={translate(pathToStringForTranslation.pathToStringForTranslation)}
        placement="right"
        arrow
      >
        <HelpOutlineIcon className={classes.helpIcon} />
      </HtmlTooltip>
    </Grid>
  );
}

export default CustomTooltip;

import React from 'react';
import { useTranslate } from 'react-admin';

const statuses = ['TOP', 'PREFERRED', 'NICHE', 'OTHER'];

const styles = {

    square: {
        width: 113,
        height: 18,
        border: 'solid 1px #8c8c8c',
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        color: '#808386',
        backgroundColor: '#CECFD0',
        fontSize: 10,
    },
    activeSquare: {
        width: 113,
        height: 18,
        border: 'solid 1px #8c8c8c',
        textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        color: 'white',
		backgroundColor: '#70D5C3',
        fontSize: 10,
    }
}

const SegmentMatrix = ({ record }) => {

    const translate = useTranslate();

    return (
        <div
            style={{
                flexWrap: 'wrap',
                display: 'flex',
                width: 230,
                border: 'solid 1px #8c8c8c',
            }}
        >
            {statuses.map(entry => {

                return (
                    <div 
                        style={entry == record.status ? {...styles.activeSquare} : {...styles.square}}
                    >
                        {translate('resources.recommendations.field_values.status.' + entry.toLowerCase())}
                    </div>
                )
            })}
        </div>
    )
}
SegmentMatrix.defaultProps = { addLabel: true };

export default SegmentMatrix;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import Footer from '../Footer';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import usePasswordVerification from "../settings/security/PasswordStrength/PasswordVerification";
import PasswordStrengthComp from "../settings/security/PasswordStrength/PasswordStrengthComp";

import FormControlLabel from '@material-ui/core/FormControlLabel';

import CheckIcon from '@material-ui/icons/CheckCircleOutline';

import { resolveBrowserLocale } from 'react-admin';

const useStyles = makeStyles(theme => ({
    form: {
        padding: '0 1em 1em 1em',
        paddingTop: '1.5em',
        backgroundPosition: 'center top',
        backgroundImage: `url(${process.env.REACT_APP_ROOT_FOLDER}logotype-440x158.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 2.5rem',
    },
    input: {
        marginTop: '1em',
    },
    button: {
        width: '100%',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    link: {
        textAlign: 'center',
        padding: '0.75em',
    },
    userLink: {
        textAlign: 'center',
        paddingBottom: '0.75em',
    },
    blueIcon: {
        fontSize: 50,
        color: '#39a1de',
    },
}));

const Input = ({
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm = ({ redirectTo }) => {
    const [loading, setLoading] = useSafeSetState(false);
    const [submitted, setSubmitted] = useSafeSetState(false);
    const [email, setEmail] = useState(null);

    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles({});

    var isStrong_aux = true;

    const [
        passwordToVerify,
        setPasswordToVerify,
        isStrong
      ] = usePasswordVerification("ExamplePassword1$");

    const validate = (values) => {
        const errors = { email: undefined, password: undefined, passwordConfirmation: undefined };

        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }

        if (!values.password) {
            errors.password = translate("application.password_activation.requiredField");
        } else if (!values.passwordConfirmation) {
            errors.passwordConfirmation = translate("application.password_activation.requiredField");
        } else if (values.password !== values.passwordConfirmation) {
            errors.passwordConfirmation = translate("application.password_activation.matchPassword");
        }

        return errors;
    };

    const submit = values => {
        
        const rules = /^.*(?=.{8,20})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\\\_\+\`\~\.\,\<\>\/\?\;\:\'\"\\\|\[\]\{\}]).*$/;
        const errors = validate(values);

        isStrong_aux = rules.test(values.password);
        setPasswordToVerify(values.password);

        if (!isStrong_aux) {
            return errors;
        }

        if (JSON.stringify(errors) !== "{}") return errors;

        if (!document.getElementById("condition-one-checkbox").checked) {
            document.getElementById("condition-one-content").style.color = "red";
            return;
        } else {
            document.getElementById("condition-one-content").style.color = "inherit";
        }
        if (!document.getElementById("condition-two-checkbox").checked) {
            document.getElementById("condition-two-content").style.color = "red";
            return;
        } else {
            document.getElementById("condition-two-content").style.color = "inherit";
        }

        setEmail(values.email);

        // values.email = encodeURIComponent(values["email"]);
        // values.password = encodeURIComponent(values["password"]);
        values.conditionsAccepted = true;

        let language = resolveBrowserLocale();
        if (language !== 'de' && language !== 'en') {
            console.log('set language')
            language = 'en';
        }
        values.language = language.toUpperCase();

        setLoading(true);

        // setLoading(false);
        // setSubmitted(true);

        fetch(`${process.env.REACT_APP_API_URL}/users/provider`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(values)
            })
            .then(result => result.json())
            .then(response => {

                if (!response.id) {
                    notify(
                    typeof response.message === 'string'
                        ? response.message
                        : typeof response.message === 'undefined' || !response.message
                        ? 'application.registration.error'
                        : response.message.email,
                    'warning'
                );
                } else {
                    setSubmitted(true);
                }

                setLoading(false);

            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            });
    };

    return (
        <div>
            {submitted ? 
                <div style={{ width: 300 }}>
                    <div className={classes.form}>
                        <Typography style={{ position: 'relative', top: 25, fontSize: 16, marginBottom: 40   }} align="center" component="h3">{translate('application.login.provider_registration')}</Typography>
                        
                        <div>
                            <ListItem style={{ paddingLeft: 0, paddingTop: 20, paddingBottom: 40 }}>
                                <ListItemIcon>
                                    <CheckIcon className={classes.blueIcon} />
                                </ListItemIcon>
                                <ListItemText style={{ color: '#39a1de' }} primary={translate('application.registration.message_1')} />
                            </ListItem>
                        </div>
                        <Typography style={{ marginBottom: 15 }} component="p">{translate('application.registration.message_2', { email: email })}</Typography>
                        <Typography component="p">{translate('application.registration.message_3')}</Typography>
                    </div>
                </div>
                :
                <Form
                    onSubmit={submit}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <div className={classes.form}>
                                <Typography style={{ position: 'relative', top: 25, fontSize: 16, marginBottom: 40   }} align="center" component="h3">{translate('application.login.provider_registration')}</Typography>

                                <div className={classes.input}>
                                    <label style={{ fontSize: 14 }}>{translate('application.registration.email.title')}</label>
                                    <Field
                                        style={{ marginTop: 10 }}
                                        autoFocus
                                        id="email"
                                        name="email"
                                        component={Input}
                                        // label={translate('application.registration.email.title')}
                                        placeholder={translate('application.registration.email.placeholder')}
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <label style={{ fontSize: 14 }}>{translate('application.registration.password.title')}</label>
                                    <Field
                                        style={{ marginTop: 10 }}
                                        id="password"
                                        name="password"
                                        component={Input}
                                        placeholder={translate('application.registration.password.placeholder')}
                                        type="password"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        id="passwordConfirmation"
                                        name="passwordConfirmation"
                                        component={Input}
                                        // label={translate('application.registration.password_confirmation.title')}
                                        placeholder={translate('application.registration.password_confirmation.placeholder')}
                                        type="password"
                                        disabled={loading}
                                    />
                                </div>
                                <div style={{ width: 300 }}>
                                    <PasswordStrengthComp isStrong={isStrong} />
                                </div>

                                <div style={{ width: 300, marginTop: 25 }}>
                                    <FormControlLabel control={<Checkbox id="condition-one-checkbox"/>} label={(<div id="condition-one-content" style={{ fontSize: 10 }} dangerouslySetInnerHTML={{__html: translate('application.conditions.privacy_policy_content',{link:'<a target="_blank" href='+translate('application.footer.link.privacy_policy')+'>'+translate('application.conditions.privacy_policy')+'</a>'})}} /> )} />
                                    <FormControlLabel control={<Checkbox id="condition-two-checkbox"/>} label={(<div id="condition-two-content" style={{ fontSize: 10 }} dangerouslySetInnerHTML={{__html: translate('application.conditions.consumer_terms_content',{link:'<a target="_blank" href='+translate('application.footer.link.age')+'>'+translate('application.conditions.consumer_terms')+'</a>'})}} />)} />
                                </div>
                            </div>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    className={classes.button}
                                >
                                    {loading && (
                                        <CircularProgress
                                            className={classes.icon}
                                            size={18}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('application.registration.register')}
                                </Button>
                            </CardActions>
                            <div className={classes.link}>
                                <a href={`${process.env.REACT_APP_PROVIDER_HOST_URL}/#/login`}>{translate('application.registration.to_login')}</a>
                            </div>
                            <div className={classes.userLink}>
                                <a href={`https://knooing.com/en/user/`}>{translate('application.registration.user')}</a>
                            </div>
                        </form>
                    )}
                />
            }
            
        </div>
    );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginForm;
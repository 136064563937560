import React, { useCallback, useState, Component } from 'react';
import { CardActions, ListButton, SimpleForm, Create, TextInput, required, useTranslate } from 'react-admin';
import AutocompleteKeywordArrayInput from './AutocompleteKeywordArrayInput';
import TopicInput from '../topic/TopicInput';
import UnsavedDataObserver from '../UnsavedDataObserver';
import { fetch } from '../utilities';
import ProjectCreateForm from "./ProjectCreateForm";

class ProjectCreate extends Component {


/*     componentDidMount() {
        try {
            var divs3 = document.querySelector("div[data-testid]");
            divs3.style.minHeight = "100px";
        } catch (e) {
            // ignore exceptions
        }
    } */


    render() {

        return <ProjectCreateForm {...this.props} />
    }

}

export default ProjectCreate;
import React from 'react';
import { ChipField, ArrayField, SingleFieldList, BooleanField, Datagrid, DateField, Show, SimpleShowLayout, TextField, EditButton, ListButton, CardActions, ShowController, ShowView, ReferenceField } from 'react-admin';

import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { isSiemens } from '../utilities';

import TextArrayField from '../common/TextArrayField';
import RecommendationState from './RecommendationState';

const CustomActions = ({ permissions, basePath, data, resource }) => (
    <CardActions>
        <ListButton basePath={basePath} />
        {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? <EditButton basePath={basePath} record={data} /> : null}
    </CardActions>
);

const SoftwarePortfolioShow = ({ permissions, theme, ...props }) => {

    return (
        <ShowController {...props}>
            {controllerProps =>
                <ShowView 
                    {...props} 
                    {...controllerProps} 
                    title="resources.recommendations.it_vendor.singular_name"
                    actions={<CustomActions permissions={permissions} />}
                >
                    <SimpleShowLayout>
                        {isSiemens() && <TextField label="resources.recommendations.fields.supplier_number" source="attribute" />}
                        <TextField label="resources.recommendations.software_portfolio.publisher_name" source="providerName" />
                        <TextField source="website" />
                        <ArrayField source="products" label="resources.recommendations.software_portfolio.software">
                            <Datagrid>
                                <TextField source="name" label="resources.recommendations.software_portfolio.software_name" sortable={false} />
                                <TextField source="useCase" label="resources.recommendations.fields.useCase" sortable={false} />
                            </Datagrid>
                        </ArrayField>
                        <DateField label="resources.recommendations.fields.createdAt" source="createdAt" />
                        <DateField label="resources.recommendations.fields.updatedAt" source="updatedAt" />
                        <BooleanField label="resources.recommendations.fields.blocked" source="blocked" />
                        <DateField label="resources.recommendations.fields.blockedAt" source="blockedAt" />
                        {controllerProps.record && controllerProps.record.blocked && (
                            <ReferenceField label="resources.recommendations.fields.blockedBy" source="blockedBy" reference="users">
                                <TextField source="lastName" />
                            </ReferenceField>
                        )}
                        {controllerProps.record && controllerProps.record.blocked && <TextField label="resources.recommendations.fields.blockedAt" source="blockedComment" />}
                        <RecommendationState />
                    </SimpleShowLayout>
                </ShowView>
            }
        </ShowController>
    )
};

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
    // translate,
    // withStyles(styles)
);

export default enhance(SoftwarePortfolioShow);
import React from 'react';
import Intercom from 'react-intercom';

const intercomIconStyle = {
   position: 'fixed',
   top: '10%',
   left: '45%',
};


class ChatIntercomm extends React.Component {

    componentDidUpdate() {
    }

    componentDidMount() {
    }


  render () {
    const { chatUser } = this.props;

    // console.log('chatUser', chatUser);

    if (chatUser) {
      return (
     
        <div className="app" style={intercomIconStyle}>
          <Intercom appID="t1jhdpx3" horizontal_padding="0" vertical_padding="0" { ...chatUser } />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ChatIntercomm;
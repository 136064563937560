import React from 'react';
import { translate, changeLocale, Title, showNotification, useSetLocale } from 'react-admin';
import { connect } from 'react-redux';

import compose from 'recompose/compose';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import { changeTheme } from './actions';

const styles = {
    label: { width: '7em', display: 'inline-block', fontSize: '1rem', color: 'rgba(0, 0, 0, 0.54)' },
    button: { margin: '1em', width: '100px' },
};

const saveLanguage = newLanguage => {
    
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}/users/${JSON.parse(localStorage.getItem('decoded_token')).user_name}/language`, { 
            method: 'PUT', 
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
            body: JSON.stringify(newLanguage.toUpperCase()) 
        })
        .then((response) => {
            if (response.status !== 204) {
                throw new Error('Server responded with status: ' + response.status);
            } else {
                localStorage.setItem('language', newLanguage);
            }
        })
        .catch((exception) => {
            // showNotification('', 'warning')
        });
}

const saveTheme = newTheme => {
    
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_API_URL}/users/${JSON.parse(localStorage.getItem('decoded_token')).user_name}/theme`, { 
            method: 'PUT', 
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
            body: JSON.stringify(newTheme.toUpperCase()) 
        })
        .then((response) => {
            if (response.status !== 204) {
                throw new Error('Server responded with status: ' + response.status);
            } else {
                localStorage.setItem('theme', newTheme);
            }
        })
        .catch((exception) => {
            // showNotification('', 'warning')
        });
}

const Configuration = ({
    classes,
    theme,
    locale,
    changeTheme,
    changeLocale,
    translate
}) => {
    const setLocale = useSetLocale();
    return(
    <Card>
        <Title title={translate('application.menu.general_configuration')} />
        <CardContent>
            <div>
                <div className={classes.label}>
                    {theme === 'light' ? 
                        <span>{translate('application.profile.fields.theme')}:</span>
                        :
                        <span style={{ color: 'white' }}>{translate('application.profile.fields.theme')}:</span>
                    }
                </div>
                <Button
                    size="small"
                    variant="contained"
                    className={classes.button}
                    color={theme === 'light' ? 'primary' : 'default'}
                    onClick={() => { saveTheme('light'); changeTheme('light') }}
                >
                    {translate('application.profile.field_values.theme.light')}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    className={classes.button}
                    color={theme === 'dark' ? 'primary' : 'default'}
                    onClick={() => { saveTheme('dark'); changeTheme('dark') }}
                >
                    {translate('application.profile.field_values.theme.dark')}
                </Button>
            </div>
            <div>
                <div className={classes.label}>
                    {theme === 'light' ? 
                        <span>{translate('application.profile.fields.language')}:</span>
                        :
                        <span style={{ color: 'white' }}>{translate('application.profile.fields.language')}:</span>
                    }
                </div>
                <Button
                    size="small"
                    variant="contained"
                    className={classes.button}
                    color={locale === 'en' ? 'primary' : 'default'}
                    onClick={() => { saveLanguage('en'); setLocale('en') }}
                >
                    {translate('application.profile.field_values.language.english')}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    className={classes.button}
                    color={locale === 'de' ? 'primary' : 'default'}
                    onClick={() => { saveLanguage('de'); setLocale('de'); }}
                >
                    {translate('application.profile.field_values.language.deutsch')}
                </Button>
            </div>
        </CardContent>
    </Card>
    )
};

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {
            changeLocale,
            changeTheme,
            showNotification,
        }
    ),
    translate,
    withStyles(styles)
);

export default enhance(Configuration);
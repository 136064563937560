import React, { Component, Fragment, useState, useEffect } from 'react';
import { useTranslate, Show, SimpleShowLayout, TopToolbar, required,
    Button as CancelButton,
    SaveButton,
    Toolbar,
    useNotify, 
    useRedirect,
    useDataProvider,
    useRefresh,
    Confirm,
} from 'react-admin';

import ProjectLayout from './ProjectLayout';

import EyeIcon from '@material-ui/icons/RemoveRedEye';
import CreateIcon from '@material-ui/icons/Create';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import CancelIcon from '@material-ui/icons/Cancel';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

import SendIcon from '@material-ui/icons/Send';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toolbar: {
        background:'none',
        paddingTop:'10px',
        flex: '0 0 auto',
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    button:{
        marginRight:'10px'
    }
}));

export const ArchivedProjectActions = ({ record }) => {

    // console.log('record: ', record)

    const translate = useTranslate();

    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [choicesTwo, setChoicesTwo] = useState([]);

    const [loading, setLoading] = useState(false);

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const dataProvider = useDataProvider();

    useEffect(() => {

        
    }, []);

    function activate(event) {
        event.stopPropagation();
        hideMenu();
        setDialogOpen(true);
    }

    function hideDialog(event) {
        event.stopPropagation();

        setDialogOpen(false);
    }

    const [anchor, setAnchor] = useState(null);

    const showMenu = event => {
        // console.log('event: ', event)
        event.stopPropagation();

        setAnchor(event.currentTarget);
        setOpen(true);
    };

    const hideMenu = () => {
        setAnchor(null);
        setOpen(false);
    };

    const submit = (values) => {
        
        // console.log('values: ', values);

        dataProvider
            .update('projects', { id: `${record.id}/activated` })
            .then(response => {
                
                // success side effects go here
                redirect('/projects');
                refresh();
                notify('resources.projects.activate_dialog.notification.activated_success');
            })
            .catch(error => {

                // failure side effects go here 
                notify('resources.projects.activate_dialog.notification.activated_error', 'warning');
            })
            .finally(() => {
                setDialogOpen(false);
            });
    };

    return (
        <Fragment>
            <Button variant="outlined" color="primary" aria-haspopup="true" onClick={showMenu}>
                {translate('resources.projects.action.actions')}
            </Button>
            <Menu
                keepMounted
                open={open}
                anchorEl={anchor}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={hideMenu}
                onClick={(event) => event.stopPropagation()}
            >
                <MenuItem onClick={activate}>
                    <ListItemIcon><UnarchiveIcon /></ListItemIcon>
                    <Typography variant="inherit">{translate("resources.projects.action.activate")}</Typography>
                </MenuItem>
            </Menu>
            <Confirm
                isOpen={dialogOpen}
                title="resources.projects.activate_dialog.title"
                content="resources.projects.activate_dialog.message.message_1"
                onConfirm={submit}
                onClose={hideDialog}
            />
        </Fragment>
    );
}
import { UPDATE } from 'react-admin';

export const SUGGESTION_PROVIDER_NAME = 'SUGGESTION_PROVIDER_NAME';

export const suggestionProviderName = (id, data, callback) => ({
    type: SUGGESTION_PROVIDER_NAME,
    payload: { id, data: { ...data } },
    meta: {
        resource: 'suggestions',
        fetch: UPDATE,
        onSuccess: {
            notification: null,
            // basePath,
            callback: () => callback(),
        },
        onFailure: {
            notification: null,
        },
    },
});

export const SUGGESTION_ACCEPT = 'SUGGESTION_ACCEPT';

export const suggestionAccept = (id, data, callback) => ({
    type: SUGGESTION_ACCEPT,
    payload: { id, data: { ...data } },
    meta: {
        resource: 'suggestions',
        fetch: UPDATE,
        onSuccess: {
            notification: null,
            // basePath,
            callback: () => callback(data.feedbackType),
        },
        onFailure: {
            notification: {
                body: 'resources.suggestions.notification.accepted_error',
                level: 'warning',
            },
        },
    },
});

export const SUGGESTION_DECLINE = 'SUGGESTION_DECLINE';

export const suggestionDecline = (id, data, callback) => ({
    type: SUGGESTION_DECLINE,
    payload: { id, data: { ...data } },
    meta: {
        resource: 'suggestions',
        fetch: UPDATE,
        onSuccess: {
            notification: null,
            // basePath,
            callback: () => callback(data.feedbackType),
        },
        onFailure: {
            notification: {
                body: 'resources.suggestions.notification.declined_error',
                level: 'warning',
            },
        },
    },
});
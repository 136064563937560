import englishMessages from "ra-language-english";

export default {
  ...englishMessages,
  resources: {
    messages: {
      singular_name: "Message",
      plural_name: "Messages",
      name: "Message |||| Messages",
      fields: {
        subject: "Subject",
        suggestion: {
          id: "Project"
        },
        product: {
          id: "Product / Service"
        },
        recipient: "Recipient",
        attachments: "Related files"
      },
      field_values: {
        subject: {
          accepted_product_question: "Request more information",
          provider_question: "Get to know this provider",
          project_question: "Request more information about a project",
          product_question: "Issues related to product / service publications",
          company_question: "Company / employee management",
          other: "Other"
        },
        recipient: {
          project_owner: "Project owner",
          administrator: "Administrator"
        }
      },
      action: {
        send: "Send"
      },
      notification: {
        sent_success: "Message has been sent",
        sent_error: "Error: Message could not be sent"
      }
    },
    project_messages: {
      fields: {
        message: "Message",
        type: "type"
      },
      action: {
        create: "Write a message..."
      },
      message: {
        dialog_content:
          "Our business consultant will reply to you as soon as possible",
        file_upload: "Drop a file to upload, or click to select it",
        information:
          "Here you can contact your Business Consultant if you have further questions regarding your project or the suggested solutions. The Business Consultant will also act as mediator between you and the provider of your selected solution.",
        no_messages: "No messages yet"
      },
      USER_TO_CRM: "Sent",
      CRM_TO_USER: "Received"
    },
    recommendations: {
      singular_name: "My recommendation",
      plural_name: "My recommendations",
      name: "My recommendation |||| My recommendations",
      fields: {
        providerName: "Provider name",
        product_name: "Product or Service",
        product_name_long: "Name of product or service (optional)",
        productName: "Product name",
        website: "Website",
        further_information: "Further Information",
        actively_used_long: "Is your company actively using this product or service?",
        activelyUsed: "Actively used",
        blocked: "Blocked",
        blockedAt: "Blocked date",
        blockedBy: "Blocked by",
        blockedComment: "Comment",
        useCase: "Use case or purpose",
        status: "Supplier status",
        createdAt: "Created",
        updatedAt: "Last modified",
        products: "Products or services",
        offerings: "Offerings",
        offering: "Type of offering",
        supplier_number: "Supplier number",
        state: 'State',
      },
      field_values: {
          status: {
              top: 'Top Supplier',
              preferred: 'Preferred Supplier',
              standard: 'Standard',
              niche: 'Niche Supplier',
              other: 'Other Supplier',
              phase_out: 'Phase Out',
              blocked: 'Blocked Supplier',
          },          
          offerings: {
            software: 'Software',
            service: 'Service'
          },
          state: {
            company_not_verified: 'Verification pending',
            data_collection: 'Data collection',
            clarification_needed: 'Clarification needed',
            available: 'Verified',
          }
      },
      it_vendor: {
        plant : 'Plant',
        spendType : 'Spend type',
        valuation : 'Performance',
        valuation_0: '0 - Others',
        valuation_1: '1 - Gold',
        valuation_2: '2 - Silver',
        valuation_3: '3 - Bronze',
        internalUrl: 'Custom target URL',
        supplier_number: "Supplier number",
        name: 'IT Vendor |||| IT Vendors',
        providerName: 'Vendor name',
        website: 'Website',
        singular_name: "IT Vendor",
        id: 'Supplier Number (ERP)',
        vendor_name: 'Vendor name',
        segment: 'Segment',
        internal_company: 'Internal company',
        mentor: 'Mentor',
        management: 'Vendor management',
        region: 'Region',
        status: 'Status',
        ofa: 'OFA',
        sourcing_cluster: 'Sourcing cluster',
        contract_type: 'Contract type',
        duns_global_name: 'DUNS Global Name',
      },
      software_portfolio: {
        name: 'Software Portfolio',
        publisher_name: 'Publisher',
        software: 'Software',
        software_name: 'Name',
      },
      action: {
        create: "Add recommendation",
        add: "Add supplier",
        add_it_vendor: "Add IT Vendor",
        add_publisher: "Add Publisher",
      },
      message: {
        description: "By giving personal recommendations about solutions and providers we can improve future matchings for your company and the whole community.",
        title: "My submitted recommendations",
        empty: "Add now your first recommendation",
      },
      similar_recommendations: {
        title: "Based on your input we detected similar active provider already existing",
        title_2: "Select which information to keep",
        recommendation: "You can add your product / service to one of these active provider or create a new one:",
        new_recommendation: "Create a new active provider",
      },
    },
    suggestions: {
      fields: {
        status: "Status",
        matching_score: "Matching-Score",
        not_matched: "Not matched",
        details: "Details"
      },
      field_values: {
        consumer_status: {
          pending: "Waiting for your response",
          accepted: "Pending approval",
          declined: "Declined by you",
          self_service: "Fitting - Provider will be contacted by yourself",
        },
        provider_status: {
          pending: "Pending provider response",
          accepted: "Accepted by provider",
          approved: "Accepted by provider",
          declined: "Declined by provider",
          self_service: "You have received information in a message",
        },
        details: {
          fit_less_100: "This product does not contain information about",
          fit_100: "This product fits your request"
        }
      },
      matrix: {
        synonyms: "Synonyms",
        source: "Source",
        excerpt: "Excerpt",
        criteria: "Criteria",
        solutions: "Solutions",
        no_details_available: "NO DETAILS AVAILABLE - Criteria is part of the meta information of this solution",
        criteria_tooltip: "Mouse over a criteria point to get detailed information of used synonyms in matching",
        matching_score_tooltip: "Mouse over a positive result to see detailed information",
      },
      action: {
        accept: "Fitting",
        decline: "Decline",
        cancel: "Cancel decision",
        less_information: "Less information",
        more_information: "More information",
        show_matrix: "Show matrix",
        show_more: "Show more",
        hide_matrix: "Hide matrix",
        show_description: "Show description",
        cancel_and_edit: "Cancel & edit feedback",
        submit: "Submit"
      },
      dialog: {
        next_step: "Many thanks for your feedback on our suggestions",
        accept: "How would you like to proceed?",
        decline: "Decline this suggestion",
        cancel: "Cancel decision",
        fields: {
          feedback: "Choose an option",
          question: "Your question",
          message: "Message (optional)",
        },
        field_values: {
          feedback: {
            option_1: "It does not fit my project in general",
            option_2: "It does not meet some of my specific requirements",
            option_3: "The given information is not sufficient",
            option_4: "I need more information",
            option_5: "Send anonymous RFI to get direct contact details",
            option_6: "I want to see provider's name",
            option_7: "I don't want to see provider's name anymore",
            option_8: "I will contact the provider myself",
            continue_suggestion_search_reviewed_accepted: "I need further solutions",
            stop_suggestion_search_reviewed_accepted: "Stop research and continue with chosen solutions",
            continue_suggestion_search_reviewed_mixed: "Research and qualification of further solutions by taking your feedback into consideration",
            stop_suggestion_search_reviewed_mixed: "Stop research and continue with chosen solutions",
            research_completed: "Stop research to check internally with chosen solutions",
            step_1_1: "Continue searching in Internal Insights",
            step_1_2: "Continue searching in Further Suppliers",
            step_2: "Continue with External Research (Matching)",
            skip_step_1: "Skip Top & Preferred",
            skip_step_2: "Skip Further Suppliers",
            skip_step_2_mixed: "Skip Internal Insights",
            skip_mixed: "Skip Internal Insights",
          }
        },
        message: {
          message_1:
            "To improve our Matching and customize our AI to your needs, please select why this suggestion is not interesting for you:",
          message_2: "Please let us know how you want to proceed:",
          message_3:
            "Thank you for your feedback! We will check with the provider and get back to you as soon as possible. You will get notified by us when we receive the necessary information.",
          message_4:
            "Thank you for your feedback! We will forward the necessary information to the provider and check if they are available. We will also notify you with their response.",
          confirmation:
            "Are you sure you want to cancel your previous decision?",
          reviewed_accepted: 'As all suggestions are fitting you have the following options to proceed:',
          reviewed_mixed: 'Based on your feedback to our suggestions we can offer you the following options to proceed:',
          reviewed_declined: 'Based on your feedback research and qualification of new solutions will be started. In case of unclear requirements or feedback one of our Consultants will contact you directly.',
        }
      },
      dialog_one: {
        title: "SHARE MY CONTACT WITH PROVIDER",
        message_1: "I agree that knooing shares following contact details with the provider in order for them to get in touch with me. (Please select your choices)",
        message_2: "Add a note for the provider to receive with your contact details (optional):",
        textarea_placeholder: '...start typing your message here e.g. "Please call me tomorrow afternoon."',
        action_1: "AGREE & SEND",
      },
      dialog_two: {
        title: "ASK KNOOING TO ARRANGE A MEETING",
        message_1: "Please select your preferred meeting options. After sending the form, we will contact the provider and get back to you as soon they reply.",
        message_2: "I agree that knooing can share my contact details (full name, company, position, email, phone numbers) with the provider in order to set up a meeting.",
        fields: {
          favouredDate: "Favoured date *",
          alternativeDate: "Alternative date",
          preferredMedium: "Preferred medium",
          alternativeMedium: "Alternative preferred medium",
          comment: "Comment (optional)",
        },
        field_values: {
          preferredMedium: {
            option_1: "Websession",
            option_2: "Phone call",
            option_3: "Other",
          }
        },
        action_1: "AGREE & SEND",
        validation: {
          message_1: "You need to agree to share your contact details in order to proceed."
        },
      },
      contact_information: {
        name: "Contact information",
        notes: "Notes",
        send_message: "Send a message",
        create_pdf: "Create PDF",
        other_options: {
          title: "Other options",
          action_1: "Share my contact with provider",
          action_1_tooltip: "You already shared your contact details.",
          action_2: "Ask knooing to arrange a meeting",
          action_2_tooltip: "knooing has already been asked to arrange a meeting. You will be notified as soon as there are any updates. Please contact your business consultant in case of questions.",
          action_3: "I have more questions",
        },
        message: {
          message_1: "The provider asked knooing to arrange a meeting. You will be notified by one of our business consultants soon with further information.",
          primary_text:
            "Do you still need more information or our help to organize an appointment?",
          secondary_text: "Send us a message"
        }
      },
      message: {
        top_recommended: "Top recommended",
        no_matching:
          "Your request has been successfully created! Unfortunately, based on current information, no matching could take place. One of our Business Consultants will continue to process your request and contact you as soon as possible."
      },
      notification: {
        accepted_success: "Product has been accepted",
        accepted_error: "Error: product has not been accepted",
        declined_success: "Product has been declined",
        declined_error: "Error: product has not been declined",
        shared_success: "Your contact information was sent to the provider successfully",
        appointment_created_success: "Your request has been submitted successfully",
      },
      validation: {
        message_1: 'You need to select at least "company" and "email" to proceed.',
        message_2: 'You need to select at least your email or your phone or your mobile number in order to proceed.'
      }
    },
    companies: {
      name: "Company information",
      fields: {
        name: "Name",
        description: "Description (short profile)",
        address: "Address (headquarters)",
        website: "Website",
        topics: "Topics (max. 5)",
        size: "Number of employees",
        foundedAt: "Founded date"
      },
      field_values: {
        size: {
          less_than_10: "1 - 9",
          less_than_50: "10 - 49",
          less_than_100: "50 - 99",
          less_than_500: "100 - 499",
          less_than_1K: "500 - 999",
          less_than_5K: "1000 - 4999",
          less_than_10K: "5000 - 9999",
          greater_than_10K: "10000+"
        }
      }
    },
    users: {
      singular_name: "User",
      name: "Employee |||| Employees",
      fields: {
        salutation: "Salutation",
        firstName: "First name",
        lastName: "Last name",
        position: "Position",
        email: "Email",
        phone: "Phone",
        mobilePhone: "Mobile phone",
        role: {
          name: "Role"
        },
        jobExperience: "Job experience",
        roleName: "Role",
        notificationOptions: {
          agent: "Receive agent notifications"
        },
        enabled: "Registration confirmed",
        enabledAt: "Registration date",
        locked: "Locked",
        createdAt: "Created date",
        language: "Language"
      },
      field_values: {
        salutation: {
          male: "Mr.",
          female: "Ms."
        },
        role: {
          user: "User",
          power_user: "Power user",
          executive: "Executive"
        },
        job_experience: {
          level_0: "Student or intern",
          level_1: "Career starter (junior)",
          level_2: "Employee with professional experience (senior)",
          level_3: "Head of department",
          level_4: "Area manager",
          level_5: "Managing director",
          undefined: "Other"
        },
        language: {
          DE : 'German',
          EN : 'English',   
        },
      },
      action: {
        new_user: "New user",
        send_invitation: "Send invitation",
      },
      message: {
        delete_user_title: 'Delete user: %{full_name}',
        delete_user_content: 'Are you sure you want to delete this user? If you delete a user by mistake, contact us within 28 days and we can retrieve the information. After 28 days this data will be permanently deleted.',
      },
    },
    projects: {
      singular_name: "Request",
      name: "Request |||| Requests",
      active_requests: "ACTIVE REQUESTS",
      archived_requests: "ARCHIVED REQUESTS",
      fields: {
        title: "Title",
        status: "Current status",
        name: "Name",
        description: "Description",
        topics: "Topics",
        keywords: "Keywords",
        createdAt: "Created date",
        creatorId: "Created by",
        updatedAt: "Last modified date",
        updaterId: "Last modified by",
        owner: {
          id: "Owner"
        },
        watchers: "Watchers",
        state: "Current status",
        uploadDocuments: "Upload documents",
        uploadedDocuments: "Uploaded documents",
        maximumFiles: "Maximum amount of 5 files is reached.",
        maximumFileSize: "File size is too big. Maximum 5 MB per file allowed.",
        dropzonePlaceholder: "Only JPEG, PNG and PDF with a file size of max 5 MB. 5 files maximum.",
        offering: "Request Type",
        offering_placeholder: "Please select",
      },
      field_values: {
        status: {
          in_review: "In review",
          approved: "Approved",
          archived: "Archived"
        },
        state: {
          analysis_running: "Analysis & Research running",
          matching_available: "Matching available",
          verification_running: "Verification running",
          sourcing: "Sourcing",
        },
        keywords: "Please enter possible keywords here.",
        description:
          "Please describe your project, the requirements you already know about and/or what your objectives are."
      },
      action: {
        next: "Next",
        submit: "Submit",
        previous: "Previous",
        archive: "Close request",
        activate: "Activate",
        add_watcher: "Add watcher",
        actions: "Actions",
        archived_projects: "GO TO ARCHIVE",
        active_projects: "BACK TO ACTIVE REQUESTS",
        vendor_consolidation: "Vendor Consolidation",
        export_matrix: "Export matrix"
      },
      vendor_consolidation: {
        description: "Based on the selected Vendors and their provided capabilities, we calculated the following consolidation matrix. The evaluation and categorization is done by strategic vendor assessments and knooings market screening technology.",
        title_1: "Vendor Consolidation",
        description_1: "Start your Vendor Consolidation by comparing selected Vendors and dedicated capabilities. Using your strategic vendor assessments and knooings market screening technology to get a fast overview and clear comparison.",
        open: "OPEN",
        description_2: "You can reopen previous run Vendor Consolidation tasks if you are an owner or watcher. To start a new analyzation, use the following button, select your capabilities and vendors you want to compare.",
        create: "Create",
        title: "Title",
        owner: "Owner",
        watcher: "Watcher",
        created_date: "Created",
        delete: "Delete",
        actions: "ACTIONS",
        add_watcher: "Add Watcher",
        edit_capability: "Edit Capability",
        edit_capability_subtitle: "Add Capabilities by start typing and select, remove by using the x at the listed tags.",
        edit_vendors: "Edit Vendors",
        refresh_result: "Refresh Result",
        delete_task: "Delete Task",
        description_3: "Based on the selected Vendors and capabilities, we calculated the following consolidation matrix. The evaluation and categorization is done by strategic vendor assessments and knooings market screening technology.",
        example_1: "Example request 1"
      },
      internal_insight: {
        text_1: "Based on collected or imported data about %{company_name}, we have identified the following vendors with whom you already have or have had an active business relationship.",
        text_1_only_favorite_providers_1: "Based on collected or imported data about %{company_name}, we have identified the following ",
        text_1_only_favorite_providers_2: "Top & Preferred Suppliers ",
        text_1_only_favorite_providers_3: "with whom you already have or have had an active business relationship.",
        text_2_title: "NOTE: We can't ensure, that every vendor you're working with gets taken into consideration. This can be because of the following reasons:",
        text_2_1: "We don't have a direct connection to your database (API Integration)",
        text_2_2: "The latest vendors are missing due to periodical imports (Importer)",
        text_2_3: "Data sets don't reflect all vendors or legal entities",
        text_2_4: "Public data availability about vendor isn't given (e.g. no website)",
        text_3: "For further question or increasing your individual database, please contact us.",
        accepted: 'Save',
        neutral: 'Clear',
        rejected: 'Ignore',
        product_name: 'Internally used product or service',
        use_case: 'Internal use case',
        title: "Calculation running",
        text_4: "The analyzation of your quest is still running. First results will be immediately shown when the process is finished. Parallel to that the quality check is running, so results can change and improve until you will be notified that Matchings are available. During the iterative matching process your Internal Insights will also further improve as given Feedback to Solutions and the adjustment of the Matching Criteria apply also here.",
        text_5: "The analyzation of your quest is still running. First results will be immediately shown when the process is finished. Parallel to that the quality check is running, so results can change and improve until you will be notified that Matchings are available.",
        exact_match: "Only exact match",
        next_step_text: "Are you sure that you want to skip this step?",
        capability_title: "Alternative providers that have no obviously declared capabilities",
        capabilities: "Capabilities",
        adoit_link: "Open in ADOIT",
        collected_file: "Collected file about",
      },
      meta_matching: {
        title_1: "META MATCHING",
        title_2: "Potential Provider",
        text_1_part_1: "We are constantly improving our platform and taking feedback from our users into account.",
        text_1_part_2: "With the latest ",
        text_1_part_3: "",
        text_1_part_4: "of META MATCHING, we give users the opportunity to directly access further results in specific requests.",
        text_1_part_5: "The META MATCHING is an addition to our proven matching process, but uses our extended database.",
        text_2_part_1: "NOTE: The results shown here are based on the sum of the information we have collected about a company. In our standard matching, the focus remains on solutions or services provided by companies we have evaluated.",
        text_2_part_2: "We are always happy to receive comments and suggestions for improvement. Simply contact your Business Consultant or send us a message via ",
        score: "Score",
        company: "Company",
        last_updated: "Last updated",
        top_hit_url: "Top hit URL",
      },
      message: {
        dialog_title: "Matching starts",
        dialog_content_text: "The matching and qualification process has started. As soon as this is completed and first results are available, you will receive a notification.",
        dialog_action: "TO THE OVERVIEW",
        dialog_title_1: "It's a Match!",
        dialog_title_2: "Almost there!",
        dialog_content_text_1_1:
          "Great work! Based on your given information we could already find a few first Matches for your project! Our intelligent platform will now start the iterative process of analyzing your request further in order to get an even more precise result.",
        dialog_content_text_1_2:
          "These Results are customized for each request. We will inform you as soon as they are available. Now you can decide whether you want to get a preview of the Matches or wait for your customized results:",
        dialog_content_text_2_1:
          "Based on the information you provided, we were unable to generate any results. Our platform will further analyze your request and test individual parameter combinations and variations to find possible solutions. If needed, one of our business consultants will optimizeyour request and contact you regarding this.",
        dialog_content_text_2_2:
          "Of course, you can also edit your request further by giving more information or keywords in order to find a possible Match.",
        dialog_action_1: "Show Preview",
        dialog_action_2: "Edit request",
        dialog_action_3: "Wait for results",
        tab_title_1: "Request details",
        tab_title_2: "Matching",
        tab_title_3: "Project Messages",
        tab_title_4: "Meta matching",
        tab_title_5: "Internal insights",
        tab_title_6: "Top & Preferred Suppliers",
        tab_title_7: "Further Suppliers",
        in_review:
          "Each new request will be checked by our administrators based on anonymity, prohibited content etcetera. This check will be done usually within 2 business days.",
        preview: "This is a PREVIEW and the results aren't final. A notification will be sent, when verified results are available.",
        tab_disabled: "This step is currently deactived and will become available after finishing or skipping the previos step(s)",
        preview_disabled: "This tab will be activated when the qualified results are available. You will receive a notification.",
      },
      progress: {
        dialog_title_1: "Matching started (Step 1 / 2)",
        dialog_title_2: "Generate Results (Step 2 / 2)",
        dialog_title_3: "Step 3",
        dialog_content_text_1: "The matching and qualification process started. Should we try to calculate a preview our would you like to wait for qualified result?",
        dialog_content_text_2_1: "Search & Matching against database started. Results are being generated.",
        dialog_content_text_2_2: "You can leave the page and come back later. The Process continues in the back.",
        dialog_action_1: "Try preview calculation",
        dialog_action_2: "Wait for qualified results",
        dialog_action_3: "Go to dashboard",
      },
      notification: {
        created_success: "Project has been created",
        created_error: "Error: project has not been created",
        updated_success: "Project has been updated",
        updated_error: "Error: project has not been updated",
        activated_success: "Project has been activated",
        activated_error: "Error: project has not been activated",
        archived_success: "Project has been archived",
        archived_error: "Error: project has not been archived"
      },
      placeholder: {
        title: "Enter your request title or project name here.",
        description:
          "Please describe your project, the requirements you already know about and/or what your objectives are. However you prefer, you can use full sentences or list your items. ",
        keywords: "Please enter important keywords here.",
        topics:
          "To improve the results you can choose up to 5 relevant topics. "
      },
      tips: {
        title:
          "The title will be used for the Matching and will also be the identifier on the platform and in the communication.",
        description:
          "The format of your input is really flexible. If you already have a written discription, feel free to just copy and paste.<br/><br/>NOTE: To ensure a fitting Matching result, try to use a minimum of 100 words or 5 listed items.",
        keywords:
          "As soon as you start typing, you will also get auto-complete suggestions. New keywords can be saved by pressing enter or by seperating them using a comma."
      },
      legend_dialog: {
        link: "Legend: What the colors mean",
        title: "What the colors mean",
        green_color: "The requirement itself or a synonym was found in the solution data. A synonym can be an abbreviation, long form etc.",
        green_color_example: "Example: “App” is a synonym of “Application”.",
        orange_color: "An indirectly related term to the requirement was found in the solution data. An indirect related term can be a subcategory, a parent-/child relationship etc.",
        orange_color_example: "Example: “Mobile app development” is a subcategory of “Application development”.",
        gray_color: "Our system did not find any information regarding this requirement in the solution data. However, this does not necessarily mean the solution does not fulfill the requirement, but that a final evaluation should be done with the provider.",
      },
      archive_dialog: {
        title: "Close this request and move to archive",
        fields: {
          comment: "Comment (optional)",
        },
        field_values: {
          feedback: {
            option_1: "I commissioned a provider/supplier for this request",
            option_2: "The request was stopped or canceled",
            option_3: "I used this request to gather information (no decision was taken)",
            option_4: "Others",
            option_1_other: "Other (not suggested by knooing)",
          }
        },
        message: {
          message_1: "To improve our quality and offer you a better experience, an optional feedback on your decision would be helpful. Thank you in advance.",
          message_2: "Why are you closing this request?",
          message_3: "Which provider/supplier did you commission?",
          message_4: "Please type the provider/supplier name here (optional)",
        },
        notification: {
          archived_success: "Project has been archived",
          archived_error: "Error: project has not been archived"
        }
      },
      activate_dialog: {
        title: "Open this request and move to active",
        message: {
          message_1: "Are you sure you want to activate this request?",
        },
        notification: {
          activated_success: "Project has been activated",
          activated_error: "Error: project has not been activated",
        }
      },
    },
    products: {
      singular_name: "Product",
      name: "Product |||| Products",
      fields: {
        // Product
        status: "Current status",
        language: "Language",
        owner: {
          id: "Owner"
        },
        topics: "Topics (max. 5)",
        keywords: "Keywords",
        createdAt: "Created date",
        creator: {
          id: "Created by"
        },
        updatedAt: "Last modified date",
        updater: {
          id: "Last modified by"
        },

        translation: "Translation",

        // Product description
        name: "Name",
        neutralName: "Neutral name",
        challenge: "Challenges / problems",
        advantage: "Advantages / benefits",
        solution: "Solution",
        feature: "Functionalities / features"
      },
      field_values: {
        status: {
          in_review: "In review",
          approved: "Approved",
          archived: "Archived"
        },
        translation: {
          automatic: "Automated translation (with the possibility to edit)",
          manual: "Add translation of this product manually",
          default: "Do not change current translations"
        }
      },
      action: {
        next: "Next",
        submit: "Submit",
        previous: "Previous",
        archive: "Archive",
        activate: "Activate"
      },
      message: {
        page_title_1: "Add product descriptions in other languages",
        page_title_2: "Selected language",
        aside_title: "Add a new product",
        in_review:
          "Each new product will be checked by our administrators based on anonymity, prohibited content etcetera. This check will be done usually within 2 business days. There will be no notification after a successful approval. Only in case of a major issue."
      },
      notification: {
        created_success: "Product has been created",
        created_error: "Error: product has not been created",
        updated_success: "Product has been updated",
        updated_error: "Error: product has not been updated",
        activated_success: "Product has been activated",
        activated_error: "Error: product has not been activated",
        archived_success: "Product has been archived",
        archived_error: "Error: product has not been archived"
      }
    },
    topics: {
      action: {
        add: "Add topics"
      },
      message: {
        maximum_limit: "Please choose up to %{maximum} topics"
      },
      notification: {
        added_error:
          "You have already chosen %{maximum} topics which is the maximum"
      }
    },
    quick_search_software: {
      headline: "Quick Search",
      title: "Find existing Software",
      text: "The tool to find existing software solutions within the company. Easy to use as every search engine, powered by knooing AI.",
      time_note: "Immediate results",
      button: "OPEN",
      use_case: "use case",
      missing_words: "Missing words",
      must_contain: "Must contain",
      page: {
        title: "Software - Quick Search",
        subtitle: "Search fast and easy through the existing software solutions of your Company. The search is based on the Software Portfolio and enriched knooing data.",
        tooltip: {
          text_1: "We can't ensure that every vendor or software you are having, gets taken into consideration. This can be because of the following reasons:",
          text_2: "It can take up to several days to collect and enrich data, after entries get added or edited in the portfolio.",
          text_3: "The latest vendors are missing, because they didn't get updated on knooing.",
          text_4: "The portfolio doesn't cover all geographical regions or legal entities.",
          text_5: "There is no public available data about the vendor or software.",
          text_6: "For further questions or enriching the portfolio database, please contact us.",
        },
        button: "SEARCH",
        selector: "Only exact match",
      }
    },
    quick_search_vendors: {
      headline: "Quick Search",
      title: "Find existing Vendors & Services",
      text: "Easy & Quick solution to discover the IT Vendor base of the company. Save stress and time by finding registered Vendors and their services within seconds.",
      time_note: "Immediate results",
      button: "OPEN",
      page: {
        title: "Vendors & Services - Quick Search",
        subtitle: "Search fast and easy through the existing Vendors & Services of your Company. The search is based on the Vendor Portfolio and enriched knooing data.",
        tooltip: {
          text_1: "We can't ensure that every vendor or software you are having, gets taken into consideration. This can be because of the following reasons:",
          text_2: "It can take up to several days to collect and enrich data, after entries get added or edited in the portfolio.",
          text_3: "The latest vendors are missing, because they didn't get updated on knooing.",
          text_4: "The portfolio doesn't cover all geographical regions or legal entities.",
          text_5: "There is no public available data about the vendor or software.",
          text_6: "For further questions or enriching the portfolio database, please contact us.",
        },
        button: "SEARCH",
        selector: "Only exact match",
      }
    },
    internal_external_matching: {
      headline: "Project Check & Market Research",
      title: "Create Project",
      text: "You are not sure, what to search for? You need support through the whole process? Create your project now. In our step-by-step process, you have the possibility to describe your project or need. Our knooing AI will analyze your project to find the best fitting solutions or partners which are already existing in your company and on the market.",
      time_note: "Multiple days",
      button: "CREATE PROJECT"
    },
    external_matching: {
      headline: "Market Research",
      title: "Create Project",
      text: "You are not sure, what to search for? You need support through the whole process? Create your project now. In our step-by-step process, you have the possibility to describe your project or need. Our knooing AI will analyze your project to find the best fitting solutions or partners on the market.",
      time_note: "Multiple days",
      button: "CREATE PROJECT"
    }
  },
  application: {
    login: {
      email: "Email",
      password_recovery: "Forgot password?",
      provider_registration: "PROVIDER REGISTRATION",
    },
    registration: {
      email: {
        title: "Email",
        placeholder: "Please enter your email here",
      },
      password: {
        title: "Password",
        placeholder: "Please set your password",
      },
      password_confirmation: {
        placeholder: "Please repeat your password",
      },
      register: "Register for free",
      to_login: "I already have an account",
      user: "I am a USER",
      message_1: "THANK YOU FOR YOUR REGISTRATION",
      message_2: "We just sent a confirmation email to %{email}.",
      message_3: "Please activate the included link in order to finish your registration.",
      error: 'An unexpected error has occurred please try later'
    },
    dashboard: {
      welcome: {
        header: "STOP SEARCHING - START KNOOING",
        content: "AI-BASED DEMAND AND KNOWLEDGE PLATFORM"
      },
      new_request: "Create a new request right now",
      suggestions: {
        name: "Pending suggestions",
        score: "Score"
      },
      notifications: {
        name: "Notifications",
        status: "Status",
        found_suggestion: "suggestion found",
        found_suggestions: "suggestions found",
        declined_by_provider: "Your project declined by provider",
        accepted_by_provider: "Your project accepted by provider"
      },
      info_box: {
        title: 'What can I use knooing for?',
        item1: 'Search for information regarding software or services within your vendor landscape',
        item2: 'Find fitting vendors for projects regarding software or services',
        item3: 'Project requests must be criteria / requirement base',
        item4: 'The more criteria, the more specific the results',
        item5: 'No strategy or implementation recommendations',
        item6: 'No hardware or goods',
      }
    },
    menu: {
      company_information: "Company",
      employees: "Users",
      requests: "Requests",
      suitable_projects: "Matching projects",
      profile: "Profile",
      settings: "Settings",
      personal_data: "Personal information",
      general_configuration: "Settings",
      general_configuration_description: "Manage language, theme settings",
      security: "Security",
      security_description: "Manage your security settings (change your password and etc.)",
      notification_configuration: "Notification options",
      faq: "FAQs",
      chat: "Chat",
      knowledge_center: "Knowledge Center",
      invoice: "Invoice",
      analytics: "Analytics",
      feedbacks: "Feedbacks",
      action: {
        show: "Show / Edit"
      },
      active_providers: "Active Suppliers",
      it_vendors: "Vendor Portfolio",
      software_portfolio: "Software Portfolio",
      dashboard: 'Dashboard',
      quick_search: 'Quick Search',
      software: 'Software Search',
      vendors_services: 'Vendor & Service Search',
      project_check: 'Project Check (Requests)',
      company_portfolio: 'Company Portfolio',
      vendors: 'Vendors',
      account: 'Account',
      company_settings: 'My Company',
      users_management: 'User Management',
      my_profile: 'My Profile',
      solution_insights: 'Solution insights',
      solution_overlaps: 'Solution overlaps',
      overview: 'Overview',
    },
    profile: {
      fields: {
        theme: "Theme",
        language: "Language",
        email: "Change email",
        password: "Change password",
        delete_account: "Delete my profile",
        delete_account_text_1: "If you want to delete your profile, please be aware that this is functionality doesn't delete the company and your requests or products. ",
        delete_account_text_2: 'Your personal information will be completely removed after 28 days or replaced with “Anonymous User”. For deleting the Company account (including Requests/Products) please contact as directly.',
        delete_account_confirmation_title: "Delete my profile",
        delete_account_confirmation_content: "Are you sure you want to delete your profile?If you delete your profile mistake, contact us within 28 days and we can retrieve the information. After 28 days this data will be permanently deleted.",
        new_email: "New email",
        old_password: "Current password",
        new_password: "New password",
        password_confirmation: "Password confirmation",
        password_verification_tooltip:
          "The password needs to be 8 to 20 characters long and must contain at least one uppercase letter, one lowercase letter, one digit and a special character.",
        password_verification:
          "To ensure the security of your account, please assign a password according to the following rules:\n\n- Length at least 8 characters and maximum of 20.\n- Must contain at least one digit from 0-9\n- Must contain at least one lowercase character\n- Must contain at least one uppercase character\n- Must contain at least one of the following special characters !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~"
      },
      field_values: {
        theme: {
          light: "Light",
          dark: "Dark"
        },
        language: {
          english: "English",
          deutsch: "Deutsch"
        }
      },
      message: {
        current: "Current",
        pending: "Pending",
        confirmed: "Confirmed",
        unconfirmed: "Unconfirmed",
        action_confirmation: "Action confirmation",
        action_confirmation_password: "Enter your password",
      },
      notification: {
        email: {
          updated_success:
            "Your profile has been updated, please confirm your new email address",
          updated_error: "This email is already in use"
        },
        password: {
          updated_success: "Password has been updated",
          updated_error: "Error: entered current password is wrong"
        },
        notification_configuration: {
          updated_success: "Notification configuration has been updated",
          updated_error: "Notification configuration has not been updated"
        }
      }
    },
    validation: {
      required: "Please add relevant information to this field"
    },
    password_recovery: {
      name: "Password recovery",
      message_1:
        "Please enter your email and we will send you a link to reset your password",
      message_2:
        "Please check your emails and use the sent link to reset your password"
    },
    password_activation: {
      name: "Password activation",
      fields: {},
      message_1: "Please enter a new password",
      password: "Password",
      confirmPassword: "Password confirmation",
      submit: "Submit",
      requiredField: "This field is required!",
      matchPassword: "Password and password confirmation do not match!"
    },
    email_activation: {
      name: "Email activation",
      message_1: "Your new email address has been activated successfully.",
      message_2: "Go to Dashboard"
    },
    action: {
      list: "Overview",
      send: "Send",
    },
    unsaved_changes: {
      name: "Unsaved changes",
      message:
        "Are you sure you want to leave the page? Your changes have not been saved.",
      yes: "Discard changes and leave",
      no: "Save changes"
    },
    message: {
      delete_title: "Delete %{name}: %{id}",
      session_expired: "Your session has expired. Please log in to pick up where you left off.",
      not_available: 'Not available',
      characters_left: "characters left",
      locked: "Due to too many incorrect login attempts, your account has been locked. Please contact your internal contact, a knooing Business Consultant or info@knooing.com for clarification.",
    },
    footer: {
      legal: {
        imprint: "Imprint",
        privacy_policy: "Privacy Policy",
        age: "General Business Terms"
      },
      link: {
        imprint: "https://knooing.com/en/imprint/",
        privacy_policy: "https://knooing.com/en/privacy-policy/",
        age: "https://knooing.com/en/agb/"
      }
    },
    conditions: {
      title: "Privacy Policy & General Business Terms",
      content:
        "Please confirm below that you agree with our General Business Terms and Privacy Policy.",
      privacy_policy: "Privacy Policy",
      privacy_policy_content:
        'I have read and accept the %{link} I agree that my details and data will be collected and stored electronically to answer my request. Note: You can revoke your consent at any time for the future by e-mail to <a href="mailto:datenschutz@knooing.com">datenschutz@knooing.com</a>.',
      consumer_terms: "General Business Terms",
      consumer_terms_content: "I accept the %{link} ",
      accept: "Accept"
    },
    internal_search: {
      title: "Discover Active Provider",
      description: "Discover all collected or imported active providers of %{company_name}. You also have the option to search deeply through the active providers  to identify fitting providers outside your requests.",
      text_1: "Search fast and easy through the active providers of %{company_name}. The search is based on the data collected for each provider.",
    },
  }
};

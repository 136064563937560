import React from 'react';
import { CardActions, EditButton, DateField, UrlField, SelectField, Show, SimpleShowLayout, RichTextField, TextField, ReferenceArrayField, SingleFieldList, ChipField, translate } from 'react-admin';
import get from 'lodash/get';
import { connect } from 'react-redux';

import compose from 'recompose/compose';

import { Badge, Typography, withStyles, Chip, Tooltip, Icon } from '@material-ui/core';

const CustomActions = ({ permissions, basePath, data, resource }) => (
    <CardActions>
        {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? <EditButton basePath={basePath} record={data} /> : null}
    </CardActions>
);

const CustomUrlField = ({ source, record = {}, theme }) => {
    let url = get(record, source);

    if (url && !url.includes('http')){
        url = 'https://' + url;
    }
    return theme === 'dark' ? 
        <a href={url} style={{ color: 'white' }} target="_blank">{get(record, source)}</a>
        :
        <a href={url} target="_blank">{get(record, source)}</a>
};
CustomUrlField.defaultProps = { addLabel: true };

const CustomChip = (props) => {
    const { record, source } = props;

    if (!record) {
        return null;
    }

    let icon = <Icon>{record.icon}</Icon>;
    return (
        <Chip 
            style={{ margin: 4 }} 
            icon={icon} 
            color="default" 
            label={record[source]} 
        />
    )
}

const CompanyShow = ({ permissions, theme, ...props }) => (
    <Show title='application.menu.company_information' actions={<CustomActions permissions={permissions} />} {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <RichTextField source="description" />

            <RichTextField source="address" />
            <CustomUrlField source="website" theme={theme} />

            <ReferenceArrayField source="topics" reference="topics">
                <SingleFieldList linkType={false}>
                    <CustomChip source="name" />
                </SingleFieldList>
            </ReferenceArrayField>

            <SelectField source="size" choices={[
                { id: 'LESS_THAN_10', name: 'resources.companies.field_values.size.less_than_10' },
                { id: 'LESS_THAN_50', name: 'resources.companies.field_values.size.less_than_50' },
                { id: 'LESS_THAN_100', name: 'resources.companies.field_values.size.less_than_100' },
                { id: 'LESS_THAN_500', name: 'resources.companies.field_values.size.less_than_500' },
                { id: 'LESS_THAN_1K', name: 'resources.companies.field_values.size.less_than_1K' },
                { id: 'LESS_THAN_5K', name: 'resources.companies.field_values.size.less_than_5K' },
                { id: 'LESS_THAN_10K', name: 'resources.companies.field_values.size.less_than_10K' },
                { id: 'GREATER_THAN_10K', name: 'resources.companies.field_values.size.greater_than_10K' },
            ]} />
            <TextField source="foundedAt"/>
        </SimpleShowLayout>
    </Show>
);

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
    translate,
    // withStyles(styles)
);

export default enhance(CompanyShow);
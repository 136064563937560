import React from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    card: {
        float: 'left',
        margin: '-20px 20px 0 15px',
        zIndex: 100,
        borderRadius: 3,
    },
    icon: {
        float: 'right',
        width: 40,
        height: 40,
        padding: 10,
        color: '#fff',
    },
};

const CustomIcon = ({ Icon, classes, backgroundColor }) => (
    <Card className={classes.card} style={{ backgroundColor: backgroundColor }}>
        <Icon className={classes.icon} />
    </Card>
);

export default withStyles(styles)(CustomIcon);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const mainDivStyle = {
   position: 'fixed',
   top: '30%',
   left: '45%',
};

const useStyles = makeStyles({
  card: {
    maxWidth: 845,
  },
  media: {
    height: 300,
    backgroundSize: 'contain',
  },
});


function MediaCard() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="https://knooing.com/wp-content/uploads/2019/10/under-construction-v2.jpg"
          title="under construction"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
             	&nbsp;  	&nbsp;  	 Under construction
          </Typography>
            {/* <Typography variant="body2" color="textSecondary" component="p">
            ...
            </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

class Invoice extends React.Component {

  render () {

    return (
      <div style={mainDivStyle}>
        <MediaCard />
      </div>
    );
  }
}

export default Invoice;
import React, { useState, useEffect } from 'react';
import { SelectInput, useDataProvider } from 'react-admin';

export const valuationChoices = [
    { id: '0', name: '0 - Others' },
    { id: '1', name: '1 - Gold' },
    { id: '2', name: '2 - Silver' },
    { id: '3', name: '3 - Bronze' },
];

export const SupplierValuation = (props) => {

    const dataProvider = useDataProvider();

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    const [allowed, setAllowed] = useState(false);

    useEffect(() => {

        if (decodedToken) {
            const companyId = decodedToken.company_id;

            if (parseInt(companyId) === parseInt(process.env.REACT_APP_RWE_ID)) {
                setAllowed(true);
            } else {
                dataProvider.getOne('companies', { id:  companyId + '/valuation' })
                    .then(({ data }) => {
                        setAllowed(data.enabled);
                    });
            }
        }
    }, []);

    return (
        allowed ? 
            <SelectInput 
                defaultValue={'0'}
                label="Performance"
                source="valuation"
                choices={valuationChoices}
                {...props}
            />
            :
            null
    )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '20px 0px 150px 0px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));

export default function ProjectBestPractices() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>

      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}><b>Gewonnene Erkenntnisse</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem>
                <Typography>
                  <b>1.	Der Preis ist ziemlich hoch</b>
                  <br/>
                  Obwohl die Preispolitik von ServiceNow alles andere als klar ist, gibt es eine Sache, die jeder sicher weiß - ServiceNow-Lizenzen sind nicht billig. Aber das ist noch nicht alles: Sie müssen auch bereit sein, für die Implementierung und den Support von ServiceNow zu bezahlen. Wenn Sie sich jedoch die geschätzten Einsparungen ansehen, wird der Preis kein Thema sein.
                </Typography>
              </ListItem>
              <ListItem>
                  <Typography>
                    <b>2.	Die Reporting Services sind nicht stark von ServiceNow</b>
                    <br/>
                    ServiceNow Reporting ist mehr als fähig, wenn es um niedrige betriebliche Anforderungen geht, z.B. um zu sehen, wie viele Tickets ein bestimmter Mitarbeiter bearbeitet oder welche Priorität sie haben. Wenn Sie jedoch eine gründliche Analyse der Leistungsdynamik auf hohem Niveau benötigen (um die Leistungstrends der IT-Abteilungen zu sehen und ihre Ursachen aufzudecken), müssen Sie dies entweder außerhalb von ServiceNow tun oder eine ServiceNow-App namens Performance Analytics kaufen.
                  </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  <b>3.	Individuelle Anpassungen können eine laufende Wartung erfordern</b>
                  <br/>
                  Wenn Ihre Lösung stark individuell angepasst ist, kann es zu Schwierigkeiten kommen, diese mit zukünftigen ServiceNow-Versionen kompatibel zu machen. Und dies wird dazu führen, dass eine ständige Wartung der Anpassung erforderlich ist. Wenn jedoch im Rahmen der ServiceNow-Anpassung spezifische Entwicklungsempfehlungen eingehalten werden, können Sie mit Support rechnen.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  <b>4.	Häufige Änderungen in der Lizenzpolitik und die Bündelung</b>  machen die Produkterneuerung schwierig.
                 
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  <b>5. ServiceNow hat den Support auf die aktuelle und frühere Version des Produkts beschränkt</b>
                  <br/>
                  was bedeutet, dass Kunden mindestens einmal jährlich ein Upgrade durchführen müssen. Dies kann Kunden mit begrenzten Ressourcen zur Unterstützung ihrer ITSM-Implementierungen belasten.
                </Typography>
              </ListItem>                           
            </List>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <br />

      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >

        <Typography className={classes.heading}><b>Migration Herausforderungen & Tipps</b></Typography>
        </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <List component="nav" aria-label="secondary mailbox folders">
                <ListItem>
                  <Typography>
                    <b>1.	Gründe für Misserfolg</b>
                    <br/>
                    <ul>
                      <li>nicht überzeugende Vision</li>

                      <li>schwacher Business Case</li>

                      <li>Roadmap mit eingeschränkter Sicht</li>

                      <li>Fehlen eines umfassenden Organisational Change Management Plans</li>

                      <li>Ressourcenmangel</li>

                      <li>Wissensdefizit</li>                     
                    </ul> 
                  </Typography>
                </ListItem>
                <ListItem>
                    <Typography>
                      <b>2.	Was ist bei der Einführung zu beachten? </b>
                      <br/>
                      ServiceNow kann auf nahezu jede erdenkliche Weise konfiguriert werden, aber das bedeutet nicht, dass es bereit ist, vom ersten Tag an für Ihr Unternehmen zu arbeiten. Noch bevor Sie über die technischen Aspekte der ServiceNow-Konfiguration nachdenken, müssen Sie eine Beherrschung darüber entwickeln, wie die strategischen Prioritäten und Ziele Ihres Unternehmens durch die Implementierung der breiten Palette von Anwendungen, Funktionen und Konfigurationen von ServiceNow erreicht werden können. Dieser Prozess erfordert Recherche, Planung und Anpassung.
                    
                      <ul>
                          <li>Workflows ändern sich, da meistens nicht optimal</li>
                          <li>hoch entwickelte Automatisierung und Funktionen beachten</li>
                          <li>neue Technologie -  moderne angepasste Arbeitsabläufe</li>
                      </ul>
                     </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>3.	Veränderung zulassen:</b>
                    <br/>
                    <ul>
                        <li>Unternehmen verändern sich bei der Einführung</li>
                        <li>strategischen Geschäftswert für Unternehmen </li>
                          <li>strategische Geschäftsziele und neue Profitabilität durch die Plattform</li>
                        <li>Arbeitsabläufen und Arbeitserwartungen verändern sich, Mitarbeiter müssen sich anpassen</li>   
                    </ul>                 
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>4.	Planung und Vorbereitung</b>
                    <br/>
                    Ressourcen über einen bestimmten Zeitraum fest einplanen. Viele Ressourcen budgetieren, gehen Sie davon aus, dass Sie mehr benötigen. Wenn Sie erfolgreich ServiceNow für eine einzelne Abteilung wie die IT implementieren, werden Sie von der starken Dynamik, die ServiceNow in Ihrem Unternehmen entfaltet, überrascht sein.

                    <br/><br/>
                    <p style={{ color: '#64c7f3', fontWeight: 'bold' , font: 'large'}}>
                      Die knooing Plattform kann sie im Projektverlauf bestens begleiten, um Ressourcen optimal einzusetzen und Fragen direkt zu beantworten.  
                    </p>
                  </Typography>
                </ListItem>                         
              </List>
            </Typography>
          </ExpansionPanelDetails>
      </ExpansionPanel>

      <br />

      <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}><b>ITSM, ITBM, ITOM Tendenzen</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem>
                <Typography>
                  <b>1.	AUTOMATISIERUNG UND AI-NUTZUNG</b>
                  <br/>
                  Automatisierung stellt die Verwendung mehrerer Werkzeuge dar, die kombiniert werden, um zusammenzuarbeiten, einschließlich Roboter-Prozessautomatisierung (RPA), KI und intelligente Unternehmensführung. 
                  <br/>
                  <br/>
                  Die KI wird uns nicht nur bei den kleinen Routineaufgaben unterstützen. Es ist für uns da, wenn wir große Datenmengen analysieren, intelligenzbasiertes Reporting oder Data Mining betreiben müssen, was zu besser fundierten Entscheidungen führt.
                </Typography>
              </ListItem>
              <ListItem>
                  <Typography>
                    <b>2.	VERBESSERTE INFORMATIONSSICHERHEIT</b>
                    <br/>
                    Die alarmierende Häufigkeit von hochkarätigen Sicherheitsverletzungen im vergangenen Jahr führte dazu, dass Unternehmen mehr denn je vom Datenschutz besessen waren. Es ist nicht verwunderlich, dass laut der von INAP durchgeführten Umfrage im Jahr 2019 die Cybersicherheit für über 36% der Unternehmen eine Priorität darstellt.                  </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  <b>3.	CLOUD MIGRATION</b>
                  <br/>
                  Es ist eine bekannte Tatsache, dass die Kosten für die Einrichtung und Wartung von selbst gehosteten Systemen höher sind, da wir sowohl für die Geräte als auch für die Administratoren, die sich um sie kümmern, bezahlen müssen. 
                  <br/><br/>
                  Umstellung von DevOps auf die CloudOps-Methodik, bei der die Vermeidung von Ausfallzeiten im Vordergrund steht.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  <b>4.	AGILE Organization </b>
                </Typography>
              </ListItem>
                        
            </List>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <br />

      <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}><b>ITSM, ITBM, ITOM Technische Fokusse</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem>
                <ol>
                  <li>Agile</li>
                  <li>Artificial intelligence (AI)</li>
                  <li>Automation</li>
                  <li>Business relationship management (BRM)</li>
                  <li>Chatbots</li>
                  <li>Cloud</li>
                  <li>COBIT</li>
                  <li>Customer experience (CX)</li>
                  <li>DevOps</li>
                  <li>Digital transformation</li>
                  <li>Employee experience</li>
                  <li>Enterprise service management</li>
                  <li>ISO/IEC 20000 - 2018 revision</li>
                  <li>IT asset management (ITAM) and software asset management (SAM)</li>
                  <li>IT4IT</li>
                  <li>ITIL</li>
                  <li>ITIL 4</li>
                  <li>ITSM "advanced"</li>
                  <li>ITSM "basics"</li>
                  <li>ITSM tools</li>
                  <li>Knowledge management</li>
                  <li>Metrics</li>
                  <li>People (including attitude, behavior, and culture (ABC)</li>
                  <li>Security</li>
                  <li>Self-service</li>
                  <li>Service integration and management (SIAM)</li>
                  <li>Value demonstration</li>
                  <li>VerSM</li>
                  <li>Wellbeing</li>
                </ol>
              </ListItem>                    
            </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <br/>
      <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}><b>Wettbewerber und Alternativen</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
           <b>...</b>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <br/>
      <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}><b>Success Stories</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
           <b>...</b>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
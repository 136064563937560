import React, { useState, useEffect, Fragment } from 'react';
import { Button, Toolbar, SaveButton, SimpleForm, required, useNotify, SelectInput, useDataProvider, useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from "react-router-dom";

import LinearProgress from '@material-ui/core/LinearProgress';

import { ProjectProgress } from './ProjectProgress';

import { 
    DialogTitle, 
    Dialog, 
    DialogContent, 
    DialogContentText, 
} from "@material-ui/core";
import { ProjectNotification } from './ProjectNotification';

const useStyles = makeStyles(theme => ({
    toolbar: {
        background:'none',
        paddingTop:'10px',
        flex: '0 0 auto',
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    button:{
        padding:'3px 9px',
        marginLeft: 16
    }
}));

const EmptyIcon = () => {
    return null;
}

const CustomToolbar = ({ loading, hideDialog, ...rest}) => {

    const classes = useStyles();

    return (
        <Toolbar className={classes.toolbar} {...rest} >
            
            <Button variant="contained" color="default" label="resources.suggestions.action.cancel_and_edit" onClick={()=> hideDialog()}></Button>
            <SaveButton
                label="resources.suggestions.action.submit"
                icon={<EmptyIcon />}
                disabled={loading}
                className={classes.button}
            />
        </Toolbar>
    )
}

export const SuggestionsProcessed = ({ record, refreshProject }) => {

    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const history = useHistory();
    const notify = useNotify();

    const [visible, setVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState();

    const [status, setStatus] = useState('');

    useEffect(() => {

        const blocked = !visible && !record.archived && record.suggestionStatus != null && record.suggestionStatus != 'IN_REVIEW';

        const unblock = history.block((nextLocation) => {
    
            if (blocked) {
    
                if (nextLocation.pathname.includes('projects') && nextLocation.pathname.includes('show')) {
                    return true;
                } else {
                    setOpen(true);
                    return false;
                }
            } else {
                return true;
            }
        });

        var timeout = null;

        if (blocked) {
            timeout = setTimeout(() => {
                setStatus(record.suggestionStatus);
                setOpen(true);
            }, 2000);
        }

        return () => { clearTimeout(timeout); unblock(); }

    }, [record.suggestionStatus, visible]);

    // const stepAddition = record.step === "STEP_2" ? "" : record.onlyFavoriteProviders ? "_2" : "_1";
    const stepAddition = "";

    const choices = [
        { 
            id: 'CONTINUE_SUGGESTION_SEARCH_' + status, 
            name: record.step === 'STEP_3' ? 'resources.suggestions.dialog.field_values.feedback.continue_suggestion_search_' + status.toLowerCase() : 'resources.suggestions.dialog.field_values.feedback.' + record.step.toLowerCase() + stepAddition
        },
        { 
            id: record.step === 'STEP_3' ? 'STOP_SUGGESTION_SEARCH_' + status : 'RESEARCH_COMPLETED', 
            name: record.step === 'STEP_3' ? 'resources.suggestions.dialog.field_values.feedback.stop_suggestion_search_' + status.toLowerCase() : 'resources.suggestions.dialog.field_values.feedback.research_completed'
        },
    ]

    const handleSubmit = (values) => {

        setLoading(true);

        const currentStatus = status;

        const url  = record.step === 'STEP_3' ? 'suggestion-status' : 'internal-insight-feedback';

        dataProvider
            .update('projects', { id: `${record.id}/${url}`, data: { ...values } })
            .then(response => {

                hideDialog();

                if (record.step !== 'STEP_3' && values.feedback.startsWith('CONTINUE_SUGGESTION_SEARCH')) {
                    setVisible(true);
                } else {
                    refreshProject();
                    
                    if (currentStatus !== 'REVIEWED_DECLINED') {
                        history.push(`/projects/${record.id}/show/messages`);
                    }
                }
            })
            .catch(error => {
                
                hideDialog();
                notify(`${error.message}`, 'warning');
            });
    }

    const hideDialog = () => {
        setLoading(false);
        setOpen(false);
    }

    return (
        <Fragment>

            <Dialog
                fullWidth
                open={open}
                disableEnforceFocus
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{ textAlign: 'center' }}>{translate('resources.suggestions.dialog.next_step')}</DialogTitle>

                {status && status !== '' && 
                    <DialogContent>
                        
                        <DialogContentText>
                            {translate('resources.suggestions.dialog.message.' + status.toLowerCase())}
                        </DialogContentText>
                        <SimpleForm
                                id="suggestions-reviewed"
                                variant="standard"
                                resource="projects"
                                save={handleSubmit}
                                toolbar={<CustomToolbar loading={loading} hideDialog={hideDialog} />}
                            >
                            {status !== 'REVIEWED_DECLINED' && <SelectInput 
                                style={{ marginLeft: -16, marginRight: -16, marginTop: -16 }} 
                                label="resources.suggestions.dialog.fields.feedback" 
                                source="feedback" 
                                validate={required()} 
                                choices={choices} 
                                fullWidth
                            />
                            }
                        </SimpleForm>
                    </DialogContent>
                }
                {(!status || status === '') && <div style={{ margin: 16 }}><LinearProgress /></div>}
            </Dialog>

            {/* <ProjectProgress project={record} show={visible} /> */}
            <ProjectNotification project={record} show={visible} />
        </Fragment>
    );
}
import { DELETE } from 'react-admin';

export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';

export const notificationDelete = (id, data, basePath) => ({
    type: NOTIFICATION_DELETE,
    payload: { id, data: { ...data }, basePath },
    meta: {
        resource: 'notifications',
        fetch: DELETE,
        onSuccess: {
            notification: {
                body: 'Notification has been deleted',
                level: 'info',
            },
            basePath,
        },
        onFailure: {
            notification: {
                body: 'Notification has not been deleted',
                level: 'warning',
            },
        },
    },
});
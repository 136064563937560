import ProjectList from './ProjectList';
import ProjectCreate from './ProjectCreate';
import Projectdit from './ProjectEdit';
import ProjectShow from './ProjectShow';

export default {
    list: ProjectList,
    create: ProjectCreate,
    edit: Projectdit,
    show: ProjectShow,
}
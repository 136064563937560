import React, { useState, useEffect, Fragment } from 'react';
import { useRedirect, useTranslate, useDataProvider, useRefresh, useNotify } from 'react-admin';

import { 
    Button, 
    Typography, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogContentText, 
    DialogActions, 
    LinearProgress 
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const styleDialogAction = makeStyles({
    root: { justifyContent: 'space-around', paddingTop: 0, paddingBottom: 20 },
});
const styleDialogTitle = makeStyles({
    root: { paddingBottom: 10 },
});
const styleDialogContent = makeStyles({
    root: { paddingTop: 0, paddingBottom: 10 },
});

export const ProjectNotification = ({ project, show, basePath = '' }) => {

    const classesDialogAction = styleDialogAction();
    const classesDialogTitle = styleDialogTitle();
    const classesDialogContent = styleDialogContent();

    const translate = useTranslate();
    const doRedirect = useRedirect();

    const [open, setOpen] = useState(false);

    const showDashboard = () => {
        doRedirect('/projects', '');
    };

    useEffect(() => {

        if (project && project.id && show) {

            setOpen(true);
        }
    }, [project, show]);

    return (

        <Dialog
            fullWidth
            open={open}
            onClose={() => setOpen(false)}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" classes={classesDialogTitle}>

                {translate(`resources.projects.message.dialog_title`)}
            </DialogTitle>
            <DialogContent classes={classesDialogContent}>
                <DialogContentText id="alert-dialog-description">

                    <Typography gutterBottom>{translate(`resources.projects.message.dialog_content_text`)}</Typography>
                </DialogContentText>
            </DialogContent>

            <DialogActions disableSpacing={false} classes={classesDialogAction}>

                <Button onClick={showDashboard} color="primary" variant="contained">
                    {translate(`resources.projects.message.dialog_action`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
import React, { useState, useEffect } from "react";
import { Labeled, REDUX_FORM_NAME, translate, showNotification, useNotify } from 'react-admin';

import { useForm } from 'react-final-form';
import { Field } from 'react-final-form';

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { Paper, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Chip, Icon, IconButton, Tooltip, Button, Checkbox } from "@material-ui/core";

import ArrowForwardIcon from '@material-ui/icons/ChevronRight'; 
import ArrowBackIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Check';

const chipStyle = {
    margin: 4,
};

const TopicInput = (props) => {

    const [mounted, setMounted] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [page, setPage] = useState(1);
    const [opened, setOpened] = useState(false);
    const [maximum, setMaximum] = useState(5);

    const form = useForm();

    const notify = useNotify();

    const { record, source, label, placeholder , translate, marginTop = 0 } = props;

    useEffect(() => {
        console.log('update');
        const token = localStorage.getItem('token');
        
        fetch(`${process.env.REACT_APP_API_URL}/topic-categories?language=EN`, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
                .then((result) => result.json())
                .then((result) => {
                    setCategories(result.content);
                })
                .catch((exception) => {

                });

        if (record.topics && record.topics.length > 0) {
    
            const token = localStorage.getItem('token');
        
            fetch(`${process.env.REACT_APP_API_URL}/topics?id=${record.topics.join(',')}`, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
                    .then((result) => result.json())
                    .then((result) => {
                        setSelectedTopics(result);
                        setMounted(true);
                    })
                    .catch((exception) => {
    
                    });
        } else {
            setMounted(true);
        }
    }, []);

    useEffect(() => {
        console.log('update topics');

        if (mounted) {
            form.change('topics', selectedTopics.map(selectedTopic => selectedTopic.id));
        }
    }, [selectedTopics]);

    const addTopic = topic => {
        
        if (selectedTopics.length == maximum) {
            notify('resources.topics.notification.added_error', 'definfoult', { maximum: maximum });
            return;
        }

        topic.checked = true;
        
        setSelectedTopics([...selectedTopics, topic]);
    };

    const deleteTopic = topic => {
        topic.checked = false;

        const { change } = props;

        setSelectedTopics(selectedTopics.filter(selectedTopic => selectedTopic.id !== topic.id));
    };
 
    const toggleTopic = (topic) => {

        const index = selectedTopics.indexOf(topic);
        if (index === -1) {
            addTopic(topic)
        } else {
            deleteTopic(topic);
        }
    };
 
    const displayTopic = topic => {
        let icon = <Icon>{topic.icon}</Icon>;
        return (
            <Chip 
                style={chipStyle} 
                icon={icon} 
                color="default" 
                key={topic.id} 
                label={topic.name} 
                onDelete={() => deleteTopic(topic)}
            />
        )
    };

    const displayTopics = (category) => {

        const token = localStorage.getItem('token');
        
        fetch(`${process.env.REACT_APP_API_URL}/topics?language=EN&categoryId=${category.id}`, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
                .then((result) => result.json())
                .then((result) => {
                    let topics = result.content;
                    topics = topics.map(topic => {
                        topic.checked = selectedTopics.some(selectedTopic => selectedTopic.id === topic.id);
                        return topic;
                    });

                    setPage(page + 1);
                    setSelectedCategory(category);
                    setTopics(topics);
                })
                .catch((exception) => {

                });
    }
 
    const displayCategories = () => {
        setPage(page - 1);
    }

    const toggleWindow = () => {
        setOpened(!opened);
    }

    const closeWindow = () => {
        setOpened(false);
    }

    const renderSelectedTopics = () => (
        <span>
            {selectedTopics.map(topic => displayTopic(topic))}
        </span>
    );

    return (
        <div style={{ marginTop: marginTop }}>
            <Labeled label={translate(label)}>
                <div>
                {placeholder !=null && placeholder != "" && props.theme ==="light" && <div style={{color: 'rgba(0, 0, 0, 0.54)',fontSize: '16px',paddingBottom: '10px'}}>{placeholder}</div>}
                {placeholder !=null && placeholder != "" && props.theme ==="dark" && <div style={{color: 'rgba(255, 255, 255, 0.7)',fontSize: '16px',paddingBottom: '10px'}}>{placeholder}</div>}
                <Field name={source} component={renderSelectedTopics} />
                </div>
            </Labeled>
            <div>
                <Tooltip placement="right" title={translate('resources.topics.message.maximum_limit', { maximum: maximum })}>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => toggleWindow()}
                    >
                        <AddIcon style={{ paddingRight: '0.25em', color: 'green' }} />
                        {translate('resources.topics.action.add')}
                    </Button>
                </Tooltip>
            </div>

            {opened && 
                <Paper style={{ marginTop: 15 }}>
                    {page === 1 && categories && 
                        <List component="nav">
                            <ListItem style={{ height: 48 }}>
                                <ListItemSecondaryAction style={{ paddingRight: 0 }}>
                                    <IconButton edge="end" onClick={() => closeWindow()} >
                                        <CloseIcon />
                                    </IconButton> 
                                </ListItemSecondaryAction>
                            </ListItem>
                            {categories.map(category => (
                                <ListItem key={category.id} button onClick={() => displayTopics(category)}>
                                    <ListItemIcon><Icon>{category.icon}</Icon></ListItemIcon>
                                    <ListItemText secondary={category.name} />
                                    <ArrowForwardIcon color="action" />
                                </ListItem>
                            ))}
                        </List>
                    }
                    {page === 2 && 
                        <List component="nav">
                            <ListItem style={{ paddingTop: 5, paddingBottom: 5 }}>
                                <Button onClick={() => displayCategories()} size="small" variant="text">
                                    <ArrowBackIcon /> Back to overview
                                </Button>
                                <ListItemSecondaryAction style={{ paddingRight: 0 }}>
                                    <IconButton edge="end" onClick={() => closeWindow()} >
                                        <CloseIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {topics.map(topic => (
                                <ListItem onClick={(event) => toggleTopic(topic)} style={{ paddingTop: 3, paddingBottom: 3 }} key={topic.id} role={undefined} dense button>
                                    <ListItemIcon>
                                        <Checkbox 
                                            edge="start" 
                                            checked={topic.checked} 
                                            tabIndex={-1} 
                                            disableRipple 
                                            color="default" 
                                            // onChange={(event, newState) => toggleTopic(topic, newState)} 
                                        />
                                    </ListItemIcon>
                                    <ListItemText secondary={topic.name} />
                                </ListItem>
                            ))}
                        </List>
                    }
                </Paper>
            }
        </div>
    );
}
export default compose(
    translate,
    connect(
        null,
        { showNotification }
    )
) (TopicInput);
import React from 'react'
import { useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';

import Select from 'react-select'

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { useForm } from 'react-final-form';

const defaultOptions = [
    { 
        label: 'Phishing Test', 
        value: '1',
    },
    { 
        label: 'Threat Detection', 
        value: '2',
    },
    { 
        label: 'Pentests', 
        value: '3',
    },
    { 
        label: 'Cyber Security Intelligence', 
        value: '4',
    },
    { 
        label: 'Securityanalyse AD', 
        value: '5',
    },
    { 
        label: 'Application Management Services', 
        value: '6',
    },
    { 
        label: 'Incident Response and Forensics', 
        value: '7',
    },
    { 
        label: 'Infrastructure Services', 
        value: '8',
    },
    { 
        label: 'IT Security Managed Services', 
        value: '9',
    },
    { 
        label: 'Implementation Services', 
        value: '10',
    },
    { 
        label: 'Enterprise Backup Software', 
        value: '11',
    },
    { 
        label: 'OnDemand Migration Services', 
        value: '12',
    },
    { 
        label: 'Health and Performance Monitoring of Database Platforms', 
        value: '13',
    },
    { 
        label: 'Process Automation Services', 
        value: '14',
    },
    { 
        label: 'Cloud Security', 
        value: '15',
    },
    { 
        label: 'Industrial Security', 
        value: '16',
    },
    { 
        label: 'User Experience Services', 
        value: '17',
    },
    { 
        label: 'IT Hardening',
        value: '18', 
    }
]

const usePaperStyles = makeStyles(theme => ({
    paper: {
        overflow: 'visible',
    },
    
}));
       

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        minWidth: 290,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

function Control(props) {
    const {
        children,
        innerProps,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Placeholder(props) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.any.isRequired,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool.isRequired,
    removeProps: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        onMouseDown: PropTypes.func.isRequired,
        onTouchEnd: PropTypes.func.isRequired,
    }).isRequired,
    selectProps: PropTypes.object.isRequired,
};

const components = {
    Control,
    MultiValue,
    Placeholder,
    SingleValue,
    ValueContainer,
};

export default function EditCapability({ open, setOpen, options = defaultOptions }) {

    const classes = useStyles();

    const paperClasses = usePaperStyles()

    const translate = useTranslate();

    const save = () => {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth
                open={open}
                onClose={() => setOpen(false)}
                disableEnforceFocus
                classes={{ paper: paperClasses.paper }}
                maxWidth="md"
            >
                <DialogTitle>{translate('resources.projects.vendor_consolidation.edit_capability')}</DialogTitle>
                <DialogContent  style={{ overflow: 'visible' }}>
                    <Typography style={{ marginBottom: 25 }}>{translate('resources.projects.vendor_consolidation.edit_capability_subtitle')}</Typography>
                    {/* <Autocomplete
                        multiple
                        options={options}
                        getOptionLabel={(option) => option}
                        defaultValue={options}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={translate('resources.projects.internal_insight.capabilities')}
                            // placeholder=""
                        />
                        )}
                    /> */}
                    <Select 
                        classes={classes}
                        options={options}
                        defaultValue={options}
                        isMulti
                        components={components}
                    />
                </DialogContent>
                <DialogActions>
                    
                    <Button onClick={save} color="primary">
                        {translate('ra.action.save')}
                    </Button>
                    <Button onClick={() => setOpen(false)} color="primary">
                        {translate('ra.action.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
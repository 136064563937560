import React from 'react';
import {
  Admin,
  Resource,
  fetchUtils
} from 'react-admin';
import { getQueryParameters } from '../utilities';

import { isShowcaseTrue } from '../showcase'

class EnvsInfo extends React.Component {


  componentDidMount() {
    const parameters = getQueryParameters();
    var paraSize = Object.keys(parameters).length;
    if (paraSize >= 1) {
      var isShowcaseParamAvailable = parameters.hasOwnProperty('showcase');
      if (isShowcaseParamAvailable) {
        localStorage.setItem("showcase", parameters.showcase);
      }
    }
  }


  render() {
    return (
      <div>
        <h2>Env Variables</h2>
        <hr />
        <h3>NODE_ENV</h3>
        <p>{process.env.NODE_ENV}</p>
        <hr />
        <h3>showcase</h3>
        <p>{isShowcaseTrue()}</p>
        <hr />
        <h3>PUBLIC_URL</h3>
        <p>{process.env.PUBLIC_URL}</p>
        <hr />
        <h3>REACT_APP_UAA_URL</h3>
        <p>{process.env.REACT_APP_UAA_URL}</p>
        <hr />
        <h3>REACT_APP_API_URL</h3>
        <p>{process.env.REACT_APP_API_URL}</p>
        <hr />
        <h3>REACT_APP_ROOT_FOLDER</h3>
        <p>{process.env.REACT_APP_ROOT_FOLDER}</p>
        <hr />
      </div>
    );
  }

}


export default EnvsInfo;

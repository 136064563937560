import React from 'react';
import { render } from "react-dom";
import { Chart } from "react-google-charts";

const intercomIconStyle = {
   position: 'fixed',
   top: '10%',
   left: '20%',
};


class ProjectCharts extends React.Component {

  render () {
    const { appUser } = this.props;

    return (
      <div className="app" style={intercomIconStyle}>

        <h3>Gantt Chart for your project 'Beispiel Projekt'</h3>

       <Chart
         width={'900px'}
         height={'300px'}
         chartType="Gantt"
         loader={<div>Loading Chart</div>}
         data={[
           [
             { type: 'string', label: 'Task ID' },
             { type: 'string', label: 'Task Name' },
             { type: 'string', label: 'Resource' },
             { type: 'date', label: 'Start Date' },
             { type: 'date', label: 'End Date' },
             { type: 'number', label: 'Duration' },
             { type: 'number', label: 'Percent Complete' },
             { type: 'string', label: 'Dependencies' },
           ],
           [
             'Requested',
             'Request created',
             null,
             new Date(2015, 0, 1),
             new Date(2015, 0, 2),
             null,
             100,
             null,
           ],
           [
             'Write',
             'Contact Provider 2',
             'write',
             null,
             new Date(2015, 0, 9),
             3 * 24 * 60 * 60 * 1000,
             25,
             'Requested,Outline',
           ],
           [
             'Cite',
             'Contact Provider 3',
             'write',
             null,
             new Date(2015, 0, 7),
             1 * 24 * 60 * 60 * 1000,
             20,
             'Requested',
           ],
           [
             'Complete',
             'Close contract',
             'complete',
             null,
             new Date(2015, 0, 10),
             1 * 24 * 60 * 60 * 1000,
             0,
             'Cite,Write',
           ],
           [
             'Outline',
             'Contact Provider 1',
             'write',
             null,
             new Date(2015, 0, 6),
             1 * 24 * 60 * 60 * 1000,
             5,
             'Requested',
           ],
         ]}
         options={{
           gantt: {
             criticalPathEnabled: false,
             innerGridHorizLine: {
               stroke: '#ffe0b2',
               strokeWidth: 2,
             },
             innerGridTrack: { fill: '#fff3e0' },
             innerGridDarkTrack: { fill: '#ffcc80' },
           },
         }}
         rootProps={{ 'data-testid': '7' }}
       />


      </div>
    );
  }
}

export default ProjectCharts;
import React from 'react';
import { ChipField, ArrayField, SingleFieldList, BooleanField, Datagrid, DateField, Show, SimpleShowLayout, TextField, EditButton, ListButton, CardActions } from 'react-admin';

import { connect } from 'react-redux';
import compose from 'recompose/compose';

import TextArrayField from '../common/TextArrayField';

const CustomActions = ({ permissions, basePath, data, resource }) => (
    <CardActions>
        <ListButton basePath={basePath} />
        <EditButton basePath={basePath} record={data} />
    </CardActions>
);

const RecommendationShow = ({ permissions, theme, ...props }) => (
    <Show 
        title="resources.recommendations.singular_name" 
        actions={<CustomActions permissions={permissions} />} 
        basePath="/knowledge/recommendations"
        resource="recommendations"
        id={props.match.params.id}
        {...props}
    >
        <SimpleShowLayout>
            <TextArrayField source="offerings">
                <SingleFieldList>
                    <ChipField source="id" />
                </SingleFieldList>
            </TextArrayField>
            <TextField source="providerName" />
            {/* <TextField source="roductName" /> */}
            <TextField source="website" />
            <BooleanField source="activelyUsed" />
            {/* <TextField source="useCase" /> */}
            <ArrayField source="products">
                <Datagrid>
                    <TextField source="offering" label="resources.recommendations.fields.offering" sortable={false} />
                    <TextField source="name" label="resources.recommendations.fields.productName" sortable={false} />
                    <TextField source="useCase" label="resources.recommendations.fields.useCase" sortable={false} />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
    // translate,
    // withStyles(styles)
);

export default enhance(RecommendationShow);
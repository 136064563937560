import React from 'react';
import { Toolbar, SaveButton, SimpleForm, Edit, TextInput, SelectInput, required, CardActions,useTranslate } from 'react-admin';
import ShowButton from '../settings/ShowButton';
import UnsavedDataObserver from '../UnsavedDataObserver';

const CustomActions = ({ permissions, basePath, data, resource }) => {
    return <CardActions>
        <ShowButton basePath={basePath} />
    </CardActions>
};

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const PersonalDataEdit = ({ staticContext, permissions, ...props }) => {
    const translate = useTranslate();
    const message = translate('application.validation.required');
    return (
        <Edit undoable={false} actions={<CustomActions />} id={JSON.parse(localStorage.getItem('decoded_token')).user_name} resource="users" title="application.menu.personal_data" basePath="/profile/personal-data" {...props}>
            <SimpleForm variant="standard" margin="normal" redirect={'/profile/personal-data'} toolbar={<CustomToolbar />}>

                <UnsavedDataObserver />

                <SelectInput source="salutation" choices={[
                    { id: 'MALE', name: 'resources.users.field_values.salutation.male' },
                    { id: 'FEMALE', name: 'resources.users.field_values.salutation.female' },
                ]} />
                <TextInput source="firstName" validate={required(message)} />
                <TextInput source="lastName" validate={required(message)} />

                <TextInput source="position" />

                <TextInput source="phone" />
                <TextInput source="mobilePhone" />

                <SelectInput source="jobExperience" choices={[
                    { id: 'LEVEL_0', name: 'resources.users.field_values.job_experience.level_0' },
                    { id: 'LEVEL_1', name: 'resources.users.field_values.job_experience.level_1' },
                    { id: 'LEVEL_2', name: 'resources.users.field_values.job_experience.level_2' },
                    { id: 'LEVEL_3', name: 'resources.users.field_values.job_experience.level_3' },
                    { id: 'LEVEL_4', name: 'resources.users.field_values.job_experience.level_4' },
                    { id: 'LEVEL_5', name: 'resources.users.field_values.job_experience.level_5' },
                    { id: 'UNDEFINED', name: 'resources.users.field_values.job_experience.undefined' },
                ]} />
            </SimpleForm>
        </Edit>
    )
}
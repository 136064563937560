import React, {
    Children,
    cloneElement,
    isValidElement,
} from 'react';
import { Labeled, InputHelperText, useTranslate, useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import { shallowEqual } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import classnames from 'classnames';
import { useInput } from 'ra-core';

import FileInputPreview from './FileInputPreview';
import sanitizeInputRestProps from './sanitizeInputRestProps';

const useStyles = makeStyles(
    theme => ({
        dropZone: {
            background: theme.palette.background.default,
            cursor: 'pointer',
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.getContrastText(
                theme.palette.background.default
            ),
        },
        preview: {},
        removeButton: {},
        root: { width: '100%' },
    }),
    { name: 'RaFileInput' }
);

const FileInput = (props) => {
    const {
        accept,
        children,
        className,
        classes: classesOverride,
        format,
        helperText,
        label,
        labelMultiple = 'ra.input.file.upload_several',
        labelSingle = 'ra.input.file.upload_single',
        secondLabelMultiple,
        maxSize,
        maxFileSize,
        minSize,
        maxFiles,
        multiple = false,
        options: {
            inputProps: inputPropsOptions,
            ...options
        } = {},
        parse,
        placeholder,
        resource,
        source,
        validate,
        ...rest
    } = props;
    const translate = useTranslate();
    const classes = useStyles(props);

    // turn a browser dropped file structure into expected structure
    const transformFile = file => {
        if (!(file instanceof File)) {
            return file;
        }

        const { source, title } = (Children.only(children)).props;

        const preview = URL.createObjectURL(file);
        const transformedFile = {
            rawFile: file,
            [source]: preview,
        };

        if (title) {
            transformedFile[title] = file.name;
        }

        return transformedFile;
    };

    const transformFiles = (files) => {
        if (!files) {
            return multiple ? [] : null;
        }

        if (Array.isArray(files)) {
            return files.map(transformFile);
        }

        return transformFile(files);
    };

    const {
        id,
        input: { onChange, value, ...inputProps },
        meta,
        isRequired,
    } = useInput({
        format: format || transformFiles,
        parse: parse || transformFiles,
        source,
        type: 'file',
        validate,
        ...rest,
    });
    const { touched, error, submitError } = meta;
    const files = value ? (Array.isArray(value) ? value : [value]) : [];

    const notify = useNotify();

    const onDrop = (newFiles, rejectedFiles, event) => {

        let filteredFiles = newFiles
            .filter(file => {
                if (file.size > maxFileSize) {
                    notify('resources.projects.fields.maximumFileSize', 'warning');
                    return false;
                }
                return true;
            });

        if (maxFiles < files.length + filteredFiles.length) {

            notify('resources.projects.fields.maximumFiles', 'warning');
        }

        if (maxFiles > files.length) {

            filteredFiles = filteredFiles.slice(0, maxFiles - files.length);

            const updatedFiles = multiple ? [...files, ...filteredFiles] : [...filteredFiles];

            if (multiple) {
                onChange(updatedFiles);
            } else {
                onChange(updatedFiles[0]);
            }

            if (options.onDrop) {
                options.onDrop(filteredFiles, rejectedFiles, event);
            }
        }
    };

    const onRemove = file => () => {
        if (multiple) {
            const filteredFiles = files.filter(
                stateFile => !shallowEqual(stateFile, file)
            );
            onChange(filteredFiles);
        } else {
            onChange(null);
        }

        if (options.onRemove) {
            options.onRemove(file);
        }
    };

    const childrenElement =
        children && isValidElement(Children.only(children))
            ? (Children.only(children))
            : undefined;

    const { getRootProps, getInputProps } = useDropzone({
        ...options,
        accept,
        maxSize,
        minSize,
        multiple,
        onDrop,
    });

    return (
        <Labeled
            id={id}
            label={label}
            className={classnames(classes.root, className)}
            source={source}
            resource={resource}
            isRequired={isRequired}
            meta={meta}
            {...sanitizeInputRestProps(rest)}
        >
            <>
                <div
                    data-testid="dropzone"
                    className={classes.dropZone}
                    {...getRootProps()}
                >
                    <input
                        id={id}
                        {...getInputProps({
                            ...inputProps,
                            ...inputPropsOptions,
                        })}
                    />
                    {placeholder ? (
                        placeholder
                    ) : multiple ? (
                        <React.Fragment>
                            <p>{translate(labelMultiple)}</p>
                            {secondLabelMultiple ? <p>{translate(secondLabelMultiple)}</p> : null}
                        </React.Fragment>
                    ) : (
                        <p>{translate(labelSingle)}</p>
                    )}
                </div>
                <FormHelperText>
                    <InputHelperText
                        touched={touched}
                        error={error || submitError}
                        helperText={helperText}
                    />
                </FormHelperText>
                {children && (
                    <div className="previews">
                        {files.map((file, index) => (
                            <FileInputPreview
                                key={index}
                                file={file}
                                onRemove={onRemove(file)}
                                className={classes.removeButton}
                            >
                                {cloneElement(childrenElement, {
                                    record: file,
                                    className: classes.preview,
                                })}
                            </FileInputPreview>
                        ))}
                    </div>
                )}
            </>
        </Labeled>
    );
};

FileInput.propTypes = {
    accept: PropTypes.string,
    children: PropTypes.element,
    classes: PropTypes.object,
    className: PropTypes.string,
    id: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    labelMultiple: PropTypes.string,
    labelSingle: PropTypes.string,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    multiple: PropTypes.bool,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    placeholder: PropTypes.node,
};

export default FileInput;
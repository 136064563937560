import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslate } from 'ra-core';
import compose from 'recompose/compose';

import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

class NavigationPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {nextLocation: null, openModal: false};
        this.onCancel = this.onCancel.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
        this.unblock = this.props.history.block((nextLocation) => {
            if (this.props.when) {
                this.setState({
                    openModal: true,
                    nextLocation: nextLocation
                });
            }
            return !this.props.when;
        });
    }

    componentWillUnmount() {
        this.unblock();
    }

    onCancel() {
        this.setState({ nextLocation: null, openModal: false });
    }

    onConfirm() {
        this.navigateToNextLocation();
    }

    navigateToNextLocation() {
        this.unblock();
        this.props.history.push(this.state.nextLocation.pathname);
    }

    render() {

        const { translate } = this.props;
        const { openModal } = this.state;

        return (
            <Dialog
                fullWidth
                open={openModal}
                onClose={this.onCancel}
                disableEnforceFocus
            >
                <DialogTitle>{translate('application.unsaved_changes.name')}</DialogTitle>
                <DialogContent>
                    <Typography style={{ marginBottom: 16 }} variant="body2" id="alert-dialog-description">
                        {translate('application.unsaved_changes.message')}
                    </Typography>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCancel} color="primary">
                        {translate('application.unsaved_changes.no')}
                    </Button>
                    <Button onClick={this.onConfirm} color="primary" autoFocus>
                        {translate('application.unsaved_changes.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

NavigationPrompt.propTypes = {
    when: PropTypes.bool.isRequired,
};

export default compose(
    withTranslate,
    withRouter,
) (NavigationPrompt);
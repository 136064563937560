import React, { useState, useEffect, Fragment } from 'react';
import { useSetLocale, useTranslate, useDataProvider } from 'react-admin';

import { Redirect } from 'react-router';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PendingSuggestions from './dashboard/PendingSuggestions';
import Notifications from './dashboard/Notifications';

import compose from 'recompose/compose';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Logotype from './layout/Logotype';

const useStyles = makeStyles(theme => ({
    root: {
        height: '20em',
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftColumn: { flex: 1, marginRight: '1em' },
    rightColumn: { flex: 1, marginLeft: '1em' },
    singleColumn: { marginBottom: '2em' },
    fab: {
        margin: theme.spacing(1),
        backgroundColor: '#304d75'
    },
}));

const images = new Map();
images.set(parseInt(process.env.REACT_APP_RWE_ID), `${process.env.REACT_APP_ROOT_FOLDER}images/background-rwe-image.jpeg`);
const defaultImage = `${process.env.REACT_APP_ROOT_FOLDER}images/background-image.png`;

const DashboardBox = ({ header, textTitle, text, period, action, href }) => {

    const translate = useTranslate();

    return (
        <div 
            style={{ 
                border: '1px solid #76b3ef',
                borderRadius: 5,
                height: '100%'
            }}
        >
            <div style={{ borderBottom: '1px solid #76b3ef', padding: '1rem', color: '#FFB30A' }}>
                <Typography variant="h5" style={{ fontSize: '1.25rem' }}>{translate(header)}</Typography>
            </div>
            <div style={{ padding: '0.5rem 1rem ' }}>
                <Typography gutterBottom variant="h5" style={{ fontSize: '1.25rem' }}>{translate(textTitle)}</Typography>
                <Typography variant="body2" component="p">{translate(text)}</Typography>
                <div style={{flexGrow: 1 }}></div>
                <div style={{ justifyContent: 'space-between', width: '100%', display: 'flex', marginTop: 15 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon style={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: 5 }} /> <Typography variant="body2" color="textSecondary" component="span">{translate(period)}</Typography>
                    </div>
                    <Button href={href} variant="contained" color="primary" style={{ backgroundColor: '#0d6efd' }}>{translate(action)}</Button>
                </div>
            </div>
        </div>
    );
    
}

const DashboardInfoBox = ({ }) => {

    const translate = useTranslate();

    return (
        <div 
            style={{ 
                border: '1px solid #76b3ef',
                borderRadius: 5,
                height: '100%'
            }}
        >
            <div style={{ padding: '0.5rem 1rem ', textAlign: 'center' }}>
                <Typography gutterBottom variant="h5" style={{ fontSize: '1.25rem' }}>
                <b>{translate('application.dashboard.info_box.title')}</b><br />
                <img src="tick-green.png" height="16"/> {translate('application.dashboard.info_box.item1')}<br />
                <img src="tick-green.png" height="16"/> {translate('application.dashboard.info_box.item2')}<br />
                <img src="tick-green.png" height="16"/> {translate('application.dashboard.info_box.item3')}<br />
                <img src="tick-green.png" height="16"/> {translate('application.dashboard.info_box.item4')}<br /> 
                <img src="x-red.png" height="16"/> {translate('application.dashboard.info_box.item5')}<br />
                <img src="x-red.png" height="16"/> {translate('application.dashboard.info_box.item6')}<br />
                </Typography>
            </div>
        </div>
    );
    
}

const Dashboard = ({ basePath = '' }) => {

    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const classes = useStyles();

    const [companyId, setCompanyId] = useState();
    const [companyProperties, setCompanyProperties] = useState();

    const setLocale = useSetLocale();

    const fetchCompanyProperties = () => {

        const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

        if (decodedToken) {

            const companyId = decodedToken.company_id;

            dataProvider.getOne('companies', { id: companyId + '/properties' })
                .then(({ data }) => {
                    setCompanyProperties(data);
                });
        }
    }

    useEffect(() => {

        const referer = localStorage.getItem('referer');
        if (referer && referer !== '' && referer !== window.location.href) {

            localStorage.removeItem('referer');
            window.location = referer;
        } else {
            fetchCompanyProperties();
        }
    }, []);

    let backgroundImage = `${process.env.REACT_APP_ROOT_FOLDER}images/background-image.jpeg`;

    return (
        <Fragment>
            {/* <Card>
                <CardContent>
                    
                </CardContent>
            </Card> */}

            <div style={{ paddingLeft: '1rem' }}>
                <div 
                    style={{ 
                        backgroundImage: 'url(' + backgroundImage + ')',
                        width: '100%',
                        height: '15rem',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Logotype height="4rem" />
                    <Typography style={{ color: 'white', fontWeight: 'bold' }} gutterBottom variant="h5">{translate('application.dashboard.welcome.header')}</Typography>
                    <Typography style={{ color: 'white', fontSize: '1rem' }} variant="body2">{translate('application.dashboard.welcome.content')}</Typography>
                </div>
                {companyProperties && (
                    <Grid style={{ padding: '0.5rem' }} container spacing={2}>
                        {companyProperties.softwareSearchEnabled && (
                            <Grid item xs={companyProperties.softwareSearchEnabled && companyProperties.vendorSearchEnabled ? 6 : 12}>
                                <DashboardBox 
                                    header={'resources.quick_search_software.headline'} 
                                    textTitle={'resources.quick_search_software.title'}
                                    text={'resources.quick_search_software.text'} 
                                    period={'resources.quick_search_software.time_note'} 
                                    action={'resources.quick_search_software.button'} 
                                    href={`${process.env.REACT_APP_ROOT_FOLDER}#/software-search`}
                                />
                            </Grid>
                        )}
                        {companyProperties.vendorSearchEnabled && (
                            <Grid item xs={companyProperties.softwareSearchEnabled && companyProperties.vendorSearchEnabled ? 6 : 12}>
                                <DashboardBox 
                                    header={'resources.quick_search_vendors.headline'} 
                                    textTitle={'resources.quick_search_vendors.title'}
                                    text={'resources.quick_search_vendors.text'} 
                                    period={'resources.quick_search_vendors.time_note'} 
                                    action={'resources.quick_search_vendors.button'} 
                                    href={`${process.env.REACT_APP_ROOT_FOLDER}#/vendor-search`}
                                />
                            </Grid>
                        )}
                        {(companyProperties.vendorSearchEnabled || companyProperties.softwareSearchEnabled) && (
                            <Grid item xs={12}>
                                <DashboardBox 
                                    header={'resources.internal_external_matching.headline'} 
                                    textTitle={'resources.internal_external_matching.title'}
                                    text={'resources.internal_external_matching.text'} 
                                    period={'resources.internal_external_matching.time_note'} 
                                    action={'resources.internal_external_matching.button'} 
                                    href={`${process.env.REACT_APP_ROOT_FOLDER}#/projects/create`}
                                />
                            </Grid>
                        )}
                        {!companyProperties.vendorSearchEnabled && !companyProperties.softwareSearchEnabled && (
                            <Grid item xs={12}>
                                <DashboardBox 
                                    header={'resources.external_matching.headline'} 
                                    textTitle={'resources.external_matching.title'}
                                    text={'resources.external_matching.text'} 
                                    period={'resources.external_matching.time_note'} 
                                    action={'resources.external_matching.button'} 
                                    href={`${process.env.REACT_APP_ROOT_FOLDER}#/projects/create`}
                                />
                            </Grid>
                        )}
                            <Grid item xs={12}>
                                <DashboardInfoBox />
                            </Grid>
                    </Grid>
                )}
            </div>
            {/* <img alt="knooing" src={images.has(companyId) ? images.get(companyId) : defaultImage} /> */}
            
        </Fragment>
    )
};

export default Dashboard;
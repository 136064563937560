import React from 'react';
import { connect } from 'react-redux';
import { Layout, Sidebar } from 'react-admin';
import CustomBar from './CustomBar';
import CustomMenu from './CustomMenu';
import { darkTheme, lightTheme } from './themes';
import Footer from '../Footer';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const CustomSidebar = props => (
    <span></span>
);

let backgroundImage = `${process.env.REACT_APP_ROOT_FOLDER}images/background-image.jpeg`;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        backgroundImage: "url('" + backgroundImage + "')",
        backgroundSize: 'cover',
        position: 'relative',
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
    },
    contentWithSidebar: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        padding: theme.spacing(3),
        marginTop: '4em',
        paddingLeft: 5,
    },
}));

const lightThemeBackground = {
    backgroundColor: '#fff'
}

const CustomLayout = ({ themeName, ...rest }) => {

    const user = localStorage.getItem('user_name');

    const classes = useStyles();
    
    return (
        <div>
            {user ?
                <Layout
                    {...rest}
                    appBar={CustomBar}
                    menu={CustomMenu}
                    sidebar={localStorage.getItem('user_name') ? Sidebar : CustomSidebar}
                    style={themeName == 'light' ? lightThemeBackground : null}
                />
                :
                <div className={classes.root}>
                    <div className={classes.content}>
                        {rest.children}
                    </div>
                </div>
            }
            {/* <Layout
                {...props}
                appBar={CustomBar}
                menu={CustomMenu}
                sidebar={localStorage.getItem('user_name') ? Sidebar : CustomSidebar}
            /> */}
            <Footer/>
        </div>
    )
};

export default connect(
    state => ({
        theme: state.theme === 'dark' ? darkTheme : lightTheme,
        themeName: state.theme
    }),
    {}
)(CustomLayout);
import React, { Component } from 'react';
import { Edit, fetchStart, fetchEnd, REDUX_FORM_NAME, showNotification, Toolbar, BooleanInput, SimpleForm, translate,SaveButton } from 'react-admin';
//import { submit, change } from 'redux-form'; todo:sheng

import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

class NotificationConfiguration extends Component {
    handleSubmit = updatedUser => {
        
        const { fetchStart, fetchEnd, showNotification } = this.props;

        fetchStart();

        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_API_URL}/users/${updatedUser.id}/notification-options`, { 
                method: 'PATCH', 
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
                body: JSON.stringify(updatedUser.notificationOptions) 
            })
            .then((response) => {
                if (response.status !== 204) {
                    throw new Error('Server responded: ' + response.status);
                } else {
                    showNotification('application.profile.notification.notification_configuration.updated_success');
                }
            })
            .catch((exception) => {
                showNotification('application.profile.notification.notification_configuration.updated_error', 'warning')
            })
            .finally(() => {
                fetchEnd();
            });
    }

    render() {
        const { translate } = this.props;

        const CustomToolbar = props => (
            <Toolbar {...props} >
                <SaveButton />
            </Toolbar>
        );

        const CustomArrayInput = ({ record }) => {
            // Replace: load defaults from the server.
            record.notificationOptions = (record.hasOwnProperty("userNotificationConfigsDto") && Array.isArray(record.userNotificationConfigsDto.notificationConfigs) && record.userNotificationConfigsDto.notificationConfigs.length)
                                        ? record.userNotificationConfigsDto.notificationConfigs
                                        : [ { name: 'AGENT', enabled: false } ];

            return record.notificationOptions.map((option, index) => {
                return <BooleanInput 
                            key={index} 
                            label={"resources.users.fields.notificationOptions." + option.name.toLowerCase()} 
                            record={record} 
                            source={"notificationOptions[" + index + "].enabled"} 
                        />
            })
        };

        return (
            <Edit title="application.menu.notification_configuration" id={JSON.parse(localStorage.getItem('decoded_token')).user_name} resource="users" basePath="/settings/notification-configuration">
                <SimpleForm toolbar={<CustomToolbar />} onSubmit={this.handleSubmit}>
                    <CustomArrayInput />
                </SimpleForm>
            </Edit>
        )
    }
}

const enhance = compose(
    connect(
        null,
        {
            push,
            showNotification, 
            fetchStart,
            fetchEnd,
        }
    ),
    translate,
);

export default enhance(NotificationConfiguration);
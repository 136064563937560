import React, { Component } from 'react'
import { SimpleShowLayout, ArrayField, TextField, Show, RichTextField, Datagrid, ListButton, CardActions } from 'react-admin';

const cardActionStyle = {
    paddingTop: '24px',
    paddingBottom: '18px',
};
const FeedbackAttachment = ({ record = {}, source, lable }) => (
    <a href={record[source]}>
        {record[lable]}
    </a>
);

const CustomActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
    </CardActions>
);

export const FeedbackShow = ({ permissions, ...props }) => (
    <Show actions={<CustomActions permissions={permissions} />} {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="email" />
            <TextField source="phone" />
            <RichTextField source="description" />

            <ArrayField source="feedbackAttachments">
                <Datagrid>
                    <FeedbackAttachment source="fileDownloadUrl" lable="fileName" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export default FeedbackShow;
import React, { Component } from 'react';
import { showNotification, Title } from 'react-admin';
import { withTranslate } from 'ra-core';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
// import { Field, reduxForm } from 'redux-form';
import { Form, Field } from 'react-final-form';
import compose from 'recompose/compose';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';

import { withStyles } from '@material-ui/core';
import { Fragment } from 'react';

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    field: {
        width: '256px',
    },
    button: {
        float: 'right',
        marginTop: '15px',
    },
    error: {
        backgroundColor: '#e57373',
    },
}

class PasswordRecovery extends Component {

    state = {
        sent: false,
        id: null,
    }

    submit = values => {
        values.tokenType = 'PASSWORD_RECOVERY';

        fetch(`${process.env.REACT_APP_API_URL}/activation-tokens`, { 
                method: 'POST', 
                headers: { 'Content-Type': 'application/json' }, 
                body: JSON.stringify(values) 
            })
            .then(response =>  response.json().then(data => ({ status: response.status, body: data })))
            .then((response) => {

                if ((response.status < 200 || response.status >= 300) && response.body.code == 3000) {
                    this.setState({ locked: true, message: this.props.translate('application.message.locked') });
                } else {
                    this.setState({ sent: true, id: response.id });
                }
            })
            .catch((exception) => {
                
            });
    }

    renderTextField = ({
        label,
        input,
        meta: { touched, invalid, error },
        ...custom
    }) => (
        <TextField
            label={label}
            placeholder={label}
            error={touched && invalid}
            helperText={touched && error}
            {...input}
            {...custom}
        />
    )

    render() {
        const { handleSubmit, classes, translate } = this.props;

        return (
            <div className={classes.main}>
                {!this.state.sent ? 
                    <Fragment>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={this.state.locked}
                            ContentProps={{ style: { backgroundColor: '#e57373' } }}
                            message={this.state.message}
                        />
                        <Form 
                            onSubmit={this.submit}
                            validate={values => {
                                const errors = {};
                                if (!values.email) {
                                    errors.email='This field is required!';
                                } 
                                return errors;
                        }}
                        >
                            {({ handleSubmit, pristine, reset, submitting }) => (
                                <form onSubmit={handleSubmit}>
                                    <Card className={classes.card}>
                                        <Title title="Password recovery" />
                                        <CardContent>
                                            <Typography>{translate('application.password_recovery.message_1')}</Typography>
                                            <div>
                                                <Field className={classes.field} name="email" component={this.renderTextField} label={translate('application.login.email')} type="email" />
                                                <Button className={classes.button} variant="contained" color="primary" type="submit">{translate('application.action.send')}</Button>
                                            </div>
                                            <div style={{ textAlign: 'center', paddingTop: '1.75em' }}>
                                                <a href={`${process.env.REACT_APP_ROOT_FOLDER}#/login`}>{translate('ra.auth.sign_in')}</a>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </form>
                            )}
                        </Form>
                    </Fragment>
                    :
                    <Card className={classes.card}>
                        <Title title={translate('application.password_recovery.name')} />
                        <CardContent>
                            <Typography>{translate('application.password_recovery.message_2')}</Typography>
                            <div id="hiddenId" hidden>{this.state.id}</div>
                        </CardContent>
                    </Card>
                }
            </div>
        )
    }
}

export default compose(
    connect(
        null,
        { showNotification, push }
    ),
    withTranslate,
    // reduxForm({
    //     form: 'passwordRecovery',
    //     validate: (values, props) => {
    //         const errors = { email: '' };
    //         // const { translate } = props;
    //         if (!values.email) {
    //             errors.email = 'This field is required';
    //         }
    //         return errors;
    //     },
    // }),
    withStyles(styles)
) (PasswordRecovery);
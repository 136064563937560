import React, { Fragment, useState, useEffect } from 'react';
import { Labeled, RichTextField, UrlField, useTranslate, useDataProvider } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { ATTACHMENT_URL_PART } from './InternalInsight';

import { 
    Typography, 
    Button,
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogContentText, 
    DialogActions,
    Link
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        height: '20em',
    },
    label: {
        fontWeight: 'bold',
        fontSize: '1.15em',
        textDecoration: 'underline',
        marginBottom: '-8px'
    },
}));

const ProductField = ({ record, source }) => {

    const classes = useStyles();

    return (
        <Labeled classes={classes} fullWidth label={"resources.products.fields." + source}>
            <RichTextField record={record} source={source} style={{ color: 'rgba(0, 0, 0, 0.87)'}} />
        </Labeled>
    )
}

export const ProductDetails = (props) => {

    const { record, language, style } = props;

    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const [ product, setProduct ] = useState(null);
    const [ dialogOpen, setDialogOpen ] = useState(false);

    useEffect(() => {
        if (record.productId) {

            dataProvider.getOne('products', { id: record.productId + '/description?language=' + language })
                .then(({ data }) => {
                    setProduct(data);
                });
        }
    }, []);

    if (record.productId) {
        
        return product != null ? 
            <Fragment>
                <Link style={style} href={'javascript:;'} onClick={() => setDialogOpen(true)}>knooing details: {product.neutralName}</Link>
                <Dialog
                    fullWidth
                    maxWidth={'md'}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                >
                    <DialogTitle><Typography display="inline" style={{ fontWeight: 'bold', color: '#39a1de' }} variant="button">{product.neutralName}</Typography></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <ProductField record={product} source="challenge" />
                            <ProductField record={product} source="advantage" />
                            <ProductField record={product} source="solution" />
                            <ProductField record={product} source="feature" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)} color="primary">
                            {translate(`ra.action.close`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
            :
            null;
    } else if (record.url && record.url.includes(ATTACHMENT_URL_PART)) {
        return <span>{translate(`resources.projects.internal_insight.collected_file`)} {record.productName}</span>
    } else {
        const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

        if (decodedToken && parseInt(decodedToken.company_id) == parseInt(process.env.REACT_APP_ADOIT_ID)) {

            return (
                <a href={record['url']} target="_blank">
                    {translate(`resources.projects.internal_insight.adoit_link`)}
                </a>
            )
        }
        return <UrlField {...props} target="_blank" style={style} />
    }
}
import React from 'react';

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import InternalSearch from './InternalSearch';

const SoftwareSearch = (props) => {

    return (
        <InternalSearch type="SOFTWARE" {...props} />
    );
}

export default compose(
    connect(
        state => ({
            theme: state.theme
        }),
    )
)(SoftwareSearch);
import React, { Fragment } from 'react';
import { withDataProvider, DELETE, ShowController, ShowView, DateField, Show, TabbedShowLayout, SimpleShowLayout, TextField, RichTextField, EditButton, ListButton, CardActions, CreateButton} from 'react-admin';

import compose from 'recompose/compose';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { connect } from 'react-redux';

import clsx from 'clsx';

import classnames from 'classnames';

import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Menu, MenuItem } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Notifications';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';

import DeleteIcon from '@material-ui/icons/Delete';

import MoreIcon from '@material-ui/icons/MoreVert';

import CustomIcon from './CustomIcon';

import ExpandIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { notificationDelete } from './dashboardActions';

const styles = theme => ({
    main: {
        flex: '1',
        // marginRight: '1em',
        marginTop: 20,
    },
    titleLink: { textDecoration: 'none', color: 'inherit' },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        // textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,

        textAlign: 'right'
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        overflowY: 'hidden',
        height: '4em',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    cardHeader: {
        textAlign: 'left', paddingTop: 10, paddingBottom: 10,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    grey: { backgroundColor: '#e0e0e0' },
    green: { backgroundColor: '#dcedc8' },
    orange: { backgroundColor: '#ffe0b2' },
});

const location = {
    pathname: 'reviews',
    query: { filter: JSON.stringify({ status: 'pending' }) },
};

const NotificationHeader = ({ record, translate, classes }) => {
        switch (record.eventType) {
        case 'PROJECT_STATUS_CHANGED':
            return (
                <Fragment>
                    {translate('application.dashboard.notifications.status')}:
                    <Chip 
                        component="span" 
                        size="small" 
                        label={translate(`resources.projects.field_values.status.${record.targetValue.toLowerCase()}`)} 
                        className={classnames({
                            [classes.grey]: record.targetValue === 'IN_REVIEW',
                            [classes.green]: record.targetValue === 'APPROVED',
                            [classes.orange]: record.targetValue === 'ARCHIVED',
                        })}
                        style={{ marginLeft: 10 }}
                    />
                </Fragment>
            );
        case 'NEW_SUGGESTION_FOUND':
            let message = 'application.dashboard.notifications.found_suggestion';
            if (Number(record.targetValue) > 1) {
                message += 's';
            }
            return (
                <Fragment>
                    <span style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>{record.targetValue}</span> {translate(message)}
                </Fragment>
            );
        case 'SUGGESTION_DECLINED_BY_PROVIDER':
            return (
                <Fragment>
                    {translate('application.dashboard.notifications.declined_by_provider')}
                </Fragment>
            );
        case 'SUGGESTION_ACCEPTED_BY_PROVIDER':
            return (
                <Fragment>
                    {translate('application.dashboard.notifications.accepted_by_provider')}
                </Fragment>
            );
        default:
            return null;
    }
}

const Notification = ({ record, translate, classes, dataProvider, fetchNotifications }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const deleteOne = (record) => {
        console.log('record: ', record);

        dataProvider(DELETE, 'notifications', { id: record.id })
            .then(({ data }) => {
                fetchNotifications();
                closeMenu();
            })
            .catch(error => {
                // showNotification(error.message, 'error');
            });
    }

    return (
        <Fragment>
            <CardHeader
                action={
                    <Fragment>
                        <IconButton aria-label="settings" aria-haspopup="true" onClick={openMenu}>
                            <MoreIcon />
                        </IconButton>
                        <Menu
                            id={`menu-${record.id}`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={closeMenu}
                        >
                            <MenuItem onClick={() => deleteOne(record)}>{translate('ra.action.delete')}</MenuItem>
                        </Menu>
                    </Fragment>
                }
                title={
                    <Fragment>
                        <DateField style={{ fontSize: '0.8rem', }} record={record} source="createdAt" showTime />
                    </Fragment>
                }
                subheader={
                    <Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                        >
                            <NotificationHeader record={record} translate={translate} classes={classes} />
                        </Typography>
                    </Fragment>
                }
            />
            <CardActionArea component={Link} to={`/projects/${record.targetId}/show`}>
                <CardContent style={{ textAlign: 'left', paddingTop: 0, paddingBottom: 0 }}>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {record.targetName}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <Divider />
        </Fragment>
    )
}

const Notifications = ({
    notifications,
    translate,
    classes,
    notificationDelete,
    fetchNotifications, 
    dataProvider, 
}) => (
    <div className={classes.main}>
        <CustomIcon Icon={ScheduleIcon} backgroundColor={'#923168'} />
        <Card className={classes.card}>
            <Typography style={{ fontSize: '0.8rem', textAlign: 'right' }} className={classes.title} color="textSecondary">
                {translate('application.dashboard.notifications.name')}
            </Typography>
            <Typography
                variant="h4"
                className={classes.value}
            >
                {notifications.length}
            </Typography>
            <Divider />
            <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                {notifications.sort((one, two) => new Date(two.createdAt) - new Date(one.createdAt)).map(record => (
                    <Notification key={record.id} notificationDelete={notificationDelete} fetchNotifications={fetchNotifications} dataProvider={dataProvider} record={record} translate={translate} classes={classes} />
                ))}
            </div>
            {/* <List>
                {notifications.content.sort((one, two) => new Date(two.createdAt) - new Date(one.createdAt)).map(record => (
                    <Fragment key={record.id}>
                        <ListItem
                            
                            button
                            component={Link}
                            to={`/projects`}
                            style={{ paddingTop: 5, paddingBottom: 5 }}
                        >
                            <ListItemText
                                primary={<Fragment>
                                    <Typography
                                        component="span"
                                        variant="caption"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {new Date(record.createdAt).toLocaleDateString()}
                                    </Typography>
                                    
                                </Fragment>}
                                secondary={<Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        New status <Chip component="span" size="small" label={translate(`resources.projects.field_values.status.${record.targetValue.toLowerCase()}`)} />
                                    </Typography>
                                    {` ${record.targetName}`}
                                </Fragment>}
                                className={classes.listItemText}
                                style={{ paddingRight: 0 }}
                            />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider component="li" />
                    </Fragment>
                ))}
            </List> */}
        </Card>
    </div>
);

export default compose(
    withStyles(styles),
    translate,
    withDataProvider, 
    connect(
        null,
        { notificationDelete: notificationDelete }
    ),
) (Notifications);
import React, { Component, isValidElement } from 'react';
import { Labeled } from 'react-admin';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MuiTab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import { translate } from 'ra-core';
import classnames from 'classnames';

const sanitizeRestProps = ({
    contentClassName,
    label,
    icon,
    value,
    ...rest
}) => rest;

class Tab extends Component {

    renderHeader = ({ className, label, icon, value, tooltip = "", ...rest }) => (
        <Tooltip title={tooltip}>
            <div>
                <MuiTab
                    key={label}
                    label={label}
                    value={value}
                    icon={icon}
                    className={classnames('show-tab', className)}
                    component={Link}
                    to={value}
                    {...sanitizeRestProps(rest)}
                />
            </div>
        </Tooltip>
    );

    renderContent = ({
        contentClassName,
        children,
        basePath,
        record,
        resource,
    }) => (
        <span className={contentClassName}>
            {React.Children.map(children, field =>
                field && isValidElement(field) ? (
                    <div
                        key={field.props.source}
                        className={classnames(
                            'ra-field',
                            `ra-field-${field.props.source}`,
                            field.props.className
                        )}
                    >
                        {field.props.addLabel ? (
                            <Labeled
                                label={field.props.label}
                                source={field.props.source}
                                basePath={basePath}
                                record={record}
                                resource={resource}
                            >
                                {field}
                            </Labeled>
                        ) : typeof field.type === 'string' ? (
                            field
                        ) : (
                            React.cloneElement(field, {
                                basePath,
                                record,
                                resource,
                            })
                        )}
                    </div>
                ) : null
            )}
        </span>
    );

    render() {
        const { children, context, ...rest } = this.props;
        return context === 'header'
            ? this.renderHeader(rest)
            : this.renderContent({ children, ...rest });
    }
}

Tab.propTypes = {
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    children: PropTypes.node,
    context: PropTypes.oneOf(['header', 'content']),
    icon: PropTypes.element,
    translate: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default translate(Tab);
import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import Typography from '@material-ui/core/Typography';

import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import CheckIcon from '@material-ui/icons/CheckCircle';

import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    border : {
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    greenIcon: {
        color: 'rgb(144, 198, 83)',
        fontSize: 40,
    },
    orangeIcon: {
        color: '#ffb74d',
        fontSize: 40,
    },
    yellowIcon: {
        color: '#ecd476',
        fontSize: 40,
    },
    grayIcon: {
        color: '#cccccc',
        fontSize: 40,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    example: {
        fontStyle: 'italic',
    },
}));

export const Legend = (props) => {

    const translate = useTranslate();
    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <Fragment>
            <Link
                component="button"
                variant="body2"
                onClick={() => setDialogOpen(true)}
            >
                {translate('resources.projects.legend_dialog.link')}
            </Link>

            <Dialog
                fullWidth
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                disableEnforceFocus
                // disableBackdropClick
                maxWidth={'md'}
            >
                <DialogTitle>
                    <div className={classes.title}>
                        {translate('resources.projects.legend_dialog.title')}
                        <IconButton size="small" onClick={() => setDialogOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <Table aria-label="legend">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" className={classes.border}>
                                        <CheckIcon className={classes.greenIcon} />
                                    </TableCell>
                                    <TableCell className={classes.border}>
                                        <Typography>{translate('resources.projects.legend_dialog.green_color')}</Typography>
                                        <Typography className={classes.example}>{translate('resources.projects.legend_dialog.green_color_example')}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" className={classes.border}>
                                        <CheckIcon className={classes.yellowIcon} />
                                    </TableCell>
                                    <TableCell className={classes.border}>
                                        <Typography>{translate('resources.projects.legend_dialog.orange_color')}</Typography>
                                        <Typography className={classes.example}>{translate('resources.projects.legend_dialog.orange_color_example')}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" className={classes.border}>
                                        <CheckIcon className={classes.grayIcon} />
                                    </TableCell>
                                    <TableCell className={classes.border}>
                                        <Typography>{translate('resources.projects.legend_dialog.gray_color')}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        {translate('ra.action.close')}
                    </Button>
                </DialogActions> */}
            </Dialog>
        </Fragment>
    );
}
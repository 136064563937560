import React, { useState, useEffect } from 'react';
import { TextInput, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    limitedInput: {
        marginTop: 0,
        '& p' :{
            textAlign: 'right',
        }
    },
}));

const LimitedInput = ({ maxLength, ...rest }) => {

    const { record, source } = rest;

    const [characterCount, setCharacterCount] = useState(maxLength);
    const translate = useTranslate();

    const classes = useStyles();
    
    useEffect(() => {

        let cleanedSource = source;
        if (source.includes('.')) {
            cleanedSource = source.split('.')[1];
        }
        let text = record[cleanedSource];
        if (text) {
            calculateCharacterCount(text);
        }
    }, []);

    const updateCount = (event) => {

        calculateCharacterCount(event.target.value);
    }

    const calculateCharacterCount = (text) => {
        let length = text.length || 0;

        let leftCharacters = Math.max(maxLength - length, 0)
        
        setCharacterCount(leftCharacters);
    }

    const renderText = () => {
        return `${characterCount} ${translate('application.message.characters_left')}`;
    }

    return (
        <TextInput 
            id="recommendation-use-case"
            onChange={updateCount} 
            inputProps={{ maxLength: maxLength }} 
            helperText={renderText()}
            {...rest} 
            className={classes.limitedInput}
        />
    )
};

export default LimitedInput;
import React, { useCallback } from 'react';
import { useCreate, useRedirect, useNotify, Toolbar, SaveButton, CardActions, ListButton, SimpleForm, Create, TextInput, SelectInput, required, useTranslate, email} from 'react-admin';
import UnsavedDataObserver from '../UnsavedDataObserver';
import { fetch } from '../utilities';
import IconCancel from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';

import { CreateButton } from '../component/CreateButton';
import Button from '@material-ui/core/Button';

const CustomActions = ({ basePath, data, resource }) => (
    <CardActions>
        <ListButton basePath={basePath} label="application.action.list" />
    </CardActions>
);

const redirect = (basePath, id, data) => `/users/${id}/show`;

const CustomButton = ({ color, label, onClick, icon }) => {
    return <Button startIcon={icon} variant="contained" color={color} onClick={onClick}>{label}</Button>
}

const CustomToolbar = (props) => {
    const translate = useTranslate();
    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CreateButton redirect={redirect} undoable={false} label="resources.users.action.send_invitation" icon={<SendIcon />} />
            <CustomButton label={translate('ra.action.cancel')} icon={<IconCancel />} onClick={() => document.location.href = `${process.env.REACT_APP_ROOT_FOLDER}#/users`} />
        </Toolbar>
    )
}

export const UserCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const message =  translate('application.validation.required');

    const roles = [ { id: 'ROLE_USER', name: 'resources.users.field_values.role.user' } ];
    if (permissions && permissions.includes('ROLE_EXECUTIVE')) {
        roles.push({ id: 'ROLE_POWER_USER', name: 'resources.users.field_values.role.power_user' });
        roles.push({ id: 'ROLE_EXECUTIVE', name: 'resources.users.field_values.role.executive' });
    }

    return (
    <Create actions={<CustomActions />} {...props}>
        <SimpleForm toolbar={<CustomToolbar />} variant="standard" margin="normal" defaultValue={{ companyId : JSON.parse(localStorage.getItem('decoded_token')).company_id }}>
            <div>
                <p id="error-message"></p>
            </div>
            {/* <UnsavedDataObserver /> */}
            
            <SelectInput source="salutation" choices={[
                { id: 'MALE', name: 'resources.users.field_values.salutation.male' },
                { id: 'FEMALE', name: 'resources.users.field_values.salutation.female' },
                
            ]} validate={required(message)}
            />

            <TextInput source="firstName" validate={required(message)} />
            <TextInput source="lastName" validate={required(message)} />

            <TextInput source="position" />

            <TextInput source="email" type="email" validate={[required(message), email()]} />
            <TextInput source="phone" />
            <TextInput source="mobilePhone" />

            <SelectInput source="roleName" validate={required(message)} choices={roles} />
            <SelectInput source="jobExperience" choices={[
                { id: 'LEVEL_0', name: 'resources.users.field_values.job_experience.level_0' },
                { id: 'LEVEL_1', name: 'resources.users.field_values.job_experience.level_1' },
                { id: 'LEVEL_2', name: 'resources.users.field_values.job_experience.level_2' },
                { id: 'LEVEL_3', name: 'resources.users.field_values.job_experience.level_3' },
                { id: 'LEVEL_4', name: 'resources.users.field_values.job_experience.level_4' },
                { id: 'LEVEL_5', name: 'resources.users.field_values.job_experience.level_5' },
                { id: 'UNDEFINED', name: 'resources.users.field_values.job_experience.undefined' },
            ]} />
            <SelectInput
                    label="resources.users.fields.language"
                    source="language"
                    optionValue="code"
                    choices={[
                        { code: 'DE', name: 'resources.users.field_values.language.DE' },
                        { code: 'EN', name: 'resources.users.field_values.language.EN' },
                    ]}
                    validate={required(message)}
                />
        </SimpleForm>
    </Create>
    )
};
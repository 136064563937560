import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DropzoneArea } from 'material-ui-dropzone';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import axios from "axios";
import {
  DateField,
} from 'react-admin';
import { translate } from 'react-admin';

class ProjectMessageFileUploadForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      textFieldValue: '',
      files: [],
      newProjectMessageIsEmpty: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    try {
      var divs = document.querySelectorAll('div[class^="DropzoneArea-dropZone"]');
      divs.forEach(function (item, index) {
        console.log(item);
        var ps2 = item.getElementsByTagName("p").item(0);
        ps2.style.fontSize = "small";
      });
    } catch (e) {
      // ignore exceptions
    }
  }


  handleChange(files) {
    this.setState({
      files: files
    });
  }

  onChangeText(element) {
    var textFieldElement = document.getElementById("new_project_message");
  }

  handleClose(e) {
    var dialogElement = document.getElementById("add_new_message_dialog");
    dialogElement.style.display = "none";
    window.localStorage.removeItem('projectMessageFieldValidationDone');
    window.history.back();
    //window.location.reload();
  }

  handleSubmit(e) {
    var textFieldElement = document.getElementById("new_project_message");

    textFieldElement.style.display = "none";
    var newMessage = document.getElementById("new_project_message").value;
    if (newMessage.trim().length < 1) {
      //alert("message field must be filled out");
      window.localStorage.setItem('projectMessageFieldValidationDone', 'true');
      window.location.reload();
      //this.forceUpdate();
      return;
    } else {
      window.localStorage.removeItem('projectMessageFieldValidationDone');
    }

    let postUrl = process.env.REACT_APP_API_URL + "/project-messages";



    const userNameAsId = JSON.parse(localStorage.getItem('decoded_token')).user_name;


    var bodyFormData = new FormData();
    bodyFormData.append('projectId', this.props.projectId);
    bodyFormData.append('creatorId', userNameAsId);
    bodyFormData.append('messageType', "USER_TO_CRM");
    bodyFormData.append('message', newMessage);

    for (var i = 0; i < this.state.files.length; i++) {
      let file = this.state.files[i];
      let fileIndexOnBackend = i + 1;
      bodyFormData.append('file' + fileIndexOnBackend, file);
    }



    const defaultOptions = {
      url: postUrl,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        'client-type': 'USER',
      },
    };


    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
      const token = localStorage.getItem('token');
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      return config;
    });

    instance.post(postUrl, bodyFormData)
      .then(res => {
        //console.log(res);
      });


    //window.history.back();
    var oldURL = document.referrer;
    //alert(oldURL);
    //window.history.back();
    window.location.href = `${process.env.REACT_APP_ROOT_FOLDER}#/progress-wait`

  }


  render() {
    const isNewProjectMessageIsEmpty = this.state.newProjectMessageIsEmpty ? "true" : "false";
    const isInitDialog = window.localStorage.getItem('projectMessageFieldValidationDone') === null;
    const showErrorMessages = !isInitDialog && isNewProjectMessageIsEmpty;
    const styleForErrorMessage = " style='color:blue;' ";
    return (
      <div>
        <DialogContent>
          <DialogContentText>
            Our business consultant answers you as soon as possible.
          </DialogContentText>
          <form
            className="uploader"
            encType="multipart/form-data"
            id="add_new_project_message_form"
          >

            <TextField
              error={showErrorMessages}
              autoFocus
              margin="dense"
              id="new_project_message"
              label="Message"
              type="email"
              multiline={true}
              rows={3}
              fullWidth
              //errorText= {this.props.errorText}
              //onSubmit={onChangeText(this)}
              aria-describedby="component-error-text"
            />

            <FormHelperText error={showErrorMessages} id="component-error-text">Please enter your message!</FormHelperText>

            <br />
            <DropzoneArea
              onChange={this.handleChange.bind(this)}
              filesLimit={5}
              showFileNamesInPreview={true}
              showFileNames={true}
              dropzoneText="Drag and drop a file here or click, up to 5 files are allowed"
            />

          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit} color="primary">
            Send
          </Button>
        </DialogActions>
      </div>
    )
  }
}


export default ProjectMessageFileUploadForm;

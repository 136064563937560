import React, { useEffect, useCallback } from 'react';
import { useSetLocale, useUpdate, useRedirect, useNotify } from 'react-admin';

export const LanguageObserver = ({ language }) => {

    const setLocale = useSetLocale();

    var savedLanguage = localStorage.getItem('language');
    if (savedLanguage == null) {
        savedLanguage = 'en';
    }

    useEffect(() => {
        setLocale(savedLanguage);
    }, [savedLanguage]);

    return null;
};
import RecommendationList from './RecommendationList';
import { RecommendationCreate } from './RecommendationCreate';
import { RecommendationEdit } from './RecommendationEdit';
import RecommendationShow from './RecommendationShow';

export default {
    list: RecommendationList,
    create: RecommendationCreate,
    edit: RecommendationEdit,
    show: RecommendationShow,
}
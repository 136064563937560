import React, { Component } from 'react';
import { useFormState, useForm } from 'react-final-form'

import NavigationPrompt from './NavigationPrompt';

const UnsavedDataObserver = () => {
    let formState = useFormState();
    let form = useForm();

    // console.log('form state: ', formState);

    return (
        <NavigationPrompt when={formState.dirty && !formState.submitSucceeded} />
    );
}

export default UnsavedDataObserver;
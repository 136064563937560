import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
     height: "30%",
     width: "30%",
     //marginTop: "-100px",
     //marginLeft: "-150px",
     position: "absolute",
     marginLeft: "auto",
     marginRight: "auto",
     top: "40%",
     left: "40%",
  },
});

export default function LinearBuffer() {
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progress = React.useRef(() => {});
  React.useEffect(() => {
    progress.current = () => {
      if (completed > 100) {
        setCompleted(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setCompleted(completed + diff);
        setBuffer(completed + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    function tick() {
      progress.current();
    }
    const timer = setInterval(tick, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
      <div className={classes.root}>
            <LinearProgress variant="buffer" value={completed} valueBuffer={buffer} />
            <br />
            <LinearProgress color="secondary" variant="buffer" value={completed} valueBuffer={buffer} />
      </div>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '20px 0px 150px 0px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));

export default function ProjectGeneralInformation() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>

      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}><b>Definition</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            ServiceNow ist eine Softwareplattform, die IT-Services-Management <b>(ITSM)</b>, IT-Operations-Management <b>(ITOM)</b> und IT-Business-Management <b>(ITBM)</b> unterstützt.
                <br />
            Der Wert, den ITSM-Tools für IT-Organisationen bieten, ist ihre Fähigkeit, eng integrierte Prozesse und Funktionen bereitzustellen, die mit den Aktivitäten der gesamten IT-Organisation korrelieren.
                <br /><br />
            Dies ist ein Plattform-as-a-Service-<b>(PaaS)</b>-Anbieter für Servicemanagement.
            <br />
            Diese Software as a Service <b>(SaaS)</b>-Plattform enthält eine Reihe modularer Anwendungen.
            <br /><br />
            ServiceNow ist in der Cloud viel einfacher zu warten als on-premise Anwendungen.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <br />

      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}><b>Best for</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List component="nav" aria-label="secondary mailbox folders">
            <ListItem>
              <Typography>
                ITSM mit ITOM. Dazu gehören wachsende Unternehmen jeder Größe und Branche.
              </Typography>
            </ListItem>
            <ListItem href="#simple-list">
              <img src="images/showcase/servicenow-fits-best.png" style={{ width: '943px', height: '554px' }} />
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <br />

      <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}><b>Funktionsübersicht</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List component="nav" aria-label="secondary mailbox folders">
            <ListItem>
              <img src="images/showcase/feature-overview-01.png" style={{ width: '943px', height: '568px' }} />
            </ListItem>
            <ListItem>
              <img src="images/showcase/feature-overview-02.png" style={{ width: '943px', height: '527px' }} />
            </ListItem>
            <ListItem>
              <img src="images/showcase/feature-overview-03.png" style={{ width: '943px', height: '522px' }} />
            </ListItem>
            <ListItem>
              <img src="images/showcase/feature-overview-04.png" style={{ width: '943px', height: '410px' }} />
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <br />

      <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}><b>Architektur Übersicht</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List component="nav" aria-label="secondary mailbox folders">
            <ListItem>
              <Typography>
                Servicenow nutzt die Multi-Instanz-Architektur. <br />
                Für jeden Kunde wird eine eindeutige Instanz erstellt, die einen separaten Ressourcenstapel verwaltet.
                </Typography>
            </ListItem>
            <ListItem>
              <img src="images/showcase/architecture-overview-01.png" style={{ width: '943px', height: '358px' }} />
            </ListItem>
            <ListItem>
              <Typography>
                Hier ist die Architektur Übersicht:
                </Typography>
            </ListItem>
            <ListItem>
              <img src="images/showcase/architecture-overview-02.png" style={{ width: '943px', height: '360px' }} />
            </ListItem>
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <br />
      <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}><b>Unternehmensinformationen und Reviews</b></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Typography>
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem>
                <Typography>
                  <b>Contact Details</b>
                  <br/>
                  http://www.servicenow.com/
                  <br/>
                  Founded in 2003
                  <br/>
                  Located in United States
                </Typography>
              </ListItem>
              <ListItem>
                  <Typography>
                  <br/>
                    <b>Company Reviews</b>
                    <br/>
                    <a href="https://www.glassdoor.de/Bewertungen/ServiceNow-Bewertungen-E403326.htm?countryRedirect=true">zur glassdoor</a>
                    <br/>
                    <a href="https://www.trustradius.com/products/servicenow/reviews">zur trustradius</a>
                  </Typography>
              </ListItem>              
            </List>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
import React from 'react';
import { Edit } from 'react-admin';
import { SoftwarePortfolioForm } from './SoftwarePortfolioForm';

export const SoftwarePortfolioEdit = (props) => {

    return (
        <Edit undoable={false} {...props}>
            <SoftwarePortfolioForm editMode={true} />
        </Edit>
    )
}
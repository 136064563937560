import React, { useState, useEffect } from 'react';
import { SelectInput, useTranslate, useDataProvider } from 'react-admin';

export const SupplierStatus = (props) => {

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    const [allowed, setAllowed] = useState(true);

    const dataProvider = useDataProvider();

    // useEffect(() => {

    //     if (decodedToken) {
    //         const companyId = decodedToken.company_id;

    //         dataProvider.getOne('companies', { id:  companyId + '/properties' })
    //             .then(({ data }) => {
    //                 setAllowed(data.onlyFavoriteProviders);
    //             });
    //     }
    // }, []);

    return (
        allowed ? 
            <SelectInput 
                defaultValue={'NICHE'}
                source="status"
                choices={[
                    { id: 'TOP', name: 'resources.recommendations.field_values.status.top' },
                    { id: 'PREFERRED', name: 'resources.recommendations.field_values.status.preferred' },
                    // { id: 'STANDARD', name: 'resources.recommendations.field_values.status.standard' },
                    { id: 'NICHE', name: 'resources.recommendations.field_values.status.niche' },
                    { id: 'OTHER', name: 'resources.recommendations.field_values.status.other' },
                    // { id: 'PHASE_OUT', name: 'resources.recommendations.field_values.status.phase_out' },
                    { id: 'BLOCKED', name: 'resources.recommendations.field_values.status.blocked' },
                ]}
                {...props}
            />
            :
            null
    )
}
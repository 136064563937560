import React, { Component, Fragment, useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { Select, MenuItem, Card, CardHeader, CardContent, TablePagination, Grid, List, ListItem, RadioGroup, Radio, FormControlLabel, ListItemAvatar, Avatar, Stepper, Step, StepLabel, ListItemIcon, ListItemText, Typography, Chip, Icon, IconButton, Tooltip, Button, Checkbox } from "@material-ui/core";

import CrossIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@material-ui/icons/Delete';

import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import InformationIcon from '@material-ui/icons/Info';

import classnames from 'classnames';
import { Legend } from './Legend';

const useStyles = makeStyles(theme => ({
    root: {
        height: '20em',
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftColumn: { flex: 1, marginRight: '1em' },
    rightColumn: { flex: 1, marginLeft: '1em' },
    singleColumn: { marginBottom: '2em' },
    fab: {
        margin: theme.spacing(1),
        backgroundColor: '#304d75'
    },
    tableContainer: {
        maxWidth: '75vw',
        overflowX: 'auto',
        margin: '0 auto',
    },
    table: {
        tableLayout: 'fixed',
        margin: '0px auto 0px auto',
    },
    fixedHeader: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        height: 50,
        backgroundColor: 'white',
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
    },
    fixedCell: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        borderRight: '1px solid black',
        height: 29,
        backgroundColor: 'white',
        zIndex: 1,
    },
    header: {
        width: 200,
        height: 50
    },
    cell: {
        width: 200,
        borderCollapse: 'collapse',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        height: 29
    },
    firstHeader: {
        width: 200,
        paddingLeft: 300,
        height: 50,
    },
    firstCell: {
        width: 200,
        paddingLeft: 300,
        borderCollapse: 'collapse',
        borderRight: '1px solid black',
        height: 29
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    border : {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    green: {
        color: 'rgb(144, 198, 83)',
    },
    orange: {
        color: '#ffb74d',
    },
    blue: {
        color: '#39a1de',
    },
    gray: {
        color: '#cccccc',
    }
}));

export const Vendors = (props) => {

    const { record, step, type } = props;

    const classes = useStyles();
    
    const translate = useTranslate();

    const headers = [
        translate('resources.projects.vendor_consolidation.title'),
        translate('resources.projects.vendor_consolidation.owner'),
        translate('resources.projects.vendor_consolidation.watcher'),
        translate('resources.projects.vendor_consolidation.created_date'),
        '',
    ]

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" style={{ margin: 30, textAlign: 'center' }}>{translate('resources.projects.vendor_consolidation.title_1')}</Typography>
                <Typography component="p" style={{ fontSize: '0.875rem' }}>{translate('resources.projects.vendor_consolidation.description_1')}</Typography>
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: 20 }}>
                    <Button color="primary" startIcon={<AddIcon />}>{translate('resources.projects.vendor_consolidation.create')}</Button>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => 
                                <TableCell>
                                    {header}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <a href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge/vendor`}>{translate('resources.projects.vendor_consolidation.example_1')}</a>
                            </TableCell>
                            <TableCell>
                                Testine Anwender
                            </TableCell>
                            <TableCell>
                                Salome Capuccio
                            </TableCell>
                            <TableCell>
                                18.3.2022
                            </TableCell>
                            <TableCell>
                                <Button href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge/vendor`} variant="outlined">{translate('resources.projects.vendor_consolidation.open')}</Button>
                                <Button variant="outlined" color="secondary" style={{ color: '#cc0000', borderColor: '#cc0000', marginLeft: 20 }} startIcon={<DeleteIcon />}>{translate('resources.projects.vendor_consolidation.delete')}</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
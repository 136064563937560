import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { SaveButton, Toolbar, SimpleForm, RadioButtonGroupInput, TextInput, useCreate, useDataProvider, useRedirect, useNotify, useTranslate, required } from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '@material-ui/core/Button';

import CancelIcon from '@material-ui/icons/Cancel';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { isRwe, isZf, isSiemens } from '../utilities';

const useStyles = makeStyles(theme => ({
    toolbar: {
        background:'none',
        flex: '0 0 auto',
        display: 'flex',
        padding: '0px 20px',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    button:{
        marginRight:'10px'
    }
}));

function mergeObjects(target, source) {
    for (const key in source) {
        if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
            // Recursively merge nested objects
            target[key] = mergeObjects(target[key] || {}, source[key]);
        } else if (Array.isArray(source[key])) {
            // Merge arrays, keeping only unique values
            if (Array.isArray(target[key])) {
                target[key] = Array.from(new Set(target[key].concat(source[key])));
            } else {
                target[key] = source[key].slice();
            }
        } else {
            // Replace simple values
            target[key] = source[key];
        }
    }
    return target;
}

const DialogSaveButton = props => {

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [ loading, setLoading ] = useState(false);

    const { basePath, doRedirect, setOpen, recommendation, similarRecommendations, selectedRecommendation, next, setNext } = props;
    recommendation.products = recommendation.products || [];
    recommendation.similarIgnored = true;

    const completeRequest = (type) => {

        doRedirect();
        setOpen(false);
        notify('ra.notification.' + type, 'info', {
            smart_count: 1,
        });
        setLoading(false);
    }

    const formState = useFormState();
    const handleClick = () => {

        if (!formState.valid) {
            return;
        }

        // console.log(formState.values);
        // console.log(recommendation);
        // console.log(selectedRecommendation);

        mergeObjects(recommendation, formState.values);
        mergeObjects(selectedRecommendation, recommendation);

        console.log(selectedRecommendation);

        setLoading(true);

        if (formState.values.recommendation == 'NEW') {

            dataProvider
                .create('recommendations', { data: {...recommendation} })
                .then(response => {

                    completeRequest('created');
                })
                .catch(error => {
                    notify(error.message, 'warning');
                });
        } else {

            // const record = similarRecommendations.find(element => element.id === formState.values.recommendation);
            // record.products = [...record.products, ...recommendation.products];
            const record = selectedRecommendation;
            let offerings = new Set([...record.offerings, ...recommendation.offerings]);
            record.offerings = Array.from(offerings);
            
            dataProvider
                .update('recommendations', { id: record.id, data: { ...record } })
                .then(response => {

                    completeRequest('updated');
                })
                .catch(error => {
                    notify(error.message, 'warning');
                });
        }
    }

    if (!next && selectedRecommendation && selectedRecommendation.id !== 'NEW') {
        return <Button variant="contained" color="primary" onClick={() => { setNext(true); }}>Next</Button>
    }

    return <SaveButton {...props} disabled={loading} loading={loading} handleSubmitWithRedirect={handleClick} />
};

const CustomToolbar = props => {

    const { setOpen, clear } = props;
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Toolbar {...props} className={classes.toolbar}>
            <Button startIcon={<CancelIcon />} variant="contained" onClick={()=> {
                setOpen(false);
                if (clear) {
                    clear();
                }
            }}>{translate("ra.action.cancel")}</Button>
            <DialogSaveButton {...props} />
        </Toolbar>
    )
};

function generateProperties(propertyNames, sourceObject, targetObject) {
    const properties = [];

    for (const propertyName of propertyNames) {
        const sourceValue = getProperty(sourceObject, propertyName.value);
        const targetValue = getProperty(targetObject, propertyName.value);

        if (sourceValue !== undefined && targetValue !== undefined && sourceValue !== targetValue) {
            const property = {
                'label': `resources.recommendations.it_vendor.${propertyName.label}`,
                'source': propertyName.value,
                'choices': [
                    {
                        'id': sourceValue,
                        'name': propertyName.valueTranslation ? propertyName.valueTranslation + sourceValue.toLowerCase() : sourceValue,
                    },
                    {
                        'id': targetValue,
                        'name': propertyName.valueTranslation ? propertyName.valueTranslation + targetValue.toLowerCase() : targetValue,
                    }
                ]
            };
            properties.push(property);
        }
    }

    return properties;
}

// Helper function to get nested properties
function getProperty(object, path) {
    const keys = path.split('.');
    let value = object;
    
    for (const key of keys) {
        if (value && value.hasOwnProperty(key)) {
            value = value[key];
        } else {
            return undefined;
        }
    }
    
    return value;
}


export const CustomSaveButton = props => {

    const [create] = useCreate('recommendations');
    const redirect = useRedirect();
    const notify = useNotify();
    const [ open, setOpen ] = useState();
    const [ similarRecommendations, setSimilarRecommendations ] = useState([]);
    const [ recommendation, setRecommendation ] = useState();
    const [ selectedRecommendation, setSelectedRecommendation ] = useState();
    const [ changedProperties, setChangedProperties ] = useState([]);
    const [ next, setNext ] = useState(false);
    const translate = useTranslate();

    const { basePath } = props;

    const selectRecommendation = (id) => {

        let recommendation = similarRecommendations.find(item => item.id == id);
        setSelectedRecommendation(recommendation);
    }

    const doRedirect = () => {
        redirect(basePath);
    }

    const formState = useFormState();


    useEffect(() => {

        if (selectedRecommendation) {
            let propertyNames = [
                { label: 'providerName', value: 'providerName' },
                { label: 'website', value: 'website' },
                { label: 'segment', value: 'status', valueTranslation: 'resources.recommendations.field_values.status.' }
            ]
            if (isSiemens()) {
                propertyNames.push({ label: 'supplier_number', value: 'attribute' });
            } else if (isRwe()) {
                propertyNames.push({ label: 'id', value: 'itVendorData.id' });
                propertyNames.push({ label: 'duns_global_name', value: 'itVendorData.dunsGlobalName' });
                propertyNames.push({ label: 'internalUrl', value: 'internalUrl' });
                propertyNames.push({ label: 'valuation', value: 'valuation', valueTranslation: 'resources.recommendations.it_vendor.valuation_' });
                propertyNames.push({ label: 'mentor', value: 'itVendorData.mentor' });
                propertyNames.push({ label: 'management', value: 'itVendorData.management' });
                propertyNames.push({ label: 'status', value: 'itVendorData.status' });
                propertyNames.push({ label: 'ofa', value: 'itVendorData.ofa' });
            } else if (isZf()) {
                propertyNames.push({ label: 'plant', value: 'itVendorData.plant' });
                propertyNames.push({ label: 'spendType', value: 'itVendorData.spendType' });
            }
            let properties = generateProperties(propertyNames, selectedRecommendation, recommendation);
            setChangedProperties(properties);
        }
    }, [selectedRecommendation]);

    const clear = () => {
        setNext(false);
        setSelectedRecommendation(null);
        setChangedProperties([]);
        setSimilarRecommendations([]);
    }

    const handleClick = useCallback(() => {
        if (!formState.valid) {
            return;
        }

        setRecommendation({ ...formState.values });

        create(
            {
                payload: { data: { ...formState.values } },
            },
            {
                onSuccess: ({ data }) => {

                    if (data.similarRecommendations) {

                        let similarRecommendations = data.similarRecommendations;
                        let empty = { id: 'NEW', providerName: translate('resources.recommendations.similar_recommendations.new_recommendation') }
                        similarRecommendations.push(empty);

                        setSimilarRecommendations(similarRecommendations);
                        setOpen(true);

                    } else {
                        notify('ra.notification.created', 'info', {
                            smart_count: 1,
                        });
                        doRedirect();
                    }
                },
            }
        );
    }, [
        formState.valid,
        formState.values,
        create,
        notify,
        redirect,
        basePath,
    ]);

    return (

        <Fragment>
            <SaveButton {...props} handleSubmitWithRedirect={handleClick} />

            <Dialog
                fullWidth
                open={open}
                onClose={() => { setOpen(false); clear(); }}
                disableEnforceFocus
            >
                <DialogTitle style={{ paddingBottom: 0 }}>
                    {next ? translate('resources.recommendations.similar_recommendations.title_2') : translate('resources.recommendations.similar_recommendations.title')}
                </DialogTitle>
                <DialogContent style={{ overflow: 'auto', padding: 0, margin: 0 }}>
                    {!next && 
                        <SimpleForm variant="standard" toolbar={<CustomToolbar clear={clear} setNext={setNext} next={next} selectedRecommendation={selectedRecommendation} recommendation={recommendation} similarRecommendations={similarRecommendations} doRedirect={doRedirect} setOpen={setOpen} />}>
                            <RadioButtonGroupInput 
                                label={translate('resources.recommendations.similar_recommendations.recommendation')} 
                                row={false}
                                fullWidth
                                source="recommendation"
                                optionText={(choice) => 
                                    <div>
                                        <span style={{ fontWeight: 'bold' }}>{choice.providerName}</span>{choice.website && <span> - {choice.website}</span>}
                                    </div>
                                }
                                onChange={(id) => selectRecommendation(id)}
                                choices={similarRecommendations} 
                                translateChoice={false} 
                                validate={required()}
                            />
                        </SimpleForm>
                    }
                    {next &&
                        <Fragment>
                            <SimpleForm variant="standard" toolbar={<CustomToolbar clear={clear} next={next} selectedRecommendation={selectedRecommendation} recommendation={recommendation} similarRecommendations={similarRecommendations} doRedirect={doRedirect} setOpen={setOpen} />}>
                                
                                {changedProperties.map(property => {

                                    return (
                                        <RadioButtonGroupInput 
                                            label={property.label} 
                                            row={false}
                                            fullWidth
                                            source={property.source}
                                            // optionText={(choice) => 
                                            //     <div>
                                            //         <span style={{ fontWeight: 'bold' }}>{choice.value}</span>
                                            //     </div>
                                            // }
                                            choices={property.choices} 
                                            // translateChoice={false} 
                                            validate={required()}
                                        />
                                    )
                                })}
                            </SimpleForm>
                        </Fragment>
                    }
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};
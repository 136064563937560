import React, { Fragment, useState, useEffect } from 'react';
import { UrlField, DateField, List as DefaultList, RichTextField, TextField, useTranslate, useDataProvider, Pagination } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import InformationIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import CircularProgress from '@material-ui/core/CircularProgress';

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { 
    List, 
    ListItem, 
    ListItemText, 
    Typography, 
    Divider, 
    Tooltip, 
    Button,
    InputBase,
    Paper,
    Grid,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";

import withStyles from '@material-ui/core/styles/withStyles';
import { ProductDetails } from '../projects/ProductDetails';
import { isOnlyInternalProducts } from '../projects/InternalInsight';

const useStyles = makeStyles(theme => ({
    containerRoot: {
        margin: '0 auto',
        width: '80%',
        marginBottom: 45
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        // width: '70%',
        // margin: '0 auto',
        borderRadius: 28,
        // border: '1px solid #e7f0fe'
    },
    searchInput: {
        // marginLeft: theme.spacing(1),
        flex: 1,
    },
    searchIcon: {
        padding: 10,
    },
    exactMatch: {
        marginTop: 0,
        marginLeft: 37,
        position: 'absolute',
    },
    label: {
        fontSize: '.75em',
    },
    checkbox: {
        fontSize: 15
    }
}));

const StyledTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 620,
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const UseCase = ({ record }) => {

    const translate = useTranslate();

    const { useCase } = record;

    let companyName = JSON.parse(localStorage.getItem('decoded_token')).company_name;

    if (!useCase) {
        return null;
    }

    return (
        <div style={{ paddingLeft: 55, marginTop: 5 }}>
            <span style={{ fontWeight: 'bold', marginRight: 5 }}>
                {companyName} {translate('resources.quick_search_software.use_case')}: 
            </span>
            {useCase}
        </div>
    )
}

function wrapWordInQuotes(text, word) {
    // Escape any special characters in the word to avoid issues with the regular expression
    const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression to find the word in the text (case-insensitive)
    const regex = new RegExp(`\\b${escapedWord}\\b`, 'gi');

    // Replace the word with the word wrapped in quotes
    const newText = text.replace(regex, `"${word}"`);

    return newText;
}

const MissingWords = ({ record, value, setValue, search }) => {
    
    const translate = useTranslate();

    const { missingWords } = record;

    if (!missingWords || missingWords.length === 0) {
        return null;
    }

    const handleClick = (word) => {
        let newValue = wrapWordInQuotes(value, word);
        setValue(newValue);
        
        var button = document.getElementById('quick-search-button');
        if (button) {
            setTimeout(() => {
                button.click();
            }, 100);
        }
    };

    return (
        <div>
            <p>
                {translate('resources.quick_search_software.missing_words')}:{' '}
                {missingWords.map((word, index) => (
                    <span key={word} style={{ textDecoration: 'line-through' }}>
                        {word}
                        {index !== missingWords.length - 1 ? ', ' : ''}
                    </span>
                ))}{' '}
                | {translate('resources.quick_search_software.must_contain')}:{' '}
                {missingWords.map((word, index) => (
                    <span key={word}>
                        <span
                            onClick={() => handleClick(word)}
                            style={{ color: '#0000EE', cursor: 'pointer' }}
                        >
                            {word}
                        </span>
                        {index !== missingWords.length - 1 ? ', ' : ''}
                    </span>
                ))}
            </p>
        </div>
    );
}

const CustomGrid = ({ ids, data, basePath, theme, loading, setLoading, type, ...rest }) => {

    const [onlyInternalProducts, setOnlyInternalProducts] = useState();
    const dataProvider = useDataProvider();

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));
    var language = 'EN';
    if (decodedToken && decodedToken.language) {
        language = decodedToken.language.toUpperCase();
    }

    useEffect(() => {

        if (decodedToken) {

            const companyId = decodedToken.company_id;

            dataProvider.getOne('companies', { id: companyId + '/properties' })
                .then(({ data }) => {
                    let company = {
                        matchingSetup: data
                    }
                    let onlyInternalProducts = isOnlyInternalProducts(company, type);
                    setOnlyInternalProducts(onlyInternalProducts);
                });
        }
    }, []);

    return loading ? <div style={{ position: 'relative', top: 25, textAlign: 'center' }}><CircularProgress /></div> : <List>
        {ids.map(id => {
            let extendedRecord = data[id];
            extendedRecord.fullName = extendedRecord.companyName
            if (onlyInternalProducts) {
                extendedRecord.fullName = extendedRecord.productName + ' - ' + extendedRecord.companyName;
            }
            return (
                <Fragment>
                    <ListItem key={id} alignItems="flex-start">
                        <ListItemText
                            primary={<TextField style={{ fontWeight: 'bold', fontSize: '1rem' }} record={extendedRecord} source="fullName" />}
                            secondary={
                                <React.Fragment>
                                    {/* <UrlField record={data[id]} source="url" target="_blank" style={{ display: 'block', margin: '5px 0px' }} /> */}
                                    <ProductDetails record={data[id]} source="url" language={language} style={{ display: 'block', margin: '5px 0px', color: theme === 'dark' ? 'white' : '' }} />

                                    <DateField record={data[id]} source="crawledAt" style={{ fontWeight: 'bold' }} options={{ year: 'numeric', month: 'long', day: 'numeric' }} />
                                    <span> - </span>

                                    <RichTextField record={data[id]} source="excerpt" />
                                    <MissingWords record={data[id]} {...rest} />
                                    {onlyInternalProducts && <UseCase record={data[id]} />}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </Fragment>
            )
        }

        )}
    </List>
};

const CustomPagination = ({ loading, ...rest }) => loading ? <div style={{ height: 40 }}></div> : <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...rest} />;

const InternalSearch = (props) => {

    const { theme, type, basePath } = props;

    let translationPrefix;
    if (type == 'SOFTWARE') {
        translationPrefix = 'resources.quick_search_software.page.'
    } else if (type == 'SERVICE') {
        translationPrefix = 'resources.quick_search_vendors.page.'
    }

    const classes = useStyles();
    const translate = useTranslate();

    const [value, setValue] = useState('');
    const [checked, setChecked] = useState(false);
    const [query, setQuery] = useState();
    const [exactMatch, setExactMatch] = useState(false);
    const [loading, setLoading] = useState(false);

    const search = () => {

        console.log('search')

        setLoading(true);

        if (value && value !== '') {
            setQuery(value);
        }
        setExactMatch(checked);
    }

    const updateValue = (event) => {
        setValue(event.target.value);
    }

    const toggleExactMatch = (event) => {
        setChecked(event.target.checked);
    }

    useEffect(() => {

        return () => {
            setValue('');
            setChecked(false);
            setLoading(false);
            setQuery(null);
        }
    }, []);

    return (
        <div style={{ padding: '0px 24px'}}>
            <div style={{ marginTop: 16, marginBottom: 24, textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom>{translate(translationPrefix + 'title')}</Typography>
                <Typography style={{ display: 'inline' }}>{translate(translationPrefix + 'subtitle')}</Typography>
                <StyledTooltip
                    title={
                        <Fragment>
                            <Typography color="inherit">{translate(translationPrefix + 'tooltip.text_1')}</Typography>
                            <ul>
                                <li>{translate(translationPrefix + 'tooltip.text_2')}</li>
                                <li>{translate(translationPrefix + 'tooltip.text_3')}</li>
                                <li>{translate(translationPrefix + 'tooltip.text_4')}</li>
                                <li>{translate(translationPrefix + 'tooltip.text_5')}</li>
                            </ul>
                            <Typography color="inherit">{translate(translationPrefix + 'tooltip.text_6')}</Typography>
                        </Fragment>
                    }
                >
                    <InformationIcon style={{ color: '#39a1de', position: 'relative', top: 5, marginLeft: 5 }} />
                </StyledTooltip>
            </div>
            
            <Grid container spacing={2} alignItems="center" alignContent="center" justify="center" className={classes.containerRoot}>
                <Grid item xs={10}>
                    <Paper className={classes.searchRoot} style={{ backgroundColor: theme === 'dark' ? '#999999' : 'white' }}>
                        <span className={classes.searchIcon} aria-label="menu">
                            <SearchIcon style={{ color: 'rgb(95, 99, 104)' }} />
                        </span>
                        <InputBase
                            className={classes.searchInput}
                            autoFocus
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    search();
                                }
                            }}
                            value={value}
                            onChange={updateValue}
                            // disabled={loading}
                        />
                    </Paper>
                    <FormControlLabel
                        classes={{
                            label: classes.label,
                        }}
                        control={
                            <Checkbox 
                                checked={checked} 
                                onChange={toggleExactMatch} 
                                color="primary" 
                                icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
                                checkedIcon={<CheckBoxIcon className={classes.checkbox} />} 
                            />
                        }
                        label={translate(translationPrefix + 'selector')}
                        className={classes.exactMatch}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button 
                        // disabled={loading} 
                        variant="outlined" 
                        onClick={search}
                        id="quick-search-button"
                    >
                        {translate(translationPrefix + 'button')}
                    </Button>
                </Grid>
            </Grid>
            

            {query && 
                <DefaultList 
                    title=" "
                    basePath={basePath}
                    exporter={false}
                    pagination={<CustomPagination loading={loading} rowsPerPageOptions={[25]} />}
                    filter={{ query: query, exactMatch: exactMatch, type: type, offering: type }}
                    resource="internal-search"
                    actions={null}
                >
                    <CustomGrid 
                        type={type} 
                        loading={loading} 
                        setLoading={setLoading} 
                        theme={theme} 
                        value={value} 
                        setValue={setValue} 
                        search={search} 
                    />
                </DefaultList>
            }
        </div>
    );
}

export default compose(
    connect(
        state => ({
            theme: state.theme
        }),
    )
)(InternalSearch);
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import decodeJwt from 'jwt-decode';
import { isAllowed } from "./utilities";

export default (type, params, setLanguage, setChatUser) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        let url = `${process.env.REACT_APP_UAA_URL}/token?grant_type=password&username=${username}&password=${password}`;
        const request = new Request(url, {
            method: 'POST',
            headers: new Headers({ 'Authorization': 'Basic d2ViLWFwcGxpY2F0aW9uOnNlY3JldA==' }),
        })
        return fetch(request)
            .then(response =>  response.json().then(data => ({ status: response.status, body: data })))
            .then((response) => {

                if (response.status < 200 || response.status >= 300) {

                    if (response.body.error_description && response.body.error_description === 'LOCKED') {
                        throw new Error(response.body.error_description);
                    } else {
                        throw new Error();
                    }
                }

                const token = response.body;
                const decodedToken = decodeJwt(token.access_token);

                let expiresIn = new Date();
                let lastActivity = new Date();
                expiresIn.setSeconds(expiresIn.getSeconds() + token.expires_in);
                localStorage.setItem('expires_in', expiresIn);
                localStorage.setItem('last_activity', lastActivity);
                localStorage.removeItem('is_session_expired');
                localStorage.setItem('refresh_token', token.refresh_token);

                localStorage.setItem('user_name', decodedToken.user_name);
                localStorage.setItem('token', token.access_token);
                localStorage.setItem('decoded_token', JSON.stringify(decodedToken));

                localStorage.setItem('conditions_accepted', decodedToken.conditions_accepted);

                // Set language from DB.
                if (decodedToken.language != null && decodedToken.language != '') {
                    var oldLanguage = localStorage.getItem("language");
                    var newLanguage = decodedToken.language.toLowerCase();

                    if (newLanguage != null && oldLanguage != newLanguage) {
                        localStorage.setItem('language', newLanguage);
                        setLanguage(newLanguage);
                    }
                }

                if (!decodedToken.authorities.includes('ROLE_CONSUMER')) {

                    // Redirect to another web-application

                    if (decodedToken.authorities.includes('ROLE_ADMINISTRATOR')) {
                        window.location.replace(`${process.env.REACT_APP_CRM_HOST_URL}`);
                        return Promise.reject();

                    } else if (decodedToken.authorities.includes('ROLE_PROVIDER') 
                            || decodedToken.authorities.includes('ROLE_ANONYMOUS_PROVIDER')
                            || decodedToken.authorities.includes('ROLE_PROVIDER_FREE')
                            || decodedToken.authorities.includes('ROLE_PROVIDER_BASIC')
                            || decodedToken.authorities.includes('ROLE_PROVIDER_ADVANCED')) {
                        window.location.replace(`${process.env.REACT_APP_PROVIDER_HOST_URL}`);
                        return Promise.reject();
                    } else {
                        throw new Error('Access to this resource is denied for this account type!');
                    }

                } else {

                    if (!isAllowed(decodedToken.company_id)) {
                        throw new Error('Access to this resource is denied for this account type!');
                    }
                }
                return Promise.resolve();
            });
    }

    if (type === AUTH_LOGOUT) {
        cleanLocalStorage();
        
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {

        const status = params.status;
        if (status === 401 || status === 403) {
            cleanLocalStorage();

            if (!window.location.href.includes('/login')) {
                localStorage.setItem('referer', window.location.href);
            }

            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {

        const allowed = ['/user-activation', '/email-activation', '/password-activation', '/password-recovery', '/complete-verification', '/provider-registration'];
        if (allowed.some(url => window.location.href.includes(url))) {

            if (isUrlRaw(window.location.href)) {
                window.location.replace(cleanUrl(window.location.href));
            }
            return Promise.resolve();
        }

        const decodedToken = localStorage.getItem('decoded_token');
        if (decodedToken === null) {
            if (!window.location.href.includes('/login')) {
                localStorage.setItem('referer', window.location.href);
            }

            return Promise.reject();
        }

        const authorities = JSON.parse(decodedToken).authorities;

        // Temporary solution we need to define a list of allowed pages.
        if (authorities.includes('ROLE_CONSUMER') || authorities.includes('ROLE_ADMINISTRATOR')) {

            if (!isAllowed(JSON.parse(decodedToken).company_id)) {

                console.log('reject: ', JSON.parse(decodedToken).company_id);
                
                return Promise.reject();
            }
            
            return Promise.resolve();
        } else {

            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                // Development
                // No possibility to share "localStorage" for different ports (no sense to redirect)
                return Promise.reject();
            } else {
                // Production
                if (authorities.includes('ROLE_ADMINISTRATOR')) {

                    // A set of pages that are allowed to be seen by administrator
                    const allowedForAdministrators = ['/show'];
                        
                    if (allowedForAdministrators.some(url => window.location.href.includes(url))) {
                        return Promise.resolve();
                    } else {
                        window.location.replace(`${process.env.REACT_APP_CRM_HOST_URL}`);
                        return Promise.reject();
                    }

                } else if (authorities.includes('ROLE_PROVIDER') 
                        || authorities.includes('ROLE_ANONYMOUS_PROVIDER')
                        || authorities.includes('ROLE_PROVIDER_FREE')
                        || authorities.includes('ROLE_PROVIDER_BASIC')
                        || authorities.includes('ROLE_PROVIDER_ADVANCED')) {

                    window.location.replace(`${process.env.REACT_APP_PROVIDER_HOST_URL}`);
                    return Promise.reject();
                } else {

                    return Promise.reject();
                }
            }
        }
    }
    if (type === AUTH_GET_PERMISSIONS) {

        const allowed = ['/user-activation', '/email-activation', '/password-activation', '/password-recovery', '/complete-verification', '/provider-registration'];
        if (allowed.some(url => window.location.href.includes(url))) {

            if (isUrlRaw(window.location.href)) {
                window.location.replace(cleanUrl(window.location.href));
            }
            return Promise.resolve();
        }

        if (!JSON.parse(localStorage.getItem('decoded_token'))) {
            return Promise.reject();
        }

        const authorities = JSON.parse(localStorage.getItem('decoded_token')).authorities;
        return authorities ? Promise.resolve(authorities) : Promise.reject();
    }
    return Promise.reject('Unknown method');
};

function cleanUrl(url) {

    return url.replace(/(dashboard)\/.+#\//, "\$1/#/");
}

function isUrlRaw(url) {

    return /dashboard\/.+#\//i.test(url);
}

function cleanLocalStorage() {

    localStorage.removeItem('expires_in');
    localStorage.removeItem('last_activity');
    localStorage.removeItem('is_session_expired');

    localStorage.removeItem('token');
    localStorage.removeItem('user_name');
    localStorage.removeItem('decoded_token');
    localStorage.removeItem('language');

    localStorage.removeItem('conditions_accepted');
    localStorage.removeItem('chat_user');
}
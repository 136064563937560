import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Divider from '@material-ui/core/Divider';
import { faCloud, faChartBar, faBrain, faNetworkWired, faShieldAlt, faVoicemail, faFileCode, faDatabase, faChartPie, faTh } from '@fortawesome/free-solid-svg-icons'

import Grid from "@material-ui/core/Grid";
import MediaCardServicenow from "./MediaCardServicenow";
import MediaCardSap from "./MediaCardSap";
import MediaCardAws from "./MediaCardAws";


import MediaCardApi from "./MediaCardApi";
import MediaCardLibs from "./MediaCardLibs";

import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteForeverSharpIcon from '@material-ui/icons/DeleteForeverSharp';

const mainDivStyle = {
  position: 'fixed',
  top: '14%',
  left: '19%',
};


const main2DivStyle = {
  position: 'fixed',
  top: '54%',
  left: '19%',
};

const spanTitle = {
  color: '#64c7f3',
  fontWeight: 'bold',
};

const approachTitle = {
  color: '#64c7f3',
  fontWeight: 'bold',
  fontSize: '300%',
};

const logos = {
  maxWidth: 1345,
  display: 'inline-block',
  border: '1px solid #92316',
  marginTop: '20px',
};


const useNavStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

function IconBreadcrumbs() {
  const classes = useNavStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge`} >
            Knowledge Center
          </Link>
          <Typography color="textPrimary">Ecosystems & Integration</Typography>
        </Breadcrumbs>
      </Paper>
    </div>
  );
}

class Ecosystems extends React.Component {

  render() {
    return (
      <div >
        <IconBreadcrumbs />
        <br />
        <div>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <DeveloperBoardIcon />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h7" >
                You are using an IT service mangement platform?
                <span style={spanTitle}> knooing is available soon in the following platforms: </span>
              </Typography>
              <br />
              <Typography variant="h7" >
                -> get more from your existing system landscape: optimize your solutions and find best partners in an easy way
            </Typography>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={24}>
            <Grid item md={3}>
              <MediaCardServicenow />
            </Grid>
            <Grid item md={3}>
              <MediaCardSap />
            </Grid>
            <Grid item md={3}>
              <MediaCardAws />
            </Grid>
          </Grid>
        </div>

        <br />
        <br />
        <Divider />
        <br />
        <br />


                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                      <DeveloperBoardIcon />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="h7" >
                        You prefer to have knooing in your individual system landscape?
                        <span style={spanTitle}> knooing prepared aleady various approaches: </span>
                      </Typography>
                      <br />
                      <Typography variant="h7" >
                    </Typography>
                    </Grid>
                  </Grid>


                  <br />
                            <br />
                            <Grid container spacing={24}>
                              <Grid item md={3}>
                                <MediaCardApi />
                              </Grid>
                              <Grid item md={3}>
                                <MediaCardLibs />
                              </Grid>
                            </Grid>

      </div>
    );
  }
}

export default Ecosystems;
import React from 'react';
import { browserHistory } from 'react-router'

import LinearBuffer from './LinearBuffer.js';


class ProjectMessageWait extends React.Component {


      componentDidMount() {
          this.interval = setInterval(() => {
            window.history.go(-2);
          }, 1200);
        }

     componentWillUnmount() {
         clearInterval(this.interval);
     }

      render() {

        return (
          <div>


            <LinearBuffer />

          </div>
        );
      }

}


export default ProjectMessageWait;

import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { useNotify, usePermissions, useTranslate, required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red, blue } from '@material-ui/core/colors';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Divider from '@material-ui/core/Divider';

import ExpandIcon from '@material-ui/icons/ExpandMore';
import MoreIcon from '@material-ui/icons/MoreVert';

import { Form, Field } from 'react-final-form'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const useStyles = makeStyles(theme => ({
    card: {
        boxShadow: 'none',
    },
    cardContent: {
        paddingTop: 0,
    },
    cardActions: {
        padding: '0px 16px 8px 16px',

    },
    avatarRed: {
        backgroundColor: red[500],
    },
    avatarBlue: {
        backgroundColor: blue[500],
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    expanded: {
        transform: 'rotate(180deg) !important',
    },
    notExpanded: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
}));

const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <TextField
        variant="outlined"
        fullWidth
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
);

const CustomAvatar = ({ message }) => {

    const classes = useStyles();

    let initials = null;

    if (message.sender != null) {
        let fullName = message.sender.toUpperCase().split(' ');
        initials = fullName[0].charAt(0);
        if (fullName[1]) {
            initials = initials + fullName[1].charAt(0)
        }
    }

    return (
        <Avatar className={message.type === 'CRM_TO_USER' ? classes.avatarRed : null}>
            {initials}
        </Avatar>
    )
}

export const SuggestionMessage = ({ record, project }) => {

    const [messages, setMessages] = useState([]);
    const [expanded, setExpanded] = useState(true);

    const [focused, setFocused] = useState(false);

    const classes = useStyles();
    const notify = useNotify();
    const { permissions } = usePermissions();

    const translate = useTranslate();

    const [value, setValue] = useState('');

    const isFeedback = (message) => {

        return message.type === 'DIRECT_CONTACT_TO_PROVIDER' 
                || message.type === 'NOT_FIT_PROJECT' 
                || message.type === 'NOT_MEET_SOME_REQUIREMENTS';
    }

    const showText = (message) => {

        let text = null;

        switch (message.type) {
            case 'DIRECT_CONTACT_TO_PROVIDER':
                text = translate('resources.suggestions.dialog.field_values.feedback.option_5');
                if (message.text) {
                    text = text + " - " + message.text;
                }
                break;
            case 'NOT_FIT_PROJECT':
                text = translate('resources.suggestions.dialog.field_values.feedback.option_1');
                if (message.text) {
                    text = text + " - " + message.text;
                }
                break;
            case 'NOT_MEET_SOME_REQUIREMENTS':
                text = translate('resources.suggestions.dialog.field_values.feedback.option_2');
                if (message.text) {
                    text = text + " - " + message.text;
                }
                break;
            default:
                text = message.text;
                break;
        }

        return text;
    }

    const sendMessage = (values, form) => {

        console.log('values: ', values);

        const token = localStorage.getItem('token');

        const type = 'USER_TO_CRM';

        fetch(`${process.env.REACT_APP_API_URL}/suggestions/${record.id}/messages`, { 
                method: 'POST', 
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
                body: JSON.stringify({...values, type: type })
            })
            .then(response => response.json())
            .then(message => {

                setMessages([...messages, message]);
                setTimeout(() => clearForm(form));
        
                notify('The message has been sent successfully!');
            })
            .catch((error) => {
                const isString = Object.prototype.toString.call(error.message) == '[object String]';

                var message = error.message;

                if (!isString) {
                    message = Object.entries(error.message)
                            .map(([key, value]) => value)
                            .join('\n');
                }

                notify(message, 'warning');
            });
    }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    const clearForm = (form) => {
        form.reset();
        setValue('');
        setFocused(false);
    }

    useEffect(() => {
        
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_API_URL}/suggestions/${record.id}/messages`, { 
                method: 'GET', 
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            })
            .then(response => response.json())
            .then((messages) => {
                // console.log('messages: ', messages);
                setMessages(messages);
            })
            .catch((exception) => {

            });

    }, []);

    return (
        <div style={{ width: '100%' }}>

            <Button 
                size="small" 
                variant="text" 
                color="primary" 
                onClick={toggleExpanded}
            >
                {translate('resources.messages.plural_name')} / Feedback
                <ExpandIcon 
                    className={clsx(classes.notExpanded, {
                        [classes.expanded]: expanded,
                    })} 
                />
            </Button>

            <Collapse in={expanded}>
                {messages.sort((one, two) => new Date(one.createdAt) - new Date(two.createdAt)).map(message => {

                    return (
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <CustomAvatar message={message} />
                                }
                                // action={
                                //     <IconButton aria-label="settings">
                                //         <MoreIcon />
                                //     </IconButton>
                                // }
                                title={message.sender}
                                subheader={new Date(message.createdAt).toLocaleString()}
                            />
                            <CardContent className={classes.cardContent}>
                                {isFeedback(message) && <span class="MuiTypography-colorTextSecondary MuiTypography-body2" style={{ fontWeight: 'bold' }}>Feedback: </span>}
                                <Typography style={{ display: 'inline-block' }} variant="body2" color="textSecondary" component="p">
                                    <div dangerouslySetInnerHTML={{ __html: showText(message) }} />
                                </Typography>
                            </CardContent>
                        </Card>
                    )
                    })
                }

                {/* <Divider variant="middle" /> */}

                {!project.archived && 
                    <Form
                        onSubmit={sendMessage}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {
                            
                            return (
                                <form id="message-form" onSubmit={event => handleSubmit(event, form)}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            {/* <Field
                                                name="text"
                                                component={renderTextField}
                                                type="text"
                                                placeholder={translate('resources.project_messages.action.create')}
                                                onFocus={() => setFocused(true)}
                                            /> */}
                                            {/* <RichTextInput placeholder={translate('resources.project_messages.action.create')} source="text" label="" /> */}

                                            {!focused && <div id="message-placeholder" onClick={() => setFocused(true)} style={{ backgroundColor: 'rgb(244, 245, 247)', color: 'rgb(153, 153, 153)', width: '100%', display: 'flex', alignItems: 'center', cursor: 'text', padding: 10 }}>{translate('resources.project_messages.action.create')}</div>}

                                            {focused && 
                                                <Field
                                                    name="text"
                                                    // validate={required}
                                                    placeholder={translate('resources.project_messages.action.create')}
                                                    render={({ input, meta, ...props }) => {
                                                        if (meta.touched && meta.error){

                                                        }
                                                        return (
                                                            <div>
                                                                <style dangerouslySetInnerHTML = {{__html: `
                                                                    .ck-editor__editable {
                                                                        min-height: 80px;
                                                                    }
                                                                    .ck.ck-editor__main>.ck-editor__editable{
                                                                        background: none;
                                                                    }
                                                                    .ck.ck-toolbar {
                                                                        background: none;
                                                                    }
                                                                `}} />

                                                                {props.theme === 'dark' ?
                                                                    <style dangerouslySetInnerHTML = {{__html: `
                                                                        .ck.ck-editor__editable > .ck-placeholder::before {
                                                                            color: rgba(255, 255, 255, 0.7);
                                                                        }
                                                                    `}} />
                                                                    :
                                                                    <style dangerouslySetInnerHTML = {{__html: `
                                                                        .ck.ck-editor__editable > .ck-placeholder::before {
                                                                            color: rgba(0, 0, 0, 0.54);
                                                                        }
                                                                    `}} />
                                                                }
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={value}
                                                                    config={ {
                                                                        placeholder : props.placeholder,
                                                                        toolbar:  [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
                                                                    } }
                                                                    onInit={editor => {

                                                                        editor.editing.view.focus();
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setValue(data);
                                                                        input.onChange(data);
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        //console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        // console.log( 'Focus.', editor );
                                                                    }}
                                                                />

                                                                <textarea style={{ display: 'none' }} {...input} name={props.source} value={value} />
                                                                <div className={classes.error}>{meta.touched && meta.error && <span>{meta.error}</span>}</div>
                                                            </div>
                                                        )
                                                    }}
                                                /> }
                                        </CardContent>
                                        {focused && 
                                            <CardActions className={classes.cardActions}>
                                                <Button type="submit" disabled={submitting || pristine} size="small" color="primary" variant="contained">
                                                    {translate('resources.messages.action.send')}
                                                </Button>
                                                <Button type="button" onClick={() => clearForm(form)} disabled={submitting} size="small" color="primary">
                                                    {translate('ra.action.cancel')}
                                                </Button>
                                            </CardActions>
                                        }
                                    </Card>
                                </form>
                            )
                        }}
                    />
                }
            </Collapse>
        </div>
    )
}
import React from 'react';
import { Toolbar, SaveButton, SimpleForm, Edit, TextInput, BooleanInput, SelectInput, FormDataConsumer, required ,useTranslate } from 'react-admin';
import UnsavedDataObserver from '../UnsavedDataObserver';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
    </Toolbar>
);

export const UserEdit = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const message = translate('application.validation.required');

    const roles = [ { id: 'ROLE_USER', name: 'resources.users.field_values.role.user' } ];
    if (permissions && permissions.includes('ROLE_EXECUTIVE')) {
        roles.push({ id: 'ROLE_POWER_USER', name: 'resources.users.field_values.role.power_user' });
        roles.push({ id: 'ROLE_EXECUTIVE', name: 'resources.users.field_values.role.executive' });
    }

    return (
        <Edit undoable={false} title="resources.users.singular_name" {...props}>
            <SimpleForm variant="standard" margin="normal" toolbar={<CustomToolbar />}>

                <UnsavedDataObserver />

                {/* This should not happen because this page is hidden from not allowed users but if a user will try to access somehow this will throw an error */}
                <FormDataConsumer>
                    {({ formData, ...rest }) =>  {
                        if (formData.roleName !== 'ROLE_USER' && permissions && permissions.includes('ROLE_POWER_USER')) {
                            throw new Error('Forbidden!');
                        }
                    }}
                </FormDataConsumer>

                <SelectInput source="salutation" choices={[
                    { id: 'MALE', name: 'resources.users.field_values.salutation.male' },
                    { id: 'FEMALE', name: 'resources.users.field_values.salutation.female' },
                ]} 
                validate={required(message)}
                />
                <TextInput source="firstName" validate={required(message)} />
                <TextInput source="lastName" validate={required(message)} />

                <TextInput source="position" />

                <TextInput source="phone" />
                <TextInput source="mobilePhone" />

                <FormDataConsumer>
                    {({ formData, ...rest }) => permissions && permissions.includes('ROLE_EXECUTIVE') && formData.id !== Number(JSON.parse(localStorage.getItem('decoded_token')).user_name) &&
                        <BooleanInput source="locked" {...rest} />
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.id !== Number(JSON.parse(localStorage.getItem('decoded_token')).user_name) &&
                        <SelectInput source="roleName" validate={required(message)} {...rest} choices={roles} />
                    }
                </FormDataConsumer>
                <SelectInput source="jobExperience" choices={[
                    { id: 'LEVEL_0', name: 'resources.users.field_values.job_experience.level_0' },
                    { id: 'LEVEL_1', name: 'resources.users.field_values.job_experience.level_1' },
                    { id: 'LEVEL_2', name: 'resources.users.field_values.job_experience.level_2' },
                    { id: 'LEVEL_3', name: 'resources.users.field_values.job_experience.level_3' },
                    { id: 'LEVEL_4', name: 'resources.users.field_values.job_experience.level_4' },
                    { id: 'LEVEL_5', name: 'resources.users.field_values.job_experience.level_5' },
                    { id: 'UNDEFINED', name: 'resources.users.field_values.job_experience.undefined' },
                ]} />
                
                <SelectInput
                    label="resources.users.fields.language"
                    source="language"
                    optionValue="code"
                    choices={[
                        { code: 'DE', name: 'resources.users.field_values.language.DE' },
                        { code: 'EN', name: 'resources.users.field_values.language.EN' },
                    ]}
                    validate={required(message)}
                />
            </SimpleForm>
        </Edit>
    )
}
import React from 'react';
import { ArrayInput, SimpleFormIterator, Toolbar, SaveButton, SimpleForm, Edit, TextInput, BooleanInput, SelectInput, FormDataConsumer, required, useTranslate, SelectArrayInput } from 'react-admin';

import CancelIcon from '@material-ui/icons/Cancel';

import Button from '@material-ui/core/Button';
import LimitedInput from '../component/LimitedInput';
import { SupplierStatus } from './SupplierStatus';

import { makeStyles } from '@material-ui/core/styles';
import { SupplierValuation } from './SupplierValuation';
import { arrayInputChoicesOfferings } from './offerings';

const useStyles = makeStyles(theme => ({
    formIterator: {
        '& p:nth-child(1)' :{
            textAlign: 'center',
        }
    },
}));

const CustomButton = ({ color, label, onClick, icon }) => {
    return <Button startIcon={icon} variant="contained" color={color} onClick={onClick}>{label}</Button>
}

const CustomToolbar = ({ basePath, ...rest }) => {
    const translate = useTranslate();
    return (
        <Toolbar {...rest} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SaveButton undoable={false} />
            {/* <DeleteButton /> */}
            <CustomButton label={translate('ra.action.cancel')} icon={<CancelIcon />} onClick={() => document.location.href = `${process.env.REACT_APP_ROOT_FOLDER}#${basePath}`} />
        </Toolbar>
    )
}

export const RecommendationEdit = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const message = translate('application.validation.required');

    const basePath = "/knowledge/recommendations";

    const classes = useStyles();

    return (
        <Edit basePath={basePath} resource="recommendations" undoable={false} title="resources.recommendations.singular_name" id={props.match.params.id} {...props} title=" ">
            <SimpleForm toolbar={<CustomToolbar />} variant="standard">
                <SelectArrayInput 
                    validate={required(message)} 
                    source="offerings"
                    choices={arrayInputChoicesOfferings}
                />
                <TextInput source="providerName" validate={required(message)} />
                <TextInput source="website" validate={required(message)} />
                {parseInt(JSON.parse(localStorage.getItem('decoded_token')).company_id) === parseInt(process.env.REACT_APP_RWE_ID) &&
                    <TextInput label="Custom target URL" source="internalUrl" />
                }
                <BooleanInput source="activelyUsed" fullWidth />
                <ArrayInput source="products" className={classes.formIterator}>
                    <SimpleFormIterator>
                        <SelectInput validate={required(message)} source="offering" choices={arrayInputChoicesOfferings} />
                        <TextInput source="name" label="resources.recommendations.fields.productName" />
                        <LimitedInput source="useCase" label="resources.recommendations.fields.useCase" multiline fullWidth maxLength={4096} />
                    </SimpleFormIterator>
                </ArrayInput>
                {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) &&
                    <SupplierStatus />
                }
                {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) &&
                    <SupplierValuation />
                }
            </SimpleForm>
        </Edit>
    )
}
import React, { useState, useEffect, Fragment } from 'react';

import { useTranslate, useNotify, useLogout, useLogin } from 'react-admin';

import { render } from 'react-dom'
import { useLocation } from 'react-router-dom'

import decodeJwt from 'jwt-decode';

import { useIdleTimer } from 'react-idle-timer'
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';

const isLoginPage = () => {
    return window.location.href.includes('/login');
}

const isPermittedPage = () => {
    const permittedPages = ['/user-activation', '/email-activation', '/password-activation', '/password-recovery', '/provider-registration'];
    return permittedPages.some(url => window.location.href.includes(url));
}

const TokenValidator = () => {

    const logout = useLogout();
    const notify = useNotify();
    const location = useLocation();

    const translate = useTranslate();

    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    }

    const timeoutInMilliseconds = 180 * 60 * 1000;

    const handleOnIdle = event => {

        if (!isLoginPage() && !isPermittedPage()) {

            localStorage.setItem('referer', window.location.href);

            logout();

            localStorage.setItem('is_session_expired', true);
        }
    }

    const handleOnActive = event => {

    }

    async function handleOnAction(event) {

        if (isLoginPage() || isPermittedPage()) {
            return;
        }
        
        const expiresIn = localStorage.getItem('expires_in');
        const lastActivity = localStorage.getItem('last_activity');

        let now = new Date();

        if ((new Date(expiresIn) - new Date(now)) < timeoutInMilliseconds) {

            const refreshToken = localStorage.getItem('refresh_token');
            let url = `${process.env.REACT_APP_UAA_URL}/token?grant_type=refresh_token&refresh_token=${refreshToken}`;

            try {
                let response = await fetch(url, { 
                    method: 'POST', 
                    headers: { 'Authorization': 'Basic d2ViLWFwcGxpY2F0aW9uOnNlY3JldA==' }, 
                });
    
                if (response.status === 200) {

                    response = await response.json();
                    
                    let updated = new Date();
                    updated.setSeconds(updated.getSeconds() + response.expires_in);
                    localStorage.setItem('expires_in', updated);
    
                    localStorage.setItem('token', response.access_token);
                    localStorage.setItem('refresh_token', response.refresh_token);
                } else {

                    handleOnIdle();
                }
            } catch (error) {
                handleOnIdle();
            }
        }
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: timeoutInMilliseconds,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    // Check access token validity
    useEffect(() => {

        handleOnAction();
    }, []);

    return (
        <div></div>
    )
}
export default TokenValidator;
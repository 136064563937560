import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';

import CustomIcon from './CustomIcon';

const styles = theme => ({
    main: {
        flex: '1',
        // marginRight: '1em',
        marginTop: 20,
    },
    titleLink: { textDecoration: 'none', color: 'inherit' },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        overflowY: 'hidden',
        height: '4em',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
});

const location = {
    pathname: 'reviews',
    query: { filter: JSON.stringify({ status: 'pending' }) },
};

const PendingSuggestions = ({
    suggestions = [],
    translate,
    classes,
}) => (
    <div className={classes.main}>
        <CustomIcon Icon={ScheduleIcon} backgroundColor={'#64c7f3'} />
        <Card className={classes.card}>
            <Typography style={{ fontSize: '0.8rem' }} className={classes.title} color="textSecondary">
                {translate('application.dashboard.suggestions.name')}
            </Typography>
            <Typography
                variant="h4"
                className={classes.value}
            >
                {suggestions.length}
            </Typography>
            <Divider />
            {suggestions.length > 0 ? 
                <List style={{ maxHeight: '73.25vh', overflowY: 'auto' }}>
                    {suggestions.sort((one, two) => new Date(two.createdAt) - new Date(one.createdAt)).map(record => (
                        <ListItem
                            key={record.id}
                            button
                            component={Link}
                            to={`/projects/${record.projectId}/show/1?suggestion=${record.id}`}
                        >
                            <ListItemText
                                primary={
                                    <div style={{ verticalAlign: 'middle', fontSize: '0.8rem' }}>
                                        {!record.addedByCrm ?
                                            <Fragment>
                                                {translate('application.dashboard.suggestions.score')}: <span style={{ fontSize: 18, fontWeight: 'bold' }}>{record.score / 10}</span> / 10
                                            </Fragment>
                                            :
                                            <Typography color="secondary" variant="overline" >{translate('resources.suggestions.message.top_recommended')}</Typography>
                                        }
                                    </div>
                                }
                                secondary={record.productNeutralName}
                                // className={classes.listItemText}
                                style={{ paddingRight: 0 }}
                            />
                        </ListItem>
                    ))}
                </List>
                :
                null
            }
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(PendingSuggestions);
import React, { useState, useEffect } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { SimpleForm, CardActions, ListButton,Create, TextInput, required,email, useTranslate } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import axios from 'axios';

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { setDarkRichTextInput } from '../utilities';

const dropzoneStyle = {
    width: "50%",
};
const cardActionStyle = {
    paddingTop: '24px',
    paddingBottom: '18px',
};

export const FeedbakCreate = ({ permissions, ...props }) => {
    const [files, setData] = useState();
    const translate = useTranslate();
    const message=translate('application.validation.required');
    const emailerrmsg=translate('application.validation.email');

    useEffect(() => {
        if (props.theme === 'dark') {
            setDarkRichTextInput();
        }
    });

    function handleChange(files) {
        setData(files);
    };
    function handleSubmit(values) {
        let postUrl = process.env.REACT_APP_API_URL + "/feedbacks";
        let redirectUrl = process.env.REACT_APP_API_URL +"#/feedbacks";

        var formData = new FormData();
        formData.append('title', values.title);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('description', values.description);
        formData.append('companyId', JSON.parse(localStorage.getItem('decoded_token')).company_id);
        for (var i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }

        axios.post(postUrl,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(document.location.href = redirectUrl)
    }
    return (
        <div>
            <CardActions style={cardActionStyle} {...props}>
                <ListButton basePath={props.basePath} />
            </CardActions>
            <SimpleForm save={handleSubmit}>
                <TextInput source="title" validate={required(message)} style={{ width: '50%' }} />
                <TextInput source="email" reference="email" validate={[required(message),email(emailerrmsg)]} style={{ width: '50%' }} />
                <TextInput source="phone" reference="phone" validate={required(message)} style={{ width: '50%' }} />
                <RichTextInput source="description" validate={required(message)} />

                <div style={dropzoneStyle}>
                    <p>Upload File</p>
                    <DropzoneArea
                        filesLimit={10}
                        maxFileSize={5000000}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        onChange={handleChange}
                        dropzoneText="Drag and drop an image file here or click"
                    />
                </div>
            </SimpleForm>
        </div>
    )
}

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
);
export default enhance(FeedbakCreate);
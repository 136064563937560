import React from 'react';
import { ArrayInput, SimpleFormIterator, Toolbar, SaveButton, SimpleForm, Edit, TextInput, BooleanInput, SelectInput, FormDataConsumer, required, useTranslate, useRefresh, SelectArrayInput } from 'react-admin';

import CancelIcon from '@material-ui/icons/Cancel';

import Button from '@material-ui/core/Button';
import LimitedInput from '../component/LimitedInput';
import { SupplierStatus } from './SupplierStatus';

import { makeStyles } from '@material-ui/core/styles';
import { SupplierValuation } from './SupplierValuation';
import { CustomSaveButton } from './CustomSaveButton';

import { arrayInputChoicesOfferings } from './offerings';

import { isRwe, isZf, isSiemens } from '../utilities';
import CapabilityManagement from './CapabilityManagement';

const useStyles = makeStyles(theme => ({
    formIterator: {
        '& p:nth-child(1)' :{
            textAlign: 'center',
        }
    },
}));

export const sourcingClusters = [
    { id: 'Software', name: 'Software' },
    { id: 'Network', name: 'Network' },
    { id: 'Services', name: 'Services' },
    { id: 'Hardware', name: 'Hardware' },
    { id: 'Infrastructure', name: 'Infrastructure' },
];

export const regions = [
    { id: 'EMEA', name: 'EMEA' },
    { id: 'AMERICAS', name: 'AMERICAS' },
    { id: 'APAC', name: 'APAC' },
];

export const operationCompanies = [
    { id: 'Generation', name: 'Generation' },
    { id: 'Power', name: 'Power' },
    { id: 'Renewables', name: 'Renewables' },
    { id: 'RWEST', name: 'RWEST' },
];

export const segments = [
    { id: 'TOP', name: 'resources.recommendations.field_values.status.top' },
    { id: 'PREFERRED', name: 'resources.recommendations.field_values.status.preferred' },
    { id: 'NICHE', name: 'resources.recommendations.field_values.status.niche' },
    { id: 'OTHER', name: 'resources.recommendations.field_values.status.other' },
];

export const statuses = [
    { id: 'Predefined', name: 'Predefined' },
    { id: 'Aligned', name: 'Aligned' },
    { id: 'Open', name: 'Open' },
]

export const contractTypes = [
    { id: 'T+M', name: 'T+M' },
    { id: 'FixPrice', name: 'FixPrice' },
    { id: 'SLA', name: 'SLA' },
];

export const spendTypes = [
    { id: 'IT spend', name: 'IT spend' },
    { id: 'local spend', name: 'local spend' },
];

const CustomButton = ({ color, label, onClick, icon }) => {
    return <Button startIcon={icon} variant="contained" color={color} onClick={onClick}>{label}</Button>
}

const CustomToolbar = (props) => {
    const translate = useTranslate();
    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
            {props.editMode ? <SaveButton undoable={false} /> : <CustomSaveButton undoable={false} />}
            <CustomButton label={translate('ra.action.cancel')} icon={<CancelIcon />} onClick={() => document.location.href = `${process.env.REACT_APP_ROOT_FOLDER}#${props.basePath}`} />
        </Toolbar>
    )
}

export const ItVendorForm = ({ permissions, editMode, ...props }) => {
    const translate = useTranslate();
    const refresh = useRefresh();
    const message = translate('application.validation.required');

    const classes = useStyles();

    let defaultValue = null;
    if (!editMode) {
        defaultValue = {
            companyId : JSON.parse(localStorage.getItem('decoded_token')).company_id,
            offerings: ['SERVICE'],
            activelyUsed: true,
        };
    }

    return (
        <SimpleForm {...props} toolbar={<CustomToolbar editMode={editMode} />} defaultValue={defaultValue} variant="standard">
            {/* This should not happen because this page is hidden from not allowed users but if a user will try to access somehow this will throw an error */}
            <FormDataConsumer>
                {({ formData, ...rest }) =>  {
                    if (permissions && (permissions.includes('ROLE_USER'))) {
                        throw new Error('Forbidden!');
                    }
                }}
            </FormDataConsumer>

            
            {isRwe() && editMode && (
                <CapabilityManagement refresh={refresh} />
            )}
            
            {isSiemens() && <TextInput source="attribute" label="resources.recommendations.fields.supplier_number" />}

            {isRwe() && <TextInput source="itVendorData.id" label="resources.recommendations.it_vendor.id" validate={required(message)} />}
            
            <TextInput source="providerName" label="resources.recommendations.it_vendor.vendor_name" validate={required(message)} />
            {isRwe() && <TextInput source="itVendorData.dunsGlobalName" label="resources.recommendations.it_vendor.duns_global_name" />}
            <TextInput source="website" validate={required(message)} />
            {isRwe() && permissions && (permissions.includes('ROLE_EXECUTIVE')) && 
                <TextInput label="Custom target URL" source="internalUrl" />
            }
            {isRwe() && permissions && (permissions.includes('ROLE_EXECUTIVE')) &&
                <SupplierValuation />
            }
            
            {isZf() && <TextInput label="Plant" source="itVendorData.plant" />}
            {isZf() && <SelectInput label="Spend type" source="itVendorData.spendType" choices={spendTypes} />}
            <ArrayInput label="resources.recommendations.fields.products" source="products" className={classes.formIterator}>
                <SimpleFormIterator>
                    <SelectInput label="resources.recommendations.fields.offering" source="offering" choices={arrayInputChoicesOfferings} />
                    <TextInput source="name" label="resources.recommendations.fields.productName" />
                    <LimitedInput source="useCase" label="resources.recommendations.fields.useCase" multiline fullWidth maxLength={4096} />
                </SimpleFormIterator>
            </ArrayInput>
            <SelectInput 
                validate={required(message)} 
                label="resources.recommendations.it_vendor.segment"
                defaultValue={'NICHE'}
                source="status"
                choices={segments}
            />
            {isRwe() && (
                <SelectArrayInput 
                    validate={required(message)} 
                    label="resources.recommendations.it_vendor.internal_company"
                    source="operationCompanies"
                    choices={operationCompanies}
                    translateChoice={false}
                />
            )}
            {isRwe() && <TextInput label="resources.recommendations.it_vendor.mentor" source="itVendorData.mentor" />}
            {isRwe() && <TextInput label="resources.recommendations.it_vendor.management" source="itVendorData.management" />}
            {isRwe() && (
                <SelectArrayInput 
                    validate={required(message)} 
                    label="resources.recommendations.it_vendor.region"
                    source="itVendorData.regions"
                    choices={regions}
                    translateChoice={false}
                />
            )}
            {isRwe() && (
                <SelectInput 
                    label="resources.recommendations.it_vendor.status"
                    validate={required(message)} 
                    source="itVendorData.status"
                    choices={statuses}
                    translateChoice={false}
                />
            )}
            {isRwe() && <TextInput label="resources.recommendations.it_vendor.ofa" source="itVendorData.ofa" />}
            {isRwe() && (
                <SelectArrayInput 
                    validate={required(message)} 
                    label="resources.recommendations.it_vendor.sourcing_cluster" 
                    source="itVendorData.sourcingClusters"
                    choices={sourcingClusters}
                    translateChoice={false}
                />
            )}
            {isRwe() && (
                <SelectArrayInput 
                    validate={required(message)} 
                    label="resources.recommendations.it_vendor.contract_type" 
                    source="itVendorData.contractTypes"
                    choices={contractTypes}
                    translateChoice={false}
                />
            )}
            <BooleanInput label="resources.recommendations.fields.blocked" source="blocked" />
            <FormDataConsumer>
                {({ formData, ...rest }) =>  {
                    if (formData.blocked) {
                        return <TextInput fullWidth variant="standard" label="resources.recommendations.fields.blockedComment" source="blockedComment" />
                    }
                }}
            </FormDataConsumer>
        </SimpleForm>
    )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const imageUrl = `${process.env.REACT_APP_ROOT_FOLDER}images/servicenow.jpg`;

export default function MediaCardLibs() {
  const classes = useStyles();



  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="images/libs-modules.png"
          title="Libs/ Connectors"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Libs / Modules
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
             Most functionalities are avialable as libs or connectors in the popular programming language e.g. Java, Python, .NET.
            <br/> <br/>
            <br/>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

import React, { Component, Fragment, useState, useEffect, cloneElement } from 'react';
import { Show, SimpleShowLayout, UrlField, Pagination, ReferenceManyField, useNotify, DateField, SelectField, TopToolbar, ReferenceField, ReferenceArrayField, SingleFieldList, List, Datagrid, TextField, useTranslate, sanitizeListRestProps } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import classnames from 'classnames';

import { 
    Select, 
    MenuItem, 
    FormControlLabel, 
    Typography, 
    Chip, 
    Icon, 
    Tooltip, 
    Button,
} from "@material-ui/core";

import withStyles from '@material-ui/core/styles/withStyles';

import { Matrix } from './Matrix'

const useStyles = makeStyles(theme => ({
    root: {
        height: '20em',
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftColumn: { flex: 1, marginRight: '1em' },
    rightColumn: { flex: 1, marginLeft: '1em' },
    singleColumn: { marginBottom: '2em' },
    fab: {
        margin: theme.spacing(1),
        backgroundColor: '#304d75'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'start',
        width: '100%'
    },
}));

const Expand = (props) => {

    const classes = useStyles();

    return (

        <Show
            {...props}
            title=" "
        >
            <SimpleShowLayout>
                <UrlField source="url" label="Top hit URL" />
                <DateField source="crawledAt"/>
            </SimpleShowLayout>
        </Show>
    )
};

export const ExternalSolution = (props) => {

    const { record } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [viewMode, setViewMode] = useState('DESCRIPTION');

    return (
        <Fragment>

            <div style={{ marginTop: 16, marginBottom: 16 }}>
                <Typography style={{ fontSize: '0.875rem', fontWeight: 'bold', color: '#39a1de' }} variant="button">{translate('resources.projects.meta_matching.title_1')}</Typography>
            </div>

            <Typography component="p" style={{ fontSize: '0.875rem' }}>{translate('resources.projects.meta_matching.text_1_part_1')}</Typography>
            <Typography component="p" style={{ fontSize: '0.875rem' }}>
                {translate('resources.projects.meta_matching.text_1_part_2')}
                <span style={{ fontWeight: 'bold' }}>{translate('resources.projects.meta_matching.text_1_part_3')}</span>
                {translate('resources.projects.meta_matching.text_1_part_4')}
            </Typography>
            <Typography component="p" style={{ marginBottom: 16, fontSize: '0.875rem' }}>{translate('resources.projects.meta_matching.text_1_part_5')}</Typography>

            <div style={{ paddingTop: 24, paddingBottom: '0.875rem' }}>
                <Typography style={{ fontSize: '0.875rem', fontWeight: 'bold', color: '#39a1de' }} variant="button">{translate('resources.projects.meta_matching.title_2')}</Typography>
            </div>

            {record.externalMatrixDisplayed && viewMode === 'DESCRIPTION' &&
                <Button style={{ marginBottom: 15 }} variant="outlined" onClick={() => setViewMode('MATRIX')}>{translate('resources.suggestions.action.show_matrix')}</Button>
            }

            {viewMode === 'MATRIX' && 
                <div style={{ marginBottom: 25 }}>
                    <Button style={{ marginBottom: 15 }} variant="outlined" onClick={() => setViewMode('DESCRIPTION')}>{translate('resources.suggestions.action.hide_matrix')}</Button>
                    <Matrix record={record} type="external-matrix" />
                </div>
            }

            <ReferenceManyField 
                {...props}
                reference="external-solutions" 
                target= "projectId" 
                allowEmpty
                perPage= {250}
                filter={{ feedback: ['FIXED'] }} 
                sort={{ field: 'score', order: 'DESC' }}
            >
                <Datagrid>
                    <TextField source="score" label="resources.projects.meta_matching.score" />
                    <UrlField source="companyUrl" label="resources.projects.meta_matching.company" />
                    <DateField source="crawledAt" lable="resources.projects.meta_matching.last_updated"/>
                    <UrlField source="url" label="resources.projects.meta_matching.top_hit_url" target="_blank" />
                </Datagrid>
            </ReferenceManyField>
            
            <Typography component="p" style={{ marginTop: 25, fontSize: '0.875rem' }}>{translate('resources.projects.meta_matching.text_2_part_1')}</Typography>
            <Typography component="p" style={{ fontSize: '0.875rem' }}>{translate('resources.projects.meta_matching.text_2_part_2')}<a href={`mailto:kontakt@knooing.com&subject=knooing | META MATCHING`}>kontakt@knooing.com</a></Typography>
        </Fragment>
    );
}
import germanMessages from "ra-language-german";

export default {
  ...germanMessages,
  resources: {
    messages: {
      singular_name: "Nachricht",
      plural_name: "Nachrichten",
      name: "Nachricht |||| Nachrichten",
      fields: {
        subject: "Thema",
        suggestion: {
          id: "Projekt"
        },
        product: {
          id: "Produkt / Bedienung"
        },
        recipient: "Empfänger",
        attachments: "Zugehörige Dateien"
      },
      field_values: {
        subject: {
          accepted_product_question: "Mehr Informationen anfordern",
          provider_question: "Lernen Sie diesen Anbieter kennen",
          project_question:
            "Fordern Sie weitere Informationen zu einem Projekt an",
          product_question:
            "Probleme im Zusammenhang mit Produkt-/ Dienstleistungsveröffentlichungen",
          company_question: "Unternehmens-/ Mitarbeiterführung",
          other: "Andere"
        },
        recipient: {
          project_owner: "Projektverantwortlicher",
          administrator: "Administrator"
        }
      },
      action: {
        send: "Senden"
      },
      notification: {
        sent_success: "Nachricht wurde gesendet.",
        sent_error: "Fehler: Nachricht konnte nicht versendet werden."
      }
    },
    project_messages: {
      fields: {
        message: "Nachricht",
        type: "Typ"
      },
      action: {
        create: "Eine Nachricht schreiben..."
      },
      message: {
        dialog_content:
          "Ihr Business Consultant wird Ihnen so schnell wie möglich antworten.",
        file_upload:
          "Eine Datei zum Hochladen ablegen oder auf die Schaltfläche klicken, um sie auszuwählen.",
        information:
          "Hier können Sie Ihren persönlichen Business Consultant kontaktieren, wenn Sie Fragen rund um Ihr Projekt und die vorgeschlagenen Lösungen haben. Ihr Business Consultant wird ebenfalls als Vermittler zwischen Ihnen und dem Anbieter Ihrer gewählten Lösung agieren.",
        no_messages: "Noch keine Nachrichten"
      },
      USER_TO_CRM: "Gesendet",
      CRM_TO_USER: "Empfangen"
    },
    recommendations: {
      singular_name: "Meine Empfehlung",
      plural_name: "Meine Empfehlungen",
      name: "Meine Empfehlung |||| Meine Empfehlungen",
      fields: {
        providerName: "Unternehmensname",
        product_name: "Product or Service",
        product_name_long: "Name des Produkts oder Services (optional)",
        productName: "Produktname",
        website: "Website",
        further_information: "Further Information",
        actively_used_long: "Verwendet Ihr Unternehmen aktiv das Produkt oder den Service?",
        activelyUsed: "Aktiv in Verwendung",
        blocked: "Gesperrt",
        blockedAt: "Gesperrtes Datum",
        blockedBy: "Gesperrt von",
        blockedComment: "Kommentar",
        useCase: "Einsatzzweck",
        status: "Lieferanten Status",
        createdAt: "Erstellt",
        updatedAt: "Zuletzt geändert",
        products: "Produkte oder Leistungen",
        offerings: "Angebot",
        offering: "Angebotsart",
        supplier_number: "Lieferantennummer",
        state: 'Zustand',
      },
      field_values: {
          status: {
              top: 'Strategischer Lieferant',
              preferred: 'Vorzugslieferant',
              standard: 'Standard',
              niche: 'Nischenlieferant',
              other: 'anderer Lieferant',
              phase_out: 'Ausphasend',
              blocked: 'Gesperrter Lieferant',
          },          
          offerings: {
            software: 'Software',
            service: 'Dienstleistung'
          },
          state: {
            company_not_verified: 'Verifizierung steht aus',
            data_collection: 'Datensammlung',
            clarification_needed: 'Klärungsbedarf',
            available: 'Verifiziert',
          }
      },
      it_vendor: {
        plant : 'Plant',
        spendType : 'Spend type',
        valuation : 'Performance',
        valuation_0: '0 - Others',
        valuation_1: '1 - Gold',
        valuation_2: '2 - Silver',
        valuation_3: '3 - Bronze',
        internalUrl: 'Custom target URL',
        supplier_number: "Lieferantennummer",
        name: 'IT Vendor |||| IT Vendors',
        providerName: 'Vendor name',
        website: "Webseite",
        singular_name: "IT Vendor",
        id: 'Supplier Number (ERP)',
        vendor_name: 'Vendor name',
        segment: 'Segment',
        internal_company: 'Internal company',
        mentor: 'Mentor',
        management: 'Vendor management',
        region: 'Region',
        status: 'Status',
        ofa: 'OFA',
        sourcing_cluster: 'Sourcing cluster',
        contract_type: 'Contract type',
        duns_global_name: 'DUNS Global Name',
      },
      software_portfolio: {
        name: 'Software Portfolio',
        publisher_name: 'Hersteller',
        software: 'Software',
        software_name: 'Name',
      },
      action: {
        create: "EMPFEHLUNG HINZUFÜGEN",
        add: "LIEFERANT HINZUFÜGEN",
        add_it_vendor: "IT Vendor HINZUFÜGEN",
        add_publisher: "Hersteller HINZUFÜGEN",
      },
      message: {
        description: "Mit persönlichen Empfehlungen zu Lösungen und Anbietern, können wir zukünftige Matchings für Ihr Unternehmen und die gesamte Community verbessern.",
        title: "Meine abgegebenen Empfehlungen",
        empty: "Füge jetzt die erste Empfehlung hinzu",
      },
      similar_recommendations: {
        title: "Basierend auf der Eingabe haben wir festgestellt, dass ähnliche aktive Lieferanten bereits existieren",
        title_2: "Wählen Sie aus, welche Informationen aufbewahrt werden sollen",
        recommendation: "Sie können das Produkt / die Leistung zu einem der aktiven Lieferanten hinzufügen oder einen neuen erstellen:",
        new_recommendation: "Neuen aktiven Lieferanten anlegen",
      },
    },
    suggestions: {
      fields: {
        status: "Status",
        matching_score: "Matching-Score",
        not_matched: "Nicht passend",
        details: "Details"
      },
      field_values: {
        consumer_status: {
          pending: "Warten auf Ihre Antwort",
          accepted: "Genehmigung ausstehend",
          declined: "Von Ihnen abgelehnt",
          self_service: "Passend - Anbieter wird selbst kontaktiert",
        },
        provider_status: {
          pending: "Antwort des Anbieters ausstehend",
          approved: "Vom Anbieter akzeptiert",
          accepted: "Vom Anbieter akzeptiert",
          declined: "Vom Anbieter abgelehnt",
          self_service: "Sie haben Informationen in einer Nachricht erhalten",
        },
        details: {
          fit_less_100: "Diese Lösung enthält keine Informationen über",
          fit_100: "Diese Lösung passt zu Ihrer Anfrage"
        }
      },
      matrix: {
        synonyms: "Synonyme",
        source: "Quelle",
        excerpt: "Textauszug",
        criteria: "Kriterien",
        solutions: "Lösungen",
        no_details_available: "KEINE DETAILS VERFÜGBAR - Kriterium ist Teil der Meta Informationen dieser Lösung",
        criteria_tooltip: "Wird der Maus Zeiger über ein Kriterium bewegt erscheit eine Liste der im Matching verwendeten Synonyme",
        matching_score_tooltip: "Wird der Maus Zeiger über ein positives Ergebnis bewegt erscheinen weitere Details",
      },
      action: {
        accept: "Passend",
        decline: "Ablehnen",
        cancel: "Entscheidung abbrechen",
        less_information: "Weniger Informationen",
        more_information: "Mehr Informationen",
        show_matrix: "Zeige Matrix",
        show_more: "Mehr anzeigen",
        hide_matrix: "Schließe Matrix",
        show_description: "Zeige beschreibung",
        cancel_and_edit: "Abbrechen & Feeback anpassen",
        submit: "Absenden"
      },
      dialog: {
        next_step: "Vielen Dank für dein Feedback zu unseren Vorschlägen",
        accept: "Wie möchten Sie fortfahren?",
        decline: "Diesen Vorschlag ablehnen",
        cancel: "Entscheidung abbrechen",
        fields: {
          feedback: "Wähle eine Option",
          question: "Ihre Frage",
          message: "Nachricht (optional)"
        },
        field_values: {
          feedback: {
            option_1: "Der Vorschlag passt allgemein nicht zu meinem Projekt",
            option_2: "Der Vorschlag erfüllt nicht alle meine Anforderungen",
            option_3: "Die angegebenen Informationen sind nicht ausreichend",
            option_4: "Ich benötige weitere Informationen",
            option_5: "Anonymen RFI senden, um direkte Kontaktdaten zu erhalten",
            option_6: "Ich möchte den Namen des Anbieters sehen",
            option_7: "Ich möchte den Namen des Anbieters nicht mehr sehen",
            option_8: "Ich werde den Anbieter selbst kontaktieren",
            continue_suggestion_search_reviewed_accepted: "Ich benötige weitere Lösungen",
            stop_suggestion_search_reviewed_accepted: "Recherche beenden und mit ausgewählten Lösungen fortfahren",
            continue_suggestion_search_reviewed_mixed: "Recherche und Qualifizierung von weiteren Lösungen unter Berücksichtigung des Feedbacks",
            stop_suggestion_search_reviewed_mixed: "Recherche beenden und mit ausgewählten Lösungen fortfahren",
            research_completed: "Recherche stoppen und die ausgewählten Lieferanten intern prüfen",
            step_1_1: "Suchen Sie weiter in Internen Einblicken",
            step_1_2: "Suche in “Weiteren Lieferanten” fortsetzten",
            step_2: "Externe Recherche starten (Matching)",
            skip_step_1: "Strategische & Vorzugslieferanten überspringen",
            skip_step_2: "Weitere Lieferanten überspringen",
            skip_step_2_mixed: "Interne Einblicke überspringen",
            skip_mixed: "Interne Einblicke überspringen",
          }
        },
        message: {
          message_1:
            "Wählen Sie bitte aus, warum dieser Vorschlag für Sie nicht interessant ist, damit wir unser Matching verbessern können:",
          message_2: "Bitte teilen Sie uns mit, wie Sie fortfahren möchten:",
          message_3:
            "Danke für Ihre Rückmeldung! Wir werden bei dem Anbieter für Sie nachfragen und uns so schnell wie möglich wieder bei Ihnen melden. Sobald eine Antwort vorliegt, werden wir Sie benachrichtigen.",
          message_4:
            "Danke für Ihre Rückmeldung! Wir werden die nötigen Informationen an den Anbieter weiterleiten und prüfen, ob dieser verfügbar ist. Sobald eine Antwort vorliegt, werden wir Sie benachrichtigen.",
          confirmation:
            "Möchten Sie Ihre vorherige Entscheidung wirklich widerrufen?",
          reviewed_accepted: 'Da alle Vorschläge passend sind, gibt es folgende Möglichkeiten fortzufahren:',
          reviewed_mixed: 'Basierend auf den Rückmeldungen zu unseren Vorschlägen gibt es folgende Möglichkeiten fortzufahren:',
          reviewed_declined: 'Auf Basis Ihres Feedbacks wird die Recherche und Qualifizierung neuer Lösungen gestartet. Bei unklaren Anforderungen oder Feedback wird sich einer unserer Consultants direkt mit Ihnen in Verbindung setzen',
        }
      },
      dialog_one: {
        title: "MEINEN KONTAKT MIT ANBIETER TEILEN​",
        message_1: "Ich bin damit einverstanden, dass knooing folgende Kontaktdaten mit dem Anbieter teilt, damit dieser sich mit mir in Verbindung setzen kann. (Bitte wählen Sie die Daten aus, die Sie teilen möchten)​",
        message_2: "Fügen Sie eine Notiz für den Anbieter hinzu, die er zusammen mit Ihren Kontaktdaten erhält (optional): ",
        textarea_placeholder: '...geben Sie hier Ihre Nachricht ein z.B. "Bitte rufen Sie mich morgen Nachmittag an.​"',
        action_1: "ZUSTIMMEN & ABSENDEN​",
      },
      dialog_two: {
        title: "MEETING VEREINBAREN VIA KNOOING​",
        message_1: "Bitte wählen Sie Ihre bevorzugten Meeting-Optionen aus. Nach dem Absenden des Formulars werden wir mit dem Anbieter Kontakt aufnehmen und uns mit Ihnen in Verbindung setzen, sobald er antwortet.",
        message_2: "Ich stimme zu, dass knooing meine Kontaktdaten (vollständiger Name, Unternehmensdaten, Position, E-Mail, Telefonnummern) an den Anbieter weitergeben kann, um ein Treffen einzurichten.",
        fields: {
          favouredDate: "Wunschtermin *",
          alternativeDate: "Alternativer Termin",
          preferredMedium: "Bevorzugtes Medium",
          alternativeMedium: "Alternatives bevorzugtes Medium",
          comment: "Kommentar (optional)",
        },
        field_values: {
          preferredMedium: {
            option_1: "Websession",
            option_2: "Telefon",
            option_3: "Sonstiges",
          }
        },
        action_1: "ZUSTIMMEN & ABSENDEN",
        validation: {
          message_1: "Sie müssen zustimmen, Ihre Kontaktdaten zu teilen, um fortfahren zu können."
        },
      },
      contact_information: {
        name: "KONTAKTINFORMATIONEN",
        notes: "Anmerkungen",
        send_message: "EINE NACHRICHT SENDEN",
        create_pdf: "PDF erstellen",
        other_options: {
          title: "Andere Optionen",
          action_1: "MEINEN KONTAKT MIT ANBIETER TEILEN​",
          action_1_tooltip: "Sie haben Ihre Kontaktdaten bereits geteilt.",
          action_2: "MEETING VEREINBAREN VIA KNOOING​",
          action_2_tooltip: "knooing wurde bereits gebeten, ein Treffen zu vereinbaren. Sie werden benachrichtigt, sobald Updates vorliegen. Bei Fragen wenden Sie sich bitte an Ihren Unternehmensberater.",
          action_3: "ICH HABE WEITERE FRAGEN​",
        },
        message: {
          message_1: "Der Anbieter hat bereits knooing angefragt, ein Meeting zu arrangieren. Sie werden demnächst von einem unserer Business Consultants mit weiteren Informationen kontaktiert.",
          primary_text:
            "Benötigen Sie noch weitere Informationen oder unsere Hilfe bei der Terminvereinbarung?",
          secondary_text: "Schreiben Sie uns eine Nachricht"
        }
      },
      message: {
        top_recommended: "Top empfohlen",
        no_matching:
          "Ihre Anfrage wurde erfolgreich erstellt! Leider konnte nach aktuellem Kenntnisstand kein Matching stattfinden. Einer unserer Business Consultants wird Ihre Anfrage weiterhin bearbeiten und sich mit Ihnen in Verbindung setzen."
      },
      notification: {
        accepted_success: "Produkt wurde angenommen",
        accepted_error: "Fehler: Produkt konnte nicht angenommen werden",
        declined_success: "Produkt wurde abgelehnt",
        declined_error: "Fehler: Produkt konnte nicht abgelehnt werden",
        shared_success: "Ihre Kontaktdetails wurden erfolgreich an den Anbieter weitergegeben",
        appointment_created_success: "Ihre Anfrage wurde erfolgreich gesendet",
      },
      validation: {
        message_1: 'Sie müssen zumindest "Unternehmensdaten" und "E-Mail" auswählen, um fortzufahren.',
        message_2: 'Sie müssen zumindest Ihre  Email oder Telefon- oder Mobilnummer auswählen um fortzufahren.'
      }
    },
    companies: {
      name: "Unternehmensdaten",
      fields: {
        name: "Name",
        description: "Beschreibung (Kurzprofil)",
        address: "Adresse (Hauptsitz)",
        website: "Webseite",
        topics: "Themenbereiche (max. 5)",
        size: "Anzahl der Mitarbeiter",
        foundedAt: "Gründungsdatum"
      },
      field_values: {
        size: {
          less_than_10: "1 - 9",
          less_than_50: "10 - 49",
          less_than_100: "50 - 99",
          less_than_500: "100 - 499",
          less_than_1K: "500 - 999",
          less_than_5K: "1000 - 4999",
          less_than_10K: "5000 - 9999",
          greater_than_10K: "10000+"
        }
      }
    },
    users: {
      singular_name: "Mitarbeiter",
      name: "Mitarbeiter |||| Mitarbeiter",
      fields: {
        salutation: "Anrede",
        firstName: "Vorname",
        lastName: "Nachname",
        position: "Position",
        email: "Email",
        phone: "Telefon",
        mobilePhone: "Mobiltelefon",
        role: {
          name: "Rolle"
        },
        roleName: "Rolle",
        jobExperience: "Berufserfahrung",
        notificationOptions: {
          agent: "Erhalten Sie Agentenbenachrichtigungen"
        },
        enabled: "Anmeldung bestätigt",
        enabledAt: "Registrierungsdatum",
        locked: "Gesperrt",
        createdAt: "Erstellungsdatum",
        language: "Sprache"
      },
      field_values: {
        salutation: {
          male: "Herr",
          female: "Frau"
        },
        role: {
          user: "Benutzer",
          power_user: "Hauptbenutzer",
          executive: "Führungskraft"
        },
        job_experience: {
          level_0: "Student oder Praktikant",
          level_1: "Berufseinsteiger (Junior)",
          level_2: "Angestellter mit Berufserfahrung (Senior)",
          level_3: "Abteilungsleiter",
          level_4: "Bereichsleiter",
          level_5: "Geschäftsführer",
          undefined: "Andere"
        },
        language: {
          DE : 'Deutsch',
          EN : 'English',   
        },
      },
      action: {
        new_user: "NEUER NUTZER",
        send_invitation: "EINLADUNG SENDEN",
      },
      message: {
        delete_user_title: 'Lösche Nutzer: %{full_name}',
        delete_user_content: 'Sind Sie sicher, dass Sie diesen Nutzer löschen möchten? Wenn Sie einen Nutzer versehentlich löschen, kontaktieren Sie uns innerhalb von 28 Tagen, damit wir die Informationen wiederherstellen können. Nach 28 Tagen werden diese Daten endgültig gelöscht.',
      },
    },
    projects: {
      singular_name: "Anfrage",
      name: "Anfrage |||| Anfragen",
      active_requests: "AKTIVE ANFRAGEN",
      archived_requests: "ARCHIVIERTE ANFRAGEN",
      fields: {
        title: "Titel",
        status: "Aktueller Status",
        name: "Arbeitstitel Ihres Projekts",
        description: "Beschreibung",
        topics: "Themen",
        keywords: "Schlüsselwörter",
        createdAt: "Erstellungsdatum",
        creatorId: "Erstellt von",
        updatedAt: "Zuletzt geändertes Datum",
        updaterId: "Zuletzt geändert durch",
        owner: {
          id: "Besitzer"
        },
        watchers: "Beobachter",
        state: "Aktueller Status",
        uploadDocuments: "Dokumente hochladen",
        uploadedDocuments: "Hochgeladene Dokumente",
        maximumFiles: "Die maximale Anzahl von 5 Dateien ist erreicht.",
        maximumFileSize: "Die Datei ist zu groß. Maximal 5 MB pro Datei erlaubt.",
        dropzonePlaceholder: "Nur JPEG, PNG und PDF mit einer Dateigröße von maximal 5 MB. Maximal 5 Dateien.",
        offering: "Anfrage Typ",
        offering_placeholder: "Bitte auswählen",
      },
      field_values: {
        status: {
          in_review: "In Prüfung",
          approved: "Genehmigt",
          archived: "Archiviert"
        },
        state: {
          analysis_running: "Analyse & Recherche läuft",
          matching_available: "Matching verfügbar",
          verification_running: "Überprüfung läuft",
          sourcing: "Beschaffung",
        },
        keywords: "Bitte geben Sie hier mögliche Schlüsselwörter ein.",
        description:
          "Bitte beschreiben Sie hier Ihr Projekt, welche Anforderungen Sie bereits kennen und/oder was Ihre Zielvorstellungen sind."
      },
      action: {
        next: "Weiter",
        submit: "Absenden",
        previous: "Zurück",
        archive: "Anfrage beenden",
        activate: "Aktivieren",
        add_watcher: "Beobachter hinzufügen",
        actions: "Aktionen",
        archived_projects: "ARCHIV ÖFFNEN",
        active_projects: "ZURÜCK ZU AKTIVEN ANFRAGEN",
        vendor_consolidation: "Vendorkonsolidierung",
        export_matrix: "Matrix exportieren"
      },
      vendor_consolidation: {
        description: "Basierend auf den ausgewählten Anbietern und deren Capabilities, haben wir die folgende Konsolidierungsmatrix berechnet. Die Bewertung und Kategorisierung erfolgt durch eine strategische Bewertung der Anbieter und die Kenntnis der Marktscreening-Technologie.",
        title_1: "Vendorkonsolidierung",
        description_1: "Beginnen Sie Ihre Vendorkonsolidierung mit dem Vergleich ausgewählter Vendoren und deren Fähigkeiten. Nutzen Sie Ihre strategischen Anbieterbewertungen und die knooings Marktscreening-Technologie, um einen schnellen Überblick und klaren Vergleich zu erhalten.",
        open: "ÖFFNEN",
        description_2: "Wenn Sie Owner oder Beobachter sind, können Sie bereits durchgeführte Maßnahmen zur Lieferantenkonsolidierung erneut öffnen. Um eine neue Analyse zu starten, verwenden Sie den nachfolgenden Button, wählen Sie Ihre Capabilities und die Lieferanten, die Sie vergleichen möchten.",
        create: "Erstellen",
        title: "Titel",
        owner: "Owner",
        watcher: "Beaobachter",
        created_date: "Erstellungsdatum",
        delete: "Löschen",
        actions: "AKTIONEN",
        add_watcher: "Beobachter hinzufügen",
        edit_capability: "Capability ändern",
        edit_capability_subtitle: "Fügen Sie Capabilities hinzu, indem Sie mit der Tippen beginnen und auswählen, entfernen Sie sie, indem Sie das x bei den aufgelisteten Tags verwenden.",
        edit_vendors: "Lieferanten ändern",
        refresh_result: "Ergebnis aktualisieren",
        delete_task: "Analyse löschen",
        description_3: "Basierend auf den ausgewählten Anbietern und Capabilities, haben wir die folgende Konsolidierungsmatrix berechnet. Die Bewertung und Kategorisierung erfolgt durch eine strategische Bewertung der Anbieter und die Kenntnis der Marktscreening-Technologie.",
        example_1: "Beispiel Anfrage 1"
      },
      internal_insight: {
        text_1: "Auf Basis gesammelter oder importierter Daten über %{company_name} haben wir folgende Anbieter identifiziert mit denen Sie bereits eine aktive Geschäftsbeziehung haben oder hatten.",
        text_1_only_favorite_providers_1: "Auf Basis gesammelter oder importierter Daten über %{company_name} haben wir folgende ",
        text_1_only_favorite_providers_2: "Strategischen & Vorzugslieferanten ",
        text_1_only_favorite_providers_3: "identifiziert, mit denen Sie bereits eine aktive Geschäftsbeziehung haben oder hatten.",
        text_2_title: "HINWEIS: Wir können nicht sicherstellen, dass jeder Lieferant, mit dem Sie arbeiten, berücksichtigt wird. Dies kann aus folgenden Gründen der Fall sein:",
        text_2_1: "Wir haben keine direkte Verbindung zu Ihrer Datenbank (API-Integration)",
        text_2_2: "Es fehlen die aktuellsten Anbieter aufgrund regelmäßiger Importe (Importer)",
        text_2_3: "Die Datensätze spiegeln nicht alle Anbieter oder juristische Personen wider",
        text_2_4: "Öffentliche Datenverfügbarkeit über Anbieter ist nicht gegeben (z.B. keine Website)",
        text_3: "Für weitere Fragen oder die Erweiterung Ihrer individuellen Datenbank, kontaktieren Sie uns bitte.",
        accepted: 'Speichern',
        neutral: 'Löschen',
        rejected: 'Ignorieren',
        product_name: 'Intern verwendetes Product oder Service',
        use_case: 'Interner use case',
        title: "Berechnung läuft",
        text_4: "Die Analyse der Anfrage läuft noch. Erste Ergebnisse werden sofort angezeigt, wenn der Vorgang abgeschlossen ist. Parallel dazu läuft die Qualitätsprüfung, so dass sich die Ergebnisse noch entsprechend verändern und verbessern können, bis wir Ihnen mitteilen, dass Matchings verfügbar sind. Während des iterativen Matching-Prozesses werden sich auch Ihre internen Einblicke weiter verbessern, da auch hier das gegebene Feedback zu Lösungen und die Anpassung der Matching-Kriterien berücksichtigt werden.",
        text_5: "Die Analyse der Anfrage läuft noch. Erste Ergebnisse werden sofort angezeigt, wenn der Vorgang abgeschlossen ist. Parallel dazu läuft die Qualitätsprüfung, so dass sich die Ergebnisse noch entsprechend verändern und verbessern können, bis wir Ihnen mitteilen, dass Matchings verfügbar sind.",
        exact_match: "Nur exakte Treffer",
        next_step_text: "Möchten Sie diesen Schritt wirklich überspringen?",
        capability_title: "Alternative Anbieter, die keine offensichtlich deklarierten Fähigkeiten haben",
        capabilities: "Fähigkeiten",
        adoit_link: "In ADOIT öffnen",
        collected_file: "Gesammelte Datei über",
      },
      meta_matching: {
        title_1: "META MATCHING",
        title_2: "Potenzielle Anbieter",
        text_1_part_1: "Wir entwickeln unsere Plattform jeden Tag weiter und lassen das Feedback unserer Nutzer einfließen.",
        text_1_part_2: "Mit der neusten ",
        text_1_part_3: "",
        text_1_part_4: "des META MATCHINGS geben wir Nutzern, in ausgewählten Anfragen, die Möglichkeit direkt auf weitere Ergebnisse zuzugreifen.",
        text_1_part_5: "Das META MATCHING ist eine Ergänzung unseres bewährten Matchings, greift hierbei aber auf unsere erweiterte Datenbank zu.",
        text_2_part_1: "HINWEIS: Die hier gezeigten Ergebnisse basieren auf der Summe der Information, die wir über ein Unternehmen gesammelt haben. Im normalen Matching bleibt der Bezug auf dedizierte Lösungen oder Leistungen geprüfter Unternehmen.",
        text_2_part_2: "Über Hinweise und Verbesserungsvorschläge freuen wir uns jederzeit. Kontaktieren Sie hierzu einfach Ihren Business Consultant oder schreiben uns eine Nachricht via ",
        score: "Score",
        company: "Unternehmen",
        last_updated: "Letzte Aktualisierung",
        top_hit_url: "Top hit URL",
      },
      message: {
        dialog_title: "Matching startet",
        dialog_content_text: "Der Matching- und Qualifizierungsprocess hat begonnen. Sobald dieser abgeschlossen ist und erste Ergebnisse zur Verfügung stehen, erhalten Sie eine Benachrichtigung.",
        dialog_action: "ZUR ÜBERSICHT",
        dialog_title_1: "It's a Match!",
        dialog_title_2: "Fast geschafft!",
        dialog_content_text_1_1:
          "Super gemacht! Dank Ihrer Angaben konnten wir bereits einige ersten Matches für Ihr Projekt finden. Unsere Plattform wird nun iterativ weitere Analysen Ihrer Anfrage vornehmen, um noch präzisere Ergebnisse zu erhalten.",
        dialog_content_text_1_2:
          "Basierend auf den von Ihnen hinterlegten Informationen konnten wir leider keine Ergebnisse erzeugt werden. Unsere Plattform wird Ihre Anfrage weiter analysieren und individuelle Parameterkombinationen und -variationen testen, um mögliche Lösungen zu finden. Bei Bedarf wird einer unserer Business Consultants Ihre Anfrage optimieren und Sie diesbezüglich kontaktieren.",
        dialog_content_text_2_1:
          "Basierend auf den von Ihnen hinterlegten Informationen konnten wir leider kein direktes Match für Ihr Projekt finden. Unsere Plattform wird Ihre Anfrage weiter analysieren und individuelle Parameterkombinationen und -variationen testen, um mögliche Lösungen zu finden. Bei Bedarf wird einer unserer Business Consultants Ihre Anfrage optimieren und Sie diesbezüglich kontaktieren.",
        dialog_content_text_2_2:
          "Natürlich können Sie Ihre Anfrage auch selbst überarbeiten, indem Sie weitere Informationen oder Schlüsselwörter angeben, um mögliche Matches zu finden. ",
        dialog_action_1: "Preview anzeigen",
        dialog_action_2: "Anfrage bearbeiten",
        dialog_action_3: "Auf Ergebnisse warten",
        tab_title_1: "Anfragedetails",
        tab_title_2: "Matching",
        tab_title_3: "Projektnachrichten",
        tab_title_4: "Meta matching",
        tab_title_5: "Interne Einblicke",
        tab_title_6: "Strategische & Vorzugslieferanten",
        tab_title_7: "Weitere Lieferanten",
        in_review:
          "Jede neue Anfrage wird von unseren Administratoren auf Anonymität, verbotene Inhalte etc. überprüft. Diese Prüfung erfolgt in der Regel innerhalb von 2 Werktagen.",
        preview: "Das is eine VORSCHAU und die Ergebnisse sind nicht final. Sobald verifizierte Ergebnisse verfügbar sind, wird eine Nachricht versendet.",
        tab_disabled: "Dies Schritt ist im Moment deaktiviert. Nach dem benden oder überspringen der vorherigen Schritte wird dieser Tab aktiviert",
        preview_disabled: "Dieser Tab wird aktiviert, wenn die qualifizierten Ergebnisse verfügbar sind. Sie erhalten eine Benachrichtigung.",
      },
      progress: {
        dialog_title_1: "Matching startet (Schritt 1 / 2)",
        dialog_title_2: "Ergebnisse generieren(Schritt 2 / 2)",
        dialog_content_text_1: "Der Matching- und Qualifizierungsprozess hat begonnen. Sollen wir versuchen, eine Vorschau zu berechnen oder möchten Sie auf ein qualifiziertes Ergebnis warten?",
        dialog_content_text_2_1: "Suche und Abgleich mit der Datenbank gestartet. Die Ergebnisse werden generiert.",
        dialog_content_text_2_2: "Sie können die Seite verlassen und später wiederkommen. Der Prozess wird im Hintergrund fortgesetzt.",
        dialog_action_1: "Vorschau berechnen",
        dialog_action_2: "Qualifizierte Ergebnisse abwarten",
        dialog_action_3: "Zum Dashboard",
      },
      notification: {
        created_success: "Projekt wurde erstellt",
        created_error: "Fehler: Projekt konnte nicht erstellt werden",
        updated_success: "Projekt wurde aktualisiert",
        updated_error: "Fehler: Projekt konnte nicht aktualisiert werden",
        activated_success: "Projekt wurde aktiviert",
        activated_error: "Fehler: Projekt konnte nicht aktiviert werden",
        archived_success: "Projekt wurde archiviert",
        archived_error: "Fehler: Projekt konnte nicht archiviert werden"
      },
      placeholder: {
        title:
          "Geben Sie hier den Titel Ihrer Anfrage oder den Projektnamen ein",
        description:
          "Bitte beschreiben Sie Ihr Projekt, die Ihnen bereits bekannten Anforderungen und/oder Ihre Ziele. Sie können es entweder in ganzen Sätzen als Text oder als Listenansatz schreiben.",
        keywords: "Bitte geben Sie hier wichtige Stichworte ein",
        topics:
          "Um die Ergebnisse zu verbessern, können Sie bis zu 5 relevante Themen auswählen "
      },
      tips: {
        title:
          "Der Titel wird für das Matching verwendet und ist auch die Kennung auf der Plattform und in der Kommunikation.",
        description:
          "Das Format der Eingabe ist wirklich flexibel. Wenn Sie bereits eine schriftliche Beschreibung haben, können Sie diese einfach kopieren und einfügen.<br/><br/>HINWEIS: Um ein passendes Ergebnis zu erzielen, sollten Sie mindestens 100 Wörter oder 5 Listenelemente verwenden.",
        keywords:
          "Wenn Sie mit der Eingabe beginnen, erhalten Sie auch automatisch Vorschläge zum Ausfüllen. Neue Schlüsselwörter können durch Drücken der Eingabetaste oder durch Eingabe von Komma gespeichert werden."
      },
      legend_dialog: {
        link: "Legende: Was die Farben bedeuten",
        title: "Was die Farben bedeuten",
        green_color: "Das Kriterium selbst oder ein direkt verknüpfter Begriff, mit der gleichen Bedeutung, wurde in den Lösungsinformationen gefunden. Ein direkt verbundener Begriff kann ein Synonym, eine Abkürzung, eine Langform usw. sein.",
        green_color_example: "Beispiel: “App“ ist ein direktes Synonym zu “Applikation“.",
        orange_color: "Ein indirekt verbundener Begriff, mit einer Beziehung zu dem Kriterium, wurde gefunden. Dies können übergelagerte Begriffe des Kriterium, eine Unterkategorie, einer Eltern-/Kind-Beziehung usw. sein.",
        orange_color_example: "Beispiel: “Mobile App-Entwicklung“ ist eine Unterkategorie von “Anwendungsentwicklung“.",
        gray_color: "Das bedeutet, dass unser System keine Informationen zu diesem Kriterium in den dedizierten Informationen zu dieser Lösung gefunden hat. Dies bedeutet jedoch nicht unbedingt, dass die Lösung die Anforderung nicht erfüllt, sondern dass eine abschließende Bewertung mit dem Anbieter erfolgen sollte.",
      },
      archive_dialog: {
        title: "Anfrage beenden und archivieren",
        fields: {
          comment: "Kommentar (optional)",
        },
        field_values: {
          feedback: {
            option_1: "Ich habe für diese Anfrage einen Anbieter/Lieferanten beauftragt",
            option_2: "Die Anfrage wurde gestoppt oder abgebrochen",
            option_3: "Ich habe diese Anfrage zur Informationsbeschaffung verwendet (es wurde keine Entscheidung getroffen)",
            option_4: "Andere",
            option_1_other: "Andere (nicht von knooing vorgeschlagen)",
          }
        },
        message: {
          message_1: "Um unsere Qualität zu verbessern und Ihnen eine bessere Erfahrung zu bieten, wäre ein optionales Feedback zu Ihrer Entscheidung hilfreich. Vielen Dank im Voraus.",
          message_2: "Warum beenden Sie diese Anfrage?",
          message_3: "Welcher Anbieter/Lieferant wurde beauftragt?",
          message_4: "Geben Sie den Anbieter-/Lieferantennamen hier ein (optional)",
        },
        notification: {
          archived_success: "Anfrage wurde archiviert",
          archived_error: "Fehler: Anfrage konnte nicht archiviert werden"
        }
      },
      activate_dialog: {
        title: "Anfrage aktivieren",
        message: {
          message_1: "Möchten Sie diese Anfrage wirklich aktivieren?",
        },
        notification: {
          activated_success: "Project wurde aktiviert",
          activated_error: "Fehler: project konnte nicht aktiviert werden",
        }
      },
    },
    products: {
      singular_name: "Produkt",
      name: "Produkt |||| Produkte",
      fields: {
        // Product
        status: "Aktueller Status",
        language: "Sprache",
        owner: {
          id: "Besitzer"
        },
        topics: "Themen (max. 5)",
        keywords: "Schlüsselwörter",
        createdAt: "Erstellungsdatum",
        creator: {
          id: "Erstellt von"
        },
        updatedAt: "Zuletzt geändertes Datum",
        updater: {
          id: "Zuletzt geändert durch"
        },

        translation: "Übersetzung",

        // Product description
        name: "Name",
        neutralName: "Neutrale Bezeichnung der Leistung",
        challenge: "Herausforderungen / Problemstellung",
        advantage: "Vorteile / Nutzen",
        solution: "Lösung",
        feature: "Funktionalitäten / Merkmale"
      },
      field_values: {
        status: {
          in_review: "In Prüfung",
          approved: "Genehmigt",
          archived: "Archiviert"
        },
        translation: {
          automatic:
            "Automatisierte Übersetzung (mit der Möglichkeit der Bearbeitung)",
          manual: "Fügen Sie die Übersetzung dieses Produkts manuell hinzu",
          default: "Aktuelle Übersetzungen nicht ändern"
        }
      },
      action: {
        next: "Weiter",
        submit: "Absenden",
        previous: "Zurück",
        archive: "Archivieren",
        activate: "Aktivieren"
      },
      message: {
        page_title_1: "Produktbeschreibungen in anderen Sprachen hinzufügen",
        page_title_2: "Gewählte Sprache",
        aside_title: "Fügen Sie ein neues Produkt hinzu",
        in_review:
          "Jedes neue Produkt wird von unseren Administratoren auf Anonymität, verbotene Inhalte etc. überprüft. Diese Prüfung erfolgt in der Regel innerhalb von 2 Werktagen. Nach erfolgreicher Genehmigung erfolgt keine Benachrichtigung. Nur im Falle eines größeren Problems."
      },
      notification: {
        created_success: "Produkt wurde erstellt",
        created_error: "Fehler: Produkt konnte nicht erstellt werden",
        updated_success: "Produkt wurde aktualisiert",
        updated_error: "Fehler: Produkt konnte nicht aktualisiert werden",
        activated_success: "Produkt wurde aktiviert",
        activated_error: "Fehler: Produkt konnte nicht aktiviert werden",
        archived_success: "Produkt wurde archiviert",
        archived_error: "Fehler: Produkt konnte nicht archiviert werden"
      }
    },
    topics: {
      action: {
        add: "Themen hinzufügen"
      },
      message: {
        maximum_limit: "Bitte wählen Sie bis zu %{maximum} Themen"
      },
      notification: {
        added_error:
          "Sie haben bereits %{maximum} Themen ausgewählt. Dies ist das Maximum"
      }
    },
    quick_search_software: {
      headline: "Schnellsuche",
      title: "Finde bestehende Software",
      text: "Das Werkzeug, um bestehende Softwarelösungen im Unternehmen zu finden. Einfach zu bedienen wie jede Suchmaschine, unterstützt von intelligenter KI.",
      time_note: "Sofortige Ergebnisse",
      button: "ÖFFNEN",
      use_case: "Anwendungsfall",
      missing_words: "Fehlende Wörter",
      must_contain: "Muss enthalten",
      page: {
        title: "Software - Schnellsuche",
        subtitle: "Durchsuchen Sie schnell und einfach die vorhandenen Softwarelösungen Ihres Unternehmens. Die Suche basiert auf dem Software-Portfolio und angereicherten knooing Daten.",
        tooltip: {
          text_1: "Wir können nicht sicherstellen, dass jeder Anbieter oder jede Software, die von Ihnen verwendet wird, auch berücksichtigt wird. Dies kann folgende Gründe haben:",
          text_2: "Es kann bis zu mehreren Tagen dauern, bis die Daten gesammelt und angereichert sind, nachdem Einträge im Portfolio hinzugefügt oder bearbeitet worden sind.",
          text_3: "Die neuesten Anbieter fehlen, weil noch kein Update zu knooing stattgefunden hat.",
          text_4: "Das Portfolio deckt nicht alle geografischen Regionen oder juristischen Gesellschaften ab.",
          text_5: "Es gibt keine öffentlich zugänglichen Daten über einen Anbieter oder eine Software.",
          text_6: "Für weitere Fragen oder die Erweiterung der Portfolio-Datenbank, kontaktieren Sie uns bitte.",
        },
        button: "SUCHEN",
        selector: "Nur exakte Treffer",
      }
    },
    quick_search_vendors: {
      headline: "Schnellsuche",
      title: "Finde bestehende Vendoren & Services",
      text: "Einfache und schnelle Lösung, um die Anbieter im eigenen Unternehmen zu finden. Stress und Zeit sparen, indem Sie in Sekundenschnelle registrierte Anbieter und deren Dienstleistungen finden.",
      time_note: "Sofortige Ergebnisse",
      button: "ÖFFNEN",
      page: {
        title: "Vendoren & Services - Schnellsuche",
        subtitle: "Durchsuchen Sie schnell und einfach die vorhandenen Vendoren und deren Services, Ihres Unternehmens. Die Suche basiert auf dem Vendoren-Portfolio und angereicherten knooing Daten.",
        tooltip: {
          text_1: "Wir können nicht sicherstellen, dass jeder Anbieter oder jede Software, die von Ihnen verwendet wird, auch berücksichtigt wird. Dies kann folgende Gründe haben:",
          text_2: "Es kann bis zu mehreren Tagen dauern, bis die Daten gesammelt und angereichert sind, nachdem Einträge im Portfolio hinzugefügt oder bearbeitet worden sind.",
          text_3: "Die neuesten Anbieter fehlen, weil noch kein Update zu knooing stattgefunden hat.",
          text_4: "Das Portfolio deckt nicht alle geografischen Regionen oder juristischen Gesellschaften ab.",
          text_5: "Es gibt keine öffentlich zugänglichen Daten über einen Anbieter oder eine Software.",
          text_6: "Für weitere Fragen oder die Erweiterung der Portfolio-Datenbank, kontaktieren Sie uns bitte.",
        },
        button: "SUCHEN",
        selector: "Nur exakte Treffer",
      }
    },
    internal_external_matching: {
      headline: "Projekt Check & Marktrecherche",
      title: "Projekt anlegen",
      text: "Sie sind sich nicht sicher, wonach Sie suchen sollen? Sie brauchen Unterstützung während des gesamten Prozesses? Erstellen Sie jetzt Ihr Projekt. In unserem schrittweisen Prozess haben Sie die Möglichkeit, Ihr Projekt oder Ihren Bedarf zu beschreiben. Unsere KI analysiert Ihr Projekt und findet die am besten passenden Lösungen oder Partner, die bereits in Ihrem Unternehmen und auf dem Markt vorhanden sind.",
      time_note: "mehrere Tage",
      button: "PROJEKT ANLEGEN"
    },
    external_matching: {
      headline: "Marktrecherche",
      title: "Projekt anlegen",
      text: "Sie sind sich nicht sicher, wonach Sie suchen sollen? Sie brauchen Unterstützung während des gesamten Prozesses? Erstellen Sie jetzt Ihr Projekt. In unserem schrittweisen Prozess haben Sie die Möglichkeit, Ihr Projekt oder Ihren Bedarf zu beschreiben. Unsere KI analysiert Ihr Projekt und findet die am besten passenden auf dem Markt vorhanden sind.",
      time_note: "mehrere Tage",
      button: "PROJEKT ANLEGEN"
    }
  },
  application: {
    login: {
      email: "Email",
      password_recovery: "Passwort vergessen?",
      provider_registration: "ANBIETER REGISTRIERUNG",
    },
    registration: {
      email: {
        title: "E-mail",
        placeholder: "Bitte geben Sie hier Ihre E-Mail ein",
      },
      password: {
        title: "Passwort",
        placeholder: "Bitte legen Sie Ihr Passwort fest",
      },
      password_confirmation: {
        placeholder: "Bitte wiederholen Sie Ihr Passwort",
      },
      register: "KOSTENLOS REGISTRIEREN",
      to_login: "Ich habe bereits einen Account",
      user: "Ich bin ANWENDER",
      message_1: "VIELEN DANK FÜR IHRE REGISTRIERUNG",
      message_2: "Wir haben gerade eine Bestätigungs-E-Mail an %{email} geschickt.",
      message_3: "Bitte aktivieren Sie den darin enthaltenen Link, um Ihre Registrierung abzuschließen.",
      error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut'
    },
    dashboard: {
      welcome: {
        header: "STOP SEARCHING - START KNOOING",
        content: "KI-BASIERTE DEMAND UND KNOWLEDGE PLATTFORM"
      },
      new_request: "Erstellen Sie jetzt eine neue Anfrage",
      suggestions: {
        name: "Ausstehende Vorschläge",
        score: "Matching-Score"
      },
      notifications: {
        name: "Benachrichtigungen",
        status: "Status",
        found_suggestion: "Vorschlag gefunden",
        found_suggestions: "Vorschläge gefunden",
        declined_by_provider: "Ihr Projekt wurde vom Anbieter abgelehnt",
        accepted_by_provider: "Ihr Projekt wurde vom Anbieter akzeptiert"
      },
      info_box: {
        title: 'Für was kann ich knooing verwenden?',
        item1: 'Suche nach Informationen über Software oder Dienstleistungen innerhalb der Anbieterlandschaft',
        item2: 'Suche nach geeigneten Anbietern für Software- oder Dienstleistungsprojekte',
        item3: 'Projektanfragen müssen auf Kriterien/Anforderungen basieren',
        item4: 'Je mehr Kriterien, desto spezifischer die Ergebnisse',
        item5: 'Keine Strategie oder Umsetzungsempfehlungen',
        item6: 'Keine Hardware oder Waren',
      }
    },
    menu: {
      company_information: "Unternehmensdaten",
      employees: "Mitarbeiter",
      requests: "Anfragen",
      suitable_projects: "gematchte Projekte",
      profile: "Profil",
      settings: "Einstellungen",
      personal_data: "Persönliche Informationen",
      general_configuration: "Einstellungen",
      general_configuration_description: "Sprache ändern, Design einstellen",
      security: "Sicherheit",
      security_description: "Verwalten Sie Ihre Sicherheitseinstellungen (ändern Sie Ihr Passwort usw.)",
      notification_configuration: "Benachrichtigungsoptionen",
      faq: "FAQs",
      chat: "Chat",
      knowledge_center: "Wissenszentrum",
      invoice: "Rechnung",
      analytics: "Analysen",
      feedbacks: "Vorschläge",
      action: {
        show: "Anzeigen / Ändern"
      },
      active_providers: "Aktive Lieferanten",
      it_vendors: "Vendor Portfolio",
      software_portfolio: "Software Portfolio",
      dashboard: 'Dashboard',
      quick_search: 'Schnellsuche',
      software: 'Software Suche',
      vendors_services: 'Vendor & Service Suche',
      project_check: 'Projekt Check (Anfragen)',
      company_portfolio: 'Unternehmensportfolio',
      vendors: 'Vendoren',
      account: 'Account',
      company_settings: 'Unternehmensdaten',
      users_management: 'Nutzerverwaltung',
      my_profile: 'Mein Profil',
      solution_insights: 'Einblicke in die Lösung',
      solution_overlaps: 'Lösungsüberschneidungen',
      overview: 'Überblick',
    },
    profile: {
      fields: {
        theme: "Thema",
        language: "Sprache",
        email: "E-Mail Adresse ändern",
        password: "Passwort ändern",
        delete_account: "Mein Profil löschen",
        delete_account_text_1: "Wenn Sie Ihr Profil löschen möchten, beachten Sie bitte, dass diese Funktion nicht das Unternehmen und Ihre Anfragen oder Produkte löscht.",
        delete_account_text_2: 'Ihre persönlichen Daten werden nach 28 Tagen vollständig entfernt oder durch "Anonymer Benutzer" ersetzt. Für die Löschung des Firmenkontos (einschließlich Anfragen/Produkte) wenden Sie sich bitte direkt an uns.',
        delete_account_confirmation_title: "Mein Profil löschen",
        delete_account_confirmation_content: "Sind Sie sicher, dass Ihr Profil löschen möchten?Wenn Sie Ihr Profil versehentlich löschen, kontaktieren Sie uns innerhalb von 28 Tagen, damit wir die Informationen wiederherstellen können. Nach 28 Tagen werden diese Daten endgültig gelöscht.",
        new_email: "Neue E-Mail",
        old_password: "Aktuelles Passwort",
        new_password: "Neues Passwort",
        password_confirmation: "Passwort Bestätigung",
        password_verification_tooltip:
          "Das Passwort sollte zwischen 8 - 20 Zeichen lang sein und muss mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
        password_verification:
          "Um die Sicherheit Ihres Accounts zu gewährleisten vergiben Sie bitte ein Passwort entsprechend der folgenden Regeln:\n\n- Passwortlänge von mindestens 8 Zeichen und maximal 20 Zeichen\n- Mindestens EINE Ziffer von 0-9 enthalten\n- Mindestens EINEN Kleinbuchstaben enthalten\n- Mindestens EINEN Großbuchstaben enthalten\n- Mindestens EINES der folgenden Sonderzeichen !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~ enthalten"
      },
      field_values: {
        theme: {
          light: "Hell",
          dark: "Dunkel"
        },
        language: {
          english: "English",
          deutsch: "Deutsch"
        }
      },
      message: {
        current: "Aktuell",
        pending: "Ausstehend",
        confirmed: "Bestätigt",
        unconfirmed: "Nicht bestätigt",
        action_confirmation: "Aktionsbestätigung",
        action_confirmation_password: "Geben Sie Ihr Passwort ein",
      },
      notification: {
        email: {
          updated_success:
            "Ihr Profil wurde aktualisiert. Bitte bestätigen Sie Ihre neue E-Mail-Adresse",
          updated_error: "Diese E-Mail-Adresse wird schon verwendet"
        },
        password: {
          updated_success: "Passwort wurde aktualisiert",
          updated_error: "Fehler: Das eingegebene aktuelle Passwort ist falsch"
        },
        notification_configuration: {
          updated_success: "Benachrichtigungskonfiguration wurde aktualisiert",
          updated_error:
            "Benachrichtigungskonfiguration wurde nicht aktualisiert"
        }
      }
    },
    validation: {
      required: "Bitte geben Sie relevante Informationen in diesem Feld ein"
    },
    password_recovery: {
      name: "Passwort-Wiederherstellung",
      message_1:
        "Bitte geben Sie Ihre E-Mail ein und wir werden Ihnen einen Link zur Passwort-Wiederherstellung zusenden",
      message_2:
        "Bitte überprüfen Sie Ihre E-Mail und verwenden Sie den gesendeten Link, um Ihr Passwort zurückzusetzen."
    },
    password_activation: {
      name: "Passwort Aktivierung",
      fields: {},
      message_1: "Bitte geben Sie Ihr neues Passwort ein",
      password: "Passwort",
      confirmPassword: "Passwort bestätigen",
      submit: "Speichern",
      requiredField: "Dies ist ein Pflichtfeld",
      matchPassword: "Passwort und Passwortbestätigung stimmen nicht überein!"
    },
    email_activation: {
      name: "Email Aktivierung",
      message_1: "Ihre neue E-Mail-Adresse wurde erfolgreich aktiviert.",
      message_2: "Zurück zum Dashboard"
    },
    action: {
      list: "Überblick",
      send: "Senden",
    },
    unsaved_changes: {
      name: "Nicht gespeicherte Änderungen",
      message:
        "Möchten Sie die Seite wirklich verlassen? Ihre Änderungen wurden nicht gespeichert.",
      yes: "Änderungen verwerfen und verlassen",
      no: "Änderungen speichern"
    },
    message: {
      delete_title: "%{name} löschen: %{id}",
      session_expired: "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich an, um fortzufahren.",
      not_available: 'Nicht verfügbar',
      characters_left: "Zeichen übrig",
      locked: "Aufgrund zu vieler fehlerhafter Anmeldeversuche wurde Ihr Konto gesperrt. Bitte wenden Sie sich an ihren internen Ansprechpartner, einen knooing Business Consultant oder info@knooing.com zur Klärung.",
    },
    footer: {
      legal: {
        imprint: "Impressum",
        privacy_policy: "Datenschutz",
        age: "AGB"
      },
      link: {
        imprint: "https://knooing.com/impressum/",
        privacy_policy: "https://knooing.com/datenschutz/",
        age: "https://knooing.com/agb/"
      }
    },
    conditions: {
      title: "Datenschutz & Geschäftsbedingungen",
      content:
        "Bitte bestätigen Sie nachfolgend, dass Sie mit unseren AGBs und Datenschutzbestimmungen einverstanden sind.",
      privacy_policy: "Datenschutzerklärung",
      privacy_policy_content:
        'Ich habe die %{link} zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an <a href="mailto:datenschutz@knooing.com">datenschutz@knooing.com</a> widerrufen.',
      consumer_terms: "Allgemeinen Geschäftsbedingungen",
      consumer_terms_content: "Ich akzeptiere die %{link}",
      accept: "Akzeptiere"
    },
    internal_search: {
      title: "Aktive Anbieter entdecken",
      description: "Entdecken Sie alle gesammelten oder importierten aktiven Anbieter von %{company_name}. Sie haben auch die Möglichkeit, die aktiven Anbieter in der Tiefe zu durchsuchen, um passende Anbieter außerhalb Ihrer Anfragen zu identifizieren.",
      text_1: "Suche schnell und unkompliziert durch die aktiven Anbieter von %{company_name}. Die Suche basiert auf den gesammelten Daten zu jedem einzelnen Anbieter.",
    },
  }
};

import React from 'react'
import { useTranslate, showNotification } from 'react-admin';
import axios from 'axios';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui';

export const EmailEditForm = connect(undefined, { showNotification })(props => {
    const translate = useTranslate();
    const { showNotification ,handleExpanded} = props;
    const [dataId, setDataId] = React.useState(null);

    function handleValidate(values){
        const errors = {};
        if (!values.unconfirmedEmail) {
            errors.unconfirmedEmail = translate('application.validation.required');
        }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.unconfirmedEmail)) {
            errors.unconfirmedEmail = 'Invalid email';
        }
        return errors;
    }

    function onSubmit(values) {
        const errors = handleValidate(values);
        if (JSON.stringify(errors) !== '{}')  return errors;

        let putUrl = process.env.REACT_APP_API_URL + "/users/" + JSON.parse(localStorage.getItem('decoded_token')).user_name + "/email";
        const token = localStorage.getItem('token');

        axios.put(putUrl, JSON.stringify(values), {
            headers:{'Content-Type': 'application/json; charset=utf-8','Authorization': `Bearer ${token}`}
        }).then(function (response) {
            handleExpanded('panel-1');
            showNotification('application.profile.notification.email.updated_success');
            setDataId(response.data.id)
            values.unconfirmedEmail="";
            document.getElementsByName("unconfirmedEmail")[0].value="";
        }).catch(function (error) {
            showNotification('application.profile.notification.email.updated_error', 'warning')
        }).finally(function () {
        });
    }
    return (
        <div style={{ width: '100%' }}>
            <Form
                onSubmit={onSubmit}
                validate={handleValidate}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} noValidate id="emailEditForm">
                        <div style={{ width: '50%' }}>
                            <Field
                                fullWidth
                                required
                                name="unconfirmedEmail"
                                type="email"
                                component={TextField}
                                label={translate('application.profile.fields.new_email')}
                            />
                        </div>
                <div id='ckEmailEdit' hidden>{dataId}</div>
                    </form>
                )}
            />
        </div>
    )
})

export default EmailEditForm;
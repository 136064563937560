import React from 'react';
import {List,Datagrid,TextField,RichTextField,Filter,SearchInput,ShowButton,translate,EmailField,
} from 'react-admin';

const CustomFilter = (props) => (
    <Filter variant="standard" {...props}>
        <SearchInput source="keyword" alwaysOn />
    </Filter>
);

export const FeedbackList = ({ permissions, ...props }) => (
    <List bulkActionButtons={false} filterDefaultValues={{ companyId: JSON.parse(localStorage.getItem('decoded_token')).company_id }} {...props} filters={<CustomFilter />} title="application.menu.requests" sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid>
            <TextField source="title" />
            <RichTextField source="description"/>
            <EmailField source="email" />
            <TextField source="phone" />
            <ShowButton />   
        </Datagrid>
    </List>
);

export default FeedbackList;
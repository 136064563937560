import React from 'react';
import { ChipField, ArrayField, SingleFieldList, BooleanField, ShowController, ShowView, SelectField, ReferenceField, TextField, EditButton, ListButton, CardActions, SimpleShowLayout, DateField, Datagrid, UrlField } from 'react-admin';

import { connect } from 'react-redux';
import compose from 'recompose/compose';

import TextArrayField from '../common/TextArrayField';
import { isRwe, isZf, isSiemens } from '../utilities';
import { segments, statuses } from './ItVendorForm';
import { valuationChoices } from './SupplierValuation';
import RecommendationState from './RecommendationState';

const CustomActions = ({ permissions, basePath, data, resource }) => (
    <CardActions>
        <ListButton basePath={basePath} />
        {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? <EditButton basePath={basePath} record={data} /> : null}
    </CardActions>
);

const ItVendorShow = ({ permissions, theme, ...props }) => {

    return (
        <ShowController {...props}>
            {controllerProps =>
                <ShowView 
                    {...props} 
                    {...controllerProps} 
                    title="resources.recommendations.it_vendor.singular_name"
                    actions={<CustomActions permissions={permissions} />}
                >
                    <SimpleShowLayout>
                        {isRwe() && <TextField label="resources.recommendations.it_vendor.id" source="itVendorData.id" />}
                        {isSiemens() && <TextField label="resources.recommendations.fields.supplier_number" source="attribute" />}
                        <TextField label="resources.recommendations.it_vendor.vendor_name" source="providerName" />
                        {isRwe() && <TextField label="resources.recommendations.it_vendor.duns_global_name" source="itVendorData.dunsGlobalName" />}
                        <UrlField source="website" target="_blank" />
                        {isRwe() && permissions && (permissions.includes('ROLE_EXECUTIVE')) && 
                            <UrlField label="Custom target URL" source="internalUrl" target="_blank" />
                        }
                        {isRwe() && permissions && (permissions.includes('ROLE_EXECUTIVE')) &&
                            <SelectField label="Performance" source="valuation" choices={valuationChoices} />
                        }
                        {isZf() && <TextField label="Plant" source="itVendorData.plant" />}
                        {isZf() && <TextField label="Spend type" source="itVendorData.spendType" />}
                        <ArrayField label="resources.recommendations.fields.products" source="products">
                            <Datagrid>
                                <TextField source="offering" label="resources.recommendations.fields.offering" sortable={false} />
                                <TextField source="name" label="resources.recommendations.fields.productName" sortable={false} />
                                <TextField source="useCase" label="resources.recommendations.fields.useCase" sortable={false} />
                            </Datagrid>
                        </ArrayField>
                        <SelectField
                            label="resources.recommendations.it_vendor.segment"
                            source="status"
                            choices={segments}
                        />
                        {isRwe() && (
                            <TextArrayField label="resources.recommendations.it_vendor.internal_company" source="operationCompanies">
                                <SingleFieldList>
                                    <ChipField source="id" />
                                </SingleFieldList>
                            </TextArrayField>
                        )}
                        {isRwe() && <TextField label="resources.recommendations.it_vendor.mentor" source="itVendorData.mentor" />}
                        {isRwe() && <TextField label="resources.recommendations.it_vendor.management" source="itVendorData.management" />}
                        {isRwe() && (
                            <TextArrayField label="resources.recommendations.it_vendor.region" source="itVendorData.regions">
                                <SingleFieldList>
                                    <ChipField source="id" />
                                </SingleFieldList>
                            </TextArrayField>
                        )}
                        {isRwe() && (
                            <SelectField
                                label="resources.recommendations.it_vendor.status"
                                source="itVendorData.status"
                                choices={statuses}
                                translateChoice={false}
                            />
                        )}
                        {isRwe() && <TextField label="resources.recommendations.it_vendor.ofa" source="itVendorData.ofa" />}
                        {isRwe() && (
                            <TextArrayField label="resources.recommendations.it_vendor.sourcing_cluster" source="itVendorData.sourcingClusters">
                                <SingleFieldList>
                                    <ChipField source="id" />
                                </SingleFieldList>
                            </TextArrayField>
                        )}
                        {isRwe() && (
                            <TextArrayField label="resources.recommendations.it_vendor.contract_type" source="itVendorData.contractTypes">
                                <SingleFieldList>
                                    <ChipField source="id" />
                                </SingleFieldList>
                            </TextArrayField>
                        )}
                        <BooleanField label="resources.recommendations.fields.blocked" source="blocked" />
                        <DateField label="resources.recommendations.fields.blockedAt" source="blockedAt" />
                        {controllerProps.record && controllerProps.record.blocked && (
                            <ReferenceField label="resources.recommendations.fields.blockedBy" source="blockedBy" reference="users">
                                <TextField source="lastName" />
                            </ReferenceField>
                        )}
                        {controllerProps.record && controllerProps.record.blocked && <TextField label="resources.recommendations.fields.blockedAt" source="blockedComment" />}
                        <RecommendationState />
                    </SimpleShowLayout>
                </ShowView>
            }
        </ShowController>
    )
};

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
);

export default enhance(ItVendorShow);
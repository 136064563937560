import React, {useState,useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
    },
  }));

const ConditionsAccept = () => {
    const classes = useStyles();
    const translate = useTranslate();

    const [open, setOpenDialog] = React.useState(false);

    useEffect(() => {
        const conditions_accepted = localStorage.getItem("conditions_accepted");
        if (conditions_accepted!=null && 
            conditions_accepted!="undefined" && 
            typeof(conditions_accepted) != "undefined" &&  
            conditions_accepted.length > 0 && 
            conditions_accepted=="false" && 
            !JSON.parse(conditions_accepted)){
            setOpenDialog(true)
        }
    });

    const handleSubmit = () => { 
        let privacyPolicyAcceptedAt = 0;
        let consumerTermsAcceptedAt = 0;

        if(!document.getElementById("privacyPolicyAcceptedAt").checked){
            document.getElementById("conditionsContent").style.color="red";
            return;
        }
        if(!document.getElementById("consumerTermsAcceptedAt").checked){
            document.getElementById("conditionsContent").style.color="red";
            return;
        }

        const userId = JSON.parse(localStorage.getItem('decoded_token')).user_name;
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_API_URL}/users/conditions_accepted?userId=${userId}`, { 
            method: 'GET', 
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
        .then((response) => {
            console.log("response",response);
            if (response.status == 204) {
                localStorage.setItem('conditions_accepted', true);
                setOpenDialog(false);
            }
        })
        .catch((exception) => {
            console.log("exception",exception);
        });
        
    }

    return (
        <div className={classes.root}>
            <Dialog open={open} fullWidth={true}>
                <DialogTitle>{translate('application.conditions.title')} </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    <span id="conditionsContent">{translate('application.conditions.content')}</span>
                    </DialogContentText>
                    <div>
                        <FormControlLabel control={<Checkbox value="checkedC" id="privacyPolicyAcceptedAt"/>} label={(<div dangerouslySetInnerHTML={{__html: translate('application.conditions.privacy_policy_content',{link:'<a target="_blank" href='+translate('application.footer.link.privacy_policy')+'>'+translate('application.conditions.privacy_policy')+'</a>'})}} /> )} />
                        <FormControlLabel control={<Checkbox value="checkedC" id="consumerTermsAcceptedAt"/>} label={(<div dangerouslySetInnerHTML={{__html: translate('application.conditions.consumer_terms_content',{link:'<a target="_blank" href='+translate('application.footer.link.age')+'>'+translate('application.conditions.consumer_terms')+'</a>'})}} />)} />
                    </div>
                    <div style={{textAlign:"center",paddingTop:"10px",paddingBottom:"20px"}}>
                        <Button variant="contained" color="primary" onClick={()=> handleSubmit()} disableElevation>
                        {translate('application.conditions.accept')} 
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default ConditionsAccept;
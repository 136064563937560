import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
//import { change, submit, isSubmitting } from 'redux-form';

import compose from 'recompose/compose';

import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    Button as CancelButton,
    FormDataConsumer,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    UPDATE,
    REDUX_FORM_NAME,
    withDataProvider,
    translate,
    Toolbar
} from 'react-admin';

import IconContentAdd from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import Snackbar from '@material-ui/core/Snackbar';

import SendIcon from '@material-ui/icons/Send';

import { Button, Typography } from "@material-ui/core";

import { suggestionAccept } from './suggestionActions';

// import dataProvider from '../dataProvider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    toolbar: {
        background:'none',
        paddingTop:'10px',
        flex: '0 0 auto',
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    button:{
        marginRight:'10px'
    }
}));

const CustomToolbar = (props) => {
    const classes = useStyles();
    // console.log('props: ', props);
    return (
        <Toolbar className={classes.toolbar} {...props} >
            <SaveButton
                label="resources.messages.action.send"
                icon={<SendIcon />}
                disabled={props.loading}
                className={classes.button}
            />
            <CancelButton label="ra.action.cancel" onClick={()=> props.hideDialog()}>
                <CancelIcon />
            </CancelButton>
        </Toolbar>
    )
}

class AcceptButton extends Component {
    state = {
        error: false,
        visible: false,
        loading: false,
    };

    showDialog = () => {
        this.setState({ visible: true, loading: false });
    };

    hideDialogAndRefreshMessages = () => {
        this.props.refreshMessages();
        this.setState({ visible: false, loading: false });
    };

    hideDialogAndRefreshMessagesAndShowMessage = (messageType) => {
        this.props.refreshMessages();
        this.props.refreshProject(this.props.record.id, messageType);
        this.setState({ visible: false, loading: false });
    };

    submitForm = () => {
        const { submit } = this.props;

        document
            .getElementById('suggestion-feedback-create')
            .dispatchEvent(new Event('submit', { cancelable: true }));
    };

    hideDialog = () => {
        this.setState({ visible: false, loading: false });
    };

    handleSubmit = values => {
        // console.log('values: ', values);
        const { suggestionAccept, record, refreshProject } = this.props;

        this.setState({ loading: true });

        const closeDialog = () => {
            this.setState({ visible: false, loading: false });
            refreshProject(record.id);
        };

        if (values.feedback === 'SELF_SERVICE') {

            function selfService() {
                suggestionAccept(record.id + "/consumer-status", { consumerStatus: 'SELF_SERVICE', message: values.message, feedback: values.feedback }, closeDialog);
            }
            
            selfService();

        } else {

            suggestionAccept(record.id + "/consumer-status", { consumerStatus: 'ACCEPTED', message: values.message, feedbackType: 1, feedback: values.feedback }, this.hideDialogAndRefreshMessagesAndShowMessage);
        }
    };

    render() {
        const { visible, loading } = this.state;
        const { isSubmitting, translate, record } = this.props;

        const choices = [
            { id: 'DIRECT_CONTACT_TO_PROVIDER', name: 'resources.suggestions.dialog.field_values.feedback.option_5' },
            { id: 'SELF_SERVICE', name: 'resources.suggestions.dialog.field_values.feedback.option_8' },
        ]

        return (
            <Fragment>
                <Button
                    onClick={this.showDialog}
                    variant="contained"
                    style={{ minWidth: 110, marginLeft: 16, marginRight: 16, backgroundColor: '#c5e1a5' }}
                >
                    {translate('resources.suggestions.action.accept')}
                </Button>
                <Dialog
                    fullWidth
                    open={visible}
                    onClose={this.hideDialog}
                    disableEnforceFocus
                >
                    <DialogTitle>{translate('resources.suggestions.dialog.accept')}</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            id="suggestion-feedback-create"
                            variant="standard"
                            resource="suggestions"
                            save={this.handleSubmit}
                            toolbar={<CustomToolbar loading={loading} hideDialog={this.hideDialog.bind(this)} />}
                        >
                            <SelectInput style={{ marginLeft: -24, marginRight: -24, marginTop: -16 }} label="resources.suggestions.dialog.fields.feedback" source="feedback" validate={required()} choices={choices} />
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.feedback === 'DIRECT_CONTACT_TO_PROVIDER' &&
                                    <TextInput
                                        style={{ marginLeft: -24, marginRight: -24, marginBottom: -16, width: '100%' }}
                                        label="resources.suggestions.dialog.fields.message"
                                        source="message"
                                        resettable
                                        {...rest}
                                    />
                                }
                            </FormDataConsumer>
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}


export default compose(
    connect(
        state => ({
            //isSubmitting: isSubmitting('suggestion-feedback-create')(state)
        }),
        {
            //change,
            fetchEnd,
            fetchStart,
            showNotification,
            //submit,
            suggestionAccept: suggestionAccept
        }
    ),
    // withStyles(styles),
    translate,
    withDataProvider,
)(AcceptButton);
import React from 'react';
import { FileField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { useInput, useTranslate, InputProps } from 'ra-core';

import FileInput from '../component/FileInput';

const useStyles = makeStyles(theme => ({
    
    attachments: {
        padding: 10
    },
    gridcontainer: {
        padding: theme.spacing(1),
        marginBottom : "20px"
    },
    helpIconGrid:{
        position: "relative"
    },
}));

function CustomFileField(props) {

    const { record } = props;

    let fileSize = record.fileSize;
    if (record.rawFile) {
        fileSize = record.rawFile.size;
    }
    if (fileSize) {
        fileSize = ` (${(fileSize / 1000000).toFixed(2)} MB)`;
    }

    return (
        record ? <span {...props}>{record.title}{fileSize ? fileSize : ''}</span> : null
    )
}

var files = [];

export default function CustomFileInput(props) {

    const classes = useStyles();

    const multiple = true;

    return (
        <Grid container spacing={1} className={classes.gridcontainer}>
            <Grid item xs={12} sm={11}>
                <FileInput 
                    {...props}
                    className={classes.attachments} 
                    source="projectAttachments" 
                    label="resources.projects.fields.uploadDocuments"
                    maxFileSize={5000000} 
                    maxFiles={5}
                    // labelMultiple="resources.projects.fields.dropzonePlaceholder"
                    secondLabelMultiple="resources.projects.fields.dropzonePlaceholder"
                    accept="application/pdf, image/png, image/jpeg" 
                    multiple={multiple}
                >
                    <CustomFileField src="url" title="title" />
                </FileInput>
            </Grid>
            <Grid item xs={12} sm={1} className={classes.helpIconGrid}>
                
            </Grid>
        </Grid>
        
    )
}
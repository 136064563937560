import React, { Component } from 'react';
import { translate, Title, showNotification, Confirm } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import EmailIcon from '@material-ui/icons/Email';
import PasswordIcon from '@material-ui/icons/VpnKey';
import PasswordForm from './PasswordEditForm';
import { PasswordDialog } from './PasswordDialog';
import EmailEdit from './EmailEditForm';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const styles = {
    label: { display: 'inline-block', fontSize: '1rem' },
    icon: { marginRight: '10px' },
    button: { margin: '1em' },
    deleteButton: { borderColor: 'red', color: 'red' }
};

class Security extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            record: null,
            passwordDialogOpen: false,
            confirmationDialogOpen: false,
        }
    }

    componentDidMount() {
        this.fetchUserProfile();
    }

    fetchUserProfile() {
        const token = localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/users/${JSON.parse(localStorage.getItem('decoded_token')).user_name}`, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
            .then((result) => result.json())
            .then((result) => {
                this.setState(state => ({ record: result }));
            })
            .catch((exception) => {

            });
    }

    handleChange = (name) => {
        this.fetchUserProfile();

        let result = this.state.expanded === name ? null : name;
        this.setState({ expanded: result });
    };

    setConfirmationDialogOpen = (value) => {
        this.setState({ confirmationDialogOpen: value });
    }

    setPasswordDialogOpen = (value) => {
        this.setState({ confirmationDialogOpen: false, passwordDialogOpen: value });
    }

    deleteAccount = () => {
        this.setState({ passwordDialogOpen: false });
        const token = localStorage.getItem('token');

        const { showNotification } = this.props;

        fetch(`${process.env.REACT_APP_API_URL}/users/${JSON.parse(localStorage.getItem('decoded_token')).user_name}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
            .then((result) => {
                window.location.replace(`${process.env.REACT_APP_ROOT_FOLDER}#/complete-verification`);
            })
            .catch((exception) => {
                showNotification('application.registration.error')
            });
    }

    render() {
        const { dispatch, translate, classes } = this.props;
        return (
            <div>
                <Title title={translate('application.menu.security')} />
                <ExpansionPanel expanded={this.state.expanded === 'panel-1'} onChange={() => this.handleChange('panel-1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <EmailIcon className={classes.icon} />
                        <Typography>{translate('application.profile.fields.email')}</Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails style={{ display: 'inherit' }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography style={{ marginRight: '10px', display: 'inline-block', fontWeight: 'bold' }}>{translate('application.profile.message.current')}:</Typography>
                                {this.state.record && <Typography style={{ display: 'inline-block' }}>{this.state.record.email}</Typography>}
                            </Grid>
                            <Grid item xs={6}>
                                {this.state.record && this.state.record.enabled ? <Typography style={{ color: 'green' }}>&#10003; {translate('application.profile.message.confirmed')}</Typography> : <Typography style={{ color: 'red' }}>{translate('application.profile.message.unconfirmed')}</Typography>}
                            </Grid>
                        </Grid>
                        {this.state.record && this.state.record.unconfirmedEmail &&
                            <Grid container style={{ marginTop: '10px' }}>
                                <Grid item xs={6}>
                                    <Typography style={{ marginRight: '10px', display: 'inline-block', fontWeight: 'bold' }}>{translate('application.profile.message.pending')}:</Typography>
                                    <Typography style={{ display: 'inline-block' }}>{this.state.record.unconfirmedEmail}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ color: 'red' }}>{translate('application.profile.message.unconfirmed')}</Typography>
                                </Grid>
                            </Grid>
                        }
                        <EmailEdit handleExpanded={this.handleChange} />
                    </ExpansionPanelDetails>

                    <Divider />

                    <ExpansionPanelActions>
                    <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            startIcon={<CancelIcon />}
                            onClick={() => this.handleChange('panel-1')}
                        >
                            {translate('ra.action.cancel')}
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                            onClick={() => document.getElementById('emailEditForm').dispatchEvent(new Event('submit', { cancelable: true }))}
                        >
                            {translate('ra.action.save')}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>

                <ExpansionPanel expanded={this.state.expanded === 'panel-2'} onChange={() => this.handleChange('panel-2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <PasswordIcon className={classes.icon} />
                        <Typography>{translate('application.profile.fields.password')}</Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <PasswordForm handleExpanded={this.handleChange} />
                    </ExpansionPanelDetails>

                    <Divider />

                    <ExpansionPanelActions>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            startIcon={<CancelIcon />}
                            onClick={() => this.handleChange('panel-2')}
                        >
                            {translate('ra.action.cancel')}
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                            onClick={() => document.getElementById('passwordEditForm').dispatchEvent(new Event('submit', { cancelable: true }))}
                        >
                            {translate('ra.action.save')}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
                <ExpansionPanel expanded={this.state.expanded === 'panel-3'} onChange={() => this.handleChange('panel-3')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <AccountCircleIcon className={classes.icon} />
                        <Typography>{translate('application.profile.fields.delete_account')}</Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <Typography>
                            {translate('application.profile.fields.delete_account_text_1')}
                            <br />
                            {translate('application.profile.fields.delete_account_text_2')}
                        </Typography>
                    </ExpansionPanelDetails>

                    <Divider />

                    <ExpansionPanelActions>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            startIcon={<CancelIcon />}
                            onClick={() => this.handleChange('panel-3')}
                        >
                            {translate('ra.action.cancel')}
                        </Button>

                        <Button
                            variant="outlined"
                            // color="error"
                            className={classes.deleteButton}
                            startIcon={<DeleteIcon />}
                            onClick={() => this.setConfirmationDialogOpen(true)}
                        >
                            {translate('ra.action.delete')}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
                <Confirm
                    isOpen={this.state.confirmationDialogOpen}
                    title={translate('application.profile.fields.delete_account_confirmation_title')}
                    content={translate('application.profile.fields.delete_account_confirmation_content')}
                    onConfirm={() => this.setPasswordDialogOpen(true)}
                    onClose={() => this.setConfirmationDialogOpen(false)}
                />
                {this.state.record && <PasswordDialog userProfile={this.state.record} visible={this.state.passwordDialogOpen} onClose={() => this.setPasswordDialogOpen(false)} callback={this.deleteAccount}/>}
            </div>
        )
    }
}

export default compose(
    connect(null, {
        showNotification
    }),
    translate,
    withStyles(styles)
)(Security);
import React, { Component, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import parse from 'html-react-parser';


const useStyles = makeStyles(theme => ({
  expansionPanelRoot: {
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '5rem',
  },
  searchFieldroot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 800,
    right:'0',
    left:'0',
    margin:'auto',
  },
  question: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchHead:{
    textAlign: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  searchTitle:{

  },  
  searchSubtitle:{
    marginTop: '2rem',
    marginBottom: '2rem',
  }
}));

function FaqPage() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [expanded, setExpanded] = useState(null);

  const [count, setCount] = useState(0);

  useEffect(() => {
    axios.defaults.headers.common['Cache-Control'] = 'no-cache' 
    axios.get('/api/faq?v='+Math.random()).then(res => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    function getFilteredArray(arr, searchText) {
      if (searchText.length === 0) {
        return arr;
      }
      return filterArrayByString(arr, searchText);
    };

    function filterArrayByString(mainArr, searchText) {
      if (searchText === '') {
        return mainArr;
      }
      searchText = searchText.toLowerCase();
      return mainArr.filter(itemObj => {
        return searchInObj(itemObj, searchText);
      });
    };

    function searchInObj(itemObj, searchText) {
      for (const prop in itemObj) {
        if (!itemObj.hasOwnProperty(prop)) {
          continue;
        }

        const value = itemObj[prop];

        if (typeof value === 'string') {
          if (searchInString(value, searchText)) {
            return true;
          }
        }

        else if (Array.isArray(value)) {
          if (searchInArray(value, searchText)) {
            return true;
          }
        }

        if (typeof value === 'object') {
          if (searchInObj(value, searchText)) {
            return true;
          }
        }
      }
    }

    function searchInArray(arr, searchText) {
      for (const value of arr) {
        if (typeof value === 'string') {
          if (searchInString(value, searchText)) {
            return true;
          }
        }

        if (typeof value === 'object') {
          if (this.searchInObj(value, searchText)) {
            return true;
          }
        }
      }
    }

    function searchInString(value, searchText) {
      return value.toLowerCase().includes(searchText);
    }

    setFilteredData(getFilteredArray(data, searchText));
  }, [data, searchText]);

  function handleSearch(event) {
    setSearchText(event.target.value);
  };

  return (
    <dir>
      <div className={classes.searchHead}> 
        <Typography variant="h3" className={classes.title}>
            We're here to help
        </Typography>
        <Typography variant='subtitle1' className={classes.searchSubtitle}>
            Frequently asked questions
        </Typography>
        <Paper className={classes.searchFieldroot}>
          <IconButton className={classes.iconButton} aria-label="search" onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder="Search in faqs..."
            inputProps={{ 'aria-label': 'search faqs' }}
            onChange={handleSearch}
          />
        </Paper>
      </div>

      <div className={classes.expansionPanelRoot}>
      {useMemo(() => {
        return filteredData.map((faq) => (
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.question}>{faq.question}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>{parse(faq.answer)}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>

        ))
      }, [filteredData, classes, expanded])}
      </div>
    </dir>
  );
}

class Faqs extends Component {
  render() {
    return (
      <div>
        <FaqPage />
      </div>
    );
  }
}

export default Faqs;
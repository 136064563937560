import axios from 'axios';

export function getQueryParameters() {

    if (window.location.href == null || window.location.href == '') {
        return new Map();
    }

    let query = window.location.href.split("?")[1];

    if (query == null || query == 'undefined'){
        return new Map();
    }

    let parameters = query.split("&");
    let result = {};

    for (let index = 0; index < parameters.length; index++) {
        let pair = parameters[index].split("=");

        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1]);

        if (typeof result[key] === "undefined") {
            result[key] = value;
        }
    }
    return result;
}

function getSubdomain() {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        
        const parameters = getQueryParameters();
        return parameters.subdomain;
    } else {
        return window.location.hostname.split(".")[0];
    }
}

export const isRwe = () => {

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    if (decodedToken) {
        const companyId = decodedToken.company_id;

        return parseInt(companyId) === parseInt(process.env.REACT_APP_RWE_ID);
    }
    return false;
}

export const isZf = () => {

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    if (decodedToken) {
        const companyId = decodedToken.company_id;

        return parseInt(companyId) === parseInt(process.env.REACT_APP_ZF_ID);
    }
    return false;
}

export const isSiemens = () => {

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    if (decodedToken) {
        const companyId = decodedToken.company_id;

        return parseInt(companyId) === parseInt(process.env.REACT_APP_SIEMENS_ID);
    }
    return false;
}

const companies = new Map();
companies.set('rwe', parseInt(process.env.REACT_APP_RWE_ID));
const whiteLabels = new Map();
whiteLabels.set('rwe', process.env.REACT_APP_RWE_HOST_URL);

export function isAllowed(companyId) {

    var result = true;

    var subdomain = getSubdomain();
    if (subdomain && subdomain !== '') {
        const id = companies.get(subdomain);

        if (id && id !== companyId) {
            result = false;
        }
    }
    return result;
}

export function isWhiteLabel() {

    var result = false;

    var subdomain = getSubdomain();
    if (subdomain && subdomain !== '') {
        const id = companies.get(subdomain);

        if (id) {
            result = true;
        }
    }
    return result;
}

export function getApplicationUrl() {

    if (!isWhiteLabel()) {
        return process.env.REACT_APP_USER_HOST_URL;
    } else {

        var subdomain = getSubdomain();
        return whiteLabels.get(subdomain);
    }
}

export function setDarkRichTextInput() {

    var toolbarElements = document.querySelectorAll('.ql-stroke');
    toolbarElements.forEach(element => {
        element.style.stroke = 'white'
    });
    
    toolbarElements = document.querySelectorAll('.ql-fill');
    toolbarElements.forEach(element => {
        element.style.fill = 'white'
    });

    const pickerLabels = document.querySelectorAll('.ql-picker-label');
    pickerLabels.forEach(element => {
        element.style.color = 'white';
    });
    
    const editors = document.querySelectorAll('.ql-editor');
    editors.forEach(element => {
        element.style.backgroundColor = 'inherit';
        element.style.borderBottom = '1px solid white';
    });
}

/**
 * axios
 */
export const defaultConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'client-type': 'USER',
    },
    timeout: 60000,
    withCredentials: false
};

const instance = axios.create(defaultConfig);
instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

export const fetch = options => {
    return new Promise((resolve, reject) => {
        instance(options)
            .then(response => {
                console.log(response)
                resolve(response);
            })
            .catch(error => {
                console.log(error)
                reject(error);
                let errormsg = "";
                if (error.response != null && error.response.data != null) {
                    errormsg = error.response.data.message;
                }
                if (typeof (errormsg) == "undefined"){
                    errormsg = "";
                }
                console.log("errormsg:" + errormsg)
                let fieldnoerror = true;
                for (let option in options.data) {
                    if (errormsg.indexOf((" " + option + " ")) != -1) {
                        let fields = document.getElementsByName(option)
                        if (typeof (fields) != "undefined" && fields!=null && fields.length > 0){
                            fields[0].parentElement.classList.add("Mui-error");
                            fieldnoerror = false;
                        }
                    }
                }
                let errorMessage = document.getElementById("error-message");
                if (typeof (errorMessage) != undefined && errorMessage != null)
                    errorMessage.innerHTML = errormsg;
                errorMessage.style.color = 'red';
            });
    });
};
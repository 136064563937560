const KnooingFeatureBulletsTemplate = `
<table style="border-collapse: collapse;">
<tr style="vertical-align: top">
    <td style="border: none; padding: 5px;">{cell01}</td>
    <td style="border: none; padding: 5px;">{cell02}</td>
</tr>
<tr style="vertical-align: top">
    <td style="border: none; padding: 5px;">{cell11}</td>
    <td style="border: none; padding: 5px;">{cell12}</td>
</tr>
<tr style="vertical-align: top">
    <td style="border: none; padding: 5px;">{cell21}</td>
    <td style="border: none; padding: 5px;">{cell22}</td>
</tr>
<tr style="vertical-align: top">
    <td style="border: none; padding: 5px;">{cell31}</td>
    <td style="border: none; padding: 5px;">{cell32}</td>
</tr>
<tr style="vertical-align: top">
    <td style="border: none; padding: 5px;">{cell41}</td>
    <td style="border: none; padding: 5px;">{cell42}</td>
</tr>
<tr style="vertical-align: top">
    <td style="border: none; padding: 5px;">{cell51}</td>
    <td style="border: none; padding: 5px;">{cell52}</td>
</tr>
</table>
`;

export default KnooingFeatureBulletsTemplate;
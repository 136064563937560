import React, { Component, Fragment, useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { Select, MenuItem, Card, CardHeader, CardContent, TablePagination, Grid, List, ListItem, RadioGroup, Radio, FormControlLabel, ListItemAvatar, Avatar, Stepper, Step, StepLabel, ListItemIcon, ListItemText, Typography, Chip, Icon, IconButton, Tooltip, Button, Checkbox } from "@material-ui/core";

import CrossIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/CheckCircle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import InformationIcon from '@material-ui/icons/Info';

import classnames from 'classnames';

import { ATTACHMENT_URL_PART } from './InternalInsight';

const useStyles = makeStyles(theme => ({
    root: {
        height: '20em',
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftColumn: { flex: 1, marginRight: '1em' },
    rightColumn: { flex: 1, marginLeft: '1em' },
    singleColumn: { marginBottom: '2em' },
    fab: {
        margin: theme.spacing(1),
        backgroundColor: '#304d75'
    },
    tableContainer: {
        maxWidth: '85vw',
        overflowX: 'scroll',
        margin: '0 auto',
    },
    table: {
        tableLayout: 'fixed',
        margin: '0px auto 0px auto',
    },
    fixedHeader: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        height: 99,
        backgroundColor: 'white',
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
    },
    fixedCell: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        borderRight: '1px solid black',
        height: 29,
        backgroundColor: 'white',
        zIndex: 1,
    },
    header: {
        fontSize: '1.0rem', 
        textTransform: 'uppercase'
    },
    cell: {
        width: 200,
        borderCollapse: 'collapse',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        height: 29
    },
    firstHeader: {
        width: 200,
        paddingLeft: 300,
        height: 99,
    },
    firstCell: {
        width: 200,
        paddingLeft: 300,
        borderCollapse: 'collapse',
        borderRight: '1px solid black',
        height: 29
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    border : {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    green: {
        color: 'rgb(144, 198, 83)',
    },
    orange: {
        color: '#ffb74d',
    },
    blue: {
        color: '#39a1de',
    },
    yellow: {
        color: '#ecd476',
    }
}));

function calculateColumnWidth(totalColumns) {

    let clientWidth = document.documentElement.clientWidth;

    const totalWidth = clientWidth * 85 / 100 - 300;
    const columnWidth = totalWidth / totalColumns;
    const minimumColumnWidth = 200;

    return columnWidth < minimumColumnWidth ? minimumColumnWidth : columnWidth;
}

const renderKeySynonyms = (data, translate) => {

    if (data.keySynonyms.length === 0) {
        return '';
    }

    return translate('resources.suggestions.matrix.synonyms') + ': ' + data.keySynonyms.join(', ');
}

const renderUrl = (data, translate, record) => {

    if (data.header === null) {
        return '';
    }

    if (data.header.includes(ATTACHMENT_URL_PART)) {
        return <span>{translate(`resources.projects.internal_insight.collected_file`)} {record.productName}</span>
    }

    return (
        <a href={data.header} target="_blank">
            {data.header}
        </a>
    )
}

const renderTextFragment = (data, translate) => {

    if (data.textFragment === null) {
        return translate('resources.suggestions.matrix.no_details_available');
    }

    return (
        <Typography variant="body2">{data.textFragment}</Typography>
    )
}

const MatrixContent = ({ matrix, type, record }) => {

    const translate = useTranslate();
    const classes = useStyles();

    return matrix.rows.map((row, index) => (

        <TableRow key={index}>
            <TableCell component="th" scope="row">
                <Tooltip title={renderKeySynonyms(row, translate)} placement="bottom-start"><Typography className={classes.textOverflow}>{row.key}</Typography></Tooltip>
            </TableCell>

            {row.restCells.map((cell, index) => (
                <Fragment>
                    <TableCell style={{ width: 100 }} align="center">
                        {cell.found ? 
                            <CheckIcon 
                                className={classnames({
                                    [classes.green]: cell.foundKeyWeight == 0 || cell.foundKeyWeight == 1,
                                    [classes.yellow]: cell.foundKeyWeight == 0.5,
                                })}
                            />
                            :
                            <CheckIcon style={{ color: '#cccccc' }} />
                        }
                    </TableCell>
                    <TableCell>
                        {cell.found ? 
                            renderUrl(cell, translate, record)
                            :
                            ''
                        }
                    </TableCell>
                    <TableCell>
                        {cell.found ? 
                            renderTextFragment(cell, translate)
                            :
                            ''
                        }
                    </TableCell>
                </Fragment>
            ))}
        </TableRow>
    ));

}

export const MatrixSummary = (props) => {

    const { record, project } = props;

    const classes = useStyles();
    const [matrix, setMatrix] = useState(null);

    const translate = useTranslate();

    useEffect(() => {

        const token = localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/projects/${project.id}/external-matrix/${record.id}`, { 
                method: 'GET', 
                headers: { 'Authorization': `Bearer ${token}` }, 
            })
            .then(response => response.json())
            .then(matrix => {
                setMatrix(matrix);
            })
            .catch((exception) => {

            });
    }, []);

    return matrix !== null ? 
        <Fragment>
            <div>
                <Table aria-label="matrix">
                    <TableHead>
                        <TableRow>

                            <TableCell className={classes.header}>
                                {translate('resources.suggestions.matrix.criteria')}
                                {/* <Tooltip title={translate('resources.suggestions.matrix.criteria_tooltip')}>
                                    <InformationIcon style={{ color: '#39a1de', position: 'relative', left: 8 }} />
                                </Tooltip> */}
                            </TableCell>

                            <TableCell align="center" style={{ width: 100 }} className={classes.header}>
                                Matching
                            </TableCell>
                            <TableCell className={classes.header}>
                                {translate('resources.suggestions.matrix.source')}
                            </TableCell>
                            <TableCell className={classes.header}>
                                {translate('resources.suggestions.matrix.excerpt')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <MatrixContent matrix={matrix} record={record} />
                    </TableBody>
                </Table>
            </div>
        </Fragment>
        :
        <div style={{ display: 'flex', justifyContent: 'center', padding: 16 }}>
            <CircularProgress />
        </div>;
}
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DropzoneArea} from 'material-ui-dropzone';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import axios from "axios";
import {
    DateField,
    SelectField,
} from 'react-admin';

import ProjectMessageFileUploadForm from './ProjectMessageFileUploadForm';


class ProjectMessageCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      projectId: 0,
    };
  }

  componentWillMount() {
      const { record, props } = this.props;
      var hashFromUrl = window.location.hash;
      var thenum = hashFromUrl.match(/\d+/)[0];
      this.setState({
        projectId: thenum
      });

  }

  handleClose = () => {
    var dialogElement = document.getElementById("add_new_message_dialog");
    dialogElement.style.display = "none";
    window.history.back();
  };

  handleSubmit = () => {
    var textFieldElement = document.getElementById("new_project_message");
    textFieldElement.style.display = "none";
  };

  render() {
      return (
        <div>
          <Dialog open={this.props.dialogShouldBeOpened} onClose={this.handleClose} aria-labelledby="form-dialog-title" id="add_new_message_dialog">
              <DialogTitle id="form-dialog-title">Write a message</DialogTitle>
              <ProjectMessageFileUploadForm initOpen="true" projectId={this.state.projectId}/>
            </Dialog>
        </div>
      );
  }
}

export default ProjectMessageCreate;

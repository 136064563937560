import React, { Fragment, useState, useEffect } from "react";
import { TextField as FormTextField, RichTextField, Toolbar, SaveButton, SimpleForm, Edit, TextInput, SelectInput, DateInput, translate, required, useTranslate, showNotification, REDUX_FORM_NAME } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import TextField from '@material-ui/core/TextField';

import TopicInput from '../topic/TopicInput'
import UnsavedDataObserver from '../UnsavedDataObserver';

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';

import { fetch, setDarkRichTextInput } from '../utilities';

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const onSubmit = (values) => {
    let fields = document.getElementsByName("foundedAt");
    if (typeof (fields) != "undefined" && fields != null && fields.length > 0) {
        values.foundedAt = fields[0].value;
    }else{
        values.foundedAt = null;
    }

    fetch({
        method: "PUT",
        url: "/companies/" + values.id,
        data: values
    })
        .then((response) => {
            document.location.href = "#/companies/" + values.id + "/show";
        })
        .catch((error) => {
            //console.log(error);
            // todo
            document.location.href = "#/companies/" + values.id + "/show";
        })

}

const CompanyEdit = ({ permissions, ...props }) => {

    const translate = useTranslate();
    const foundedAtMessage = translate('resources.companies.fields.foundedAt');

    useEffect(() => {
        if (props.theme === 'dark') {
            setDarkRichTextInput();
        }
    });

    const CustomerDateInputWithMaterialUi = ({ record }) => {

        var defaultDateValue;
        if (record.foundedAt == null) {
            defaultDateValue = null;
        } else {
            defaultDateValue = new Date(record.foundedAt);
        }
        const [selectedDate, setSelectedDate] = React.useState(defaultDateValue);

        const handleDateChange = date => {
            setSelectedDate(date);
        };

        // Replace: load defaults from the server.
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="foundedAt"
                    source="foundedAt"
                    name="foundedAt"
                    label={foundedAtMessage}
                    format="yyyy-MM-dd"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>
        )
    };

    return (
        <Fragment>

            <Edit undoable={false} title='resources.companies.name' {...props}>
                <SimpleForm redirect="show" variant="standard" toolbar={<CustomToolbar />} onSubmit={onSubmit}  >

                    <div>
                        <p id="error-message"></p>
                    </div>

                    <UnsavedDataObserver />

                    {permissions && permissions.includes('ROLE_EXECUTIVE') ? <TextInput style={{ marginBottom: '2rem' }} source="name" validate={required(translate('application.validation.required'))} /> : <FormTextField style={{ color: 'rgba(0, 0, 0, 0.54)' }} source="name" />}
                    <br />
                    <RichTextInput source="description" />
                    <br />
                    {permissions && permissions.includes('ROLE_EXECUTIVE') ? <RichTextInput source="address" /> : <RichTextField style={{ color: 'rgba(0, 0, 0, 0.54)' }} source="address" />}
                    <br />
                    <TextInput source="website" />
                    <br />
                    <TopicInput label="resources.companies.fields.topics" source="topics" />
                    <br />
                    <SelectInput source="size" choices={[
                        { id: 'LESS_THAN_10', name: 'resources.companies.field_values.size.less_than_10' },
                        { id: 'LESS_THAN_50', name: 'resources.companies.field_values.size.less_than_50' },
                        { id: 'LESS_THAN_100', name: 'resources.companies.field_values.size.less_than_100' },
                        { id: 'LESS_THAN_500', name: 'resources.companies.field_values.size.less_than_500' },
                        { id: 'LESS_THAN_1K', name: 'resources.companies.field_values.size.less_than_1K' },
                        { id: 'LESS_THAN_5K', name: 'resources.companies.field_values.size.less_than_5K' },
                        { id: 'LESS_THAN_10K', name: 'resources.companies.field_values.size.less_than_10K' },
                        { id: 'GREATER_THAN_10K', name: 'resources.companies.field_values.size.greater_than_10K' },
                    ]} />
                    <br />
                    <CustomerDateInputWithMaterialUi />
                </SimpleForm>
            </Edit>
        </Fragment>
    )
};

export default compose(
    translate,
    connect(
        state => ({
            theme: state.theme
        }),
        { showNotification }
    )
)(CompanyEdit);
import React from 'react';
import { Create } from 'react-admin';

import { ItVendorForm } from './ItVendorForm';

export const ItVendorCreate = (props) => {
    
    return (
        <Create 
            undoable={false} 
            title=" " 
            {...props}
        >
            <ItVendorForm permissions={props.permissions} />
        </Create>
    )
}
export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        
    },
    overrides: {
        MuiButton: {
            textPrimary: {
                color: 'white'
            },
            outlinedPrimary: {
                color: 'white',
                borderColor: 'white'
            }
        },
        MuiTypography: {
            root: {
                color: 'white',
            },
            body2: {
                opacity: '0.7'
            },
            colorTextSecondary: {
                opacity: '1',
                color: 'white'
            }
        },
    }
};

export const lightTheme = {
    palette: {
        primary: {
            light: '#64c7f3',
            main: '#304d75',
            dark: '#1e2f48',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff77a9',
            main: '#ec407a',
            dark: '#b4004e',
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiTab: {
            root: {
                '&$selected': {
                    backgroundColor: '#e9f5fb',
                    borderTopLeftRadius: 7,
                    borderTopRightRadius: 7,
                }
            },
        },
    }
};
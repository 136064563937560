import React, { Fragment, useState, cloneElement } from 'react';
import {
    SelectField, 
    List,
    Datagrid,
    TextField,
    DateField,
    Filter,
    SearchInput,
    EditButton,
    translate,
    ReferenceField,
    SingleFieldList, 
    ReferenceManyField,
    NumberField,
    ChipField,
    FunctionField,
    TopToolbar, 
    CreateButton, 
    sanitizeListRestProps,
    useTranslate,
} from 'react-admin';

import PropTypes from 'prop-types';

import { Typography, Chip, Tooltip } from '@material-ui/core';

import classnames from 'classnames';

import { connect } from 'react-redux';

import compose from 'recompose/compose';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';

import { Actions } from './Actions';
import { ArchivedProjectActions } from './ArchivedProjectActions';

const CustomFilter = (props) => (
    <Filter variant="standard" {...props}>
        <SearchInput source="query" alwaysOn />
    </Filter>
);

const ListActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    viewMode,
    ...rest
}) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {viewMode === 'ACTIVE_PROJECTS' && <CreateButton basePath={basePath} />}
    </TopToolbar>
);

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

const styles = theme => ({
    padding: {
        padding: theme.spacing(0, 2, 0, 1),
    },
    grey: { backgroundColor: '#e0e0e0' },
    green: { backgroundColor: '#dcedc8' },
    orange: { backgroundColor: '#ffe0b2' },
});

const useStyles = makeStyles(theme => ({
    white: {
        color: 'white',
        opacity: '1'
    },
    blue: {
        color: '#304d75'
    }
}));

const Status = withStyles(styles)(({ classes, ...props }) => {
    const { record, translate } = props;

    const message = record.id !== 'IN_REVIEW' ? '' : 'resources.projects.message.in_review';

    return <Tooltip title={translate(message)}>
        <Chip 
            className={classnames({
                [classes.grey]: record.id === 'IN_REVIEW',
                [classes.green]: record.id === 'APPROVED',
                [classes.orange]: record.id === 'ARCHIVED',
            })} 
            label={translate(record.name)}
        ></Chip>
    </Tooltip>
});

const State = withStyles(styles)(({ classes, ...props }) => {

    const { record } = props;
    const translate = useTranslate();

    const message = '';

    return (
        <Tooltip title={translate(message)}>
            <Chip label={translate(record.name)}></Chip>
        </Tooltip>
    )
});

const ProjectList = props => {

    const { theme, translate } = props;

    const [viewMode, setViewMode] = useState('ACTIVE_PROJECTS');

    const classes = useStyles();

    const toggleViewMode = () => {
        if (viewMode === 'ACTIVE_PROJECTS') {
            setViewMode('ARCHIVED_PROJECTS')
        } else {
            setViewMode('ACTIVE_PROJECTS')
        }
    }

    return (
        <Fragment>
            <Typography variant="h6" align="center" style={{ fontWeight: 'bold', marginTop: 16 }}>
                {viewMode === 'ACTIVE_PROJECTS' ?
                    translate('resources.projects.active_requests')
                    :
                    translate('resources.projects.archived_requests')
                }
            </Typography>
            <List 
                {...props} 
                exporter={false} 
                filterDefaultValues={{ companyId: JSON.parse(localStorage.getItem('decoded_token')).company_id, requesterId: JSON.parse(localStorage.getItem('decoded_token')).user_name }} 
                filter={{ archived: viewMode === 'ARCHIVED_PROJECTS' }}
                filters={<CustomFilter />} 
                sort={{ field: 'createdAt', order: 'DESC' }} 
                bulkActionButtons={false}
                title="application.menu.requests"
                actions={<ListActions viewMode={viewMode} />}
            >
                <Datagrid rowClick="show">
    
                    <TextField source="name" className={theme === 'dark' ? classes.white : classes.blue } />

                    <SelectField 
                        source="offering" 
                        choices={[
                            { id: 'SOFTWARE', name: 'Software' },
                            { id: 'SERVICE', name: 'Service' },
                        ]} 
                    />

                    <SelectField 
                        source="state" 
                        choices={[
                            { id: 'ANALYSIS_RUNNING', name: 'resources.projects.field_values.state.analysis_running' },
                            { id: 'MATCHING_AVAILABLE', name: 'resources.projects.field_values.state.matching_available' },
                            { id: 'VERIFICATION_RUNNING', name: 'resources.projects.field_values.state.verification_running' },
                            { id: 'SOURCING', name: 'resources.projects.field_values.state.sourcing' },
                        ]} 
                        translateChoice={false}
                        optionText={<State {...props} />} 
                    />
    
                    <ReferenceField source="ownerId" reference="users" linkType={false} allowEmpty>
                        <FunctionField render={record => `${record.firstName} ${record.lastName}`} />
                    </ReferenceField>
    
                    <DateField source="createdAt" />
                    
                    {/* <FunctionField label="application.dashboard.suggestions.name" render={record => {
                            let count = record.suggestions.filter(suggestion => suggestion.consumerStatus === 'PENDING').length;
                            let color = count > 0 ? 'secondary' : 'default';
    
                            return (
                                <Badge showZero style={{ paddingLeft: 12 }} badgeContent={count} color={color}>
                                    <span></span>
                                </Badge>
                            )
                        }} 
                    /> */}
    
                    
                    {viewMode === 'ACTIVE_PROJECTS' ? 
                        <Actions />
                        :
                        <ArchivedProjectActions />
                    }
                </Datagrid>
            </List>
            <div style={{ textAlign: 'center', marginTop: 16 }}>
                <Button color="primary" onClick={toggleViewMode}>
                    {viewMode === 'ACTIVE_PROJECTS' ? 
                        translate('resources.projects.action.archived_projects')
                        :
                        translate('resources.projects.action.active_projects')
                    }
                </Button>
            </div>
        </Fragment>
    )
};

export default compose(
    // withStyles(styles),
    translate,
    connect(
        state => ({
            theme: state.theme
        }),
    )
) (ProjectList);
import React, { useEffect, useState } from "react";
import { useTranslate, showNotification } from "react-admin";
import axios from "axios";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import usePasswordVerification from "./PasswordStrength/PasswordVerification";
import PasswordStrengthComp from "./PasswordStrength/PasswordStrengthComp";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomTooltip from "../../component/CustomTooltip";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "80%"
  },
  gridcontainer: {
    padding: theme.spacing(1),
    marginBottom: "20px"
  }
}));

export const PasswordEditForm = connect(undefined, { showNotification })(
  props => {
    var isStrong_aux = true;
    const classes = useStyles();
    const translate = useTranslate();
    const { showNotification, handleExpanded } = props;
    const [
      passwordToVerify,
      setPasswordToVerify,
      isStrong
    ] = usePasswordVerification("ExamplePassword1$");

    function handleValidate(values) {
      const errors = {};
      if (!values.oldPassword) {
        errors.oldPassword = translate("application.validation.required");
      }
      if (!values.newPassword) {
        errors.newPassword = translate("application.validation.required");
      }
      if (!values.passwordConfirmation) {
        errors.passwordConfirmation = translate(
          "application.validation.required"
        );
      } else if (values.newPassword != values.passwordConfirmation) {
        errors.passwordConfirmation =
          "New password and password confirmation do not match!";
      }
      return errors;
    }

    function onSubmit(values) {
      const rules = /^.*(?=.{8,20})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\\\_\+\`\~\.\,\<\>\/\?\;\:\'\"\\\|\[\]\{\}]).*$/;
      const errors = handleValidate(values);

      isStrong_aux = rules.test(values.newPassword);
      setPasswordToVerify(values.newPassword);

      if (!isStrong_aux) {
        return errors;
      }

      if (JSON.stringify(errors) !== "{}") return errors;

      let putUrl =
        process.env.REACT_APP_API_URL +
        "/users/" +
        JSON.parse(localStorage.getItem("decoded_token")).user_name +
        "/password";
      const token = localStorage.getItem("token");
      axios
        .put(putUrl, JSON.stringify(values), {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`
          }
        })
        .then(function(response) {
          handleExpanded("panel-2");
          showNotification(
            "application.profile.notification.password.updated_success"
          );
          values.oldPassword = "";
          values.newPassword = "";
          values.passwordConfirmation = "";
          document.getElementsByName("oldPassword")[0].value = "";
          document.getElementsByName("newPassword")[0].value = "";
          document.getElementsByName("passwordConfirmation")[0].value = "";
        })
        .catch(function(error) {
          showNotification(
            "application.profile.notification.password.updated_error",
            "warning"
          );
        })
        .finally(function() {});
    }
    return (
      <div style={{ width: "20em" }}>
        <Form
          onSubmit={onSubmit}
          validate={handleValidate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate id="passwordEditForm">
              <div>
                <Grid container spacing={1} className={classes.gridcontainer}>
                  <Grid item xs={12} sm={11}>
                    <Field
                      fullWidth
                      required
                      name="oldPassword"
                      type="password"
                      component={TextField}
                      label={translate(
                        "application.profile.fields.old_password"
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    className={classes.helpIconGrid}
                  ></Grid>
                  <Grid item xs={12} sm={11}>
                    <Field
                      fullWidth
                      required
                      name="newPassword"
                      type="password"
                      component={TextField}
                      label={translate(
                        "application.profile.fields.new_password"
                      )}
                    ></Field>
                  </Grid>
                  <CustomTooltip pathToStringForTranslation="application.profile.fields.password_verification_tooltip"></CustomTooltip>

                  <Grid item xs={12} sm={11}>
                    <Field
                      fullWidth
                      required
                      name="passwordConfirmation"
                      type="password"
                      component={TextField}
                      label={translate(
                        "application.profile.fields.password_confirmation"
                      )}
                    />
                    <PasswordStrengthComp
                      isStrong={isStrong}
                    ></PasswordStrengthComp>
                  </Grid>
                </Grid>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
);

export default PasswordEditForm;

import React, { useEffect, useState, useMemo } from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
// import CheckboxTree from 'react-checkbox-tree';
import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from 'react';
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
// import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
// import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
// import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
// import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
// import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
// import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import { 
    Button, 
    Typography, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogContentText, 
    DialogActions, 
    LinearProgress,
    Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import UnsavedDataDialog from './UnsavedDataDialog';
import { DevicesOther } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        // flexGrow: 1,
        width: "105%"
    },
    // gridcontainer: {
    //     padding: theme.spacing(1),
    //     marginBottom: "20px"
    // }
}));


const useTreeItemStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.secondary,
        "&:focus > $content": {
            backgroundColor: `var(--tree-view-bg-color, 'white')`,
            // color: "var(--tree-view-color)"
        }
    },
    content: {
        color: theme.palette.text.secondary,
        // borderTopRightRadius: theme.spacing(2),
        // borderBottomRightRadius: theme.spacing(2),
        // paddingRight: theme.spacing(1),
        backgroundColor: 'transparent',
        fontWeight: theme.typography.fontWeightMedium,
        "$expanded > &": {
            fontWeight: theme.typography.fontWeightRegular
        }
    },
    group: {
        marginLeft: 0,
        "& $content": {
            paddingLeft: theme.spacing(2)
        }
    },
    expanded: {},
    label: {
        fontWeight: "inherit",
        color: "inherit"
    },
    labelRoot: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.25, 0)
    },
    labelRootMargin: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.25, 0),
        marginLeft: theme.spacing(3),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
        padding: 0
    },
    labelText: {
        fontWeight: "inherit",
        flexGrow: 1
    },
    parentNode: {
        "& ul li $content": {
            paddingLeft: `calc(var(--node-depth) * ${theme.spacing(4)}px)`
        }
    }
}));

const StyledTreeItem = React.memo(function StyledTreeItem({ label, color = 'black', backgroundColor = 'white', depth = 0, nodeId, onChange, checked, ...other }) {
    const classes = useTreeItemStyles();
    return (
        <TreeItem
            className={other.children ? classes.parentNode : undefined}
            label={
                <div className={!other.children ? classes.labelRootMargin : classes.labelRoot}>
                    <Checkbox
                        className={classes.labelIcon}
                        onClick={event => (event.stopPropagation())}
                        onChange={(event, checked) => onChange(event, checked, nodeId)}
                        checked={checked.includes(nodeId)}
                        color="primary"
                    />
                    <Typography variant="body2" className={classes.labelText}>
                        {label}
                    </Typography>
                </div>
            }

            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': backgroundColor,
                "--node-depth": depth
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            nodeId={nodeId}
            {...other}
        >
            {React.Children.map(other.children, child => {
                return React.cloneElement(child, { depth: depth + 1 });
            })}
        </TreeItem>
    );
});


const getExpanded = (nodes, expanded) => {

    for (let index = 0; index < nodes.length; index++) {
        const node = nodes[index];
        expanded.push(node.value);
        if (node.children) {
            getExpanded(node.children, expanded);
        }
    }
}

const searchNode = (nodes, capability, capabilities) => {

    let foundNode = null;

    for (let index = 0; index < nodes.length; index++) {
        const node = nodes[index];
        if (node.keyPhraseId == capability) {
            
            if (node.parentKeyPhraseId) {
                if (capabilities.some(capability => capability == node.parentKeyPhraseId)) {
                    foundNode = node;
                }
            } else {
                foundNode = node;
            }
        }
        if (!foundNode && node.children) {
            foundNode = searchNode(node.children, capability, capabilities);
        }
    }

    return foundNode;
}

const searchNodes = (nodes, capability, foundNodes) => {

    for (let index = 0; index < nodes.length; index++) {
        const node = nodes[index];
        if (node.keyPhraseId == capability) {
            foundNodes.push(node);
        }
        if (node.children) {
            searchNodes(node.children, capability, foundNodes);
        }
    }
}

const getChecked = (nodes, capabilities) => {
   
    return capabilities
        .flatMap(capability => {
            let foundNodes = [];
            searchNodes(nodes, capability, foundNodes);
            return foundNodes;
        })
        .filter(node => node != null)
        .map(node => node.value);
}

const CapabilityManagement = ({ record, refresh }) => {

    const [open, setOpen] = useState(false);
    const [unsavedOpen, setUnsavedOpen] = useState(false);

    const [initialChecked, setInitialChecked] = useState([]);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [nodes, setNodes] = useState();
    const classes = useStyles();

    const dataProvider = useDataProvider();

    const translate = useTranslate();

    useEffect(() => {
        if (open) {
            dataProvider.getOne('companies', { id:  record.companyId + '/capabilities' })
                .then(({ data }) => {
                    let cheked = getChecked(data, record.capabilities);
                    setChecked(cheked);
                    setInitialChecked(cheked);

                    let expanded = [];
                    getExpanded(data, expanded);
                    setExpanded(expanded);

                    setNodes(data);
                });
        }
    }, [open]);

    const save = () => {

        if (initialChecked != checked) {

            let capabilities = [...new Set(checked.map(entry => entry.split('/').pop()))];

            let payload = {
                capabilities: capabilities
            }
            
            dataProvider
                .update('recommendations', { id: `${record.id}/capabilities`, data: payload })
                .then(response => {
                    
                    refresh();
                })
                .catch(error => {

                })
                .finally(() => {
                    setOpen(false);
                });
            
        } else {
            setOpen(false);
        }
    }

    const removeLastElement = (value) => {

        let values = value.split('/');
        if (values.length > 1) {
            values.pop();
        }

        return values.join('/');
    }

    const getLastElement = (value) => {

        return value.split('/').pop();
    }

    const handleCheck = (previousChecked, targetNode) => {
        
        let value = targetNode.value;
        if (targetNode.checked) {

            let updatedChecked = [...previousChecked];

            // updatedChecked.push(value); // only this particular

            // NEW
            // add all with same value
            let foundNodes = []
            searchNodes(nodes, getLastElement(value), foundNodes);
            foundNodes = foundNodes.map(node => node.value);

            updatedChecked = updatedChecked.concat(foundNodes);

            while (value.includes('/')) {
                value = removeLastElement(value);
                if (!checked.some(checkedValue => checkedValue == value)) {
                    updatedChecked.push(value);
                }
            }

            setChecked(updatedChecked);
        } else {
            // setChecked([...previousChecked.filter(checkedValue => checkedValue != value)]);
            setChecked([...previousChecked.filter(checkedValue => !checkedValue.endsWith(getLastElement(value)))]);
        }
    }

    const onChange = (event, isChecked, value) => {
        
        let previousChecked = [...checked];

        if (isChecked) {

            let updatedChecked = previousChecked;

            let foundNodes = []
            searchNodes(nodes, getLastElement(value), foundNodes);
            foundNodes = foundNodes.map(node => node.value);

            updatedChecked = updatedChecked.concat(foundNodes);

            while (value.includes('/')) {
                value = removeLastElement(value);
                if (!checked.some(checkedValue => checkedValue == value)) {
                    updatedChecked.push(value);
                }
            }

            setChecked(updatedChecked);
        } else {
            setChecked([...previousChecked.filter(checkedValue => !checkedValue.endsWith(getLastElement(value)))]);
        }
    }

    const handleClose = () => {
        
        if (initialChecked != checked) {
            setUnsavedOpen(true);
        } else {
            setOpen(false);
        }
    }

    const renderTree = (nodes) => {

        if (!nodes) {
            return null;
        }

        return (

            nodes.map(node => {

                let label = node.label;

                return (
                    <StyledTreeItem checked={checked} key={node.value} nodeId={node.value} onChange={onChange} label={label}>
                        {Array.isArray(node.children) ? renderTree(node.children) : null}
                    </StyledTreeItem>
                )
            })
        )
    };

    // const tree = useMemo(() => renderTree(nodes), [nodes]);

    return (
        <Fragment>
            <Button 
                onClick={() => setOpen(true)} 
                variant="contained" 
                style={{ marginBottom: 20 }}
            >
                Manage Capabilities
            </Button>
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                disableEnforceFocus
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Manage Capabilities</DialogTitle>
                <DialogContent style={{ overflowX: 'hidden' }}>
                    {nodes && (
                        // <CheckboxTree
                        //     nodes={nodes}
                        //     checked={checked}
                        //     // iconsClass="fa5"
                        //     expanded={expanded}
                        //     onCheck={handleCheck}
                        //     onExpand={expanded => setExpanded(expanded)}
                        //     icons={{
                        //         check: <CheckBoxOutlinedIcon style={{ verticalAlign: 'bottom' }} />,
                        //         uncheck: <CheckBoxOutlineBlankIcon style={{ verticalAlign: 'bottom' }} />,
                        //         halfCheck: <CheckBoxOutlinedIcon style={{ verticalAlign: 'bottom' }} />,
                        //         expandClose: <ChevronRightOutlinedIcon style={{ verticalAlign: 'bottom' }} />,
                        //         expandOpen: <KeyboardArrowDownOutlinedIcon style={{ verticalAlign: 'bottom' }} />,
                        //         expandAll: <AddBoxOutlinedIcon style={{ verticalAlign: 'bottom' }} />,
                        //         collapseAll: <IndeterminateCheckBoxOutlinedIcon style={{ verticalAlign: 'bottom' }} />,
                        //         parentClose: <FolderOutlinedIcon style={{ verticalAlign: 'bottom' }} />,
                        //         parentOpen: <FolderOpenOutlinedIcon style={{ verticalAlign: 'bottom' }} />,
                        //         leaf: <InsertDriveFileOutlinedIcon style={{ verticalAlign: 'bottom' }} />,
                        //     }}
                        //     showNodeIcon={false}
                        //     noCascade
                        // />

                        <TreeView
                            className={classes.root}
                            defaultCollapseIcon={<ArrowDropDownIcon />}
                            defaultExpanded={expanded}
                            // defaultSelected={checked}
                            defaultExpandIcon={<ArrowRightIcon />}
                            // multiSelect
                        >
                            {renderTree(nodes)}
                        </TreeView>
                    )}
                </DialogContent>
                <DialogActions>
                    
                    <Button onClick={save} color="primary">
                        {translate('ra.action.save')}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        {translate('ra.action.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
            <UnsavedDataDialog unsavedOpen={unsavedOpen} setUnsavedOpen={setUnsavedOpen} setOpen={setOpen} />
        </Fragment>
    )
}

export default CapabilityManagement;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
});

const imageUrl = `${process.env.REACT_APP_ROOT_FOLDER}images/servicenow.jpg`;

export default function MediaCardServicenow() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="images/servicenow2.jpg"
          title="servicenow"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            servicenow
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            ServiceNow is an ITSM (Information Technology Service Management)
            platform. <br />
            It has 5 major services: IT, Security, HR Service Delivery, Customer
            Service and Business Applications.
            <br /> <br />
            ServiceNow is an integrated cloud solution which combines all these
            services in a single system of record.
            <br /> <br />
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          href="https://www.servicenow.com/"
          target="_blank"
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

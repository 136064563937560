import React from 'react';
import { Edit } from 'react-admin';

import { ItVendorForm } from './ItVendorForm';

export const ItVendorEdit = (props) => {

    return (
        <Edit undoable={false} {...props}>
            <ItVendorForm editMode={true} permissions={props.permissions} />
        </Edit>
    )
}
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Grid, Button, Chip, InputAdornment, TextField, Typography, Radio, RadioGroup, FormControlLabel, List, ListItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';

import { useNotify } from 'react-admin';

// import { ResponsiveChordCanvas } from '@nivo/chord'

import { makeStyles, useTheme } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import Tooltip from '@material-ui/core/Tooltip';

import CheckIcon from '@material-ui/icons/CheckCircle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableChart from '@material-ui/icons/TableChart';

import { 
    ReferenceArrayField,
    SingleFieldList,
    RichTextField,
    Button as RaButton,
  } from 'react-admin';

import ReactECharts from 'echarts-for-react';

import axios from 'axios';

const useStyles = makeStyles(theme => ({
    root: {
        // flexGrow: 1,
        minWidth: 480,
    },
    tagBlock: {
        // marginBottom: 20
    },
    buttonBlock: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        marginBottom: 20,
        marginTop: 20
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftColumn: { flex: 1, marginRight: '1em' },
    rightColumn: { flex: 1, marginLeft: '1em' },
    singleColumn: { marginBottom: '2em' },
    fab: {
        margin: theme.spacing(1),
        backgroundColor: '#304d75'
    },
    tableContainer: {
        maxWidth: '80vw',
        overflowX: 'scroll',
        // margin: '0 auto',
    },
    table: {
        tableLayout: 'fixed',
        margin: '0px auto 0px auto',
    },
    fixedHeader: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        // height: 99,
        backgroundColor: 'rgb(250, 250, 250)',
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
    },
    fixedCell: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        borderRight: '1px solid black',
        // height: 29,
        backgroundColor: 'rgb(250, 250, 250)',
        zIndex: 1,
        paddingTop: 0,
        paddingBottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    header: {
        width: 200,
        // height: 99
    },
    cell: {
        width: 200,
        borderCollapse: 'collapse',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        height: 20,
        paddingTop: 5,
        paddingBottom: 5
    },
    firstHeader: {
        width: 200,
        paddingLeft: 300,
        // height: 99,
    },
    firstCell: {
        width: 200,
        paddingLeft: 300,
        borderCollapse: 'collapse',
        borderRight: '1px solid black',
        height: 20,
        paddingTop: 5,
        paddingBottom: 5
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    textOverflowWhite: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        color: 'white',
    },
    border : {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    green: {
        color: '#2BAF6A',
    },
    orange: {
        color: '#ffb74d',
    },
    blue: {
        color: '#39a1de',
    },
    yellow: {
        color: '#ecd476',
    }
}));

const Chart = ({ companyId, tagState, searchResults, setSelectedProducts, setSelectedItems }) => {

    const [options, setOptions] = useState();

    const notify = useNotify();

    const [data, setData] = useState();

    const [rawData, setRawData] = useState([]);

    const [loading, setLoading] = useState();

    const [labelEnabled, setLabelEnabled] = useState(true);

    const loadData = () => {

        setLoading(true);

        const token = localStorage.getItem('token');

        let url = `${process.env.REACT_APP_API_URL}/dashboard/internal-product-summary`;
        if (companyId) {
            url += `?companyId=${companyId}`;
        }

        let payload = {
            tagState,
            searchResults
        }

        fetch(url, { 
                method: 'POST', 
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(payload),
            })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {

                setRawData(response);

                let inputData = response;

                inputData.forEach(item => {
                    item.areas = Array.from(new Set(item.areas)); 
                });

                // Step 1: Extract unique areas
                const uniqueAreas = Array.from(new Set(inputData.flatMap(item => item.areas)));

                // Step 2: Calculate connections between areas
                const connections = {};
                uniqueAreas.forEach(area => {
                    connections[area] = {};
                    uniqueAreas.forEach(otherArea => {
                        if (area !== otherArea) {
                            connections[area][otherArea] = 0;
                        }
                    });
                });

                // Step 3: Assign weights to each area based on frequency of occurrence
                const areaCounts = {};
                inputData.forEach(item => {
                    item.areas.forEach(area => {
                        areaCounts[area] = (areaCounts[area] || 0) + 1;
                        item.areas.forEach(otherArea => {
                            if (area !== otherArea) {
                                connections[area][otherArea]++;
                            }
                        });
                    });
                });

                // Step 4: Determine if normalization is needed
                const maxAllowedSize = 100;
                const minSize = 1;
                const maxCount = Math.max(...Object.values(areaCounts));

                // Normalization function
                const normalize = (value, min, max) => minSize + ((value - min) / (max - min)) * (maxAllowedSize - minSize);

                // Step 5: Format data for ECharts
                const categories = uniqueAreas.map(area => ({ name: area }));

                const nodes = [];

                // Generate node positions
                uniqueAreas.forEach(area => {
                    let x, y;
                    do {
                        x = Math.random() * 3000;
                        y = Math.random() * 1000;
                    } while (nodes.some(node => Math.sqrt((x - node.x) ** 2 + (y - node.y) ** 2) < 75)); // Check for overlap

                    const rawSize = areaCounts[area] * 5; // Raw size based on occurrence
                    const symbolSize = rawSize > maxAllowedSize ? normalize(areaCounts[area], 1, maxCount) : rawSize;

                    nodes.push({
                        id: area, // Unique identifier
                        name: area,
                        // symbolSize: inputData.reduce((acc, item) => acc + (item.areas.includes(area) ? 1 : 0), 0) * 1.85, // Adjust size based on occurrence
                        symbolSize: symbolSize,
                        x: x, // Random x position (multiplied for more spread)
                        y: y, // Random y position
                        value: inputData.reduce((acc, item) => acc + (item.areas.includes(area) ? 1 : 0), 0), // Value based on occurrence
                        category: area // Belongs to its respective area category
                    });
                });

                const links = [];
                uniqueAreas.forEach((area, index) => {
                    uniqueAreas.forEach((otherArea, otherIndex) => {
                        if (index < otherIndex && connections[area][otherArea] > 0) {
                            links.push({
                                source: area,
                                target: otherArea,
                                value: connections[area][otherArea] // Weight of connection
                            });
                        }
                    });
                });

                        
                        let graph = {
                            nodes,
                            links,
                            categories
                        }

                        let options = {
                            tooltip: {},
                            // legend: [
                            //     {
                            //         data: graph.categories.map(function (a) {
                            //             return a.name;
                            //         })
                            //     }
                            // ],
                            series: [
                                {
                                    // name: 'Les Miserables',
                                    type: 'graph',
                                    layout: 'none',
                                    data: graph.nodes,
                                    links: graph.links,
                                    categories: graph.categories,
                                    roam: true,
                                    label: {
                                        show: true,
                                        position: 'right',
                                        formatter: '{b}'
                                    },
                                    labelLayout: {
                                        hideOverlap: true
                                    },
                                    scaleLimit: {
                                        min: 0.4,
                                        max: 2
                                    },
                                    lineStyle: {
                                        color: 'source',
                                        curveness: 0.3
                                    }
                                }
                            ]
                        };

                        setOptions(options);
            })
            .catch((error) => {
                const isString = Object.prototype.toString.call(error.message) == '[object String]';

                var message = error.message;

                if (!isString) {
                    message = Object.entries(error.message)
                            .map(([key, value]) => value)
                            .join('\n');
                }

                if (message == null || message == '') {
                    message = 'Internall server error';
                }

                notify(message, 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        
        loadData();

    }, [companyId, tagState, searchResults]);

    function handleClick(event) {

        let value = event.data.id;

        let searchTerm = {
            searchTerm: value,
            type: "OFFERING_AREA",
            category: true,
            value: value + "OFFERING_AREA",
        }
        searchTerm.label = getLabel(searchTerm);
        setSelectedItems((previousSelectedItems) => [...previousSelectedItems, searchTerm]);
    }

    const onEvents = {
        'click': handleClick,
    }

    if (loading || !options) {
        return <div style={{ position: 'absolute', left: 0, top: 65, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>
    }

    if (!companyId || (!tagState.internal && !tagState.external) || !rawData || (rawData.length == 0)) {
        return <div style={{ position: 'absolute', left: 0, top: 65, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Typography variant="h6">No information to display</Typography></div>
    }
    
    return (
        <div style={{ position: 'absolute', left: 10, top: 60, right: 0, bottom: 0 }}>
            <ReactECharts style={{height: '445px', width: '100%'}} option={options} onEvents={onEvents} />
        </div>
    );
}

const labels = {
    "NAME": "names",
    "FEATURE": "Features",
    "FEATURE_CATEGORY": "Feature categories",
    "OFFERING_CATEGORY": "Offering categories",
    "OFFERING_AREA": "Offering areas",
}

function getLabel(item, inputValue) {

    let searchTerm = item.searchTerm;
    let firstPart = <b>{searchTerm}</b>;

    if (inputValue) {
        let regex = new RegExp(inputValue, 'gi');
        searchTerm = searchTerm.replace(regex, match => `<b>${match}</b>`);
        firstPart = <span dangerouslySetInnerHTML={{ __html: searchTerm }} />;
    }

    if (item.count) {
        return (
            <span>
                {firstPart} in {labels[item.type]}: {item.count}
            </span>
        )
    }

    return (
        <span>
            {firstPart} in {labels[item.type]}
        </span>
    )
}

const CompanySelect = ({ onChange }) => {

    const token = localStorage.getItem('token');

    const fetchOptions = (inputValue) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/companies?name=${inputValue}&companyRole=ROLE_CONSUMER`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then(data => {
                const options = data.content.map(item => ({
                    value: item.id,
                    label: item.name
                }));
                resolve(options);
            })
            .catch(error => {
                console.error('Error fetching options:', error);
                resolve([]); // Resolve with an empty array in case of error
            });
        });
    };

    return (
        <div style={{ minWidth: 210, maxWidth: 210 }}>
            <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={fetchOptions}
                placeholder="Select a company"
                onChange={onChange}
            />
        </div>
    );
};


const CustomAccordion = ({ children, name, style, ...rest }) => {

    return (
        <div style={{ padding: '5px 5px', ...style }}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    style={{ padding: '0px, 10px' }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>{name}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ paddingTop: 0, display: 'block' }}>
                    {/* {React.cloneElement(children, rest)} */}
                    {React.Children.map(children, child => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, rest);
                        }
                        return child;
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}

var totalChoices = [];

const Feature = ({ record }) => {

    if (!record) {
        return null;
    }

    return (
        <Chip
            style={{ margin: 4 }}
            color="default"
            label={`${record.featureName} (${record.featureCategoryName})`}
        />
    )
}

const Features = ({ record }) => {

    if (!record.id) {
        return null;
    }

    return (
        <ReferenceArrayField basePath="/" record={record} source="features" reference="internal-product-features">
            <SingleFieldList>
                <Feature />
            </SingleFieldList>
        </ReferenceArrayField>
    )
}

const Category = ({ record }) => {

    if (!record) {
        return null;
    }

    return (
        <Chip
            style={{ margin: 4 }}
            color="default"
            label={`${record.categoryName} (${record.categoryAreaName})`}
        />
    )
}

const Categories = ({ record }) => {

    if (!record.id) {
        return null;
    }

    return (
        <ReferenceArrayField basePath="/" record={record} source="categories" reference="internal-product-categories">
            <SingleFieldList>
                <Category />
            </SingleFieldList>
        </ReferenceArrayField>
    )
}

const Description = ({ record }) => {

    if (!record) {
        return null;
    }

    if (!record.description) {
        return "-";
    }

    return (
        <RichTextField record={record} source="description" />
    )
}

const Option = ({ children, ...props }) => (
    <components.Option {...props}>
        {props.data.category ? (
            <div style={{ padding: '7px 12px', backgroundColor: '#f2f2f2' }}>{children}</div>
        ) : (
            <div style={{ padding: '7px 12px' }}>{children}</div>
        )}
    </components.Option>
);

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        padding: '0px',
        margin: '0px',
    }),
};

const SearchBar = ({ companyId, searchResults, setSearchResults, selectedItems, setSelectedItems }) => {
    const [searchHistory, setSearchHistory] = useState([]);

    const [errorText, setErrorText] = useState('');

    const [filterType, setFilterType] = useState('OR');

    const [options, setOptions] = useState([]);
    const [fetchController, setFetchController] = useState(null);
    
    const notify = useNotify();

    const classes = useStyles();

    const handleShowResults = () => {
        if (selectedItems.length == 0) {
            // setErrorText('Type a search term');

            setSearchResults(null);
            return;
        }
        setErrorText('');

        let searchRequest = {
            filter: filterType,
            companyId: companyId,
            searches: selectedItems
        };

        const token = localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/dashboard/search-result`, { 
                method: 'POST', 
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(searchRequest)
            })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                setSearchResults(response);

                let newSearch = !searchHistory.some((history) => history == selectedItems);

                if (newSearch) {
                    setSearchHistory((prevSearchHistory) => [...prevSearchHistory.slice(-2), selectedItems]);
                }
            })
            .catch((error) => {
                const isString = Object.prototype.toString.call(error.message) == '[object String]';

                var message = error.message;

                if (!isString) {
                    message = Object.entries(error.message)
                            .map(([key, value]) => value)
                            .join('\n');
                }

                if (message == null || message == '') {
                    message = 'Internall server error';
                }

                notify(message, 'warning');
            })
            .finally(() => {

            });

        
    };

    const handleSearchHistoryClick = (history) => {
        setSelectedItems(history);
    };

    function getHistoryLabel(history) {

        return history.map(item => item.searchTerm + " in " + labels[item.type]).join(', ');
    }

    async function loadItems(inputValue) {


        if (inputValue && inputValue != '') {

            const controller = new AbortController();
            setFetchController(controller);

            const token = localStorage.getItem('token');

            var url = `${process.env.REACT_APP_API_URL}/dashboard?companyId=${companyId}&query=${inputValue}`;

            try {
                const response = await fetch(url, { 
                    signal: controller.signal,
                    method: 'GET', 
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } 
                });
                const result = await response.json();
                totalChoices = [...result];
                const items = result
                    .map(item => ({
                        searchTerm: item.searchTerm,
                        type: item.type,
                        value: item.searchTerm + item.type,
                        label: getLabel(item, inputValue),
                        category: item.category
                    }))
                    .filter(item =>
                        // item.label.toLowerCase().includes(inputValue.toLowerCase())
                        true
                    );

                setOptions(items);
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        }
    }


    const handleInputChange = (inputValue) => {
        if (fetchController) {
            fetchController.abort();
        }
        loadItems(inputValue);
    };
    function handleChange(items) {

        if (items && items.length > 0) {
            setSelectedItems(items.map(item => {
                return {
                    searchTerm: item.searchTerm,
                    type: item.type,
                    value: item.value,
                    label: item.label,
                }
            }));
        } else {
            items = [];
            setSelectedItems([]);
        }
    }

    return (
        <div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
                    
                    <div className={classes.root}>
                        <Select
                            options={options}
                            onInputChange={handleInputChange}
                            onChange={handleChange}
                            isMulti
                            closeMenuOnSelect={false}
                            value={selectedItems}
                            isClearable={false}
                            placeholder="Type a search term"
                            styles={customStyles}
                            components={{ Option }}
                        />
                    </div>
                    <Button style={{ position: 'relative' }} variant="contained" color="primary" onClick={handleShowResults}>
                        {searchHistory.length === 0 ? 'Show results' : 'Update results'}
                    </Button>
                </div>
            </div>
            {errorText && <div style={{ color: 'red' }}>{errorText}</div>}
            {selectedItems.length > 1 && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ marginRight: '10px' }}>
                        Filter:
                    </Typography>
                    <RadioGroup
                        row
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                    >
                        <FormControlLabel value="OR" control={<Radio />} label="or" />
                        <FormControlLabel value="AND" control={<Radio />} label="and" />
                    </RadioGroup>
                </div>
            )}

            {/* {searchResults && (
                <div style={{ marginTop: 20 }}>
                    <Typography variant="h6">Search results:</Typography>
                    <List>
                        {searchResults.map((item, index) => {

                            return (
                                // <ListItem key={item.id}>{item.providerName} - {item.name}</ListItem>
                                <CustomAccordion key={item.id} name={item.providerName + " - " + item.name}>
                                    
                                    <Typography style={{ display: 'block', color: 'rgba(0, 0, 0, 0.54)', marginBottom: 10, marginTop: 10 }}>Description</Typography>
                                    <Description record={item} />
                                    <Typography style={{ display: 'block', color: 'rgba(0, 0, 0, 0.54)', marginBottom: 15, marginTop: 20 }}>Categories</Typography>
                                    <Categories record={item} label="Categories" />
                                    <Typography style={{ display: 'block', color: 'rgba(0, 0, 0, 0.54)', marginBottom: 15, marginTop: 20 }}>Features</Typography>
                                    <Features record={item} label="Features" />
                                </CustomAccordion>
                            )
                        })}
                    </List>
                    {searchResults.length == 0 && (<Typography>No results found</Typography>)}
                </div>
            )} */}
            {searchHistory.length > 0 && (
                <div style={{ marginTop: 10 }}>
                    <Typography>Search History:</Typography>
                    {searchHistory.map((history, index) => (
                        <Chip
                            key={index}
                            label={getHistoryLabel(history)}
                            onClick={() => handleSearchHistoryClick(history)}
                            style={{ margin: '4px', cursor: 'pointer' }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const DataField = ({ loading, data, source }) => {

    if (loading || !data) {
        return <CircularProgress />
    }
    return <span>{data[source]}</span>
}


const getAreas = (data) => {

    // Extract unique category names
    const categories = data.reduce((list, item) => {
        item.areas.forEach(area => {
            if (!list.includes(area)) {
                list.push(area);
            }
        });
        return list;
    }, []);

    return categories;
}

const createMatrix = (data, areas) => {
    let matrix = [];

    // Initialize matrix
    areas.forEach(() => {
        matrix.push(Array(areas.length).fill(0));
    });

    // Populate matrix
    data.forEach(d => {
        const offeringAreas = d.areas;
        for (let i = 0; i < offeringAreas.length; i++) {
            for (let j = i + 1; j < offeringAreas.length; j++) {
                const area1Index = areas.indexOf(offeringAreas[i]);
                const area2Index = areas.indexOf(offeringAreas[j]);
                matrix[area1Index][area2Index]++;
                matrix[area2Index][area1Index]++;
            }
        }
        // Handle offerings with only one area
        if (offeringAreas.length === 1) {
            const areaIndex = areas.indexOf(offeringAreas[0]);
            matrix[areaIndex][areaIndex]++;
        }
    });

    // Add self-connections for areas with no connections
    areas.forEach((area, index) => {
        if (matrix[index].reduce((acc, curr) => acc + curr, 0) === 0) {
            matrix[index][index] = 1;
        }
    });

    return matrix;
};

function calculateColumnWidth(totalColumns) {

    let clientWidth = document.documentElement.clientWidth;

    const totalWidth = clientWidth * 85 / 100 - 450;
    const columnWidth = totalWidth / totalColumns;
    const minimumColumnWidth = 150;

    return columnWidth < minimumColumnWidth ? minimumColumnWidth : columnWidth;
}

const MatrixContent = ({ matrix }) => {

    const { featureNames, rows } = matrix;

    return featureNames.map((featureName, index) => {

        let features = rows.map(product => product.features[index]);

        return (
            <MatrixRow key={index} columns={[featureName, ...features]} rowLength={rows.length} />
        )
    });
}

const MatrixRow = ({ columns, rowLength }) => {

    const classes = useStyles();

    const container = useRef();
    const [containerHeight, setContainerHeight] = useState(29);

    useLayoutEffect(() => {
        if (container.current) {
            setContainerHeight(container.current.offsetHeight - 1);
        }
    }, []);

    return (
        <TableRow ref={container}>
            <TableCell style={{ height: containerHeight }} className={classes.fixedCell} component="th" scope="row">
                <Typography className={classes.textOverflow}>{columns[0]}</Typography>
            </TableCell>

            {columns.slice(1).map((cell, index) => (
                <TableCell style={{ width: calculateColumnWidth(rowLength) }} className={index == 0 ? classes.firstCell : classes.cell} align="center">
                    {cell ? 
                        <CheckIcon className={classes.green} />
                        :
                        null
                    }
                </TableCell>
            ))}
        </TableRow>
    );

}

const FeatureMatrix = ({ selectedProducts, tagState, companyId }) => {

    const classes = useStyles();
    
    const [matrix, setMatrix] = useState();
    const [loading, setLoading] = useState();
    
    const notify = useNotify();
    
    useEffect(() => {
        
        if ((tagState.internal || tagState.external) && companyId) {
            loadMatrix();
        }
        
    }, [tagState, selectedProducts, companyId]);

    const loadMatrix = () => {

        setLoading(true);

        const token = localStorage.getItem('token');

        let url = `${process.env.REACT_APP_API_URL}/dashboard/matrix`;

        let payload = {
            tagState: tagState,
            solutions: selectedProducts ? selectedProducts.map(item => item.id): null,
            companyId: companyId
        };

        fetch(url, { 
                method: 'POST', 
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(payload),
            })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                setMatrix(response);
            })
            .catch((error) => {
                notify(error, 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function exportMatrix(matrix) {

        const token = localStorage.getItem('token');

        let url = process.env.REACT_APP_API_URL + "/dashboard/matrix/download";

        axios({
            url: url,
            method: 'POST',
            headers: { 'Accept': 'application/vnd.ms-excel', 'Authorization': `Bearer ${token}` },
            responseType: 'blob',
            data: matrix,
        })
        .then((response) => {            
            let fileName = "matrix.xlsx";
            try {
                //
                // try reading the filename from the content disposition attribute
                [, fileName] = response.headers['content-disposition'].split('filename=');            
                
                if (fileName.indexOf('"') > -1)
                    fileName = fileName.substring(1, fileName.length-1);
            } catch (error) {
                console.error(error);                
            }

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        }).catch((error) => {

        });
    }

    if (!tagState.internal && !tagState.external) {
        return null;
    }

    return (
        <div style={{ marginTop: 30, width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" style={{ color: '#605e5e', marginBottom: 10 }}>Solutions listed by Feature</Typography>

                <RaButton variant="contained" style={{ marginRight: 0 }} onClick={() => exportMatrix(matrix)} label='resources.projects.action.export_matrix'><TableChart /></RaButton>
            </div>
            {loading || !matrix ? 
                <LinearProgress /> : (
                <div className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="matrix">
                        <TableHead>
                            <TableRow>

                                <TableCell className={classes.fixedHeader} style={{ fontSize: '1.25rem' }}>
                                    Feature
                                </TableCell>

                                {matrix.rows.map((row, index) => 
                                    <TableCell style={{ width: calculateColumnWidth(matrix.rows.length) }} className={index == 0 ? classes.firstHeader : classes.header} align="center">
                                        <Tooltip title={row.productName}>
                                            <span className={classes.textOverflowWhite} style={{ display: 'block' }}>
                                                <span style={{ borderRadius: (tagState.internal && !tagState.external) || row.internal ? '5px' : '0px', padding: (tagState.internal && !tagState.external) || row.internal ? '5px' : '0px', backgroundColor: (tagState.internal && !tagState.external) || row.internal ? '#3b5f91' : '', color: (tagState.internal && !tagState.external) || row.internal ? 'white' : 'rgb(96, 94, 94)' }}>{row.productName}</span>
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <MatrixContent matrix={matrix} />
                        </TableBody>
                    </Table>
                </div>
            )}
        </div>
    )
}

const Dashboard = () => {
    
    const [companyId, setCompanyId] = useState(null);
    // const [tagType, setTagType] = useState('external');

    const [tagState, setTagState] = useState({
        internal: false,
        external: false
    });

    const handleTagChange = (tag) => {
        setTagState({ ...tagState, [tag]: !tagState[tag] });
    };
    const [loading, setLoading] = useState();

    const [summary, setSummary] = useState();

    const [selectedItems, setSelectedItems] = useState([]);

    const notify = useNotify();

    useEffect(() => {

        const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));
        if (decodedToken) {
    
            let companyId = decodedToken.company_id;
            setTagState({
                internal: true,
                external: false
            });
            setCompanyId(companyId);
        }
    }, []);

    const [searchResults, setSearchResults] = useState();
    const [selectedProducts, setSelectedProducts] = useState();

    const loadTags = () => {

        setLoading(true);

        const token = localStorage.getItem('token');

        let url = `${process.env.REACT_APP_API_URL}/dashboard/summary`;
        if (companyId) {
            url += `?companyId=${companyId}`;
        }

        fetch(url, { 
                method: 'POST', 
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify(tagState),
            })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                setSummary(response);
            })
            .catch((error) => {
                const isString = Object.prototype.toString.call(error.message) == '[object String]';

                var message = error.message;

                if (!isString) {
                    message = Object.entries(error.message)
                            .map(([key, value]) => value)
                            .join('\n');
                }

                if (message == null || message == '') {
                    message = 'Internall server error';
                }

                notify(message, 'warning');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        
        loadTags();
    }, [companyId, tagState]);

    useEffect(() => {
        
        setSelectedProducts(searchResults);
    }, [searchResults]);

    const classes = useStyles();

    return (
        <div style={{ marginTop: 0, width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ position: 'relative' }}>
                    <Chart setSelectedItems={setSelectedItems} searchResults={searchResults} setSelectedProducts={setSelectedProducts} companyId={companyId} tagState={tagState} />
                    
                    <div style={{ display: 'flex', gap: 30, alignItems: 'baseline' }}>
                        
                        <Typography variant="h5" style={{ color: '#605e5e', marginBottom: 25 }}>Solution Insights</Typography>
                        <SearchBar companyId={companyId} selectedItems={selectedItems} setSelectedItems={setSelectedItems} searchResults={searchResults} setSearchResults={setSearchResults} />
                    </div>
                    

                    <Grid container spacing={2} style={{ marginTop: 10 }}>

                        <Grid item sm={3} lg={2}>
                            <div style={{ backgroundColor: '#e6e6e6', padding: 20, borderRadius: 5, width: 170, position: 'relative', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)' }}>
                                <div className={classes.tagBlock}>
                                    <Typography variant="h4">
                                        <DataField loading={loading} data={summary} source="solutions" />
                                    </Typography>
                                    <Typography variant="h6" style={{ color: '#605e5e' }}>#Solutions</Typography>
                                </div>
                                <div className={classes.buttonBlock}>
                                    <ToggleButton
                                        value="internal"
                                        selected={tagState.internal}
                                        onChange={() => handleTagChange('internal')}
                                        aria-label="internal"
                                        style={{ color: 'white', backgroundColor: tagState['internal'] ? 'rgb(236, 64, 122)' : '#b3b3b3' }}
                                    >
                                        Internal
                                    </ToggleButton>
                                    <ToggleButton
                                        value="external"
                                        selected={tagState.external}
                                        onChange={() => handleTagChange('external')}
                                        aria-label="external"
                                        style={{ color: 'white', backgroundColor: tagState['external'] ? 'rgb(236, 64, 122)' : '#b3b3b3' }}
                                    >
                                        External
                                    </ToggleButton>
                                </div>
                                <div className={classes.tagBlock}>
                                    <Typography variant="h4">
                                        <DataField loading={loading} data={summary} source="categories" />
                                    </Typography>
                                    <Typography variant="h6" style={{ color: '#605e5e' }}>#Categories</Typography>
                                </div>
                                <div className={classes.tagBlock}>
                                    <Typography variant="h4">
                                        <DataField loading={loading} data={summary} source="features" />
                                    </Typography>
                                    <Typography variant="h6" style={{ color: '#605e5e' }}>#Features</Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', justifyContent: 'left', marginTop: '-20px' }}>
                        <FeatureMatrix selectedProducts={selectedProducts} tagState={tagState} companyId={companyId} />
                    </div>
                </Grid>
            </Grid>
            
        </div>
        
    )
}

export default Dashboard;
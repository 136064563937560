import React, { useState ,useEffect} from 'react';
import { required, useTranslate } from 'react-admin';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    label : {
        color: "#333333",
        fontSize: "0.9rem",
        fontWeight: "bold",
        paddingBottom:"10px",
    },
    error : {
        color: "rgba(0, 0, 0, 0.54)",
        margin: "0",
        fontSize: "0.75rem",
        marginTop: "3px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "1.66",
        letterSpacing: "0.03333em",
        color: "#f44336",
    }
}));

const CKEditor5 = props => {
    const [value, setValue] = useState('');
    const translate = useTranslate();
    const message = translate('application.validation.required');
    const classes = useStyles();
    let labelErrorCss = {};

    useEffect(() => {
        const label = document.getElementById("ckeditorlabel")
        if (props.theme === 'dark') {
            label.style.color = "#FFFFFF";
        }else{
            label.style.color = "#333333";
        }
        label.style.fontSize = "0.9rem";
        label.style.fontWeight = "bold";
    });

    return (
        <Field
            required
            name={props.source}
            validate={required(message)}
            defaultValue={value}
            {...props}
            render={({ input, meta, ...props }) => {
                if (meta.touched && meta.error){
                    labelErrorCss = {color: "#f44336"};
                }
                return (
                    <div>
                        <style dangerouslySetInnerHTML = {{__html: `
                            .ck-editor__editable {
                                min-height: 150px;
                            }
                            .ck.ck-editor__main>.ck-editor__editable{
                                background: none;
                            }
                            .ck.ck-toolbar {
                                background: none;
                            }
                        `}} />

                        {props.theme === 'dark' ?
                            <style dangerouslySetInnerHTML = {{__html: `
                                .ck.ck-editor__editable > .ck-placeholder::before {
                                    color: rgba(255, 255, 255, 0.7);
                                }
                            `}} />
                            :
                            <style dangerouslySetInnerHTML = {{__html: `
                                .ck.ck-editor__editable > .ck-placeholder::before {
                                    color: rgba(0, 0, 0, 0.54);
                                }
                            `}} />
                        }

                        <div className={classes.label} id="ckeditorlabel">{props.label}{props.required != null && props.required && <span style={labelErrorCss}> *</span>}</div>
                        <CKEditor
                            editor={ClassicEditor}
                            data={value}
                            config={ {
                                // Use the German language for this editor.
                                //language: 'de',
                                placeholder : props.placeholder,
                                toolbar:  [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList'],
                            } }
                            onInit={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);

                                // Disable style for copy & paste.
                                const clipboardPlugin = editor.plugins.get( 'Clipboard' );
                                const editingView = editor.editing.view;

                                editingView.document.on('clipboardInput', (event, data) => {
                                    if ( editor.isReadOnly ) {
                                        return;
                                    }

                                    const dataTransfer = data.dataTransfer;
                                    let content = plainTextToHtml(dataTransfer.getData('text/plain'));

                                    content = clipboardPlugin._htmlDataProcessor.toView(content);

                                    clipboardPlugin.fire('inputTransformation', { content, dataTransfer });

                                    editingView.scrollToTheSelection();

                                    event.stop();
                                } );

                                //

                                if (props.record!=null && props.record[props.source]!=null){
                                    setValue(props.record[props.source]);
                                }
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setValue(data);
                                input.onChange(data)
                                //console.log( { event, editor, data } );
                            }}
                            onBlur={(event, editor) => {
                                //console.log( 'Blur.', editor );
                            }}
                            onFocus={(event, editor) => {
                                //console.log( 'Focus.', editor );
                            }}
                        />

                        <textarea style={{display:'none'}} {...input} name={props.source} value={value}/>
                        <div className={classes.error}>{meta.touched && meta.error && <span>{meta.error}</span>}</div>
                    </div>
                )
            }}
        />
    )
}

export default CKEditor5;
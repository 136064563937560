import React, { useState, useEffect } from "react";

//Plain Regex(no flags):^.*(?=.{8,20})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\\\_\+\`\~\.\,\<\>\/\?\;\:\'\"\\\|\[\]\{\}]).*$

//Custom hook
function usePasswordVerification(newPassword) {
  const [passwordToVerify, setPasswordToVerify] = useState(newPassword);

  const rules = /^.*(?=.{8,20})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\\\_\+\`\~\.\,\<\>\/\?\;\:\'\"\\\|\[\]\{\}]).*$/;
  const exeTest = rules.test(passwordToVerify);

  const [isStrong, setIsStrong] = useState(exeTest);

  useEffect(() => {
    setIsStrong(rules.test(passwordToVerify));
  }, [passwordToVerify]);

  return [passwordToVerify, setPasswordToVerify, isStrong];
}

export default usePasswordVerification;

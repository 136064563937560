import React from 'react';
import Chip from '@material-ui/core/Chip';
import get from 'lodash/get';

const TextArrayField = ({ record, source }) => {
  const array = get(record, source);
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return (
		<div/>
	)
  } else {
    return (
      <div style={{ display: 'flex', gap: 10 }}>
        {array.map(item => <Chip label={item} key={item}/>)}
      </div>
    )    
  }
}

TextArrayField.defaultProps = { addLabel: true }

export default TextArrayField;

import React from 'react';
import { useTranslate, SelectField } from 'react-admin';

const states = [
    'COMPANY_NOT_VERIFIED',
    'DATA_COLLECTION',
    'CLARIFICATION_NEEDED',
    'AVAILABLE',
]

const RecommendationState = ({ record }) => {

    // const translate = useTranslate();
    
    return (
        <SelectField 
            record={record}
            source="state" 
            choices={states.map(state => {
                let choice =  { id: state, name: 'resources.recommendations.field_values.state.' + state.toLowerCase() };
                return choice;
            })}
        />
    )
}
RecommendationState.defaultProps = { addLabel: true, label: 'resources.recommendations.fields.state' }

export default RecommendationState;
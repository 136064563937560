import React, { useState, useEffect, Fragment } from 'react';
import { useRedirect, useTranslate, useDataProvider, useRefresh, useNotify } from 'react-admin';

import { 
    Button, 
    Typography, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogContentText, 
    DialogActions, 
    LinearProgress 
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const styleDialogAction = makeStyles({
    root: { justifyContent: 'space-around', paddingTop: 0, paddingBottom: 20 },
});
const styleDialogTitle = makeStyles({
    root: { paddingBottom: 0 },
});
const styleDialogContent = makeStyles({
    root: { paddingTop: 0, paddingBottom: 0 },
});

export const ProjectProgress = ({ project, show, basePath = '' }) => {

    const classesDialogAction = styleDialogAction();
    const classesDialogTitle = styleDialogTitle();
    const classesDialogContent = styleDialogContent();

    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const doRedirect = useRedirect();

    const [open, setOpen] = useState(false);

    const refresh = useRefresh();
    const notify = useNotify();

    const [progressStep, setProgressStep] = useState(1);
    const [step, setStep] = useState(null);
    const [completionCode, setCompletionCode] = useState(null);

    const showDashboard = () => {
        doRedirect('/', '');
    };

    const showProject = (step) => {

        refresh();

        var path = '/show/' + step.toLowerCase();

        if (path !== null && path !== '') {
            if (!window.location.href.endsWith(path)) {
                doRedirect(`/projects/${project.id}${path}`, basePath, project.id, project);
            }
        } else {
            doRedirect(`/projects/${project.id}`, basePath, project.id, project);
        }
    };

    const preview = () => {

        setProgressStep(2);

        const token = localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/projects/${project.id}/trigger-matching-process?previewEnabled=true`, { 
                    method: 'POST', 
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
                })
                .then((response) => {
                    const interval = setInterval(() => {
            
                        dataProvider.getOne('projects', { id: `${project.id}/progress` })
                            .then(({ data }) => {
            
                                if (data.stage === 'COMPLETED') {
            
                                    clearInterval(interval);
                                    setStep(data.step);
                                    setCompletionCode(data.completionCode);

                                    if (data.step === 'STEP_3' || data.completionCode === 2) {
                                        setProgressStep(3);
                                    } else {
                                        showProject(data.step);
                                    }
                                } else if (data.stage === 'FAILED') {
            
                                    clearInterval(interval);
                                    setOpen(false);
                                    notify('ra.message.error', 'warning');
                                }
                            });
                    }, 1000);
                })
                .catch((exception) => {
                    notify('ra.message.error', 'warning');
                });
    }

    useEffect(() => {

        if (project && project.id && show) {

            setOpen(true);
        }
    }, [project, show]);

    return (

        <Dialog
            fullWidth
            open={open}
            onClose={() => setOpen(false)}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" classes={classesDialogTitle}>

                {progressStep !== 3 && translate(`resources.projects.progress.dialog_title_${progressStep}`)}
                {progressStep === 3 && completionCode && translate(`resources.projects.message.dialog_title_${completionCode}`)}
            </DialogTitle>
            <DialogContent classes={classesDialogContent}>
                <DialogContentText id="alert-dialog-description">

                    {progressStep === 1 &&  <Typography gutterBottom>{translate(`resources.projects.progress.dialog_content_text_${progressStep}`)}</Typography>}
                    {progressStep === 2 &&  
                        <Fragment>
                            <Typography gutterBottom>{translate(`resources.projects.progress.dialog_content_text_${progressStep}_1`)}</Typography>
                            <Typography>{translate(`resources.projects.progress.dialog_content_text_${progressStep}_2`)}</Typography>
                        </Fragment>
                    }
                    {progressStep === 3 && completionCode && 
                        <Fragment>
                            <Typography gutterBottom>{translate(`resources.projects.message.dialog_content_text_${completionCode}_1`)}</Typography>
                            {completionCode == 1 && <Typography>{translate(`resources.projects.message.dialog_content_text_${completionCode}_2`)}</Typography>}
                        </Fragment>
                    }
                    <br/>
                    {progressStep === 2 && <LinearProgress color={'primary'} />}
                </DialogContentText>
            </DialogContent>

            <DialogActions disableSpacing={false} classes={classesDialogAction}>

                {progressStep === 1 && 
                    <Fragment>
                        <Button onClick={preview} color="primary" variant="contained">
                            {translate(`resources.projects.progress.dialog_action_1`)}
                        </Button>
                        <Button onClick={showDashboard} color="default">
                            {translate(`resources.projects.progress.dialog_action_2`)}
                        </Button> 
                    </Fragment>
                }
                {progressStep === 2 &&  
                    <Button onClick={showDashboard} color="primary">
                        {translate(`resources.projects.progress.dialog_action_3`)}
                    </Button>
                }
                {progressStep === 3 && completionCode && step && 
                    <Fragment>
                        <Button onClick={() => showProject(step)} color="primary" variant="contained">
                            {translate(`resources.projects.message.dialog_action_${completionCode}`)}
                        </Button>
                        <Button onClick={showDashboard} color="default" variant="contained">
                            {translate(`resources.projects.message.dialog_action_3`)}
                        </Button>
                    </Fragment>
                }
            </DialogActions>
        </Dialog>
    );
};
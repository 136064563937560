import React, { useCallback } from 'react';

import { SelectArrayInput, SelectInput, ArrayInput, SimpleFormIterator, maxLength, useCreate, useRedirect, useNotify, Toolbar, SaveButton, CardActions, ListButton, SimpleForm, Create, TextInput, BooleanInput, required, useTranslate } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';

import Button from '@material-ui/core/Button';
import LimitedInput from '../component/LimitedInput';
import { SupplierStatus } from './SupplierStatus';
import { CustomSaveButton } from './CustomSaveButton';
import { makeStyles } from '@material-ui/core/styles';
import { SupplierValuation } from './SupplierValuation';
import { arrayInputChoicesOfferings } from './offerings';

const useStyles = makeStyles(theme => ({
    formIterator: {
        '& p:nth-child(1)' :{
            textAlign: 'center',
        }
    },
}));

const CustomButton = ({ color, label, onClick, icon }) => {
    return <Button startIcon={icon} variant="contained" color={color} onClick={onClick}>{label}</Button>
}

const CustomToolbar = (props) => {
    
    const translate = useTranslate();
    const { basePath } = props;

    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomSaveButton undoable={false} />
            <CustomButton label={translate('ra.action.cancel')} icon={<CancelIcon />} onClick={() => document.location.href = `${process.env.REACT_APP_ROOT_FOLDER}#${basePath}`} />
        </Toolbar>
    )
}

export const RecommendationCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const message =  translate('application.validation.required');

    const basePath = "/knowledge/recommendations";

    const classes = useStyles();

    return (
        <Create basePath={basePath} resource="recommendations" {...props} title=" ">
            <SimpleForm toolbar={<CustomToolbar />} variant="standard" defaultValue={{ companyId : JSON.parse(localStorage.getItem('decoded_token')).company_id }}>
                <SelectArrayInput 
                    validate={required(message)} 
                    source="offerings"
                    choices={arrayInputChoicesOfferings}
                />
                <TextInput source="providerName" validate={required(message)} />
                <TextInput source="website" validate={required(message)} />
                {parseInt(JSON.parse(localStorage.getItem('decoded_token')).company_id) === parseInt(process.env.REACT_APP_RWE_ID) &&
                    <TextInput label="Custom target URL" source="internalUrl" />
                }
                <BooleanInput source="activelyUsed" label="resources.recommendations.fields.actively_used_long" fullWidth />
                <ArrayInput source="products" className={classes.formIterator}>
                    <SimpleFormIterator>
                        <SelectInput source="offering" choices={arrayInputChoicesOfferings} />
                        <TextInput source="name" label="resources.recommendations.fields.productName" />
                        <LimitedInput source="useCase" label="resources.recommendations.fields.useCase" multiline fullWidth maxLength={4096} />
                    </SimpleFormIterator>
                </ArrayInput>
                {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) &&
                    <SupplierStatus />
                }
                {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) &&
                    <SupplierValuation />
                }
            </SimpleForm>
        </Create>
    )
};
import React, { Component, Fragment, useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { Select, MenuItem, Card, CardHeader, CardContent, TablePagination, Grid, List, ListItem, RadioGroup, Radio, FormControlLabel, ListItemAvatar, Avatar, Stepper, Step, StepLabel, ListItemIcon, ListItemText, Typography, Chip, Icon, IconButton, Tooltip, Button, Checkbox } from "@material-ui/core";

import CrossIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/CheckCircle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import InformationIcon from '@material-ui/icons/Info';

import classnames from 'classnames';
import { Legend } from './Legend';
import { isOnlyInternalProducts } from './InternalInsight';

const useStyles = makeStyles(theme => ({
    root: {
        height: '20em',
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftColumn: { flex: 1, marginRight: '1em' },
    rightColumn: { flex: 1, marginLeft: '1em' },
    singleColumn: { marginBottom: '2em' },
    fab: {
        margin: theme.spacing(1),
        backgroundColor: '#304d75'
    },
    tableContainer: {
        maxWidth: '75vw',
        overflowX: 'auto',
        margin: '0 auto',
    },
    table: {
        tableLayout: 'fixed',
        margin: '0px auto 0px auto',
    },
    fixedHeader: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        height: 99,
        backgroundColor: 'white',
        zIndex: 9,
        display: 'flex',
        alignItems: 'center',
    },
    fixedCell: {
        position: 'absolute', 
        left: 'auto',
        top: 'auto',
        width: 250,
        borderRight: '1px solid black',
        height: 29,
        backgroundColor: 'white',
        zIndex: 1,
    },
    header: {
        width: 200,
        height: 99
    },
    cell: {
        width: 200,
        borderCollapse: 'collapse',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        height: 29
    },
    firstHeader: {
        width: 200,
        paddingLeft: 300,
        height: 99,
    },
    firstCell: {
        width: 200,
        paddingLeft: 300,
        borderCollapse: 'collapse',
        borderRight: '1px solid black',
        height: 29
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    border : {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    green: {
        color: 'rgb(144, 198, 83)',
    },
    orange: {
        color: '#ffb74d',
    },
    blue: {
        color: '#39a1de',
    },
    yellow: {
        color: '#ecd476',
    }
}));

function calculateColumnWidth(totalColumns) {

    let clientWidth = document.documentElement.clientWidth;

    const totalWidth = clientWidth * 75 / 100 - 300;
    const columnWidth = totalWidth / totalColumns;
    const minimumColumnWidth = 200;

    return columnWidth < minimumColumnWidth ? minimumColumnWidth : columnWidth;
}

const renderKeySynonyms = (data, translate) => {

    if (data.keySynonyms.length === 0) {
        return '';
    }

    return translate('resources.suggestions.matrix.synonyms') + ': ' + data.keySynonyms.join(', ');
}

const renderTextFragment = (data, translate) => {

    if (data.textFragment === null) {
        return translate('resources.suggestions.matrix.no_details_available');
    }

    if (data.header) {
        return (
            <div>
                <div style={{ textDecoration: 'underline'}}>
                    {data.header}
                </div>
                <div style={{ marginTop: 5 }}>
                    {data.textFragment}
                </div>
            </div>
        )
    }

    return data.textFragment;
}

const getBackgroundColor = (cell) => {
    return cell.highlighted ? '#e6f9ff' : 'inherit';
}

const MatrixContent = ({ matrix, type }) => {

    const translate = useTranslate();
    const classes = useStyles();

    return matrix.rows.map((row, index) => (

        <TableRow key={index}>
            <TableCell className={classes.fixedCell} component="th" scope="row">
                <Tooltip title={renderKeySynonyms(row, translate)} placement="bottom-start"><Typography className={classes.textOverflow}>{row.key}</Typography></Tooltip>
            </TableCell>

            {row.restCells.map((cell, index) => (
                <TableCell style={{ width: calculateColumnWidth(matrix.solutions.length), backgroundColor: getBackgroundColor(cell) }} className={index == 0 ? classes.firstCell : classes.cell} align="center">
                    {cell.found ? 
                        <Tooltip title={renderTextFragment(cell, translate)}>
                            <CheckIcon 
                                className={classnames({
                                    [classes.green]: cell.foundKeyWeight == 0 || cell.foundKeyWeight == 1,
                                    [classes.yellow]: cell.foundKeyWeight == 0.5,
                                })}
                            />
                        </Tooltip>
                        :
                        <CheckIcon style={{ color: '#cccccc' }} />
                    }
                </TableCell>
            ))}
        </TableRow>
    ));

}

export const Matrix = (props) => {

    const { record, step, type } = props;

    const classes = useStyles();
    const [matrix, setMatrix] = useState(null);

    const translate = useTranslate();

    const [onlyInternalProducts, setOnlyInternalProducts] = useState();

    var stepParameter = "";
    if (step) {
        stepParameter = `&step=${step}`
    }

    useEffect(() => {

        const token = localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/projects/${record.id}/${type || "matrix"}?user=true${stepParameter}`, { 
                method: 'GET', 
                headers: { 'Authorization': `Bearer ${token}` }, 
            })
            .then(response => response.json())
            .then(matrix => {
                setMatrix(matrix);
            })
            .catch((exception) => {

            });
    }, []);

    useEffect(() => {

        if (record) {
            let onlyInternalProducts = isOnlyInternalProducts(record);
            setOnlyInternalProducts(onlyInternalProducts);
        }
    }, [record]);

    const projectId = 3028;

    return matrix !== null ? 
        <Fragment>
            <h3 style={{ fontSize: '1.25rem', textAlign: 'center', marginTop: 0, marginBottom: 5 }}>{translate('resources.suggestions.matrix.solutions')}</h3>
            <div className={classes.tableContainer}>
                <Table className={classes.table} aria-label="matrix">
                    <TableHead>
                        <TableRow>

                            <TableCell className={classes.fixedHeader} style={{ fontSize: '1.25rem', textTransform: 'uppercase' }}>
                                {translate('resources.suggestions.matrix.criteria')}
                                <Tooltip title={translate('resources.suggestions.matrix.criteria_tooltip')}>
                                    <InformationIcon style={{ color: '#39a1de', position: 'relative', left: 8 }} />
                                </Tooltip>
                            </TableCell>

                            {matrix.solutions.map((solution, index) => 
                                <TableCell style={{ width: calculateColumnWidth(matrix.solutions.length) }} className={index == 0 ? classes.firstHeader : classes.header} align="center">
                                    {solution.companyName !== null && <span className={classes.textOverflow} style={{ display: 'block', textTransform: 'uppercase' }}>{solution.companyName}</span>}
                                    {onlyInternalProducts && <span className={classes.textOverflow} style={{ display: 'block', textTransform: 'uppercase' }}>{solution.productName}</span>}
                                    {!onlyInternalProducts && <span className={classes.textOverflow} style={{ display: 'block', textTransform: 'uppercase', fontSize: '0.875rem' }}>{solution.name}</span>}
                                    {record.id != projectId && <Fragment><span style={{ fontSize: '20px', fontWeight: 'bold', color: '#39a1de' }}>{solution.score / 10}</span><span style={{ fontSize: '15px', fontWeight: 'bold', color: '#39a1de' }}> / 10</span></Fragment>}
                                    {record.id != projectId && <Fragment><span style={{ fontSize: '10px', display: 'block', lineHeight: '0.75rem' }}>
                                        <span style={{ position: 'relative', top: -4 }}>{translate('resources.suggestions.fields.matching_score')}</span>
                                        <Tooltip title={translate('resources.suggestions.matrix.matching_score_tooltip')}>
                                            <InformationIcon style={{ color: '#39a1de', position: 'relative', top: 4, left: 4 }} />
                                        </Tooltip>
                                    </span>
                                    </Fragment>}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <MatrixContent type={type} matrix={matrix} />
                    </TableBody>
                </Table>
            </div>
            <div style={{ textAlign: 'center', marginTop: 17 }}><Legend /></div>
        </Fragment>
        :
        <div style={{ display: 'flex', justifyContent: 'center', padding: 16 }}>
            <CircularProgress />
        </div>;
}
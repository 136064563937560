import React, { useState, useEffect } from 'react';

const logotypes = new Map();
logotypes.set(parseInt(process.env.REACT_APP_RWE_ID), `${process.env.REACT_APP_ROOT_FOLDER}images/rwe-logotype.png`);
logotypes.set(parseInt(process.env.REACT_APP_ADOIT_ID), `${process.env.REACT_APP_ROOT_FOLDER}images/adoit-logotype.svg`);
const defaultLogotype = `${process.env.REACT_APP_ROOT_FOLDER}images/logo.svg`;

const Logotype = props => {

    const [companyId, setCompanyId] = useState(0);

    useEffect(() => {

        const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

        if (decodedToken) {
            setCompanyId(decodedToken.company_id);
        }
    }, []);

    return (
        <img src={logotypes.has(companyId) ? logotypes.get(companyId) : defaultLogotype} alt="" style={{ height: props.height || '2.5rem', width: 'auto' }} />
    );
}

export default Logotype;
import React, { useState, useEffect, Fragment } from "react";
import { showNotification, Title, NotFound, useTranslate } from "react-admin";
import { withTranslate } from "ra-core";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { push } from "react-router-redux";
// import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Form, Field } from "react-final-form";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CustomTooltip from "../component/CustomTooltip";
import compose from "recompose/compose";
import Snackbar from "@material-ui/core/Snackbar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import usePasswordVerification from "../settings/security/PasswordStrength/PasswordVerification";
import PasswordStrengthComp from "../settings/security/PasswordStrength/PasswordStrengthComp";

import { withStyles } from "@material-ui/core";

import { getQueryParameters, getApplicationUrl } from "../utilities";

import axios from "axios";

const styles = {
  main: {
    width: "200px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
    minHeight: "100vh",
    height: "1px",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  card: {
    minWidth: 300,
    marginTop: "6em"
  },
  button: {
    width: "100%"
  }
};

const targetSuccessUrl = "/";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "80%"
  },
  gridcontainer: {
    padding: theme.spacing(1),
    marginBottom: "20px"
  }
}));

function UserActivation(props) {
  const [parameters, setParameters] = useState("");
  const [activated, isActivated] = useState(false);

  const [serverResponse, setServerResponse] = useState(false);

  const classes = useStyles();
  const translate = useTranslate();
  const { showNotification, handleExpanded } = props;
  const [
    passwordToVerify,
    setPasswordToVerify,
    isStrong
  ] = usePasswordVerification("ExamplePassword1$");

  var isStrong_aux = true;

  const onSubmit = values => {
    const rules = /^.*(?=.{8,20})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\\\_\+\`\~\.\,\<\>\/\?\;\:\'\"\\\|\[\]\{\}]).*$/;
    isStrong_aux = rules.test(values.password);

    setPasswordToVerify(values.password);

    const queryParameters = getQueryParameters();

    const form = {
      code: queryParameters.code,
      type: "USER_INVITATION",
      parameters: { password: values.password }
    };

    const errors = {};

    if (!isStrong_aux) {
      return errors;
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/activation-tokens/${queryParameters.token}`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(form)
      }
    )
      .then(response => {
        if (response.status !== 204) {
          throw new Error("Server responded: " + response.status);
        } else if (response.status === 400) {
          //Add Alert
        } else {
          window.location.replace(getApplicationUrl());
        }
      })
      .catch(exception => {});
  };

  const checkActivationToken = () => {
    const queryParameters = getQueryParameters();

    setParameters(getQueryParameters());

    axios.defaults.headers.common["Content-Type"] = "application/json";

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/activation-tokens/` + queryParameters.token
      )
      .then(res => {
        setServerResponse(true);
        isActivated(res.activated);
        console.log(
          "res.activated dopo la risposta di checkActivationToken",
          res.activated
        );
      });

    if (activated) {
      window.location.replace(getApplicationUrl());
    }
  };

  const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );

  React.useEffect(() => {
    const queryParameters = getQueryParameters();
    if (queryParameters.language) {
      localStorage.setItem('language', queryParameters.language)
    }
  })

  return (
    <Fragment>
      {!activated ? (
        <div
          className={classes.main}
          style={{
            width: "40em",
            margin: "0 auto"
          }}
        >
          <Form
            onSubmit={onSubmit}
            validate={values => {
              const errors = {};
              if (!values.password) {
                errors.password = translate("application.password_activation.requiredField");
              } else if (!values.passwordConfirmation) {
                errors.passwordConfirmation = translate("application.password_activation.requiredField");
              } else if (values.password !== values.passwordConfirmation) {
                errors.passwordConfirmation =
                translate("application.password_activation.matchPassword");
              }
              return errors;
            }}
          >
            {({ handleSubmit, pristine, reset, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Card className={classes.card}>
                  <br />
                  <Title title={translate("application.password_activation.name")} />

                  <CardContent>
                    <Typography>
                      {translate("application.password_activation.message_1")}
                    </Typography>

                    <Grid
                      container
                      spacing={1}
                      className={classes.gridcontainer}
                    >
                      <br />
                      <div>
                        <Grid item xs={12} sm={11}>
                          <Field
                            name="password"
                            style={{ minWidth: "400px" }}
                            component={renderTextField}
                            label={translate("application.password_activation.password")}
                            type="password"
                            length="50"
                          />
                        </Grid>
                      </div>

                      <br />
                      <CustomTooltip pathToStringForTranslation="application.profile.fields.password_verification_tooltip"></CustomTooltip>

                      <div>
                        <Grid item xs={12} sm={11}>
                          <Field
                            name="passwordConfirmation"
                            style={{ minWidth: "400px" }}
                            component={renderTextField}
                            label={translate("application.password_activation.confirmPassword")}
                            type="password"
                            length="50"
                          />
                        </Grid>
                        <PasswordStrengthComp
                          isStrong={isStrong}
                        ></PasswordStrengthComp>
                      </div>
                    </Grid>
                  </CardContent>
                  <br />
                  <br />

                  <CardActions>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {translate("application.password_activation.submit")}
                    </Button>
                  </CardActions>
                  <br />
                </Card>
              </form>
            )}
          </Form>
        </div>
      ) : (
        <Redirect to={targetSuccessUrl} />
      )}
    </Fragment>
  );
}

export default compose(
  connect(null, { showNotification, push }),
  withTranslate,
  withStyles(styles)
)(UserActivation);

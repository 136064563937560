import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';

import { useTranslate } from 'react-admin';

const styles = {
    listItem: {
        paddingLeft: '1rem',
        cursor: 'default',
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    listItemText: {
        paddingLeft: 2,
        // fontSize: '1rem',
        fontSize: '0.915rem'
    },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
};

const SubMenu = ({
    name,
    classes,
    children,
    disabled
}) => {

    const translate = useTranslate();

    return (
        <Fragment>
            <ListItem
                disabled={disabled}
                dense
                className={classes.listItem}
            >
                <ListItemText
                    inset
                    disableTypography
                    primary={translate(name)}
                    className={classes.listItemText}
                />
            </ListItem>
            <Collapse in={true} timeout="auto" unmountOnExit>
                <List
                    dense
                    component="div"
                    disablePadding
                    className={classes.sidebarIsOpen}
                >
                    {children}
                </List>
                <Divider />
            </Collapse>
        </Fragment>
    );
}

const enhance = compose(
    withStyles(styles),
);

export default enhance(SubMenu);
import React from 'react';
import {
    List,
    CardActions, 
    Datagrid,
    TextField,
    EmailField,
    BooleanField,
    CreateButton,
    EditButton,
    DeleteButton, 
    useTranslate
} from 'react-admin';

import { connect } from 'react-redux';

import compose from 'recompose/compose';

const CustomActions = ({ permissions, basePath, data, resource }) => (
    <CardActions>
        {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ? <CreateButton basePath={basePath} label="resources.users.action.new_user" /> : null}
    </CardActions>
);

const CustomEmailField = ({ theme, ...props }) => {

    return theme === 'dark' ? 
        <EmailField style={{ color: 'white' }} source="email" {...props} />
        :
        <EmailField source="email" {...props} />
};

const CustomEditButton = ({ permissions, record, ...props }) => {

    return record && permissions && (permissions.includes('ROLE_EXECUTIVE') || (permissions.includes('ROLE_POWER_USER') && record.roleName === 'ROLE_USER')) ? 
        <EditButton {...props} record={record} /> 
        : 
        null
};

const CustomDeleteButton = ({ permissions, record, ...props }) => {

    const userId = JSON.parse(localStorage.getItem('decoded_token')).user_name;

    const translate = useTranslate();

    return record && record.id != userId && permissions && (permissions.includes('ROLE_EXECUTIVE') || (permissions.includes('ROLE_POWER_USER') && record.roleName === 'ROLE_USER')) ? 
        <DeleteButton {...props} record={record} undoable={false} confirmTitle={translate('resources.users.message.delete_user_title', { full_name: record.firstName + " " + record.lastName })} confirmContent={translate('resources.users.message.delete_user_content')} /> 
        : 
        null
};

const UserList = ({ permissions, theme, ...props }) => (
    <List title="application.menu.employees" bulkActionButtons={false} actions={<CustomActions permissions={permissions} />} filterDefaultValues={{ companyId: JSON.parse(localStorage.getItem('decoded_token')).company_id }} {...props}>
        <Datagrid rowClick="show">
            <TextField source="firstName" />
            <TextField source="lastName" />
            
            <TextField source="position" />
            <CustomEmailField source="email" theme={theme} />
            
            <BooleanField source="enabled" />
            
            <CustomEditButton permissions={permissions} />
            <CustomDeleteButton permissions={permissions} />
        </Datagrid>
    </List>
);

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
    // translate,
    // withStyles(styles)
);

export default enhance(UserList);
import React, { useEffect } from 'react';

import { useLogout } from 'react-admin';


const Logout = () => {

    const logout = useLogout();

    useEffect(() => {

        logout();

    }, []);

    

    return (
        <div></div>
    )
}
export default Logout;
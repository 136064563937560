import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SelectAsync from 'react-select/async';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { useForm } from 'react-final-form';
import { useTranslate } from 'react-admin';

import { Field } from 'react-final-form';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        minWidth: 290,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

function Control(props) {
    const {
        children,
        innerProps,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
};

function Placeholder(props) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.any.isRequired,
    selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool.isRequired,
    removeProps: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        onMouseDown: PropTypes.func.isRequired,
        onTouchEnd: PropTypes.func.isRequired,
    }).isRequired,
    selectProps: PropTypes.object.isRequired,
};

const components = {
    Control,
    MultiValue,
    Placeholder,
    SingleValue,
    ValueContainer,
};

const CustomInput = (props => {
    const classes = useStyles();
    const theme = useTheme();
    const [mounted, setMounted] = useState(false);
    const [selectedKeywords, setSelectedKeywords] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const form = useForm();
    const [optionalKeywords, setOptionalKeywords] = useState([]);
    const translate = useTranslate();

    const selectStyles = {
        input: base => ({
            ...base,
            color: 'theme.palette.text.primary',
            '& input': {
                font: 'inherit',
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: 'black',
            };
        },
    };

    //load old data for edit
    useEffect(() => {
        //console.log("load old data",props);
        let oldChoices = props.record.keywords;

        if (oldChoices != null && oldChoices.length > 0) {
            let oldKeywords = [];
            oldChoices.map(keyword => (oldKeywords.push(createOption(keyword))))
            setSelectedKeywords(oldKeywords);
        }
    }, [props.counter]);

    async function loadKeywords(inputValue) {
        try {
            setOptionalKeywords([]);

            var newOptionalKeywords = [];
            let story = await fetch(`${process.env.REACT_APP_API_URL}/keywords?value=` + inputValue)
                .then(response => response.json())
                .then((result) => {
                    newOptionalKeywords = result;
                })

            newOptionalKeywords = newOptionalKeywords.map(keyword => ({
                value: keyword.value,
                label: keyword.value,
            })).filter(newOptionalKeyword =>{
                //only need not exist data 
                return !isExist(selectedKeywords,newOptionalKeyword);
            });

            //console.log("newOptionalKeywords", newOptionalKeywords);
            setOptionalKeywords(newOptionalKeywords);
            return newOptionalKeywords;
        } catch (err) {
            console.log(err);
        }
    }

    function isExist(keywords,keyword) {
        let isexist = false;
        if (selectedKeywords.length==0) return isexist;
        for (let j = 0; j < keywords.length; j++) {
            if (keywords[j].value.trim().toLowerCase() === keyword.value.trim().toLowerCase()) {
                isexist = true;
                break;
            }
        }
        return isexist;
    };

    useEffect(() => {
        let submitkeywords = [];
        //console.log("selectedKeywords", selectedKeywords);
        if (selectedKeywords != null) {
            selectedKeywords.map(keyword => (submitkeywords.push(keyword.value)))

            //console.log("submitkeywords", submitkeywords)
            if (mounted) {
                form.change('keywords', submitkeywords);
            }
        }
    }, [selectedKeywords]);

    const promiseOptions = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(loadKeywords(inputValue));
            }, 100);
        });

    const handleKeyDown = (event) => {
        if (!optionalKeywords) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                //if api have data , can't create option
                if (optionalKeywords.length > 0) return;
                createOptions(inputValue);
                event.preventDefault();
        }
    };

    function createOptions(inputKeywords){
        const keywordarr = inputKeywords.split(',')
        if (keywordarr.length > 0) {
            //console.log("final keywordarr ",keywordarr);
            setInputValue('');
            let options = [];
            keywordarr.forEach(keyword => {
                const option = createOption(keyword);
                if (keyword.trim().length > 0 && !ifExistValueInValues(option)) {
                    //console.log("option",option);

                    const found = options.some(el => el.value === keyword);
                    if (!found) {
                        options.push(option);
                    }
                }
            });

            //console.log("final options ",selectedKeywords.concat(options));
            setSelectedKeywords(selectedKeywords.concat(options));
            //setValue([...value, option]);
            
        }
    }

    const createOption = (value) => ({
        label: value.trim(),
        value: value.trim(),
    });

    function ifExistValueInValues(option) {
        let isexist = false;
        for (let j = 0; j < selectedKeywords.length; j++) {
            if (selectedKeywords[j].value.trim().toLowerCase() === option.value.trim().toLowerCase()) {
                isexist = true;
                break;
            }
        }
        return isexist;
    };


    const handleInputChange = inputValue => {
        if (!mounted) {
            setMounted(true);
        }
        
        setInputValue(inputValue);

        if (inputValue.indexOf(",")!=-1) {
            createOptions(inputValue);
        }
    };
    const handleChangeMulti = value => {
        setMounted(true);
        if (value != null) {
            setSelectedKeywords(value);
        } else {
            setSelectedKeywords([]);
        }
    };

    return (
        <div className={classes.root}>
            <Field name="keywords">
                {props => (
                    <div>
                        <input {...props.input} type="hidden" />
                    </div>
                )}
            </Field>
            <SelectAsync
                classes={classes}
                styles={selectStyles}
                inputId="react-select-multiple"
                TextFieldProps={{
                    label: translate('resources.projects.fields.keywords'),
                    InputLabelProps: {
                        htmlFor: 'react-select-multiple',
                        shrink: true,
                    },
                }}
                placeholder={translate('resources.projects.field_values.keywords')}
                loadOptions={promiseOptions}
                components={components}
                onChange={handleChangeMulti}
                isMulti
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                inputValue={inputValue}
                value={selectedKeywords}
            />
        </div>
    );
})

export default CustomInput;
import React, {useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    copy: {
        textAlign: 'center',
        fontSize: '0.8rem',
        height: '70px',
        backgroundColor: 'transparent',
        padding: 5,
    },
    link : {
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        color: '#fafafa',
        textDecoration: 'none',
        marginRight:'10px'
    },
    copyrightcontent : {
        color: '#fafafa',
    }

}));


const Footer = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    useEffect(() => {
        //document.body.setAttribute("style", "padding-bottom: 70px");

        const footer = document.getElementById("footer")
        const copyrightcontent = document.getElementById("copyrightcontent")
        const links = document.querySelectorAll("#footer a");
        if (props.theme === 'dark') {
            footer.setAttribute("style", "background-color: #303030");
            copyrightcontent.setAttribute("style", "background-color: #303030");
            copyrightcontent.setAttribute("style", "color: #fafafa");
            links.forEach(element => {
                element.setAttribute("style", "color: #fafafa");
            });
        }else{
            footer.setAttribute("style", "background-color: #fafafa");
            copyrightcontent.setAttribute("style", "background-color: #fafafa");
            copyrightcontent.setAttribute("style", "color: #303030");
            links.forEach(element => {
                element.setAttribute("style", "color: #303030");
            });
        }

        if (window.location.href != null && window.location.href != '' && window.location.href.indexOf('/login')!=-1) {
            footer.setAttribute("style", "position:fixed;right:0;bottom:0;left:0;zIndex:1;padding:3;background-color:none;");
            copyrightcontent.setAttribute("style", "color: #fafafa");
            links.forEach(element => {
                element.setAttribute("style", "color: #fafafa");
            });
            document.body.setAttribute("style", "padding-bottom: 0px");
        }

    });

    return (
        <div className={classes.copy} id="footer">
            <p >
                <a href={`${process.env.REACT_APP_ROOT_FOLDER}#/knowledge`} target="_blank" className={classes.link}>{translate('application.menu.knowledge_center')}</a> 
                <a href={translate('application.footer.link.imprint')} target="_blank" className={classes.link}>{translate('application.footer.legal.imprint')}</a> 
                <a href={translate('application.footer.link.privacy_policy')} target="_blank" className={classes.link}>{translate('application.footer.legal.privacy_policy')}</a> 
                <a href={translate('application.footer.link.age')} target="_blank" className={classes.link} style={{marginRight: '0em'}}>{translate('application.footer.legal.age')}</a> 
            </p>
            <p id="copyrightcontent" className={classes.copyrightcontent} >© knooing {new Date().getFullYear()}. All Rights Reserved.</p>
        </div>
    )
};

export default compose(
    connect(
        state => ({
            theme: state.theme
        }),
    )
)(Footer);

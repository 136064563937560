import React, { Component, Fragment, useState, useEffect } from 'react';

import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
    showNotification,
    usePermissions,
    getResources,
    useTranslate,
    useDataProvider
} from 'react-admin';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { useSelector } from 'react-redux';

import SettingsIcon from '@material-ui/icons/Settings';
import RequestIcon from '@material-ui/icons/Assignment';
import MessageIcon from '@material-ui/icons/Mail';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import CompanyIcon from '@material-ui/icons/AccountBalance';
import UserManagementIcon from '@material-ui/icons/People';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SecurityIcon from '@material-ui/icons/Security';
import FaqsIcon from '@material-ui/icons/LiveHelp';
import ActiveProviderIcon from '@material-ui/icons/AssignmentTurnedIn';
import DvrIcon from '@material-ui/icons/Dvr';
import KnowledgeIcon from '@material-ui/icons/MenuBook';
import InvoiceIcon from '@material-ui/icons/Euro';
import AnalyticsIcon from '@material-ui/icons/TrendingUp';
import FeedbackIcon from '@material-ui/icons/Send';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SearchIcon from '@material-ui/icons/Search';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

import CustomSubMenu from './CustomSubMenu';

import { makeStyles } from '@material-ui/core/styles';
import SubMenu from './SubMenu';

import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(theme => ({
    badge: { 
        margin: 0, 
        paddingRight: 10 
    },
    menuItemLink: {
        margin: '5px 0px',
        // fontSize: '0.915rem',
        // color: 'rgba(0, 0, 0, 0.87)',
    },
    activeMenuItemLink: {
        margin: '5px 0px',
        fontSize: '0.915rem',
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'underline',
        textDecorationColor: 'rgb(255, 179, 10)',
        textDecorationThickness: '2px',
        textUnderlineOffset: '3px'
    }
}));

const EmptyIcon = () => {
    return null;
}

export const isMenuItemActive = (path, currentPath, exactMatch) => {

    return exactMatch ? path === currentPath : currentPath.includes(path);
}

const CustomMenu = ({ onMenuClick, logout, location }) => {

    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const classes = useStyles();
    const translate = useTranslate();
    const { permissions } = usePermissions();

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    const [companyProperties, setCompanyProperties] = useState({});
    const [dashboardOpen, setDashboardOpen] = useState(false);

    const dataProvider = useDataProvider();

    const handleToggle = () => {
        setDashboardOpen(!dashboardOpen);
    };

    useEffect(() => {

        if (decodedToken) {

            const companyId = decodedToken.company_id;

            dataProvider.getOne('companies', { id: companyId + '/properties' })
                .then(({ data }) => {
                    setCompanyProperties(data);
                });
        }
    }, []);

    return (
        <div>
            {decodedToken && 
                <Fragment>
                    <CustomSubMenu
                        handleToggle={() => handleToggle()}
                        isOpen={dashboardOpen}
                        sidebarIsOpen={open}
                        name="application.menu.dashboard"
                        icon={<DashboardIcon />}
                    >
                        <MenuItemLink
                            to={'/'}
                            primaryText={translate('application.menu.overview')}
                        />
                        <MenuItemLink
                            disabled={!companyProperties.softwareSearchEnabled && !companyProperties.vendorSearchEnabled}
                            to={'/solution-insights'}
                            primaryText={translate('application.menu.solution_insights')}
                        />
                        <MenuItemLink
                            disabled={!companyProperties.softwareSearchEnabled && !companyProperties.vendorSearchEnabled}
                            to={'/solution-overlaps'}
                            primaryText={translate('application.menu.solution_overlaps')}
                        />
                    </CustomSubMenu>
                    {/* <DashboardMenuItem onClick={onMenuClick} style={{ fontSize: '1rem', margin: '5px 0px' }} /> */}
                    {/* <MenuItemLink
                        to={'/'}
                        primaryText={translate('application.menu.dashboard')}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={isMenuItemActive('/', location.pathname, true) ? classes.activeMenuItemLink : classes.menuItemLink}
                    /> */}
                    <Divider />
                    {/* <SubMenu disabled={!companyProperties.softwareSearchEnabled && !companyProperties.vendorSearchEnabled} name="application.menu.quick_search">
                        <MenuItemLink
                            disabled={!companyProperties.softwareSearchEnabled}
                            to={'/software-search'}
                            primaryText={`- ${translate('application.menu.software')}`}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            className={isMenuItemActive('/software-search', location.pathname) ? classes.activeMenuItemLink : classes.menuItemLink}
                        />
                        <MenuItemLink
                            disabled={!companyProperties.vendorSearchEnabled}
                            to={'/vendor-search'}
                            primaryText={`- ${translate('application.menu.vendors_services')}`}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            className={isMenuItemActive('/vendor-search', location.pathname) ? classes.activeMenuItemLink : classes.menuItemLink}
                        />
                    </SubMenu> */}
                    <MenuItemLink
                        disabled={!companyProperties.softwareSearchEnabled}
                        // to={'/software-search'}
                        to={'/solution-insights'}
                        primaryText={translate('application.menu.software')}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<SearchIcon />}
                        className={classes.menuItemLink}
                    />
                    <MenuItemLink
                        disabled={!companyProperties.vendorSearchEnabled}
                        // to={'/vendor-search'}
                        to={'/solution-insights'}
                        primaryText={translate('application.menu.vendors_services')}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<FindInPageIcon />}
                        className={classes.menuItemLink}
                    />
                    <Divider />
                    <MenuItemLink
                        to={'/projects'}
                        primaryText={translate('application.menu.project_check')}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}

                        leftIcon={<RequestIcon />}
                        className={classes.menuItemLink}
                        // className={isMenuItemActive('/projects', location.pathname) ? classes.activeMenuItemLink : classes.menuItemLink}
                    />

                    <Divider />

                    {/* <SubMenu disabled={!companyProperties.softwareSearchEnabled && !companyProperties.vendorSearchEnabled} name="application.menu.company_portfolio">
                        <MenuItemLink
                            disabled={!companyProperties.softwareSearchEnabled}
                            to={'/software-portfolio'}
                            primaryText={`- ${translate('application.menu.software')}`}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            className={isMenuItemActive('/software-portfolio', location.pathname) ? classes.activeMenuItemLink : classes.menuItemLink}
                        />
                        <MenuItemLink
                            disabled={!companyProperties.vendorSearchEnabled}
                            to={'/it-vendors'}
                            primaryText={`- ${translate('application.menu.it_vendors')}`}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            className={isMenuItemActive('/it-vendors', location.pathname) ? classes.activeMenuItemLink : classes.menuItemLink}
                        />
                    </SubMenu> */}

                    <MenuItemLink
                        disabled={!companyProperties.softwareSearchEnabled}
                        to={'/software-portfolio'}
                        primaryText={`${translate('application.menu.software_portfolio')}`}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<ActiveProviderIcon />}
                        className={classes.menuItemLink}
                    />
                    <MenuItemLink
                        disabled={!companyProperties.vendorSearchEnabled}
                        to={'/it-vendors'}
                        primaryText={`${translate('application.menu.it_vendors')}`}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<DvrIcon />}
                        className={classes.menuItemLink}
                    />
                    <Divider />
                    {/* <SubMenu name="application.menu.account">
                        <MenuItemLink
                            to={`/companies/${decodedToken.company_id}/show`}
                            primaryText={`- ${translate('application.menu.company_information')}`}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            className={isMenuItemActive(`/companies/${decodedToken.company_id}/show`, location.pathname) ? classes.activeMenuItemLink : classes.menuItemLink}
                        />
                        {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ?
                            <MenuItemLink
                                to={'/users'}
                                primaryText={`- ${translate('application.menu.employees')}`}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                className={isMenuItemActive('/users', location.pathname) ? classes.activeMenuItemLink : classes.menuItemLink}
                            />
                            :
                            null
                        }
                        <MenuItemLink
                            to={'/profile/personal-data'}
                            primaryText={`- ${translate('application.menu.my_profile')}`}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            className={isMenuItemActive('/profile/personal-data', location.pathname) ? classes.activeMenuItemLink : classes.menuItemLink}
                        />
                        <MenuItemLink
                            to={'/settings'}
                            primaryText={`- ${translate('application.menu.settings')}`}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            className={isMenuItemActive('/settings', location.pathname) ? classes.activeMenuItemLink : classes.menuItemLink}
                        />
                    </SubMenu> */}
                    <MenuItemLink
                        to={`/companies/${decodedToken.company_id}/show`}
                        primaryText={`${translate('application.menu.company_settings')}`}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<CompanyIcon />}
                        className={classes.menuItemLink}
                    />
                    {permissions && (permissions.includes('ROLE_EXECUTIVE') || permissions.includes('ROLE_POWER_USER')) ?
                        <MenuItemLink
                            to={'/users'}
                            primaryText={`${translate('application.menu.users_management')}`}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                            leftIcon={<UserManagementIcon />}
                            className={classes.menuItemLink}
                        />
                        :
                        null
                    }
                    <MenuItemLink
                        to={'/profile/personal-data'}
                        primaryText={`${translate('application.menu.my_profile')}`}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<ProfileIcon />}
                        className={classes.menuItemLink}
                    />
                    <MenuItemLink
                        to={'/settings'}
                        primaryText={`${translate('application.menu.settings')}`}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<SettingsIcon />}
                        className={classes.menuItemLink}
                    />
                    <Divider />
                    <Responsive
                        small={logout}
                        medium={logout}
                        className={classes.menuItemLink}
                    />
                </Fragment>
            }
        </div>
    );
}

// const enhance = compose(
//     withRouter,
//     connect(
//         state => ({
//             open: state.admin.ui.sidebarOpen,
//             theme: state.theme
//         }),
//         {}
//     ),
//     translate,
//     withStyles(styles)
// );

export default withRouter(CustomMenu);
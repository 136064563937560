import React, { Fragment, useState, useEffect } from 'react';
import { FunctionField, ChipField, ArrayField, SingleFieldList, UrlField, Pagination, ReferenceManyField, Datagrid, TextField, Confirm, useTranslate, useMutation, useRefresh, useDataProvider, useNotify } from 'react-admin';

import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import InformationIcon from '@material-ui/icons/Info';

import { 
    Typography, 
    Tooltip,
    Button,
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow,
} from "@material-ui/core";

import withStyles from '@material-ui/core/styles/withStyles';

import { ProductDetails } from './ProductDetails';

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { ProjectProgress } from './ProjectProgress';
import { PreviewMessage } from './PreviewMessage';

import { Matrix } from './Matrix';
import { MatrixSummary } from './MatrixSummary';

import { ReactComponent as Gold } from './valuation_1.svg';
import { ReactComponent as Silver } from './valuation_2.svg';
import { ReactComponent as Bronze } from './valuation_3.svg';
import { ProjectNotification } from './ProjectNotification';

export const ATTACHMENT_URL_PART = "?attachment=";

const useStyles = makeStyles(theme => ({
    root: {
        height: '20em',
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftColumn: { flex: 1, marginRight: '1em' },
    rightColumn: { flex: 1, marginLeft: '1em' },
    singleColumn: { marginBottom: '2em' },
    fab: {
        margin: theme.spacing(1),
        backgroundColor: '#304d75'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'start',
        width: '100%'
    },
    accepted: {
        backgroundColor: '#c5e1a5',
        marginRight: 16,
        color: 'black',
        minWidth: 90,
    },
    rejected: {
        backgroundColor: 'rgb(171, 84, 141)',
        color: 'white',
        minWidth: 90,
    },
    neutral: {
        
    },
    label: {
        fontWeight: 'bold',
        fontSize: '1.15em',
        textDecoration: 'underline',
        marginBottom: '-8px'
    },
    green: { backgroundColor: '#dcedc8' },
    white: { backgroundColor: 'white', border: '1px solid #777' },
    tableContainer: {
        // marginLeft: 35,
        // marginBottom: -7,
        marginBottom: 20
    },
    table: {
        width: '100%'
    },
    tableFirstHeader: {
        width: '30%',
    },
    tableSecondHeader: {
        width: '70%',
    },
    tableFirstCell: {
        width: '30%',
        color: '#777',
    },
    tableSecondCell: {
        width: '70%',
        color: '#777',
    },
}));

const Expand = (props) => {

    const { record, theme } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableFirstHeader}>
                            {translate('resources.projects.internal_insight.product_name')}
                        </TableCell>
                        <TableCell className={classes.tableSecondHeader}>
                            {translate('resources.projects.internal_insight.use_case')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {record.products.length == 0 ?
                        <TableRow>
                            <TableCell style={{ color: theme === 'dark' ? '#bfbfbf' : '' }} className={classes.tableFirstCell}>
                                {translate('application.message.not_available')}
                            </TableCell>
                            <TableCell style={{ color: theme === 'dark' ? '#bfbfbf' : '' }} className={classes.tableSecondCell}>
                                {translate('application.message.not_available')}
                            </TableCell>
                        </TableRow>
                        :
                        record.products.map((product, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ color: theme === 'dark' ? '#bfbfbf' : '' }} className={classes.tableFirstCell}>
                                    {product.name}
                                </TableCell>
                                <TableCell style={{ color: theme === 'dark' ? '#bfbfbf' : '' }} className={classes.tableSecondCell}>
                                    {product.useCase}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </div>
    )
};

const ProductExpand = (props) => {

    const { record, project, theme } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Fragment>
            {record.products.length !== 0 && 
                <div className={classes.tableContainer}>
                    <Table className={classes.table}>
                        <TableBody>
                            {record.products.map((product, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ color: theme === 'dark' ? '#bfbfbf' : '' }} className={classes.tableFirstCell}>
                                            {translate('resources.projects.internal_insight.use_case')}
                                        </TableCell>
                                        <TableCell style={{ color: theme === 'dark' ? '#bfbfbf' : '' }} className={classes.tableSecondCell}>
                                            {product.useCase}
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
            }
            <MatrixSummary project={project} record={record} />
        </Fragment>
    )
};

const FeedbackButton = ({ record, feedback, label, className }) => {

    const refresh = useRefresh();

    const [saveFeedback, { loading }] = useMutation(
        {
            type: 'update',
            resource: 'internal-insights',
            payload: { id: record.id, data: { ...record, feedback: feedback } }
        },
        {
            onSuccess: ({ data }) => {
                
                refresh();
            },
        }
    );
    return (
            <Button 
                className={className}
                variant="contained" 
                onClick={saveFeedback} 
                disabled={loading}
            >
                {label}
            </Button>
        );
};

const ButtonGroup = ({ record }) => {

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Fragment>
            {record.feedback === 'NEUTRAL' ?
                <div style={{ display: 'flex', justifyItems: 'space-between' }}>
                    <FeedbackButton 
                        record={record} 
                        feedback={'ACCEPTED'} 
                        label={translate('resources.projects.internal_insight.accepted')} 
                        className={classes.accepted}
                    />
                    <FeedbackButton 
                        record={record} 
                        feedback={'REJECTED'} 
                        label={translate('resources.projects.internal_insight.rejected')} 
                        className={classes.rejected}
                    />
                </div>
                :
                <FeedbackButton 
                    record={record} 
                    feedback={'NEUTRAL'} 
                    label={translate('resources.projects.internal_insight.neutral')} 
                    className={classes.neutral}
                />
            }
        </Fragment>
    )
};

const CustomChipField = (props) => {

    const { record } = props;
    const classes = useStyles();

    var source = props.source;
    if (record.notFoundCapability) {
        source = 'notFoundCapability';
    }

    return (

        record && (record.foundCapability || record.notFoundCapability) ? 
            <ChipField 
                
                className={classnames({
                    [classes.green]: record.declaredCapability,
                    [classes.white]: record.notFoundCapability,
                })}
                {...props} 
                source={source}
            />
            :
            null
    )
}

const CapabilityTable = (props) => {

    const { statuses, filter, record, theme } = props;

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    return (
        <ReferenceManyField 
            {...props}
            reference="internal-insights" 
            target= "projectId" 
            allowEmpty
            perPage={25}
            pagination={<Pagination rowsPerPageOptions={[25]} />}
            filter={{ statuses: statuses, filter: filter }} 
        >
            <Datagrid expand={<Expand theme={theme} />} rowStyle={rowStyle}>
                <TextField source="score" label="resources.projects.meta_matching.score" />
                <TextField source="companyName" label="resources.recommendations.fields.providerName" />
                <ProductDetails style={{ color: theme === 'dark' ? 'white' : '' }} source="url" label={parseInt(decodedToken.company_id) == parseInt(process.env.REACT_APP_ADOIT_ID) ? "" : "resources.projects.meta_matching.top_hit_url" } language={record.language} />
                <ArrayField source="searchResults" label="resources.projects.internal_insight.capabilities">
                    <SingleFieldList linkType={false}>
                        <CustomChipField source="foundCapability" />
                    </SingleFieldList>
                </ArrayField>
                <ButtonGroup />
            </Datagrid>
        </ReferenceManyField>
    )
}
const CapabilityGrid = (props) => {

    const translate = useTranslate();

    return (
        <Fragment>
            <CapabilityTable {...props} filter="DECLARED_CAPABILITIES" />
            <Typography style={{ marginBottom: 20 }}>{translate('resources.projects.internal_insight.capability_title')}:</Typography>
            <CapabilityTable {...props} filter="NOT_DECLARED_CAPABILITIES" />
        </Fragment>
    )
};

const SkipButton = ({ record, type }) => {

    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const payload = {
        feedback: 'SKIP_' + record.step + '_MIXED'
    }
    
    const handleClick = () => {

        setLoading(true);

        dataProvider
            .update('projects', { id: `${record.id}/internal-insight-feedback`, data: { ...payload } })
            .then(response => {

                setOpen(false);
                setLoading(false);
                setVisible(true);
            })
            .catch(error => {
                
                setOpen(false);
                setLoading(false);
                notify(`${error.message}`, 'warning');
            });
    }

    return (
        <Fragment>
            <Button color="primary" onClick={() => setOpen(true)}>
                {translate('resources.suggestions.dialog.field_values.feedback.skip_' + type.toLowerCase())}
            </Button>
            <Confirm
                isOpen={open}
                title={'resources.suggestions.dialog.field_values.feedback.skip_' + type.toLowerCase()}
                content="resources.projects.internal_insight.next_step_text"
                onConfirm={handleClick}
                loading={loading}
                onClose={() => setOpen(false)}
            />
            {/* <ProjectProgress project={record} show={visible} /> */}
            <ProjectNotification project={record} show={visible} />
        </Fragment>
    )
};

const StyledTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 620,
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const rowStyle = (record, index) => ({
    
    backgroundColor: record && record.highlighted ? '#e6f9ff' : record.feedback === 'REJECTED' ? 
        '#eee' 
        : 
        record.feedback === 'ACCEPTED' ? 
            '#efe' 
            : 
            '',
});

const ValuationField = (props) => {

    const { project, record } = props;


    if (!project.valuationEnabled) {
        return null;
    }

    let result = null;
    if (record && record.valuation) {

        switch (record.valuation) {
            case "1":
                result = <Gold />;
                break;
            case "2":
                result = <Silver />;
                break;
            case "3":
                result = <Bronze />;
                break;
        
            default:
                break;
        }
    }

    return result;
}

export const isOnlyInternalProducts = (record, type) => {

    if (!type) {
        type = record.offering;
    }

    let onlyInternalProducts = false;
    if (record && record.matchingSetup) {
        let product = 'PRODUCT';

        if (type) {
            if (type == 'SOFTWARE') {
                onlyInternalProducts = product == record.matchingSetup.softwareSearchType;
            } else if (type == 'SERVICE') {
                onlyInternalProducts = product == record.matchingSetup.vendorSearchType;
            }
        } else {
            onlyInternalProducts = product == record.matchingSetup.vendorSearchType && product == record.matchingSetup.softwareSearchType;
        }
    }
    return onlyInternalProducts;
}

const InternalInsight = (props) => {

    const { record, theme, type, matrixDisplayed } = props; // project

    const classes = useStyles();
    const translate = useTranslate();

    const [viewMode, setViewMode] = useState('DESCRIPTION');
    const [onlyInternalProducts, setOnlyInternalProducts] = useState();

    var step = null;

    const statuses = [];

    const decodedToken = JSON.parse(localStorage.getItem('decoded_token'));

    if (type === "STEP_1") {
        statuses.push('TOP');
        statuses.push('PREFERRED');
        step = "STEP_1";
    } else if (type === "STEP_2") {
        statuses.push('NICHE');
        statuses.push('OTHER');
        step = "STEP_2";
    } else if (type === "MIXED") {
        statuses.push('TOP');
        statuses.push('PREFERRED');
        statuses.push('NICHE');
        statuses.push('OTHER');
        step = "STEP_2";
    }

    useEffect(() => {

        if (record) {
            let onlyInternalProducts = isOnlyInternalProducts(record);
            setOnlyInternalProducts(onlyInternalProducts);
        }
    }, [record]);
    

    return (
        <Fragment>
            {!record.internalInsightStarted && 
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    {record.step === 'STEP_1' && 
                        <Fragment>
                            <Typography style={{ display: 'inline' }}>
                                {translate('resources.projects.internal_insight.text_1_only_favorite_providers_1', { company_name: JSON.parse(localStorage.getItem('decoded_token')).company_name })}
                            </Typography>
                            <Typography style={{ display: 'inline', fontWeight: 'bold' }}>{translate('resources.projects.internal_insight.text_1_only_favorite_providers_2')}</Typography>
                            <Typography style={{ display: 'inline' }}>{translate('resources.projects.internal_insight.text_1_only_favorite_providers_3')}</Typography>
                        </Fragment>
                    }
                    {record.step === 'STEP_2' && 
                        <Typography style={{ display: 'inline' }}>
                            {translate('resources.projects.internal_insight.text_1', { company_name: JSON.parse(localStorage.getItem('decoded_token')).company_name })}
                        </Typography>
                    }
                    {record.step === 'STEP_2' &&
                        <StyledTooltip
                            title={
                                <Fragment>
                                    <Typography color="inherit">{translate('resources.projects.internal_insight.text_2_title')}</Typography>
                                    <ul>
                                        <li>{translate('resources.projects.internal_insight.text_2_1')}</li>
                                        <li>{translate('resources.projects.internal_insight.text_2_2')}</li>
                                        <li>{translate('resources.projects.internal_insight.text_2_3')}</li>
                                        <li>{translate('resources.projects.internal_insight.text_2_4')}</li>
                                    </ul>
                                    <Typography color="inherit">{translate('resources.projects.internal_insight.text_3')}</Typography>
                                </Fragment>
                            }
                        >
                            <InformationIcon style={{ color: '#39a1de', position: 'relative', top: 5, marginLeft: 5 }} />
                        </StyledTooltip>
                    }
                </div>
            }

            {!record.internalInsightStarted ? 

                <Fragment>
                    {matrixDisplayed && viewMode === 'DESCRIPTION' &&
                        <Button style={{ marginBottom: 15 }} variant="outlined" onClick={() => setViewMode('MATRIX')}>{translate('resources.suggestions.action.show_matrix')}</Button>
                    }

                    {viewMode === 'MATRIX' && 
                        <div style={{ marginBottom: 25 }}>
                            <Button style={{ marginBottom: 15 }} variant="outlined" onClick={() => setViewMode('DESCRIPTION')}>{translate('resources.suggestions.action.hide_matrix')}</Button>
                            <Matrix record={record} type="external-matrix" step={step} />
                        </div>
                    }
                    {onlyInternalProducts ? (
                            <ReferenceManyField 
                                {...props}
                                reference="internal-insights" 
                                target= "projectId" 
                                allowEmpty
                                perPage={25}
                                pagination={<Pagination rowsPerPageOptions={[25]} />}
                                filter={{ statuses: statuses, state: 'FITTING' }} 
                                // sort={{ field: 'feedback', order: 'ASC' }}
                            >
                                <Datagrid expand={<ProductExpand project={record} theme={theme} />} rowStyle={rowStyle}>
                                    <FunctionField label="Score" render={record => `${record.score} / 10`} />
                                    <TextField source="productName" label="resources.recommendations.fields.product_name" />
                                    <TextField source="companyName" label="resources.recommendations.fields.providerName" />
                                    <ValuationField project={record} />
                                    <ProductDetails style={{ color: theme === 'dark' ? 'white' : '' }} source="url" label={parseInt(decodedToken.company_id) == parseInt(process.env.REACT_APP_ADOIT_ID) ? "" : "resources.recommendations.fields.further_information" } language={record.language} />
                                    <ButtonGroup />
                                </Datagrid>
                            </ReferenceManyField>
                        ) : (
                            <ReferenceManyField 
                                {...props}
                                reference="internal-insights" 
                                target= "projectId" 
                                allowEmpty
                                perPage={25}
                                pagination={<Pagination rowsPerPageOptions={[25]} />}
                                filter={{ statuses: statuses, state: 'FITTING' }} 
                                // sort={{ field: 'feedback', order: 'ASC' }}
                            >
                                <Datagrid expand={<Expand theme={theme} />} rowStyle={rowStyle}>
                                    <TextField source="companyName" label="resources.recommendations.fields.providerName" />
                                    <ValuationField project={record} />
                                    <UrlField style={{ color: theme === 'dark' ? 'white' : '' }} source="companyUrl" label="resources.recommendations.fields.website" target="_blank" />
                                    <ProductDetails style={{ color: theme === 'dark' ? 'white' : '' }} source="url" label={parseInt(decodedToken.company_id) == parseInt(process.env.REACT_APP_ADOIT_ID) ? "" : "resources.projects.meta_matching.top_hit_url" } language={record.language} />
                                    <ButtonGroup />
                                </Datagrid>
                            </ReferenceManyField>
                        )    
                    }
                    {/* {record.capabilityEnabled && type === 'STEP_1' ? 
                            <CapabilityGrid {...props} theme={theme} statuses={statuses} />
                            :
                            record.onlyInternalProducts ? (
                                <ReferenceManyField 
                                    {...props}
                                    reference="internal-insights" 
                                    target= "projectId" 
                                    allowEmpty
                                    perPage={25}
                                    pagination={<Pagination rowsPerPageOptions={[25]} />}
                                    filter={{ statuses: statuses, state: 'FITTING' }} 
                                    // sort={{ field: 'feedback', order: 'ASC' }}
                                >
                                    <Datagrid expand={<ProductExpand project={record} theme={theme} />} rowStyle={rowStyle}>
                                        <FunctionField label="Score" render={record => `${record.score} / 10`} />
                                        <TextField source="productName" label="resources.recommendations.fields.product_name" />
                                        <TextField source="companyName" label="resources.recommendations.fields.providerName" />
                                        <ValuationField project={record} />
                                        <ProductDetails style={{ color: theme === 'dark' ? 'white' : '' }} source="url" label={parseInt(decodedToken.company_id) == parseInt(process.env.REACT_APP_ADOIT_ID) ? "" : "resources.recommendations.fields.further_information" } language={record.language} />
                                        <ButtonGroup />
                                    </Datagrid>
                                </ReferenceManyField>
                            ) : (
                                <ReferenceManyField 
                                    {...props}
                                    reference="internal-insights" 
                                    target= "projectId" 
                                    allowEmpty
                                    perPage={25}
                                    pagination={<Pagination rowsPerPageOptions={[25]} />}
                                    filter={{ statuses: statuses, state: 'FITTING' }} 
                                    // sort={{ field: 'feedback', order: 'ASC' }}
                                >
                                    <Datagrid expand={<Expand theme={theme} />} rowStyle={rowStyle}>
                                        <TextField source="companyName" label="resources.recommendations.fields.providerName" />
                                        <ValuationField project={record} />
                                        <UrlField style={{ color: theme === 'dark' ? 'white' : '' }} source="companyUrl" label="resources.recommendations.fields.website" target="_blank" />
                                        <ProductDetails style={{ color: theme === 'dark' ? 'white' : '' }} source="url" label={parseInt(decodedToken.company_id) == parseInt(process.env.REACT_APP_ADOIT_ID) ? "" : "resources.projects.meta_matching.top_hit_url" } language={record.language} />
                                        <ButtonGroup />
                                    </Datagrid>
                                </ReferenceManyField>
                            )
                                
                            
                             
                    } */}
                </Fragment>   
                :
                <div>
                    <Typography variant="h6">{translate('resources.projects.internal_insight.title')}</Typography>
                    <Typography>{translate('resources.projects.internal_insight.text_4')}</Typography>
                </div>
            }

            {!record.internalInsightStarted && (type == record.step || (type == 'MIXED' && record.step == 'STEP_2')) && 
                <div>
                    {record.state === 'ANALYSIS_RUNNING' && <PreviewMessage message={translate('resources.projects.message.preview')} />}
                    <div style={{ textAlign: 'center' }}>
                        <SkipButton record={record} type={type} />
                    </div>
                </div>
            }
        </Fragment>
    );
}

const enhance = compose(
    connect(
        state => ({
            theme: state.theme
        }),
        {}
    ),
);

export default enhance(InternalInsight);